<template>
  <img :src="computedSrc" class="ImageCmp" :alt="dataImageAlt" />
</template>

<script>
import { mdAndUp, lgAndUp, xlAndUp } from '@/helpers/breakpoints.js';

export default {
  name: 'ImageCmp',
  props: {
    dataDesktopImage: {
      type: String,
      default: null,
    },
    dataMobileImage: {
      type: String,
      default: null,
    },
    dataImageAlt: {
      type: String,
      default: '',
    },
    dataSwitchBreakpoint: {
      type: String,
      default: 'md',
    },
  },
  setup() {
    return { mdAndUp, lgAndUp, xlAndUp };
  },
  computed: {
    computedSrc() {
      let src;
      if (this.dataSwitchBreakpoint === 'xl') {
        src = this.xlAndUp
          ? this.dataDesktopImage
            ? this.dataDesktopImage
            : this.dataMobileImage
          : this.dataMobileImage
          ? this.dataMobileImage
          : this.dataDesktopImage;
      } else if (this.dataSwitchBreakpoint === 'lg') {
        src = this.lgAndUp
          ? this.dataDesktopImage
            ? this.dataDesktopImage
            : this.dataMobileImage
          : this.dataMobileImage
          ? this.dataMobileImage
          : this.dataDesktopImage;
      } else {
        src = this.mdAndUp
          ? this.dataDesktopImage
            ? this.dataDesktopImage
            : this.dataMobileImage
          : this.dataMobileImage
          ? this.dataMobileImage
          : this.dataDesktopImage;
      }

      if (src) {
        return src;
      } else {
        return `${this.$context.staticRoot}project/img/placeholder.png`;
      }
    },
  },
};
</script>
