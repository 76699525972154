<template>
  <input-checkbox-cmp
    class="InputCheckboxCmpExtended"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </input-checkbox-cmp>
</template>

<script>
import { InputCheckboxCmp } from '@frankhoodbs/lib';

export default {
  name: 'InputCheckboxCmpExtended',
  components: { InputCheckboxCmp },
};
</script>

<style lang="scss" scoped>
.InputCheckboxCmpExtended.form-group__wrapper {
  --form-check-label-padding-left: 16px;
  --form-check-label-color: #{$color-blue};
  --form-check-checkbox-dim: 20px;
  --form-check-checkbox-border-color: #{$color-grey-600};
  --form-check-checkbox-border-radius: 2px;
  --form-check-checked-color: #{$color-blue};
  --form-check-checked-bullet-distance: 6px;
  --form-element-disabled-bg-color: #{$color-grey-200};
  --form-element-label-disabled-color: #{$color-grey-400};
  --form-check-checkbox-hover-border-color: #{$color-blue};
  --form-check-checkbox-hover-bg-color: #{$color-grey-200-50};
  --form-check-label-hover-color: #{$color-blue};

  ::v-deep .form-group {
    .form-check__wrapper {
      .form-check {
        .form-check-label {
          @include override-responsive-typography-properties(
            $font-primary,
            a-support,
            --form-check-label
          );

          &::after {
            left: var(--form-check-checked-bullet-distance);
            width: calc(
              var(--form-check-checkbox-dim) -
                (var(--form-check-checked-bullet-distance) * 2)
            );
            height: calc(
              var(--form-check-checkbox-dim) -
                (var(--form-check-checked-bullet-distance) * 2)
            );
            background-color: var(--form-check-checked-color);
            border-radius: var(--form-check-checkbox-border-radius);
            margin-top: 0;
            margin-left: 0;
            border: 0 !important;
            transform: translate(0, -50%) rotate(0);
          }
        }
      }
    }
  }
}
</style>
