<template>
  <div :class="['TitleSeparatorCmp', { inline: switchBreakpoint }]">
    <div v-if="hasSlot($slots.title, $scopedSlots.title)" class="title-wrapper">
      <slot name="title" />
    </div>
    <hr />
    <div v-if="hasSlot($slots.text, $scopedSlots.text)" class="text-wrapper">
      <slot name="text" />
    </div>
    <div v-if="hasSlot($slots.cta, $scopedSlots.cta)" class="cta-wrapper">
      <slot name="cta" />
    </div>
    <div
      v-if="hasSlot($slots['social-share'], $scopedSlots['social-share'])"
      class="social-share-wrapper"
    >
      <slot name="social-share" />
    </div>
  </div>
</template>

<script>
import { hasSlot } from '@/helpers/cms-support';
import { lgAndUp, mdAndUp, xlAndUp } from '@/helpers/breakpoints';

export default {
  name: 'TitleSeparatorCmp',
  props: {
    dataSwitchBreakpoint: {
      type: String,
      default: 'md',
    },
  },
  setup() {
    return { hasSlot, mdAndUp, lgAndUp, xlAndUp };
  },
  computed: {
    switchBreakpoint() {
      if (this.dataSwitchBreakpoint === 'xl') {
        return this.xlAndUp;
      } else if (this.dataSwitchBreakpoint === 'lg') {
        return this.lgAndUp;
      } else {
        return this.mdAndUp;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.TitleSeparatorCmp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--title-separator-color, $color-blue);

  .title-wrapper {
    @include responsive-typography-properties(
      $font-secondary,
      n-heading-2,
      --title-separator-title-wrapper
    );
    flex-shrink: 0;
    margin: 0 0 16px 0;
  }

  .text-wrapper {
    @include responsive-typography-properties(
      $font-primary,
      a-paragraph-3,
      --title-separator-text-wrapper
    );
  }

  .text-wrapper,
  .cta-wrapper,
  .social-share-wrapper {
    flex-shrink: 0;
    margin: 8px 0 0 0;
  }

  .cta-wrapper {
    max-width: 100%;
  }

  .social-share-wrapper {
    padding: 22.5px 0;
  }

  hr {
    flex-grow: 1;
    width: 100%;
  }

  &.inline:not(.latest) {
    flex-direction: row;
    align-items: center;

    .title-wrapper {
      margin: 0 24px 0 0;
    }

    .text-wrapper {
      @include media-breakpoint-up(md) {
        // avoid text overflow on row layout maintaining flex-shrink: 0
        max-width: 50%;
      }

      @include media-breakpoint-up(lg) {
        max-width: 63.33333333%;
      }
    }

    .text-wrapper,
    .cta-wrapper,
    .social-share-wrapper {
      margin: 0 0 0 24px;
    }
  }

  &.latest {
    .title-wrapper {
      @include override-responsive-typography-properties(
        $font-secondary,
        n-heading-3,
        --title-separator-title-wrapper
      );
    }
  }
}
</style>
