var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"CookingTipsBestPracticesDetailPage"},[_c('hero-cmp',{staticClass:"colorful-bg",scopedSlots:_vm._u([{key:"type",fn:function(){return [_vm._t("service-type")]},proxy:true},{key:"title",fn:function(){return [_vm._t("service-hero-title")]},proxy:true}],null,true)}),_c('div',{staticClass:"container pt-r"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-10"},[_vm._t("service-breadcrumbs")],2)])]),_c('focus-items-cmp',{class:[
      'pb-l',
      {
        'with-tab-menu': !_vm.config.is_detailed_tabs,
        'with-tab-menu-detailed': _vm.config.is_detailed_tabs,
      } ],attrs:{"data-active-tab-id":"1","data-tabs":_vm.config.tabs.map(function (e, index) { return ({
        id: '' + (index + 1),
        label: e.tab_label,
      }); })},scopedSlots:_vm._u([_vm._l((_vm.config.tabs.map(function (e, index) { return (Object.assign({}, e,
        {id: '' + (index + 1)})); })),function(tab,j){return {key:("tab-" + (tab.id) + "-content"),fn:function(){return [_c('div',{key:("tab-content-" + (tab.id) + "-" + _vm._uid)},[_c('focus-items-tab-content-cmp',{staticClass:"with-grid",scopedSlots:_vm._u([(tab.description)?{key:"heading",fn:function(){return [_vm._t(("tab-description-" + (j + 1)))]},proxy:true}:null,{key:"focus-items",fn:function(){return _vm._l((tab.items_attribute),function(item,i){return _c('icon-text-info-cmp',{key:("focus-item-" + i + "-" + (tab.id) + "-" + _vm._uid),class:[{ detailed: _vm.config.is_detailed_tabs }],attrs:{"data-unit-value":item.value},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('svg',[_c('use',{attrs:{"href":("#" + (item.icon))}})])]},proxy:true},{key:"unit",fn:function(){return [_vm._v(" "+_vm._s(item.value_measure_unit)+" ")]},proxy:true},{key:"text",fn:function(){return [_vm._v(" "+_vm._s(item.value_description)+" ")]},proxy:true}],null,true)})})},proxy:true},(tab.lower_description)?{key:"footer",fn:function(){return [_vm._t(("tab-lower-description-" + (j + 1)))]},proxy:true}:null],null,true)}),(tab.youtube_video_link)?_c('media-player-cmp',{staticClass:"highlighted pt-l",attrs:{"data-bootstrap-container-classes":['container-fluid', 'px-0'],"data-youtube-video-id":tab.youtube_video_link,"data-background-image-src":tab.video_poster}},[(tab.video_description)?[_vm._t(("tab-video-description-" + (j + 1)))]:_vm._e()],2):_vm._e(),(tab.image)?_c('div',{staticClass:"container-fluid pt-l px-0"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-8"},[_c('main-image-cmp',{scopedSlots:_vm._u([(tab.image_description)?{key:"figcaption",fn:function(){return [_vm._t(("tab-image-description-" + (j + 1)))]},proxy:true}:null],null,true)},[_vm._t(("tab-image-" + (j + 1)))],2)],1)])]):_vm._e()],1)]},proxy:true}})],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }