var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"IngredientsStepsListCmp"},[_c('div',{staticClass:"title-wrapper"},[_vm._t("title-separator")],2),_c('div',{staticClass:"content-wrapper"},[(_vm.hasSlot(_vm.$slots['ingredients'], _vm.$scopedSlots['ingredients']))?_c('div',{class:[
        'ingredients',
        'a-paragraph-2',
        {
          twoCols: !_vm.hasSlot(
            _vm.$slots['card-product'],
            _vm.$scopedSlots['card-product']
          ),
        } ]},[_vm._t("ingredients")],2):_vm._e(),(_vm.hasSlot(_vm.$slots['procedure'], _vm.$scopedSlots['procedure']))?_c('div',{staticClass:"howToList"},[_vm._t("procedure")],2):_vm._e(),(_vm.hasSlot(_vm.$slots['card-product'], _vm.$scopedSlots['card-product']))?_c('div',{staticClass:"card-wrapper"},[_vm._t("card-product")],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }