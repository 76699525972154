<template>
  <div class="GigyaConfig d-none" aria-hidden="true" />
</template>

<script>
import { castPropToType } from '@/helpers/cms-support';

export default {
  name: 'GigyaConfig',
  props: {
    dataConfig: {
      type: [Object, String],
      required: true,
    },
  },
  setup() {
    return { castPropToType };
  },
  computed: {
    conf() {
      return this.dataConfig
        ? this.castPropToType(this.dataConfig, 'object')
        : null;
    },
  },
  mounted() {
    if (this.conf) {
      this.$store.dispatch('gigya/setGigyaInitData', this.conf);
    }
  },
};
</script>

<style scoped></style>
