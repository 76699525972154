var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"FocusItemsCmp"},[_c('div',{staticClass:"inner"},[(_vm.themeVariant === 'with-tab-menu-with-image-bg')?_c('figure',{staticClass:"background"},[_vm._t("image"),_c('figcaption',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-10"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"figcaption-wrapper"},[_vm._t("figcaption")],2),_c('div',{staticClass:"logo-wrapper"},[_vm._t("logo")],2)])])])])])],2):_vm._e(),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{class:[
            {
              'col-12 col-md-8 col-lg-12': _vm.themeVariant === 'with-stepper',
              'col-12 col-md-10':
                _vm.themeVariant === 'with-icons' ||
                _vm.themeVariant === 'with-numbers' ||
                _vm.themeVariant === 'with-images',
              'col-12':
                _vm.themeVariant === 'with-tab-menu' ||
                _vm.themeVariant === 'with-tab-menu-detailed' ||
                _vm.themeVariant === 'with-tab-menu-with-image-bg',
            } ]},[_c('div',{ref:"$wrapper",class:['wrapper', ( _obj = {}, _obj[_vm.dataBoxColor] = !_vm.boxColor, _obj )],style:({ '--box-bg-color': _vm.boxColor })},[(_vm.themeVariant === 'with-stepper')?_vm._t("steps"):_vm._e(),(_vm.themeVariant === 'with-numbers')?[_vm._t("heading"),_c('slider-cmp-extended',{attrs:{"data-bootstrap-container-classes":[
                  'container-fluid',
                  'px-0' ],"data-component-slides":true,"data-options":{
                  observer: true,
                  navigation: false,
                },"data-disabled-from":768}},[_c('template',{slot:"custom-swipe-icon"},[_c('svg',[_c('use',{attrs:{"href":"#icon-scroller"}})])]),_c('template',{slot:"components"},[_vm._t("numbers")],2)],2)]:_vm._e(),(
                _vm.themeVariant === 'with-icons' ||
                _vm.themeVariant === 'with-images'
              )?_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-custom-md-8-10 content-wrapper"},[_vm._t("heading"),(_vm.themeVariant === 'with-icons')?_c('slider-cmp-extended',{attrs:{"data-bootstrap-container-classes":[
                    'container-fluid',
                    'px-0' ],"data-component-slides":true,"data-options":{
                    observer: true,
                    navigation: false,
                  },"data-disabled-from":768}},[_c('template',{slot:"custom-swipe-icon"},[_c('svg',[_c('use',{attrs:{"href":"#icon-scroller"}})])]),_c('template',{slot:"components"},[_vm._t("icons")],2)],2):(_vm.themeVariant === 'with-images')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-custom-md-4-8 images-wrapper"},[_vm._t("first-image")],2),_c('div',{staticClass:"col-12 col-custom-md-4-8 images-wrapper"},[_vm._t("second-image")],2)]):_vm._e()],2)]):_vm._e(),(
                _vm.themeVariant === 'with-tab-menu' ||
                _vm.themeVariant === 'with-tab-menu-detailed' ||
                _vm.themeVariant === 'with-tab-menu-with-image-bg'
              )?_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-10"},[_c('tabs-cmp',{attrs:{"data-active-tab-id":_vm.dataActiveTabId,"data-tabs":_vm.tabs},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(data){return [_vm._t(name,null,null,data)]}}})],null,true)})],1)]):_vm._e()],2)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }