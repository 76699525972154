<template>
  <div class="CtaBoxCmp">
    <div
      :class="[
        'wrapper',
        { 'shadow-soft': themeVariant !== 'squared' || lgAndDown },
      ]"
    >
      <!-- content wrapped in tag a or router-link expected -->
      <slot />
      <div v-if="themeVariant !== 'squared' || lgAndDown" class="icon-wrapper">
        <svg>
          <use href="#icon-arrow-right" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { lgAndDown } from '@/helpers/breakpoints';

export default {
  name: 'CtaBoxCmp',
  setup() {
    return { lgAndDown };
  },
  data() {
    return {
      themeVariant: null,
    };
  },
  mounted() {
    this.themeVariant = this.$el.classList.contains('squared')
      ? 'squared'
      : null;
  },
};
</script>

<style lang="scss" scoped>
.CtaBoxCmp {
  .wrapper {
    position: relative;
    height: 100%;
    border-radius: 8px;
    border: 1px solid $color-blue-10;

    & > a,
    & > router-link {
      display: block;
      height: 100%;
    }

    // not the icon-wrapper inside the title-text
    & > .icon-wrapper {
      position: absolute;
      bottom: 24px;
      right: 24px;
      display: none;
      cursor: pointer;

      @include media-breakpoint-up(md) {
        top: 50%;
        margin-top: -12px;
      }

      svg {
        width: 24px;
        height: 24px;
        color: $color-blue;
      }
    }

    & > a + .icon-wrapper,
    & > router-link + .icon-wrapper {
      display: block;
    }

    ::v-deep .TitleTextCmp {
      align-items: flex-start;
      --title-text-padding: 24px;
      --title-text-flex-direction: row;
      --title-text-flex-wrap: wrap;
      --title-text-text-align: left;
      --title-text-icon-wrapper-padding: 0 0 16px;
      @include override-responsive-typography-properties(
        $font-secondary,
        n-paragraph-1,
        --title-text-title-wrapper
      );
      @include override-responsive-typography-properties(
        $font-primary,
        a-paragraph-3,
        --title-text-text-wrapper
      );
      --title-text-title-wrapper-padding: 0 0 16px;
      --title-text-text-wrapper-padding: 0;

      @include media-breakpoint-up(md) {
        justify-content: flex-end;
        --title-text-padding: 32px;
        --title-text-icon-wrapper-padding: 0 24px 0 0;
      }

      @include media-breakpoint-up(lg) {
        --title-text-icon-wrapper-icon-width: 66px;
        --title-text-icon-wrapper-icon-height: 66px;
      }

      @include media-breakpoint-up(xl) {
        align-items: center;
      }

      .icon-wrapper {
        flex-shrink: 0;

        @include media-breakpoint-up(md) {
          position: absolute;
          left: 32px;
        }
      }

      .title-wrapper {
        width: 100%;

        @include media-breakpoint-up(md) {
          width: calc(100% - 72px);
        }

        @include media-breakpoint-up(lg) {
          width: calc(100% - 90px);
        }
      }

      .text-wrapper {
        width: 100%;

        @include media-breakpoint-up(md) {
          width: calc(100% - 72px);
        }

        @include media-breakpoint-up(lg) {
          width: calc(100% - 90px);
        }
      }
    }

    & > a ::v-deep .TitleTextCmp,
    & > router-link ::v-deep .TitleTextCmp {
      --title-text-padding: 24px 72px 24px 24px;

      @include media-breakpoint-up(md) {
        --title-text-padding: 32px 72px 32px 32px;
      }
    }
  }

  &:not(.disabled) {
    .wrapper {
      transition: border-color 1s ease;

      &:hover {
        border-color: $color-blue;
      }
    }
  }

  &.squared {
    @include media-breakpoint-up(xl) {
      height: 100%;

      .wrapper {
        border: 2px solid $color-blue-10;

        ::v-deep .TitleTextCmp {
          justify-content: flex-start;
          --title-text-padding: 24px;
          --title-text-text-align: left;
          --title-text-icon-wrapper-padding: 0 0 24px;
          @include override-responsive-typography-properties(
            $font-secondary,
            n-paragraph-1,
            --title-text-title-wrapper
          );
          @include override-responsive-typography-properties(
            $font-primary,
            a-paragraph-3,
            --title-text-text-wrapper
          );

          .icon-wrapper {
            position: relative;
            left: auto;
          }

          .title-wrapper {
            width: 100%;
          }

          .text-wrapper {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
