<template>
  <section class="FaceToFaceCmp">
    <figure v-if="themeVariant === 'image-bg'" class="background">
      <slot name="image" />
    </figure>
    <div class="container">
      <div
        :class="[
          'row',
          'justify-content-center',
          {
            'flex-wrap-reverse': themeVariant === 'with-image',
            'flex-md-wrap': themeVariant === 'with-image',
          },
        ]"
      >
        <div
          v-if="hasSlotSlim(_self, 'default')"
          :class="[
            'col-12 col-md-10',
            {
              'col-lg-8': themeVariant === 'card-list',
              'col-lg-10 col-xl-8': themeVariant !== 'card-list',
            },
          ]"
        >
          <slot />
        </div>
        <div
          v-if="themeVariant === 'with-image'"
          class="col-12 col-md-10 col-lg-10 col-xl-8"
        >
          <figure class="image-wrapper shadow-hard">
            <slot name="image" />
          </figure>
        </div>
      </div>
      <div
        v-if="themeVariant === 'with-list' || themeVariant === 'with-carousel'"
        class="row"
      >
        <div class="col-12">
          <aspect-ratio-box-cmp
            class="image-below"
            :style="computedImageStyle"
            :data-aspect-ratio="
              xlAndUp
                ? '32:15'
                : lgAndUp
                ? '113:60'
                : mdAndUp
                ? '81:40'
                : '32:25'
            "
            :data-padding-orientation="
              themeVariant === 'with-list' ? 'bottom' : 'top'
            "
          >
            <div class="row">
              <div
                :class="[
                  'col-12 offset-md-1',
                  {
                    'col-md-10': themeVariant === 'with-list',
                    'col-md-11': themeVariant === 'with-carousel',
                  },
                ]"
              >
                <ul
                  v-if="
                    themeVariant === 'with-list' &&
                    hasSlotSlim(_self, 'list-items')
                  "
                  ref="$list"
                  class="list"
                  :style="computedListStyle"
                >
                  <slot name="list-items" />
                </ul>
                <div
                  v-if="
                    themeVariant === 'with-carousel' &&
                    hasSlotSlim(_self, 'carousel')
                  "
                  ref="$carousel"
                  class="carousel"
                >
                  <slider-cmp-extended
                    class="slides-fit-content"
                    :data-bootstrap-container-classes="[
                      'container-fluid',
                      'px-0',
                    ]"
                    :data-no-gutter="true"
                    :data-component-slides="true"
                    :data-options="{
                      observer: true,
                      navigation: false,
                    }"
                  >
                    <template slot="custom-swipe-icon">
                      <svg>
                        <use href="#icon-scroller" />
                      </svg>
                    </template>
                    <template slot="components">
                      <slot name="carousel" />
                    </template>
                  </slider-cmp-extended>
                </div>
              </div>
            </div>
            <slot name="image" />
          </aspect-ratio-box-cmp>
        </div>
      </div>
      <div v-if="themeVariant === 'card-list'" class="row">
        <div class="col-12 col-md-10 offset-md-1 col-lg-12 offset-lg-0">
          <div
            class="row row-cols-2 row-cols-md-3 row-cols-lg-6 row-cols-xl-6 justify-content-center"
          >
            <slot name="columns" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AspectRatioBoxCmp from './aspect-ratio-box-cmp';
import SliderCmpExtended from './slider-cmp-extended';
import { xsOnly, mdAndUp, lgAndUp, xlAndUp } from '@/helpers/breakpoints';
import { hasSlotSlim } from '@/helpers/cms-support';

export default {
  name: 'FaceToFaceCmp',
  components: { AspectRatioBoxCmp, SliderCmpExtended },
  setup() {
    return { xsOnly, mdAndUp, lgAndUp, xlAndUp, hasSlotSlim };
  },
  data() {
    return {
      themeVariant: null,
      listHeight: 0,
      carouselHeight: 0,
      resizeObserver: null,
    };
  },
  computed: {
    computedImageStyle() {
      if (this.themeVariant === 'with-list') {
        if (this.xsOnly) {
          return { 'margin-top': `${this.listHeight - 40}px` };
        } else {
          return { 'margin-top': `${this.listHeight * 0.5}px` };
        }
      }
      if (this.themeVariant === 'with-carousel') {
        if (this.xsOnly) {
          return { 'margin-bottom': `${this.carouselHeight - 40}px` };
        } else {
          return { 'margin-bottom': `${this.carouselHeight * 0.5 + 41}px` }; // 41px half of carousel pagination height
        }
      }

      return null;
    },
    computedListStyle() {
      if (this.xsOnly) {
        return { 'margin-top': `${(this.listHeight - 40) * -1}px` };
      }

      return null;
    },
  },
  mounted() {
    this.themeVariant = this.$el.classList.contains('image-bg')
      ? 'image-bg'
      : this.$el.classList.contains('with-image')
      ? 'with-image'
      : this.$el.classList.contains('with-list')
      ? 'with-list'
      : this.$el.classList.contains('with-carousel')
      ? 'with-carousel'
      : this.$el.classList.contains('card-list')
      ? 'card-list'
      : 'image-bg';

    if (
      this.themeVariant === 'with-list' ||
      this.themeVariant === 'with-carousel'
    ) {
      this.resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.contentRect) {
            if (this.themeVariant === 'with-list') {
              this.listHeight = entry.contentRect.height;
            }
            if (this.themeVariant === 'with-carousel') {
              this.carouselHeight = entry.contentRect.height;
            }
          }
        }
      });
      this.checkRefHeight();
    }
  },
  beforeDestroy() {
    if (
      this.themeVariant === 'with-list' ||
      this.themeVariant === 'with-carousel'
    ) {
      this.resizeObserver.disconnect();
    }
  },
  methods: {
    checkRefHeight() {
      let doCheck = () => {
        if (this.themeVariant === 'with-list' && this.$refs.$list) {
          this.resizeObserver.observe(this.$refs.$list);
          return;
        }
        if (this.themeVariant === 'with-carousel' && this.$refs.$carousel) {
          this.resizeObserver.observe(this.$refs.$carousel);
          return;
        }
        setTimeout(doCheck, 10);
      };
      doCheck();
    },
  },
};
</script>

<style lang="scss" scoped>
.FaceToFaceCmp {
  overflow: hidden;

  figure.background {
    position: absolute;
    inset: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    z-index: map-get($z, under);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 100%;
    }
  }

  .TitleTextCmp {
    --title-text-icon-wrapper-padding: 0 0 24px;
    --title-text-icon-wrapper-justify-content: center;
  }

  &.image-bg {
    position: relative;
    padding: 40px 0 147px;

    @include media-breakpoint-up(md) {
      padding: 80px 0 240px;
    }

    @include media-breakpoint-up(lg) {
      padding: 80px 0 400px;
    }

    .TitleTextCmp {
      --title-text-cta-wrapper-padding: 40px 0 0;
      --title-text-cta-wrapper-row-gap: 40px;
    }
  }

  &.with-image {
    overflow: visible;

    .image-wrapper {
      width: 100%;
      border-radius: 8px;
      margin: 0 0 24px;
      overflow: hidden;

      @include media-breakpoint-up(md) {
        margin: 52px 0 0;
      }

      @include media-breakpoint-up(lg) {
        margin: 64px 0 0;
      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &.with-list,
  &.with-carousel {
    ::v-deep .image-below {
      isolation: isolate;

      img {
        border-radius: 8px;
        z-index: map-get($z, under);
      }
    }
  }

  &.with-list {
    .TitleTextCmp {
      --title-text-padding: 0 0 40px;

      @include media-breakpoint-up(lg) {
        --title-text-padding: 0 0 27px;
      }

      @include media-breakpoint-up(xl) {
        --title-text-padding: 0 0 64px;
      }
    }

    ::v-deep .image-below {
      .list {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        background-color: $color-blue;
        color: $color-white;
        padding: 8px 24px;
        margin-inline: 16px;

        @include media-breakpoint-up(md) {
          flex-direction: row;
          padding: 24px 8px;
          margin-inline: 0;
          transform: translateY(-50%);
        }

        @include media-breakpoint-up(xl) {
          padding: 40px 16px;
        }

        li {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          text-align: center;
          padding: 16px 24px;
          @include responsive-typography-properties(
            $font-primary,
            a-paragraph-3
          );
          border-bottom: 1px solid $color-grey-200-75;

          @include media-breakpoint-up(md) {
            padding: 0 16px;
            border-right: 1px solid $color-grey-200-75;
            border-bottom: 0;
          }

          @include media-breakpoint-up(xl) {
            padding: 0 24px;
          }

          &:last-child {
            border: 0 !important;
          }
        }
      }
    }
  }

  &.with-carousel {
    .TitleTextCmp {
      --title-text-padding: 0 0 40px;

      @include media-breakpoint-up(md) {
        --title-text-padding: 0 0 80px;
      }
    }

    ::v-deep .image-below {
      .carousel {
        margin: -40px 0 0 16px;

        @include media-breakpoint-up(md) {
          margin: 41px 0 0; // 41px half of carousel pagination height
          transform: translateY(-50%);
        }

        .SliderCmpExtended {
          .slider-content {
            padding-bottom: 82px;

            .swiper-container {
              .swiper-pagination {
                padding: 55px 0 15px;
                margin-left: -8px; // needed to center the pagination @ step xs

                @include media-breakpoint-up(md) {
                  margin-left: -4.5%; // needed to center the pagination @ step md and over
                }
              }
            }
          }
        }
      }
    }
  }

  &.card-list {
    .TitleTextCmp {
      @include media-breakpoint-up(md) {
        --title-text-padding: 0 0 40px;
      }
    }

    .GridColumnCmp {
      display: flex;
      align-items: flex-start;
      justify-content: center;

      .card-link {
        display: inline-block;
        margin-top: 40px;
      }
    }
  }
}
</style>
