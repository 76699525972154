<template>
  <div :class="['NewsAndMediaListPage', { loading }]">
    <hero-cmp ref="$targetScroll" class="colorful-bg">
      <template #type>
        <slot name="news-type" />
      </template>
      <template #title>
        <slot name="news-hero-title" />
      </template>
    </hero-cmp>
    <section v-if="tabs.length > 0 && category" class="py-l">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10">
            <tabs-cmp
              :data-active-tab-id="category.news_and_media_type"
              :data-tabs="tabs"
              :data-routing="true"
              @update="onNavigate"
            >
              <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
                <slot :name="name" v-bind="data" />
              </template>
            </tabs-cmp>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { castPropToType } from '@/helpers/cms-support';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'NewsAndMediaListPage',
  async beforeRouteUpdate(to, from, next) {
    const payload = {
      category_slug: to.params.currentCategorySlug,
    };
    if (to.params.pageNumber) {
      payload.page_number = to.params.pageNumber;
    }
    if (this.filters.length) {
      payload.filters = this.filters;
    }
    await this.setPages(payload);
    next();
  },
  props: {
    dataAvailableCategory: { type: String, required: true },
    dataCurrentCategory: { type: String, required: true },
    dataTopics: { type: String, required: true },
    dataPagination: { type: String, required: true },
  },
  setup() {
    return { castPropToType };
  },
  data() {
    return {
      tabs: [],
    };
  },
  computed: {
    ...mapState('newsAndMedia', [
      'hub_hero_overtitle',
      'hub_hero_title',
      'category',
      'filters',
      'pages_loading',
    ]),
    loading() {
      return this.pages_loading.length;
    },
    availableCategory() {
      return this.dataAvailableCategory
        ? this.castPropToType(this.dataAvailableCategory, 'array')
        : null;
    },
    currentCategory() {
      return this.dataCurrentCategory
        ? this.castPropToType(this.dataCurrentCategory, 'object')
        : null;
    },
    topics() {
      return this.dataTopics
        ? this.castPropToType(this.dataTopics, 'array')
        : null;
    },
    pagination() {
      return this.dataPagination
        ? this.castPropToType(this.dataPagination, 'object')
        : null;
    },
  },
  watch: {
    availableCategory: {
      handler(val) {
        if (val && val.length > 0) {
          let tabs_slugs = [];
          this.tabs = val.map((e) => {
            tabs_slugs.push(e.slug);
            return { id: e.news_and_media_type, label: e.title };
          });
          this.configPages(tabs_slugs);
        }
      },
      immediate: true,
    },
    currentCategory: {
      handler(val) {
        if (val) {
          this.setCategory(val);
        }
      },
      immediate: true,
    },
    pagination: {
      handler(val) {
        if (val) {
          this.setPagination(val);
        }
      },
      immediate: true,
    },
    topics: {
      handler(val) {
        if (val) {
          this.setTopics(val);
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('newsAndMedia', [
      'setCategory',
      'setPagination',
      'setTopics',
      'configPages',
      'setPages',
    ]),
    async onNavigate(tabId) {
      const current = this.availableCategory.find(
        (e) => e.news_and_media_type === tabId
      );
      if (current) {
        await this.setPages({ category_slug: current.slug });
        this.setCategory(current);
        this.$router.push({
          name: 'index',
          params: {
            currentCategorySlug: current.slug,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.NewsAndMediaListPage {
  &.loading {
    &:before {
      content: '';
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw !important;
      height: 100vh !important;
      background: rgba(0, 0, 0, 0.2);
    }

    &:after {
      content: '';
      display: block;
      position: fixed;
      top: 50%;
      left: 50%;
      width: 48px !important;
      height: 48px !important;
      min-width: 0;
      min-height: 0;
      color: rgba(255, 255, 255, 1);
      border: 5px solid;
      border-right-color: rgba(0, 0, 0, 0.8);
      border-radius: 50%;
      background: none;
      transform: translateX(-50%) translateY(-50%);
      animation: rotate 1s linear infinite;
    }
  }
}
</style>
