import * as types from './mutation_types';
import { colorLog } from '@/helpers/utils.js';

export default {
  setAppReady({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_APP_READY, payload);
        resolve();
      } catch (e) {
        colorLog({
          label: 'Store error',
          message: `${e}`,
          themeName: 'blue',
        });
        reject(e);
      }
    });
  },
  setLoading({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_LOADING, payload);
        resolve();
      } catch (e) {
        colorLog({
          label: 'Store error',
          message: `${e}`,
          themeName: 'blue',
        });
        reject(e);
      }
    });
  },
  setUnlockedView({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_UNLOCKED_VIEW, payload);
        resolve();
      } catch (e) {
        colorLog({
          label: 'Store error',
          message: `${e}`,
          themeName: 'blue',
        });
        reject(e);
      }
    });
  },
  setProperties({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        if (
          'module' in payload &&
          'payload' in payload &&
          Object.keys(payload).length === 2
        ) {
          Object.keys(payload.payload).forEach((k) => {
            commit(types.SET_PROPERTIES, {
              module: payload.module,
              property: k,
              value: payload.payload[k],
            });
          });
        } else {
          Object.keys(payload).forEach((k) => {
            commit(types.SET_PROPERTIES, {
              property: k,
              value: payload[k],
            });
          });
        }

        resolve();
      } catch (e) {
        colorLog({
          label: 'Store error',
          message: `${e}`,
          themeName: 'blue',
        });
        reject(e);
      }
    });
  },
};
