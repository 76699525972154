<template>
  <!--
    props:
      dataSocial -> the name of the social ['facebook','twitter','linkedin','youtube','instagram','email']
      dataIsShare -> (bool) set if the button is a share button or a link (the share is only for facebook, linkedin and twitter)
      dataHref -> url for external page
      dataTarget -> (_blank default) set where to open the link
      dataRel-> set the rel
      dataTitleShare ->  title of the share
      dataSubjectMail (only for mail) -> subject of the mail
      dataContentMail (only for mail) -> content of the mail
      dataContentWhatsapp (only for Whatsapp) -> content of the whatsapp text
      dataShareUrl -> url of the share
  -->
  <a
    class="SocialShareItem"
    :href="getUrl"
    :target="dataTarget !== '' ? dataTarget : null"
    :rel="dataRel !== '' ? dataRel : null"
    @click="onButtonClick"
  >
    <svg width="24" height="24" :class="['socialIcon', dataSocial]">
      <use :href="`#icon-${dataSocial}`" fill="currentColor" />
    </svg>
    <js-loader
      v-if="dataSocial === 'pinterest'"
      :data-async="true"
      :data-defer="true"
      data-id="pinterest-initializer"
      data-src="//assets.pinterest.com/js/pinit.js"
    />
    <!--
      buttonPin if you are using a “one Pin” button, or
      buttonBookmark if you are using an “any Pin” button
    -->
  </a>
</template>

<script>
//import SmcSupport from '@mixins/smc-support';
import { castPropToType } from '@/helpers/cms-support';

/*
Facebook Custom Share Link:
<a href="https://www.facebook.com/sharer/sharer.php?u=<URL>&t=<TITLE>" onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;" target="_blank" title="Share on Facebook">
Whatsapp Custom Share Link:
<a href="whatsapp://send?text=<URL>" data-action="share/whatsapp/share" onClick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;" target="_blank" title="Share on whatsapp"></a>
Twitter Custom Share Link:
<a href="https://twitter.com/share?url=<URL>&text=<TITLE>" onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;" target="_blank" title="Share on Twitter"></a>
LinkedIn Custom Share Link:
<a href="https://www.linkedin.com/shareArticle?mini=true&url=<URL>&t=<TITLE>" onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;" target="_blank" title="Share on Linkedin"></a>
Read this for better SEO: On-Page SEO Techniques in 2020

Google+ Custom Share Link:
<a href="https://plus.google.com/share?url=<URL>" onClick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;" target="_blank" title="Share on Google+"></a>
Pinterest Custom Share Link:
<a href="http://pinterest.com/pin/create/button/?url=[EncodedURL]&media=[MEDIA]" onClick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;" target="_blank" title="Share on Pinterest"></a>
E-Mail Custom Share Link:
<a href="mailto:?subject=[SUBJECT]&body=<URL>" onClick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;" target="_blank" title="Share on Mail"></a>
Reddit Custom Share Link:
<a href="http://www.reddit.com/submit?url=<EncodedURL>" onClick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;" target="_blank" title="Share on Mail"></a>
*/

export default {
  name: 'SocialShareItem',
  //mixins: [SmcSupport],
  props: {
    dataSocial: {
      type: String,
      default: '',
    },
    dataIsShare: {
      type: [Boolean, String],
      default: false,
    },
    dataHref: {
      type: String,
      default: '',
    },
    dataTarget: {
      type: String,
      default: '_blank',
    },
    dataRel: {
      type: String,
      default: '',
    },
    dataTitleShare: {
      type: String,
      default: '',
    },
    dataSubjectMail: {
      type: String,
      default: '',
    },
    dataContentWhatsapp: {
      type: String,
      default: '',
    },
    dataContentMail: {
      type: String,
      default: '',
    },
    dataShareUrl: {
      type: String,
      default: '',
    },
    dataShareSmcEnabled: {
      type: [Boolean, String],
      default: false,
    },
    dataShareSmcClickForced: {
      type: [Boolean, String],
      default: false,
    },
    dataShareSmcClickEvent: {
      type: String,
      default: '',
    },
    dataShareSmcRecipeId: {
      type: [String, Number],
      default: '',
    },
    dataShareSmcProductCode: {
      type: String,
      default: '',
    },
    dataShareSmcHighlightedContent: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      castPropToType,
    };
  },
  data() {
    return {
      socialShareHref: '',
    };
  },
  computed: {
    shareSmcEnabled() {
      return this.castPropToType(this.dataShareSmcEnabled, 'boolean');
    },
    shareSmcClickForced() {
      return this.castPropToType(this.dataShareSmcClickForced, 'boolean');
    },
    twitterShareUrl() {
      return `https://twitter.com/intent/tweet?url=${encodeURI(
        this.dataShareUrl
      )}`;
    },
    fbShareUrl() {
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
        this.dataShareUrl
      )}`;
    },
    linkedinShareUrl() {
      return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(
        this.dataShareUrl
      )}&title=${encodeURIComponent(this.dataTitleShare)}&source=LinkedIn`;
    },
    mailUrl() {
      return `mailto:?subject=${this.dataSubjectMail}&body=${encodeURIComponent(
        this.dataContentMail
      )} ${encodeURI(this.dataShareUrl)}`;
    },
    whatsappUrl() {
      return `whatsapp://send?text=${this.dataContentWhatsapp}`;
    },
    isShare() {
      return this.castPropToType(this.dataIsShare, 'boolean');
    },
    getUrl() {
      if (this.isShare) {
        return this.dataSocial === 'twitter'
          ? this.twitterShareUrl
          : this.dataSocial === 'facebook'
          ? this.fbShareUrl
          : this.dataSocial === 'linkedin'
          ? this.linkedinShareUrl
          : this.dataSocial === 'email'
          ? this.mailUrl
          : this.dataSocial === 'whatsapp'
          ? this.whatsappUrl
          : '#';
      } else {
        return this.dataHref;
      }
    },
  },
  methods: {
    openPopup(event) {
      this.popupCenter(event.currentTarget.href, this.dataTitleShare, 580, 470);
    },
    popupCenter(url, title, w, h) {
      // Fixes dual-screen position                            Most browsers       Firefox
      const dualScreenLeft =
        window.screenLeft !== undefined ? window.screenLeft : screen.left;
      const dualScreenTop =
        window.screenTop !== undefined ? window.screenTop : screen.top;

      const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : screen.width;
      const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : screen.height;

      const left = width / 2 - w / 2 + dualScreenLeft;
      const top = height / 3 - h / 3 + dualScreenTop;

      const newWindow = window.open(
        url,
        title,
        'scrollbars=yes, width=' +
          w +
          ', height=' +
          h +
          ', top=' +
          top +
          ', left=' +
          left
      );

      // Puts focus on the newWindow
      if (newWindow && newWindow.focus) {
        newWindow.focus();
      }
    },
    onButtonClick(event) {
      if (this.isShare && this.dataSocial !== 'email') {
        event.preventDefault();
        if (this.dataSocial === 'pinterest') {
          window.PinUtils.pinAny();
        } else if (this.dataSocial === 'print') {
          window.print();
        } else {
          this.openPopup(event);
        }
      }

      /* smc */
      if (this.shareSmcEnabled) {
        let clickEvent;
        let payload = {};

        if (this.dataShareSmcClickEvent !== '' && !this.shareSmcClickForced) {
          /*
          This code is executed if dataShareSmcClickEvent is PROD_SHARED or ZRECIPE_SHARED
          and if !shareSmcClickForced
          */

          clickEvent = this.dataShareSmcClickEvent;
          payload.productCode = this.dataShareSmcProductCode;
          payload.highlightedContent = this.dataShareSmcHighlightedContent;
          if (this.dataShareSmcClickEvent === 'ZRECIPE_SHARED') {
            payload.interactionDetail = this.dataShareSmcRecipeId;
          }
          payload.sharedPlatform = this.dataSocial;
        } else {
          switch (this.dataSocial) {
            case 'facebook':
              clickEvent = 'ZFACEBOOK_CLICK';
              break;
            case 'instagram':
              clickEvent = 'ZINSTAGRAM_CLICK';
              break;
            case 'youtube':
              clickEvent = 'ZYOUTUBE_CLICK';
              break;
          }
        }

        if (clickEvent) {
          if (!this.isShare && this.dataTarget !== '_blank') {
            event.preventDefault();
          }
          // this.$_smcSupport_sendEvent(clickEvent, payload)
          //   .then(() => {
          //     if (!this.isShare && this.dataTarget !== '_blank') {
          //       window.location.href = this.dataHref;
          //     }
          //   })
          //   .catch(() => {
          //     if (!this.isShare && this.dataTarget !== '_blank') {
          //       window.location.href = this.dataHref;
          //     }
          //   });
        }
      }

      /* emitting up */
      this.$emit('clicked');
    },
  },
};
</script>

<style lang="scss">
.SocialShareItem {
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: flex-start;
  color: inherit;
}

.socialIcon {
  display: block;
}
</style>
