import qs from 'qs';

// converts a string containing decimal numbers into decimals with custom separator defined in global settings
// conversion works all over the string on decimals using dot (.) or comma (,) as separator
export const customDecimalSeparator = (value, customSeparator) => {
  let newValue = value;
  if (value) {
    const regexDecimalsDOT = value.match(/[0-9]{1}[.][0-9]{1,3}([^,.0-9]|$)/g);
    const regexDecimalsCOMMA = value.match(
      /[0-9]{1}[,][0-9]{1,3}([^,.0-9]|$)/g
    );
    if (customSeparator) {
      if (regexDecimalsDOT) {
        regexDecimalsDOT.forEach((match) => {
          const newMatch = match.replace('.', customSeparator);
          newValue = newValue.replace(match, newMatch);
        });
      }
      if (regexDecimalsCOMMA) {
        regexDecimalsCOMMA.forEach((match) => {
          const newMatch = match.replace(',', customSeparator);
          newValue = newValue.replace(match, newMatch);
        });
      }
    }
  }
  return newValue;
};

export const currentLang = (ordered_languages) => {
  if (!ordered_languages.length) {
    return '';
  }
  return ordered_languages
    .reduce(
      (previousValue, currentValue) => [
        ...previousValue,
        ...currentValue.regions,
      ],
      []
    )
    .find((languageObject) => {
      return (
        `${languageObject.language_code}-${languageObject.country_code}` ===
        languageObject.site_code
      );
    });
};

export const cleanUrl = (url) => {
  return url.indexOf('?') !== -1 ? url.split('?')[0] : url;
};
export const dirtySearch = (url) => {
  return url.indexOf('?') !== -1 ? url.split('?')[1] : '';
};
export const pureSearch = (dirtySearch) => {
  return dirtySearch.indexOf('#') !== -1
    ? dirtySearch.split('#')[0]
    : dirtySearch;
};
export const getQsObject = (querystring) => {
  return qs.parse(querystring);
};
// we have this "complex" way to check for qs because sometimes we faced strange behaviours

export const urlDirtySearch = dirtySearch(window.location.href);
export const querystring = pureSearch(urlDirtySearch);
export const qsObj = getQsObject(querystring);

/* https://unicode.org/emoji/charts/full-emoji-list.html */
/* https://www.w3schools.com/cssref/css_colors.asp */
/* some icons for messages */
export const zzz = String.fromCodePoint('0x1F4A4');
export const bomb = String.fromCodePoint('0x1F4A3');
export const prohibited = String.fromCodePoint('0x1F6AB');
export const ok = String.fromCodePoint('0x2705');
export const rightArrow = String.fromCodePoint('0x1F4AB');
export const info = String.fromCodePoint('0x2139');
export const brain = String.fromCodePoint('0x1F9E0');
export const pop = String.fromCodePoint('0x1F389');
export const wait = String.fromCodePoint('0x23F3');
export const comet = String.fromCodePoint('0x2604');
export const bell = String.fromCodePoint('0x1F514');

/*
    icon supports both strings '0x1F4A4' or unicodestrings String.fromCodePoint('0x1F4A4')
    available themeName: vue orange red blue
    message, label strings
    custom theme can be provided via theme params example:
        {
            theme : {
              backgroundColorLeft  : "#CCFF00",
              backgroundColorRight : "#FFCC00",
              colorLeft            : "#FF66CC",
              colorRight           : "#000000"
            }
        }

    usage: colorLog({label: 'router', message: `Router customer care`, themeName: 'orange'});
*/
export const colorLog = function ({
  icon = '',
  label = 'label',
  message = 'Message',
  themeName,
  theme,
}) {
  const selectedTheme = themeName ? themeName : 'vue';
  const availableThemes = {
    vue: {
      backgroundColorLeft: 'rgb(53, 73, 94)',
      backgroundColorRight: 'rgb(65, 184, 131)',
      colorLeft: 'rgb(255, 255, 255)',
      colorRight: 'rgb(255, 255, 255)',
    },
    orange: {
      backgroundColorLeft: 'rgb(94, 53, 53)',
      backgroundColorRight: 'rgb(236, 167, 10)',
      colorLeft: 'rgb(255, 255, 255)',
      colorRight: 'rgb(255, 255, 255)',
    },
    red2: {
      backgroundColorLeft: 'rgb(94, 53, 53)',
      backgroundColorRight: 'rgb(255, 51, 17)',
      colorLeft: 'rgb(255, 255, 255)',
      colorRight: 'rgb(255, 255, 255)',
    },
    red: {
      backgroundColorLeft: 'rgb(156, 127, 61)',
      backgroundColorRight: 'rgb(255, 51, 17)',
      colorLeft: 'rgb(255, 255, 255)',
      colorRight: 'rgb(255, 255, 255)',
    },
    blue: {
      backgroundColorLeft: 'rgb(31, 53, 30)',
      backgroundColorRight: 'rgb(17, 136, 255)',
      colorLeft: 'rgb(255, 255, 255)',
      colorRight: 'rgb(255, 255, 255)',
    },
  };
  let currentTheme;

  if (selectedTheme in availableThemes) {
    currentTheme = Object.assign({}, availableThemes[selectedTheme]);
  }
  if (theme) {
    currentTheme = Object.assign({}, currentTheme, theme);
  }

  const parsedIcon =
    icon !== ''
      ? icon.indexOf('0x') !== -1
        ? String.fromCodePoint(icon)
        : /\p{Extended_Pictographic}/u.test(icon)
        ? icon
        : ''
      : '';
  const styles1 = `background: ${currentTheme.backgroundColorLeft}; padding: 2px 6px; border-radius: 3px 0px 0px 3px; color: ${currentTheme.colorLeft};`;
  const styles2 = `background: ${currentTheme.backgroundColorRight}; padding: 2px 6px; border-radius: 0px 3px 3px 0px; color: ${currentTheme.colorRight};`;
  const content =
    parsedIcon !== ''
      ? `${parsedIcon} %c${label}%c${message}`
      : `%c${label}%c${message}`;
  // eslint-disable-next-line
  console.log(content, styles1, styles2);
};

export const stringToFloatRounded = function (number, decimals) {
  return Number(
    Math.round(parseFloat(number) + 'e' + decimals) + 'e-' + decimals
  );
};

export class UnknownError extends Error {
  constructor(message) {
    super(message);
    this.name = 'UnknownError';
  }
}

export class ValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ValidationError';
  }
}
export class ApiError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ApiError';
  }
}

export const prettyPrintErrorProperties = function (e) {
  let properties = Object.getOwnPropertyNames(e);
  properties.forEach((p) => {
    let prop = Object.getOwnPropertyDescriptor(e, p);
    // eslint-disable-next-line
    console.log(p, '=>', prop);
  });
};

export const getMountPointId = function () {
  return getComputedStyle(document.documentElement)
    .getPropertyValue('--mount-point-id')
    .trim();
};

export let debug = false;
// eslint-disable-next-line
if(process.env && process.env.VUE_APP_ENV!=='production'){
  debug = true;
}
