/* Mutations, actions and getters */
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    ready: false,
    contactUsUrl: '',
    recaptchaApiKey: '',
    contactFormApiUrl: '',
    currentStep: {
      name: '',
      slug: '',
      num: null,
      content: {},
    },
    reachedView: 'home',
    /**
     *  [
     *    {
     *      name: 'home',
     *      slug: 'home',
     *      num: null,
     *      content: 'home',
     *    },
     *    {
     *      name: 'auth',
     *      slug: 'auth',
     *      num: null,
     *      content: 'auth',
     *    },
     *    {
     *      name: 'howToContactUs',
     *      slug: 'how-to-contact-us',
     *      num: 1,
     *      content: 'contact-methods',
     *    },
     *    {
     *      name: 'whatToTellUs',
     *      slug: 'what-to-tell-us',
     *      num: 2,
     *      content: 'contact-reasons',
     *    },
     *    {
     *      name: 'yourMessage',
     *      slug: 'your-message',
     *      num: 3,
     *      content: 'contact-message',
     *    },
     *    {
     *      name: 'yourPersonalData',
     *      slug: 'your-personal-data',
     *      num: 4,
     *      content: 'contact-personal-info',
     *    },
     *    {
     *      name: 'yourFiscalData',
     *      slug: 'your-fiscal-data',
     *      num: 5,
     *      content: 'contact-fiscal-info',
     *    },
     *    {
     *      name: 'thankYou',
     *      slug: 'thank-you',
     *      num: null,
     *      content: 'thank-you',
     *    },
     *  ]
     */
    views: [],
    contentData: {
      'home': {
        customers_api_url: '',
        customer_service_heading: '',
        customer_service_content: '',
        customer_service_note: '',
        /**
         *  [
         *    {
         *      icon: 'icon-chef-hat',
         *      title: 'I’m a foodservice professional',
         *      text: 'You are a restaurant chef, food service operator, restaurant manager, etc.',
         *      code: 'professional',
         *      slug: 'professional-customer',
         *    },
         *    {
         *      icon: 'icon-hot-pot',
         *      title: 'I’m a regular customer',
         *      text: 'You are a consumer trying to find your favorite products from our brands.',
         *      code: 'regular',
         *      slug: 'regular-customer',
         *    },
         *  ],
         */
        customers: [],
      },
      'auth': {
        title: '',
        text: '',
        loggedCtaUrl: '',
        loggedCtaAriaLabel: '',
        loggedCtaText: '',
        separatorText: '',
        unloggedCtaAriaLabel: '',
        unloggedCtaText: '',
      },
      'contact-methods': {
        title: '',
        text: '',
        messageTitle: '',
        messageText: '',
        messageCtaText: '',
        telephoneTitle: '',
        telephoneText: '',
        telephoneNumber: '',
        telephoneSupport: '',
        telephoneFootnote: '',
      },
      'contact-reasons': {
        title: '',
        text: '',
        reasons_api_url: '',
        /**
         *  [
         *    {
         *      icon: 'icon-information',
         *      title: 'Forniture di prodotto',
         *      text: 'Se sei interessato ad un prodotto ma non sai dove trovarlo e vuoi contattare il nostro ufficio vendite.',
         *      code: 'product-furniture',
         *      slug: 'product-furniture',
         *    },
         *    {
         *      icon: 'icon-information',
         *      title: 'Informazione prodotti',
         *      text: 'Se hai domande o sei interessato a saperne di più sui nostri prodotti.',
         *      code: 'product-info',
         *      slug: 'product-info',
         *    },
         *  ],
         */
        reasons: [],
      },
      'contact-message': {
        title: '',
        text: '',
        product: {
          active: null,
          label: '',
          placeholder: '',
          apiUrl: '',
          error: [],
        },
        file: {
          active: null,
          label: '',
          placeholder: '',
          dragText: '',
          dragTextCta: '',
          dropText: '',
          /**
           *  [
           *    {
           *      condition: 'validExt',
           *      message: 'Formato del file non consentito. (Formati consentiti: .jpg .jpeg .png .pdf)',
           *    },
           *    {
           *      condition: 'validSize',
           *      message: 'Dimensioni del file non consentite. (Dimensione massima: 3MB)',
           *    },
           *  ]
           */
          error: [],
        },
        message: {
          active: null,
          label: '',
          placeholder: '',
          error: [],
        },
        submitCtaAriaLabel: '',
        submitCtaText: '',
        footnote: '',
      },
      'contact-personal-info': {
        title: '',
        text: '',
        name: {
          active: null,
          label: '',
          placeholder: '',
          error: [],
        },
        surname: {
          active: null,
          label: '',
          placeholder: '',
          error: [],
        },
        email: {
          active: null,
          label: '',
          placeholder: '',
          error: [],
        },
        telephone: {
          active: null,
          label: '',
          placeholder: '',
          error: [],
        },
        interest: {
          active: null,
          label: '',
          placeholder: '',
          apiUrl: '',
          error: [],
        },
        work: {
          active: null,
          label: '',
          placeholder: '',
          apiUrl: '',
          error: [],
        },
        submitCtaAriaLabel: '',
        submitCtaText: '',
        footnote: '',
      },
      'contact-fiscal-info': {
        title: '',
        text: '',
        businessName: {
          active: null,
          label: '',
          placeholder: '',
          error: [],
        },
        vatNumber: {
          active: null,
          label: '',
          placeholder: '',
          error: [],
        },
        city: {
          active: null,
          label: '',
          placeholder: '',
          error: [],
        },
        address: {
          active: null,
          label: '',
          placeholder: '',
          error: [],
        },
        houseNumber: {
          active: null,
          label: '',
          placeholder: '',
          error: [],
        },
        postalCode: {
          active: null,
          label: '',
          placeholder: '',
          error: [],
        },
        province: {
          active: null,
          label: '',
          placeholder: '',
          apiUrl: '',
          error: [],
        },
        country: {
          active: null,
          label: '',
          placeholder: '',
          apiUrl: '',
          error: [],
        },
        distributorName: {
          active: null,
          label: '',
          placeholder: '',
          error: [],
        },
        distributorCity: {
          active: null,
          label: '',
          placeholder: '',
          error: [],
        },
        distributorCountry: {
          active: null,
          label: '',
          placeholder: '',
          apiUrl: '',
          error: [],
        },
        privacy: {
          active: null,
          label: '',
          radioList: [],
          error: [],
        },
        submitCtaAriaLabel: '',
        submitCtaText: '',
        footnote: '',
        globalError: '',
      },
      'thank-you': {
        title: '',
        text: '',
        homeCtaUrl: '',
        homeCtaAriaLabel: '',
        homeCtaText: '',
      },
    },
    chosenData: {
      customerType: '',
      reasonType: '',
      ga4id: '',
      product: '',
      file: null,
      message: '',
      name: '',
      surname: '',
      email: '',
      telephone: '',
      interest: '',
      work: '',
      businessName: '',
      vatNumber: '',
      city: '',
      address: '',
      houseNumber: '',
      postalCode: '',
      country: '',
      province: '',
      distributorName: '',
      distributorCountry: '',
      distributorCity: '',
      privacy: null,
    },
  },
  actions,
  mutations,
  getters,
};
