<template>
  <div class="CardCmp">
    <a :href="dataHref" :target="dataTarget" class="card-link"></a>
    <figure
      v-if="hasSlot($slots.image, $scopedSlots.image)"
      class="image-wrapper"
    >
      <slot name="image" />
    </figure>
    <div v-if="hasSlot($slots.tags, $scopedSlots.tags)" class="tags-wrapper">
      <div class="tags-list">
        <slot name="tags" />
      </div>
    </div>
    <div
      v-if="hasSlot($slots.social, $scopedSlots.social)"
      class="social-wrapper text-color-blue"
    >
      <slot name="social" />
    </div>
    <div
      v-if="hasSlot($slots.category, $scopedSlots.category)"
      class="category-wrapper text-color-grey-800"
    >
      <slot name="category" />
    </div>
    <hr
      v-if="hasSlot($slots.category, $scopedSlots.category)"
      class="separator"
    />
    <div
      v-if="hasSlot($slots.title, $scopedSlots.title)"
      class="title-wrapper text-color-blue"
    >
      <slot name="title" />
    </div>

    <div v-if="hasSlot($slots.text, $scopedSlots.text)" class="text-wrapper">
      <slot name="text" />
    </div>
    <div
      v-if="hasSlot($slots.date, $scopedSlots.date)"
      class="date-wrapper text-color-grey-600"
    >
      <slot name="date" />
    </div>
    <div v-if="hasSlot($slots.cta, $scopedSlots.cta)" class="cta-wrapper">
      <slot name="cta" />
    </div>
  </div>
</template>

<script>
import { hasSlot } from '@/helpers/cms-support';

export default {
  name: 'CardCmp',
  props: {
    dataHref: {
      type: String,
      default: '#',
    },
    dataTarget: {
      type: String,
      default: null,
    },
  },
  setup() {
    return { hasSlot };
  },
};
</script>

<style lang="scss" scoped>
.CardCmp {
  position: relative;
  isolation: isolate;

  .card-link {
    position: absolute;
    inset: 0;
    cursor: pointer;
  }

  a {
    z-index: 1;
  }

  .image-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    margin-bottom: 24px;
    border-radius: 8px;
    overflow: hidden;
    z-index: -1;

    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 1s;
      transform: scale(1.2);
    }
  }

  &:hover {
    .image-wrapper {
      img {
        transform: scale(1);
      }
    }
  }

  .tags-wrapper {
    padding-bottom: 16px;

    @include media-breakpoint-up(md) {
      padding-bottom: 24px;
    }

    .tags-list {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;
      gap: 8px;
    }
  }

  .separator {
    width: 100%;
    height: 0;
    padding-top: 8px;
    border: 0;
    border-bottom: 1px solid $color-grey-600-75;
    margin-bottom: 8px;
  }

  .date-wrapper {
    padding-top: 16px;

    @include media-breakpoint-up(md) {
      padding-top: 24px;
    }
  }

  .cta-wrapper {
    padding-top: 16px;

    @include media-breakpoint-up(md) {
      padding-top: 24px;
    }
  }

  &.media {
    .social-wrapper {
      padding-top: 16px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .text-wrapper {
      padding-top: 24px;
    }

    .cta-wrapper {
      padding-top: 40px !important;
    }
  }

  &.normal {
    .tags-wrapper {
      padding-bottom: 16px !important;
    }

    .category-wrapper {
      @include responsive-typography-properties($font-primary, a-paragraph-3);
    }

    .title-wrapper {
      @include responsive-typography-properties($font-primary, a-paragraph-1);
    }

    .date-wrapper {
      padding-top: 16px !important;
      @include responsive-typography-properties($font-primary, a-caption);
    }

    .cta-wrapper {
      padding-top: 16px !important;
    }
  }

  &.latest {
    .category-wrapper {
      @include responsive-typography-properties($font-primary, a-paragraph-3);
    }

    .title-wrapper {
      @include responsive-typography-properties($font-secondary, n-paragraph-1);
    }

    .date-wrapper {
      @include responsive-typography-properties($font-primary, a-support);
    }
  }

  &.highlight {
    .category-wrapper {
      @include responsive-typography-properties($font-primary, a-paragraph-3);
    }

    .title-wrapper {
      @include responsive-typography-properties($font-secondary, n-heading-2);
    }

    .date-wrapper {
      @include responsive-typography-properties($font-primary, a-support);
    }
  }
}
</style>
