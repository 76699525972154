<template>
  <div class="CookingTipsVideosPage">
    <hero-cmp class="colorful-bg">
      <template #type>
        <slot name="service-type" />
      </template>
      <template #title>
        <slot name="service-hero-title" />
      </template>
    </hero-cmp>
    <div class="container pt-r pb-l">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <slot name="service-breadcrumbs" />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <div class="row">
            <div
              v-for="(video, i) in config"
              :key="video.slug"
              class="col-12 col-md-6"
            >
              <media-player-cmp
                class="pb-l"
                :data-bootstrap-container-classes="['container-fluid', 'px-0']"
                :data-bootstrap-row-classes="['row']"
                :data-bootstrap-col-classes="['col-12']"
                :data-youtube-video-id="video.video_code"
              >
                <template #default>
                  <slot :name="`service-caption-${i + 1}`" />
                </template>
              </media-player-cmp>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { castPropToType } from '@/helpers/cms-support';
import { mapState } from 'vuex';

export default {
  name: 'CookingTipsVideosPage',
  props: {
    dataConfig: { type: String, required: true },
  },
  setup() {
    return { castPropToType };
  },

  computed: {
    config() {
      return this.dataConfig
        ? this.castPropToType(this.dataConfig, 'object')
        : null;
    },
    ...mapState('services', [
      'videos_overtitle',
      'videos_title',
      'breadcrumbs',
    ]),
  },
};
</script>

<style lang="scss" scoped></style>
