import { render, staticRenderFns } from "./chefs-note-banner-cmp.vue?vue&type=template&id=38577080&scoped=true"
import script from "./chefs-note-banner-cmp.vue?vue&type=script&lang=js"
export * from "./chefs-note-banner-cmp.vue?vue&type=script&lang=js"
import style0 from "./chefs-note-banner-cmp.vue?vue&type=style&index=0&id=38577080&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38577080",
  null
  
)

export default component.exports