<template>
  <div class="TitleTextCmp">
    <div v-if="hasSlot($slots.icon, $scopedSlots.icon)" class="icon-wrapper">
      <div v-if="!!dataIconNumber" class="icon-number-wrapper">
        {{
          customDecimalSeparator(
            dataIconNumber,
            $store.state.config.decimal_separator
          )
        }}
      </div>
      <slot name="icon" />
    </div>
    <div v-if="!!dataNumber" class="number-unit-wrapper">
      <div class="number-wrapper">
        {{
          customDecimalSeparator(
            dataNumber,
            $store.state.config.decimal_separator
          )
        }}
      </div>
      <div v-if="hasSlot($slots.unit, $scopedSlots.unit)" class="unit-wrapper">
        <slot name="unit" />
      </div>
    </div>
    <div
      v-if="hasSlot($slots.overtitle, $scopedSlots.overtitle)"
      class="overtitle-wrapper"
    >
      <slot name="overtitle" />
    </div>
    <div v-if="hasSlot($slots.title, $scopedSlots.title)" class="title-wrapper">
      <slot name="title" />
    </div>
    <div v-if="hasSlot($slots.text, $scopedSlots.text)" class="text-wrapper">
      <slot name="text" />
    </div>
    <div v-if="hasSlot($slots.cta, $scopedSlots.cta)" class="cta-wrapper">
      <slot name="cta" />
    </div>
  </div>
</template>

<script>
import { hasSlot } from '@/helpers/cms-support';
import { customDecimalSeparator } from '@/helpers/utils';

export default {
  name: 'TitleTextCmp',
  props: {
    dataNumber: {
      Type: [String, Number],
      default: null,
    },
    dataIconNumber: {
      Type: [String, Number],
      default: null,
    },
  },
  setup() {
    return { hasSlot, customDecimalSeparator };
  },
};
</script>

<style lang="scss" scoped>
.TitleTextCmp {
  display: flex;
  flex-direction: var(--title-text-flex-direction, column);
  flex-wrap: var(--title-text-flex-wrap, nowrap);
  text-align: var(--title-text-text-align, center);
  padding: var(--title-text-padding, 0);

  &.text-color-inherit {
    --title-text-icon-wrapper-color: inherit;
    --title-text-number-unit-wrapper-color: inherit;
    --title-text-overtitle-wrapper-color: inherit;
    --title-text-title-wrapper-color: inherit;
    --title-text-text-wrapper-color: inherit;
  }

  &[class*='text-color-'] {
    --title-text-icon-wrapper-color: currentColor;
    --title-text-number-unit-wrapper-color: currentColor;
    --title-text-overtitle-wrapper-color: currentColor;
    --title-text-title-wrapper-color: currentColor;
    --title-text-text-wrapper-color: currentColor;
  }

  .icon-wrapper,
  .number-unit-wrapper {
    display: flex;
    align-items: center;
  }

  .icon-wrapper {
    justify-content: var(--title-text-icon-wrapper-justify-content, flex-start);
    padding: var(--title-text-icon-wrapper-padding, 0);
    color: var(--title-text-icon-wrapper-color, $color-blue);

    .icon-number-wrapper {
      margin-right: 16px;
      @include responsive-typography-properties(
        $font-secondary,
        n-heading-1,
        --title-text-icon-wrapper
      );

      @include media-breakpoint-up(lg) {
        @include override-responsive-typography-properties(
          $font-secondary,
          n-display,
          --title-text-icon-wrapper
        );
      }
    }

    ::v-deep svg,
    ::v-deep img {
      width: var(--title-text-icon-wrapper-icon-width, 48px);
      height: var(--title-text-icon-wrapper-icon-height, 48px);

      @include media-breakpoint-up(lg) {
        width: var(--title-text-icon-wrapper-icon-width, 72px);
        height: var(--title-text-icon-wrapper-icon-height, 72px);
      }
    }
  }

  .number-unit-wrapper {
    flex-wrap: wrap;
    align-items: var(--title-text-number-unit-wrapper-align-items, baseline);
    justify-content: var(
      --title-text-number-unit-wrapper-justify-content,
      flex-start
    );
    padding: var(--title-text-number-unit-wrapper-padding, 0 0 8px);
    color: var(--title-text-number-unit-wrapper-color, $color-blue);

    .number-wrapper {
      margin-right: 10px;
      white-space: nowrap;
      @include responsive-typography-properties(
        $font-secondary,
        n-heading-2,
        --title-text-number-wrapper
      );
    }

    .unit-wrapper {
      flex-shrink: 0;
      white-space: nowrap;
      @include responsive-typography-properties(
        $font-primary,
        a-paragraph-3,
        --title-text-unit-wrapper
      );
    }
  }

  .overtitle-wrapper {
    @include responsive-typography-properties(
      $font-primary,
      a-paragraph-1,
      --title-text-overtitle-wrapper
    );
    padding: var(--title-text-overtitle-wrapper-padding, 0);
    color: var(--title-text-overtitle-wrapper-color, $color-blue);
  }

  .title-wrapper {
    @include responsive-typography-properties(
      $font-secondary,
      n-heading-2,
      --title-text-title-wrapper
    );
    padding: var(--title-text-title-wrapper-padding, 0);
    color: var(--title-text-title-wrapper-color, $color-blue);
  }

  .text-wrapper {
    @include responsive-typography-properties(
      $font-primary,
      a-paragraph-2,
      --title-text-text-wrapper
    );
    padding: var(--title-text-text-wrapper-padding, 24px 0 0);
    color: var(--title-text-text-wrapper-color, inherit);
  }

  .cta-wrapper {
    max-width: 100%;
    padding: var(--title-text-cta-wrapper-padding, 24px 0 0);
  }

  &.multiple-cta-inline {
    .cta-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly; /* fallback on space-around in cases of issues */
      align-items: center;
      row-gap: var(--title-text-cta-wrapper-row-gap, 24px);
      column-gap: var(--title-text-cta-wrapper-column-gap, 40px);
    }
  }

  &.multiple-cta-block {
    .cta-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include media-breakpoint-up(md) {
        align-items: flex-start;
      }

      ::v-deep .CtaButtonCmpExtended + .CtaButtonCmpExtended {
        margin-top: 24px;
      }
    }
  }
}
</style>
