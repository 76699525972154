var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"CarouselBrandsCmp"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-8"},[_c('div',{staticClass:"title-wrapper n-heading-2 text-color-blue text-center"},[_vm._t("title")],2)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('slider-cmp-extended',{attrs:{"data-bootstrap-container-classes":['container-fluid', 'px-0'],"data-component-slides":true,"data-options":{
            threshold: 5,
            observer: true,
            slidesPerView: 2,
            slidesPerGroup: 2,
            navigation: false,
            breakpoints: {
              768: {
                slidesPerView: 3,
                slidesPerGroup: 3,
              },
              1440: {
                slidesPerView: 4,
                slidesPerGroup: 4,
              },
            },
          }}},[_vm._t("custom-swipe-icon",null,{"slot":"custom-swipe-icon"}),_c('template',{slot:"components"},[_vm._t("carousel")],2)],2)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }