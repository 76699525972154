<template lang="html">
  <accordion-cmp
    class="AccordionCmpExtended"
    :data-items="items"
    v-on="$listeners"
  >
    <template #header="{ item }">
      <p class="header a-paragraph-2">
        {{ item.title }}
        <svg>
          <use href="#icon-caret-down" />
        </svg>
      </p>
    </template>
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
      <div :key="_ + 'accordion-content' + _uid" class="a-paragraph-3">
        <slot :name="name" v-bind="data" />
      </div>
    </template>
  </accordion-cmp>
</template>

<script>
import { AccordionCmp } from '@frankhoodbs/lib';
import { castPropToType } from '@/helpers/cms-support';

export default {
  name: 'AccordionCmpExtended',
  components: { AccordionCmp },
  props: {
    dataItems: {
      type: [Array, String],
    },
  },
  setup() {
    return {
      castPropToType,
    };
  },
  computed: {
    items() {
      return this.dataItems
        ? this.castPropToType(this.dataItems, 'array')
        : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.AccordionCmpExtended {
  .header {
    color: $color-blue;

    p {
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        width: 24px;
        height: 24px;
        transition: transform 0.3s ease-in-out;
        // avoid svg size change
        flex-shrink: 0;
        // distance from title on small steps
        margin-left: 10px;
      }
    }
  }

  // override css vars
  --accordion-header-link-py: 0;
  --accordion-content-py: 0;
  --accordion-item-border-color: $color-blue-10;

  ::v-deep {
    .item {
      background-color: $color-white;
      padding: 32px 24px 32px 32px;
      border-radius: 8px;
      border: 1px solid var(--accordion-item-border-color);
      // SOFT  SHADOW - this style is copy/pasted from global class .shadow-soft
      // remove this when the accordion base component allows to style item elements with classes
      box-shadow: 0 17px 7px rgb(0 0 0 / 1%), 0 10px 6px rgb(0 0 0 / 5%),
        0 4px 4px rgb(0 0 0 / 9%), 0 1px 2px rgb(0 0 0 / 10%);
      // SOFT  SHADOW - END

      &:not(:first-child) {
        margin-top: 16px;
      }

      &.open svg {
        transform: rotate(180deg);
      }

      .content {
        margin-top: 32px;
      }
    }
  }
}
</style>
