<template>
  <div v-if="show" class="LandingBannerCmp">
    <div class="wrapper">
      <button type="button" class="zoom-out" @click.prevent="onZoomOut">
        <svg>
          <use href="#icon-zoom-out" />
        </svg>
      </button>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingBannerCmp',
  data() {
    return {
      show: true,
    };
  },
  created() {
    const status = localStorage.getItem('landingBanner');
    if (status === 'hidden') {
      this.show = false;
    }
  },
  methods: {
    onZoomOut() {
      localStorage.setItem('landingBanner', 'hidden');
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.LandingBannerCmp {
  position: fixed;
  z-index: map-get($z, over);

  @include media-breakpoint-down(xl) {
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color-black, 0.48);
  }

  @include media-breakpoint-down(md) {
    align-items: flex-end;
    justify-content: flex-start;
  }

  @include media-breakpoint-up(xl) {
    width: 34.5%;
    right: 2em;
    bottom: 2em;
  }

  .wrapper {
    width: 100%;
    margin: 16px;
    text-align: right;

    @include media-breakpoint-up(md) {
      width: 55.5%;
      margin: 0;
    }

    @include media-breakpoint-up(lg) {
      width: 43%;
    }

    @include media-breakpoint-up(xl) {
      width: 100%;
    }

    .zoom-out {
      margin-bottom: 16px;

      svg {
        width: 32px;
        height: 32px;
        color: $color-white;

        @include media-breakpoint-up(xl) {
          color: $color-grey-600;
        }
      }
    }

    a {
      display: block;
    }
  }
}
</style>
