<template>
  <div class="MySimpleExamplePage">
    <main class="main-content">
      <landing-banner-cmp>
        <a href="#">
          <landing-banner-content-cmp>
            <template slot="image">
              <image-cmp
                width="80"
                height="72"
                data-desktop-image="https://picsum.photos/80/72"
              />
            </template>
            <template slot="title">
              Sie sind für Unterstützung in der Küche dankbar?
            </template>
            <template slot="text">
              Dann besuchen Sie uns auf der Internorga vom 10.-14. März an Stand
              Halle A1 auf Stand 108A.
            </template>
          </landing-banner-content-cmp>
        </a>
      </landing-banner-cmp>
      <hero-cmp
        class="home"
        data-heading-tag="h1"
        data-heading-text-desktop="SHAPING THE FUTURE §of§ FOODSERVICE"
        data-heading-text-mobile="SHAPING §the future of§ FOODSERVICE"
      >
        <template slot="seo-heading-text">
          <h1>Shaping the future of foodservice</h1>
        </template>
        <template slot="image">
          <picture-cmp
            data-desktop-image="https://picsum.photos/1440/800"
            data-mobile-image="https://picsum.photos/767/568"
          >
            <img
              width="1440"
              height="800"
              src="https://picsum.photos/1440/800"
              alt=""
            />
          </picture-cmp>
          <!--          <image-cmp-->
          <!--            width="1440"-->
          <!--            height="800"-->
          <!--            data-desktop-image="https://picsum.photos/1440/800"-->
          <!--            data-mobile-image="https://picsum.photos/767/568"-->
          <!--          />-->
        </template>
      </hero-cmp>
      <hero-cmp class="image">
        <template slot="image">
          <image-cmp
            width="1440"
            height="800"
            data-desktop-image="https://picsum.photos/1440/640"
            data-mobile-image="https://picsum.photos/767/568"
          />
        </template>
        <template slot="type">Products</template>
        <template slot="title">
          <h1>PASSION IN EVERY BITE</h1>
        </template>
        <template slot="breadcrumbs">
          <breadcrumbs-cmp data-items-number="5">
            <template slot="item-1">
              <a href="#">Home</a>
            </template>
            <template slot="item-2">
              <a href="#">Level 1</a>
            </template>
            <template slot="item-3">
              <a href="#">Level 2</a>
            </template>
            <template slot="item-4">
              <a href="#">Level 3</a>
            </template>
            <template slot="item-5">
              <a href="#">Level 4</a>
            </template>
          </breadcrumbs-cmp>
        </template>
      </hero-cmp>
      <hero-cmp class="article">
        <template slot="image">
          <image-cmp
            width="1440"
            height="800"
            data-desktop-image="https://picsum.photos/1440/640"
            data-mobile-image="https://picsum.photos/767/568"
          />
        </template>
        <template slot="breadcrumbs">
          <breadcrumbs-cmp data-items-number="5">
            <template slot="item-1">
              <a href="#">Home</a>
            </template>
            <template slot="item-2">
              <a href="#">Level 1</a>
            </template>
            <template slot="item-3">
              <a href="#">Level 2</a>
            </template>
            <template slot="item-4">
              <a href="#">Level 3</a>
            </template>
            <template slot="item-5">
              <a href="#">Level 4</a>
            </template>
          </breadcrumbs-cmp>
        </template>
        <template slot="type">Editorial</template>
        <template slot="title">
          <h1>LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISCING ELIT</h1>
        </template>
        <template slot="date">December 25, 2021</template>
        <template slot="social-share">
          <social-share-cmp>
            <a href="#">
              <svg>
                <use href="#icon-linkedin" />
              </svg>
            </a>
            <a href="#">
              <svg>
                <use href="#icon-whatsapp" />
              </svg>
            </a>
            <a href="#">
              <svg>
                <use href="#icon-email" />
              </svg>
            </a>
            <a href="#">
              <svg>
                <use href="#icon-instagram" />
              </svg>
            </a>
            <a href="#">
              <svg>
                <use href="#icon-twitter" />
              </svg>
            </a>
            <a href="#">
              <svg>
                <use href="#icon-print" />
              </svg>
            </a>
          </social-share-cmp>
        </template>
      </hero-cmp>
      <hero-cmp class="colorful-bg">
        <template slot="type">Cooking Tips & Resources</template>
        <template slot="title">
          <h1>BEST PRACTICES</h1>
        </template>
      </hero-cmp>
      <hero-cmp class="colorful-bg-with-img">
        <template slot="image">
          <image-cmp
            width="302"
            height="382"
            data-desktop-image="https://picsum.photos/302/382"
          />
        </template>
        <template slot="type">Cooking Tips & Resources</template>
        <template slot="title">
          <h1>BEST PRACTICES</h1>
        </template>
      </hero-cmp>
      <hero-cmp class="recipes">
        <template slot="type">Recipes</template>
        <template slot="title">
          <h1>
            WHOLE GRAIN PENNETTE WITH LEMON SCENTED STALKS AND LEAVES PESTO
          </h1>
        </template>
      </hero-cmp>
      <banner-cmp class="contact">
        <template slot="image">
          <image-cmp
            width="1440"
            height="330"
            data-desktop-image="https://picsum.photos/1440/330"
            data-mobile-image="https://picsum.photos/767/568"
          />
        </template>
        <template>
          <title-text-cmp>
            <template slot="title">
              <h3>HOW CAN WE HELP?</h3>
            </template>
            <template slot="text">
              <p>
                Connect with us to find the answers to your questions from our
                customer service team.
              </p>
            </template>
            <template slot="cta">
              <cta-button-cmp-extended
                class="btn-primary-reversed"
                data-url="#"
                data-aria-label="Sustainability"
              >
                Get in touch
                <template slot="seo-link">
                  <a href="#">Get in touch</a>
                </template>
              </cta-button-cmp-extended>
            </template>
          </title-text-cmp>
        </template>
      </banner-cmp>
      <banner-cmp class="newsletter">
        <title-text-cmp>
          <template slot="title">
            <h3>HUNGRY FOR MORE?</h3>
          </template>
        </title-text-cmp>
        <template slot="newsletter-text">
          Subscribe to our newsletter and receive the latest news about current
          trends as well as our products and services.
        </template>
      </banner-cmp>
      <banner-cmp class="product-story">
        <template slot="image">
          <image-cmp
            width="1062"
            height="295"
            data-desktop-image="https://picsum.photos/1062/295"
            data-mobile-image="https://picsum.photos/767/568"
          />
        </template>
        <template>
          <title-text-cmp>
            <template slot="overtitle">
              <p>Did you know?</p>
            </template>
            <template slot="text">
              <p>
                Spaghetti is the most popular shape in Italy. The name comes
                from the Italian word spaghi, which means “lengths of cord”.
                Spaghetti originates from the south of Italy and is commonly
                used with tomato sauces, fresh vegetables, or fish.
              </p>
            </template>
          </title-text-cmp>
        </template>
      </banner-cmp>
      <banner-cmp class="quote bg-color-blue-10">
        <quote-cmp>
          <template slot="excerpt">
            “When we have shared goals, we have a shared impact. We are all
            responsible for the future of the foodservice industry, but even
            more importantly the planet.”
          </template>
          <template slot="author">
            Executive Chef Barilla Yury Krasilovsky
          </template>
        </quote-cmp>
      </banner-cmp>
      <banner-cmp class="section">
        <template slot="image">
          <image-cmp
            width="1440"
            height="330"
            data-desktop-image="https://picsum.photos/1440/330"
            data-mobile-image="https://picsum.photos/767/568"
          />
        </template>
        <template>
          <title-text-cmp>
            <template slot="title">
              <h3>CERTIFIED PROCESS</h3>
            </template>
            <template slot="text">
              <p>
                Our product ranges have been certified by the International EPD®
                System to communicate Barilla’s environmental perfomances.
              </p>
            </template>
            <template slot="cta">
              <cta-button-cmp-extended
                class="btn-text"
                data-url="#"
                data-aria-label="Sustainability"
              >
                Go to Sustainability
                <template slot="after-icon">
                  <svg>
                    <use href="#icon-arrow-right" />
                  </svg>
                </template>
                <template slot="seo-link">
                  <a href="#">Go to Sustainability</a>
                </template>
              </cta-button-cmp-extended>
            </template>
          </title-text-cmp>
        </template>
      </banner-cmp>
      <side-by-side-cmp class="image-left with-carousel">
        <template>
          <title-text-cmp class="multiple-cta-block">
            <template slot="title">
              <h3>SPAGHETTI 5</h3>
            </template>
            <template slot="text">
              <p>
                Barilla for Professionals Selezione Oro Chef Spaghetti n.5 is a
                high-performing pasta made from selected durum wheat that's
                rooted in Italian traditions.
              </p>
            </template>
            <template slot="cta">
              <cta-button-cmp-extended class="btn-primary" data-url="#">
                Download product sheet
                <template slot="after-icon">
                  <svg>
                    <use href="#icon-download" />
                  </svg>
                </template>
                <template slot="seo-link">
                  <a href="#">Download product sheet</a>
                </template>
              </cta-button-cmp-extended>
              <cta-button-cmp-extended class="btn-text" data-url="#">
                Questions? Contact us
                <template slot="after-icon">
                  <svg>
                    <use href="#icon-arrow-right" />
                  </svg>
                </template>
                <template slot="seo-link">
                  <a href="#">Questions? Contact us</a>
                </template>
              </cta-button-cmp-extended>
            </template>
          </title-text-cmp>
        </template>
        <template slot="carousel">
          <div
            v-for="n in 4"
            :key="`product-slide-${n}-${_uid}`"
            class="swiper-slide"
          >
            <figure>
              <image-cmp
                width="518"
                height="325"
                data-desktop-image="https://picsum.photos/518/325"
              />
            </figure>
          </div>
        </template>
      </side-by-side-cmp>
      <h1>540</h1>
      <side-by-side-cmp
        class="image-left animated"
        :data-image-theme-classes="['shadow-hard']"
      >
        <template>
          <title-text-cmp>
            <template slot="overtitle">
              <span>About us</span>
            </template>
            <template slot="title">
              <h3>SUSTAINABILITY</h3>
            </template>
            <template slot="text">
              <p>
                Nullam porttitor nunc id tempor scelerisque. Etiam dapibus
                tellus vel egestas tincidunt. Phasellus ut felis eleifend,
                interdum metus sit amet, maximus diam. Morbi nec odio sed lorem
                nunc aver.
              </p>
            </template>
            <template slot="cta">
              <cta-button-cmp-extended
                class="btn-text"
                data-url="#"
                data-aria-label="Discover more"
              >
                Discover more
                <template slot="after-icon">
                  <svg>
                    <use href="#icon-arrow-right" />
                  </svg>
                </template>
                <template slot="seo-link">
                  <a href="#">Discover more</a>
                </template>
              </cta-button-cmp-extended>
            </template>
          </title-text-cmp>
        </template>
        <template slot="image">
          <image-cmp
            width="540"
            height="304"
            data-desktop-image="https://picsum.photos/540/304"
            data-mobile-image="https://picsum.photos/540/304"
          />
        </template>
      </side-by-side-cmp>
      <h1>708</h1>
      <side-by-side-cmp
        class="image-right animated"
        :data-image-theme-classes="['shadow-hard']"
      >
        <template>
          <title-text-cmp>
            <template slot="icon">
              <svg>
                <use href="#icon-writing" />
              </svg>
            </template>
            <template slot="icon-number">2</template>
            <template slot="overtitle">
              <span>About us</span>
            </template>
            <template slot="title">
              <h3>SUSTAINABILITY</h3>
            </template>
            <template slot="text">
              <p>
                Nullam porttitor nunc id tempor scelerisque. Etiam dapibus
                tellus vel egestas tincidunt. Phasellus ut felis eleifend,
                interdum metus sit amet, maximus diam. Morbi nec odio sed lorem
                nunc aver.
              </p>
            </template>
            <template slot="cta">
              <cta-button-cmp-extended
                class="btn-text"
                data-url="#"
                data-aria-label="Discover more"
              >
                Discover more
                <template slot="after-icon">
                  <svg>
                    <use href="#icon-arrow-right" />
                  </svg>
                </template>
                <template slot="seo-link">
                  <a href="#">Discover more</a>
                </template>
              </cta-button-cmp-extended>
            </template>
          </title-text-cmp>
        </template>
        <template slot="image">
          <image-cmp
            width="540"
            height="304"
            data-desktop-image="https://picsum.photos/708/530"
            data-mobile-image="https://picsum.photos/708/530"
          />
        </template>
      </side-by-side-cmp>
      <h1>540</h1>
      <side-by-side-cmp
        class="image-left image-sticky animated"
        :data-image-theme-classes="['shadow-hard']"
      >
        <template>
          <title-text-cmp>
            <template slot="overtitle">
              <span>About us</span>
            </template>
            <template slot="title">
              <h3>SUSTAINABILITY</h3>
            </template>
            <template slot="text">
              <p>
                Nullam porttitor nunc id tempor scelerisque. Etiam dapibus
                tellus vel egestas tincidunt. Phasellus ut felis eleifend,
                interdum metus sit amet, maximus diam. Morbi nec odio sed lorem
                nunc aver.
              </p>
            </template>
            <template slot="cta">
              <cta-button-cmp-extended
                class="btn-text"
                data-url="#"
                data-aria-label="Discover more"
              >
                Discover more
                <template slot="after-icon">
                  <svg>
                    <use href="#icon-arrow-right" />
                  </svg>
                </template>
                <template slot="seo-link">
                  <a href="#">Discover more</a>
                </template>
              </cta-button-cmp-extended>
            </template>
          </title-text-cmp>
        </template>
        <template slot="image">
          <image-cmp
            width="540"
            height="304"
            data-desktop-image="https://picsum.photos/708/530"
            data-mobile-image="https://picsum.photos/767/568"
          />
        </template>
      </side-by-side-cmp>
      <h1>708</h1>
      <side-by-side-cmp
        class="image-right image-sticky animated"
        :data-image-theme-classes="['shadow-hard']"
      >
        <template>
          <title-text-cmp>
            <template slot="overtitle">
              <span>About us</span>
            </template>
            <template slot="title">
              <h3>SUSTAINABILITY</h3>
            </template>
            <template slot="text">
              <p>
                Nullam porttitor nunc id tempor scelerisque. Etiam dapibus
                tellus vel egestas tincidunt. Phasellus ut felis eleifend,
                interdum metus sit amet, maximus diam. Morbi nec odio sed lorem
                nunc aver.
              </p>
            </template>
            <template slot="cta">
              <cta-button-cmp-extended
                class="btn-text"
                data-url="#"
                data-aria-label="Discover more"
              >
                Discover more
                <template slot="after-icon">
                  <svg>
                    <use href="#icon-arrow-right" />
                  </svg>
                </template>
                <template slot="seo-link">
                  <a href="#">Discover more</a>
                </template>
              </cta-button-cmp-extended>
            </template>
          </title-text-cmp>
        </template>
        <template slot="image">
          <image-cmp
            width="708"
            height="530"
            data-desktop-image="https://picsum.photos/708/530"
            data-mobile-image="https://picsum.photos/767/568"
          />
        </template>
      </side-by-side-cmp>
      <face-to-face-cmp class="image-bg">
        <template slot="image">
          <image-cmp
            width="1440"
            height="746"
            data-desktop-image="https://picsum.photos/708/530"
            data-mobile-image="https://picsum.photos/767/568"
            data-switch-breakpoint="lg"
          />
        </template>
        <template>
          <title-text-cmp class="multiple-cta-inline">
            <template slot="title">
              <h4>A GROUP EFFORT</h4>
            </template>
            <template slot="text">
              <p>
                By working together, the entire Barilla Group is dedicated to
                improving and innovating our processes to deliver the most
                sustainable products with the least impact on the environment
                and revolutionizing what it means to bring sustainability to the
                table.
              </p>
            </template>
            <template slot="cta">
              <cta-button-cmp-extended
                class="btn-text"
                data-url="#"
                data-aria-label="Read more on Barilla Group"
              >
                Read more on Barilla Group
                <template slot="after-icon">
                  <svg>
                    <use href="#icon-arrow-up-right" />
                  </svg>
                </template>
                <template slot="seo-link">
                  <a href="#">Read more on Barilla Group</a>
                </template>
              </cta-button-cmp-extended>
              <cta-button-cmp-extended
                class="btn-text"
                data-url="#"
                data-aria-label="Download our Sustainability report"
              >
                Download our Sustainability report
                <template slot="after-icon">
                  <svg>
                    <use href="#icon-download" />
                  </svg>
                </template>
                <template slot="seo-link">
                  <a href="#">Download our Sustainability report</a>
                </template>
              </cta-button-cmp-extended>
            </template>
          </title-text-cmp>
        </template>
      </face-to-face-cmp>
      <face-to-face-cmp class="with-image">
        <template slot="image">
          <image-cmp
            width="846"
            height="455"
            data-desktop-image="https://picsum.photos/846/455"
          />
        </template>
        <template>
          <title-text-cmp>
            <template slot="icon">
              <svg>
                <use href="#icon-writing" />
              </svg>
            </template>
            <template slot="icon-number">2</template>
            <template slot="title">
              <h4>A GROUP EFFORT</h4>
            </template>
            <template slot="text">
              <p>
                By working together, the entire Barilla Group is dedicated to
                improving and innovating our processes to deliver the most
                sustainable products with the least impact on the environment
                and revolutionizing what it means to bring sustainability to the
                table.
              </p>
            </template>
            <template slot="cta">
              <cta-button-cmp-extended
                class="btn-text"
                data-url="#"
                data-aria-label="Read more"
              >
                Read more
                <template slot="after-icon">
                  <svg>
                    <use href="#icon-arrow-right" />
                  </svg>
                </template>
                <template slot="seo-link">
                  <a href="#">Read more</a>
                </template>
              </cta-button-cmp-extended>
            </template>
          </title-text-cmp>
        </template>
      </face-to-face-cmp>
      <face-to-face-cmp class="with-list">
        <template slot="image">
          <image-cmp
            width="1280"
            height="600"
            data-desktop-image="https://picsum.photos/1280/600"
            data-mobile-image="https://picsum.photos/767/600"
          />
        </template>
        <template>
          <title-text-cmp>
            <template slot="icon">
              <svg>
                <use href="#icon-writing" />
              </svg>
            </template>
            <template slot="icon-number">2</template>
            <template slot="title">
              <h4>A GROUP EFFORT</h4>
            </template>
            <template slot="text">
              <p>
                By working together, the entire Barilla Group is dedicated to
                improving and innovating our processes to deliver the most
                sustainable products with the least impact on the environment
                and revolutionizing what it means to bring sustainability to the
                table.
              </p>
            </template>
            <template slot="cta">
              <cta-button-cmp-extended
                class="btn-text"
                data-url="#"
                data-aria-label="Read more"
              >
                Read more
                <template slot="after-icon">
                  <svg>
                    <use href="#icon-arrow-right" />
                  </svg>
                </template>
                <template slot="seo-link">
                  <a href="#">Read more</a>
                </template>
              </cta-button-cmp-extended>
            </template>
          </title-text-cmp>
        </template>
        <template slot="list-items">
          <li>Only certified and tanned seeds</li>
          <li>No added preservatives</li>
          <li>No palm oil</li>
          <li>No GMOs</li>
          <li>No animal testing</li>
        </template>
      </face-to-face-cmp>
      <face-to-face-cmp class="with-carousel bg-color-blue-10">
        <template slot="image">
          <image-cmp
            width="1280"
            height="600"
            data-desktop-image="https://picsum.photos/1280/600"
            data-mobile-image="https://picsum.photos/767/600"
          />
        </template>
        <template>
          <title-text-cmp>
            <template slot="icon">
              <svg>
                <use href="#icon-writing" />
              </svg>
            </template>
            <template slot="icon-number">2</template>
            <template slot="title">
              <h4>A GROUP EFFORT</h4>
            </template>
            <template slot="text">
              <p>
                By working together, the entire Barilla Group is dedicated to
                improving and innovating our processes to deliver the most
                sustainable products with the least impact on the environment
                and revolutionizing what it means to bring sustainability to the
                table.
              </p>
            </template>
            <template slot="cta">
              <cta-button-cmp-extended
                class="btn-text"
                data-url="#"
                data-aria-label="Read more"
              >
                Read more
                <template slot="after-icon">
                  <svg>
                    <use href="#icon-arrow-right" />
                  </svg>
                </template>
                <template slot="seo-link">
                  <a href="#">Read more</a>
                </template>
              </cta-button-cmp-extended>
            </template>
          </title-text-cmp>
        </template>
        <template slot="carousel">
          <step-card-cmp
            class="swiper-slide with-separator"
            :data-card-classes="['bg-color-white']"
          >
            <template slot="title">From the farm</template>
            <template slot="text">
              The sustainable journey begins at the farm, where we grow, harvest
              and select only the finest durum wheat.
            </template>
          </step-card-cmp>
          <step-card-cmp
            class="swiper-slide with-separator"
            :data-card-classes="['bg-color-white']"
          >
            <template slot="title">To the mill</template>
            <template slot="text">
              Here, we implement the most accurate manufacturing process for our
              semolina.
            </template>
          </step-card-cmp>
          <step-card-cmp
            class="swiper-slide with-separator"
            :data-card-classes="['bg-color-white']"
          >
            <template slot="title">To the plant</template>
            <template slot="text">
              At the plant, we use the best techniques, allowing us to produce
              high-quality products, consistently.
            </template>
          </step-card-cmp>
          <step-card-cmp
            class="swiper-slide"
            :data-card-classes="['bg-color-white']"
          >
            <template slot="title">To supply</template>
            <template slot="text">
              We optimize every step in our supply process, from pick-up to
              delivery, to provide on-time services, every time.
            </template>
          </step-card-cmp>
        </template>
      </face-to-face-cmp>
      <face-to-face-cmp class="card-list">
        <title-text-cmp>
          <template slot="title">
            <h4>PROVING SOLUTIONS FOR EVERY MENU AND NEED</h4>
          </template>
          <template slot="text">
            <p>
              Barilla for Professionals customers all over the world trust in
              our products to help them do what they do best: provide excellent
              food and snacking experiences. Which service are you? Take a look
              at the solutions we recommend for your business.
            </p>
          </template>
        </title-text-cmp>
        <template slot="columns">
          <grid-column-cmp class="col">
            <a href="#" class="card-link">
              <simple-card-cmp data-image="https://picsum.photos/192/192">
                Canteens & Catering
              </simple-card-cmp>
            </a>
          </grid-column-cmp>
          <grid-column-cmp class="col">
            <a href="#" class="card-link">
              <simple-card-cmp data-image="https://picsum.photos/192/192">
                Hotels & B&Bs
              </simple-card-cmp>
            </a>
          </grid-column-cmp>
          <grid-column-cmp class="col">
            <a href="#" class="card-link">
              <simple-card-cmp data-image="https://picsum.photos/192/192">
                Restaurants
              </simple-card-cmp>
            </a>
          </grid-column-cmp>
          <grid-column-cmp class="col">
            <a href="#" class="card-link">
              <simple-card-cmp data-image="https://picsum.photos/192/192">
                Quick Service Restaurants
              </simple-card-cmp>
            </a>
          </grid-column-cmp>
          <grid-column-cmp class="col">
            <a href="#" class="card-link">
              <simple-card-cmp data-image="https://picsum.photos/192/192">
                Bar & Travel Catering
              </simple-card-cmp>
            </a>
          </grid-column-cmp>
          <grid-column-cmp class="col">
            <a href="#" class="card-link">
              <simple-card-cmp data-image="https://picsum.photos/192/192">
                Vending
              </simple-card-cmp>
            </a>
          </grid-column-cmp>
        </template>
      </face-to-face-cmp>
      <carousel-brands-cmp>
        <template slot="title">MORE THAN JUST PASTA</template>
        <template slot="carousel">
          <div
            v-for="n in 10"
            :key="`brand-slide-${n}-${_uid}`"
            class="swiper-slide"
          >
            <a href="#">
              <figure-card-cmp>
                <image-cmp
                  width="114"
                  height="99"
                  data-desktop-image="https://picsum.photos/114/99"
                />
              </figure-card-cmp>
            </a>
          </div>
        </template>
      </carousel-brands-cmp>
      <carousel-certifications-cmp>
        <template slot="carousel">
          <div
            v-for="n in 2"
            :key="`certification-slide-${n}-${_uid}`"
            class="swiper-slide"
          >
            <figure-card-cmp>
              <image-cmp
                width="114"
                height="99"
                data-desktop-image="https://picsum.photos/114/99"
              />
              <template slot="figcaption">
                Approved by the Italian Chef Federation (FIC), the association
                representing chefs and food professionals all over Italy.
              </template>
              <template slot="cta">
                <cta-button-cmp-extended class="btn-text" data-url="#">
                  Go to Sustainability
                  <template slot="after-icon">
                    <svg>
                      <use href="#icon-arrow-right" />
                    </svg>
                  </template>
                  <template slot="seo-link">
                    <a href="#">Go to Sustainability</a>
                  </template>
                </cta-button-cmp-extended>
              </template>
            </figure-card-cmp>
          </div>
        </template>
      </carousel-certifications-cmp>
      <carousel-product-banners-cmp>
        <template slot="carousel">
          <div
            v-for="n in 4"
            :key="`product-banner-slide-${n}-${_uid}`"
            class="swiper-slide"
          >
            <side-by-side-cmp
              class="image-right"
              :data-image-theme-classes="['shadow-hard']"
            >
              <template>
                <title-text-cmp>
                  <template slot="overtitle">
                    <span>Mulino Bianco Cornetti</span>
                  </template>
                  <template slot="title">
                    <h3>NOW AS A SINGLE PORTION</h3>
                  </template>
                  <template slot="text">
                    <p>
                      The new and exclusive Cornetti single-portion packs by
                      Mulino Bianco provide a quick and easy solution for those
                      on the go.
                    </p>
                  </template>
                  <template slot="cta">
                    <cta-button-cmp-extended
                      class="btn-text"
                      data-url="#"
                      data-aria-label="Discover more"
                    >
                      Discover more
                      <template slot="after-icon">
                        <svg>
                          <use href="#icon-arrow-right" />
                        </svg>
                      </template>
                      <template slot="seo-link">
                        <a href="#">Discover more</a>
                      </template>
                    </cta-button-cmp-extended>
                  </template>
                </title-text-cmp>
              </template>
              <template slot="image">
                <image-cmp
                  width="708"
                  height="530"
                  data-desktop-image="https://picsum.photos/540/304"
                  data-mobile-image="https://picsum.photos/767/568"
                />
              </template>
            </side-by-side-cmp>
          </div>
        </template>
      </carousel-product-banners-cmp>
      <carousel-product-cards-cmp
        class="bg-color-blue"
        :data-overflow-visible="true"
      >
        <template slot="carousel">
          <div
            v-for="n in 9"
            :key="`product-card-slide-${n}-${_uid}`"
            class="swiper-slide"
          >
            <card-product-cmp
              class="product"
              data-href="#"
              data-quantity="1"
              data-measure-unit-value="2.55"
              data-measure-unit="kg"
            >
              <template slot="seo-link">
                <a href="#"></a>
              </template>
              <template slot="title">
                <h2>Spaghettini 3</h2>
              </template>
              <template slot="image">
                <image-cmp
                  width="290"
                  height="240"
                  data-desktop-image="https://picsum.photos/290/240"
                />
              </template>
              <template slot="tag">
                <tag-cmp>Classic</tag-cmp>
              </template>
            </card-product-cmp>
          </div>
        </template>
      </carousel-product-cards-cmp>
      <carousel-product-cards-cmp :data-enabled-from="768">
        <template slot="carousel">
          <div
            v-for="n in 9"
            :key="`product-card-range-slide-${n}-${_uid}`"
            class="swiper-slide"
          >
            <card-product-cmp class="range" data-href="#">
              <template slot="seo-link">
                <a href="#"></a>
              </template>
              <template slot="title">
                <h2>LOREM</h2>
              </template>
              <template slot="image">
                <img src="https://i.imgur.com/j7yMbBo.png" alt="" />
              </template>
              <template slot="text">
                <p>
                  Barilla is the brand of choice for chefs looking for products
                  that offer excellent cooking performances.
                </p>
              </template>
            </card-product-cmp>
          </div>
        </template>
      </carousel-product-cards-cmp>
      <title-format-cmp>
        <title-text-cmp>
          <template slot="overtitle">
            <span>About us</span>
          </template>
          <template slot="title">
            <h3>SUSTAINABILITY</h3>
          </template>
          <template slot="text">
            <p>
              Nullam porttitor nunc id tempor scelerisque. Etiam dapibus tellus
              vel egestas tincidunt. Phasellus ut felis eleifend, interdum metus
              sit amet, maximus diam. Morbi nec odio sed lorem nunc aver.
            </p>
          </template>
          <template slot="cta">
            <cta-button-cmp-extended
              class="btn-text"
              data-url="#"
              data-aria-label="Discover more"
            >
              Discover more
              <template slot="after-icon">
                <svg>
                  <use href="#icon-arrow-right" />
                </svg>
              </template>
              <template slot="seo-link">
                <a href="#">Discover more</a>
              </template>
            </cta-button-cmp-extended>
          </template>
        </title-text-cmp>
      </title-format-cmp>
      <title-format-cmp class="bicolor" data-background-color="bg-color-blue">
        <title-text-cmp>
          <template slot="overtitle">
            <span>About us</span>
          </template>
          <template slot="title">
            <h3>SUSTAINABILITY</h3>
          </template>
          <template slot="text">
            <p>
              Nullam porttitor nunc id tempor scelerisque. Etiam dapibus tellus
              vel egestas tincidunt. Phasellus ut felis eleifend, interdum metus
              sit amet, maximus diam. Morbi nec odio sed lorem nunc aver.
            </p>
          </template>
          <template slot="cta">
            <cta-button-cmp-extended
              class="btn-text"
              data-url="#"
              data-aria-label="Discover more"
            >
              Discover more
              <template slot="after-icon">
                <svg>
                  <use href="#icon-arrow-right" />
                </svg>
              </template>
              <template slot="seo-link">
                <a href="#">Discover more</a>
              </template>
            </cta-button-cmp-extended>
          </template>
        </title-text-cmp>
      </title-format-cmp>
      <focus-items-cmp class="with-stepper bg-color-blue text-color-white">
        <template slot="steps">
          <title-text-cmp class="text-color-inherit">
            <template slot="icon">
              <svg>
                <use href="#icon-lightbulb" />
              </svg>
            </template>
            <template slot="icon-number">1</template>
            <template slot="overtitle">
              <span>Tips</span>
            </template>
          </title-text-cmp>
          <title-text-cmp class="text-color-inherit">
            <template slot="icon">
              <svg>
                <use href="#icon-writing" />
              </svg>
            </template>
            <template slot="icon-number">2</template>
            <template slot="overtitle">
              <span>Training</span>
            </template>
          </title-text-cmp>
          <title-text-cmp class="text-color-inherit">
            <template slot="icon">
              <svg>
                <use href="#icon-chart-bar" />
              </svg>
            </template>
            <template slot="icon-number">3</template>
            <template slot="overtitle">
              <span>Insights</span>
            </template>
          </title-text-cmp>
          <title-text-cmp class="text-color-inherit">
            <template slot="icon">
              <svg>
                <use href="#icon-target" />
              </svg>
            </template>
            <template slot="icon-number">4</template>
            <template slot="overtitle">
              <span>Planning</span>
            </template>
          </title-text-cmp>
          <title-text-cmp class="text-color-inherit">
            <template slot="icon">
              <svg>
                <use href="#icon-earth" />
              </svg>
            </template>
            <template slot="icon-number">5</template>
            <template slot="overtitle">
              <span>Community</span>
            </template>
          </title-text-cmp>
        </template>
      </focus-items-cmp>
      <focus-items-cmp class="with-icons" data-box-color="bg-color-legumi">
        <template slot="heading">
          <title-text-cmp>
            <template slot="title">
              <h3>FROM GOODNESS TO GREATNESS</h3>
            </template>
            <template slot="text">
              <p>
                Through Barilla’s heritage and knowledge rooted in passion and
                Italian traditions, our chefs have identified the most important
                attributes to define the highest quality of pasta:
              </p>
            </template>
          </title-text-cmp>
        </template>
        <template slot="icons">
          <div class="swiper-slide">
            <title-text-cmp>
              <template slot="icon">
                <svg>
                  <use href="#icon-hot-pot" />
                </svg>
              </template>
              <template slot="title">Versatile cooking</template>
              <template slot="text">
                Suitable for express and double cooking
              </template>
            </title-text-cmp>
          </div>
          <div class="swiper-slide">
            <title-text-cmp>
              <template slot="icon">
                <svg>
                  <use href="#icon-grain" />
                </svg>
              </template>
              <template slot="title">Ingredients</template>
              <template slot="text">Semolina</template>
            </title-text-cmp>
          </div>
          <div class="swiper-slide">
            <title-text-cmp>
              <template slot="icon">
                <svg>
                  <use href="#icon-protein" />
                </svg>
              </template>
              <template slot="title">Protein content</template>
              <template slot="text">
                12.5% (short cuts) and 12.8% (long cuts)
              </template>
            </title-text-cmp>
          </div>
          <div class="swiper-slide">
            <title-text-cmp>
              <template slot="icon">
                <svg>
                  <use href="#icon-special-check" />
                </svg>
              </template>
              <template slot="title">Benefits</template>
              <template slot="text">
                Additive-free, lactose-free and egg-free
              </template>
            </title-text-cmp>
          </div>
        </template>
      </focus-items-cmp>
      <focus-items-cmp class="with-numbers">
        <template slot="heading">
          <title-text-cmp>
            <template slot="icon">
              <svg>
                <use href="#icon-writing" />
              </svg>
            </template>
            <template slot="icon-number">2</template>
            <template slot="title">
              <h4>A GROUP EFFORT</h4>
            </template>
            <template slot="text">
              <p>
                By working together, the entire Barilla Group is dedicated to
                improving and innovating our processes to deliver the most
                sustainable products with the least impact on the environment
                and revolutionizing what it means to bring sustainability to the
                table.
              </p>
            </template>
            <template slot="cta">
              <cta-button-cmp-extended
                class="btn-text"
                data-url="#"
                data-aria-label="Read more"
              >
                Read more
                <template slot="after-icon">
                  <svg>
                    <use href="#icon-arrow-right" />
                  </svg>
                </template>
                <template slot="seo-link">
                  <a href="#">Read more</a>
                </template>
              </cta-button-cmp-extended>
            </template>
          </title-text-cmp>
        </template>
        <template slot="numbers">
          <div class="swiper-slide">
            <title-text-cmp>
              <template slot="title">+10K</template>
              <template slot="text">
                farmers involved in sustainable agriculture projects
              </template>
            </title-text-cmp>
          </div>
          <div class="swiper-slide">
            <title-text-cmp>
              <template slot="title">-31%</template>
              <template slot="text">
                reduced greenhouse gas emissions per tonne of finished product,
                since 2010
              </template>
            </title-text-cmp>
          </div>
          <div class="swiper-slide">
            <title-text-cmp>
              <template slot="title">-21%</template>
              <template slot="text">
                reduced water consumption per tonne of finished product, since
                2010
              </template>
            </title-text-cmp>
          </div>
          <div class="swiper-slide">
            <title-text-cmp>
              <template slot="title">100%</template>
              <template slot="text">cage free eggs</template>
            </title-text-cmp>
          </div>
          <div class="swiper-slide">
            <title-text-cmp>
              <template slot="title">99.6%</template>
              <template slot="text">
                of product packaging is designed to be recycled
              </template>
            </title-text-cmp>
          </div>
        </template>
      </focus-items-cmp>
      <focus-items-cmp class="with-images">
        <template slot="heading">
          <title-text-cmp>
            <template slot="title">
              <h3>THE DIE: BRONZE VS TEFLON</h3>
            </template>
            <template slot="text">
              <p>
                When it comes to making quality pasta, there are two dies that
                determine the shape: the paler yellow bronze and the brighter
                yellow teflon. It’s important for chefs to know the difference
                for each in order to optimize cooking performances for different
                culinary occasions.
              </p>
            </template>
          </title-text-cmp>
        </template>
        <template slot="first-image">
          <title-text-cmp>
            <template slot="icon">
              <image-cmp
                width="411"
                height="260"
                data-desktop-image="https://picsum.photos/411/260"
              />
            </template>
            <template slot="title">
              <h3>Bronze die</h3>
            </template>
            <template slot="text">
              <p>
                Bronze die is the perfect match for mixing with sauces due to
                its rougher surface, which offers a more fragile texture.
                Suitable only for express cooking, its high quality results
                require astute attention and expert hands.
              </p>
            </template>
          </title-text-cmp>
        </template>
        <template slot="second-image">
          <title-text-cmp>
            <template slot="icon">
              <image-cmp
                width="411"
                height="260"
                data-desktop-image="https://picsum.photos/411/260"
              />
            </template>
            <template slot="title">
              <h3>Teflon die</h3>
            </template>
            <template slot="text">
              <p>
                Used for everyday meals, the teflon method is extremely
                versatile and easy to cook. With a smooth surface that prevents
                cracking or breaking, this pasta supports double cooking due to
                its strong resistence.
              </p>
            </template>
          </title-text-cmp>
        </template>
      </focus-items-cmp>
      <focus-items-cmp
        class="with-tab-menu"
        data-active-tab-id="1"
        :data-tabs="[
          { id: '1', label: 'Frozen Multiportion Pasta' },
          { id: '2', label: 'Frozen Short Pasta' },
          { id: '3', label: 'Frozen Filled Pasta' },
        ]"
      >
        <template slot="tab-1-content">
          <focus-items-tab-content-cmp class="with-grid">
            <template slot="heading">
              1. Cook the pasta in plenty of salted water, always keeping the
              pot on the boil for the exact cooking time indicated.
            </template>
            <template slot="focus-items">
              <icon-text-info-cmp data-unit-value="10">
                <template slot="icon">
                  <svg>
                    <use href="#icon-pot" />
                  </svg>
                </template>
                <template slot="number">10</template>
                <template slot="unit">lt</template>
                <template slot="text">of water</template>
              </icon-text-info-cmp>
              <icon-text-info-cmp data-unit-value="70.9">
                <template slot="icon">
                  <svg>
                    <use href="#icon-salt-pepper" />
                  </svg>
                </template>
                <template slot="unit">gr</template>
                <template slot="text">of salt</template>
              </icon-text-info-cmp>
              <icon-text-info-cmp data-unit-value="70.9">
                <template slot="icon">
                  <svg>
                    <use href="#icon-fork" />
                  </svg>
                </template>
                <template slot="unit">kg</template>
                <template slot="text">of pasta</template>
              </icon-text-info-cmp>
              <icon-text-info-cmp>
                <template slot="icon">
                  <svg>
                    <use href="#icon-cooking-time" />
                  </svg>
                </template>
                <template slot="title">Double Cooking</template>
              </icon-text-info-cmp>
              <icon-text-info-cmp data-unit-value="70.9">
                <template slot="icon">
                  <svg>
                    <use href="#icon-cooking-time" />
                  </svg>
                </template>
                <template slot="title">Double cooking time</template>
                <template slot="unit">seconds</template>
                <template slot="text">Second cooking</template>
              </icon-text-info-cmp>
            </template>
          </focus-items-tab-content-cmp>
        </template>
        <template slot="tab-2-content">
          <focus-items-tab-content-cmp>
            <template slot="heading">
              2. Cook the pasta in plenty of salted water, always keeping the
              pot on the boil for the exact cooking time indicated.
            </template>
            <template slot="focus-items"></template>
          </focus-items-tab-content-cmp>
        </template>
        <template slot="tab-3-content">
          <focus-items-tab-content-cmp>
            <template slot="heading">
              3. Cook the pasta in plenty of salted water, always keeping the
              pot on the boil for the exact cooking time indicated.
            </template>
            <template slot="focus-items"></template>
          </focus-items-tab-content-cmp>
        </template>
      </focus-items-cmp>
      <focus-items-cmp
        class="with-tab-menu-detailed"
        data-active-tab-id="1"
        :data-tabs="[
          { id: '1', label: 'Frozen Multiportion Pasta' },
          { id: '2', label: 'Frozen Short Pasta' },
          { id: '3', label: 'Frozen Filled Pasta' },
        ]"
      >
        <template slot="tab-1-content">
          <focus-items-tab-content-cmp class="with-grid">
            <template slot="heading">
              1. Cook the pasta in plenty of salted water, always keeping the
              pot on the boil for the exact cooking time indicated.
            </template>
            <template slot="focus-items">
              <icon-text-info-cmp class="detailed">
                <template slot="icon">
                  <svg>
                    <use href="#icon-kitchen-scale" />
                  </svg>
                </template>
                <template slot="number">1</template>
                <template slot="unit">kg</template>
                <template slot="text">net weight</template>
              </icon-text-info-cmp>
              <icon-text-info-cmp class="detailed">
                <template slot="icon">
                  <svg>
                    <use href="#icon-measuring-tape" />
                  </svg>
                </template>
                <template slot="number">36x33</template>
                <template slot="unit">cms</template>
                <template slot="text">dimensions per packet</template>
              </icon-text-info-cmp>
              <icon-text-info-cmp class="detailed">
                <template slot="icon">
                  <svg>
                    <use href="#icon-fork" />
                  </svg>
                </template>
                <template slot="number">85</template>
                <template slot="unit">gr</template>
                <template slot="text">suggested portion</template>
              </icon-text-info-cmp>
              <icon-text-info-cmp class="detailed">
                <template slot="icon">
                  <svg>
                    <use href="#icon-pasta-package" />
                  </svg>
                </template>
                <template slot="number">12</template>
                <template slot="unit">portions</template>
                <template slot="text">per packet</template>
              </icon-text-info-cmp>
              <icon-text-info-cmp class="detailed">
                <template slot="icon">
                  <svg>
                    <use href="#icon-box" />
                  </svg>
                </template>
                <template slot="number">12</template>
                <template slot="unit">packets</template>
                <template slot="text">per delivery</template>
              </icon-text-info-cmp>
              <icon-text-info-cmp class="detailed">
                <template slot="icon">
                  <svg>
                    <use href="#icon-box" />
                  </svg>
                </template>
                <template slot="number">12</template>
                <template slot="unit">packets</template>
                <template slot="text">per delivery</template>
              </icon-text-info-cmp>
            </template>
          </focus-items-tab-content-cmp>
        </template>
        <template slot="tab-2-content">
          <focus-items-tab-content-cmp>
            <template slot="heading">
              2. Cook the pasta in plenty of salted water, always keeping the
              pot on the boil for the exact cooking time indicated.
            </template>
            <template slot="focus-items"></template>
          </focus-items-tab-content-cmp>
        </template>
        <template slot="tab-3-content">
          <focus-items-tab-content-cmp>
            <template slot="heading">
              3. Cook the pasta in plenty of salted water, always keeping the
              pot on the boil for the exact cooking time indicated.
            </template>
            <template slot="focus-items"></template>
          </focus-items-tab-content-cmp>
        </template>
      </focus-items-cmp>
      <focus-items-cmp
        class="with-tab-menu-with-image-bg"
        data-active-tab-id="1"
        :data-tabs="[
          {
            id: '1',
            label: 'Selezione Oro Chef',
            desktopImage: 'https://picsum.photos/135/144',
            mobileImage: 'https://picsum.photos/120/120',
          },
          {
            id: '2',
            label: '5kg Food Service',
            desktopImage: 'https://picsum.photos/135/144',
            mobileImage: 'https://picsum.photos/120/120',
          },
          {
            id: '3',
            label: 'Emiliane Chef Egg Pasta',
            desktopImage: 'https://picsum.photos/135/144',
            mobileImage: 'https://picsum.photos/120/120',
          },
          {
            id: '4',
            label: 'Wholegrain 1kg Food Service',
            desktopImage: 'https://picsum.photos/135/144',
            mobileImage: 'https://picsum.photos/120/120',
          },
        ]"
      >
        <template slot="image">
          <image-cmp
            width="1440"
            height="760"
            data-desktop-image="https://picsum.photos/1440/760"
            data-mobile-image="https://picsum.photos/767/568"
          />
        </template>
        <template slot="figcaption">
          <title-text-cmp>
            <template slot="title">
              <h3>CERTIFIED PROCESS</h3>
            </template>
            <template slot="text">
              <p>
                Our product ranges have been certified by the International EPD®
                System to communicate Barilla’s environmental perfomances.
              </p>
            </template>
            <template slot="cta">
              <cta-button-cmp-extended
                class="btn-text-reversed"
                data-url="#"
                data-target="_blank"
              >
                Visit the EPD website to learn more
                <template slot="after-icon">
                  <svg>
                    <use href="#icon-arrow-up-right" />
                  </svg>
                </template>
                <template slot="seo-link">
                  <a href="#" target="_blank">
                    Visit the EPD website to learn more
                  </a>
                </template>
              </cta-button-cmp-extended>
            </template>
          </title-text-cmp>
        </template>
        <template slot="logo">
          <image-cmp
            width="211"
            height="100"
            data-desktop-image="https://picsum.photos/211/100"
          />
        </template>
        <template slot="tab-1-content">
          <focus-items-tab-content-cmp>
            <template slot="focus-items">
              <title-text-cmp>
                <template slot="icon">
                  <svg>
                    <use href="#icon-leaf" />
                  </svg>
                </template>
                <template slot="number">8.3</template>
                <template slot="unit">global m2/kg</template>
                <template slot="title">Ecological footprint</template>
                <template slot="text">
                  <p>
                    The area of land and water required to provide the resources
                    used and absorb the CO2 waste generated along the entire
                    life cycle.
                  </p>
                </template>
              </title-text-cmp>
              <title-text-cmp>
                <template slot="icon">
                  <svg>
                    <use href="#icon-footprint" />
                  </svg>
                </template>
                <template slot="number">1,065</template>
                <template slot="unit">gCo2 eq/kg</template>
                <template slot="title">Carbon footprint</template>
                <template slot="text">
                  <p>
                    The total amount of greenhouse gases produced along the
                    entire life cycle of a product.
                  </p>
                </template>
              </title-text-cmp>
              <title-text-cmp>
                <template slot="icon">
                  <svg>
                    <use href="#icon-drop" />
                  </svg>
                </template>
                <template slot="number">572</template>
                <template slot="unit">litres eq/kg</template>
                <template slot="title">Water scarcity</template>
                <template slot="text">
                  <p>
                    The available water remaining per unit of surface in a given
                    watershed relative to the world average.
                  </p>
                </template>
              </title-text-cmp>
            </template>
            <template slot="download">
              <title-separator-cmp>
                <template slot="cta">
                  <cta-button-cmp-extended class="btn-text" data-url="#">
                    Download the full product declaration
                    <template slot="after-icon">
                      <svg>
                        <use href="#icon-download" />
                      </svg>
                    </template>
                    <template slot="seo-link">
                      <a href="#">Download the full product declaration</a>
                    </template>
                  </cta-button-cmp-extended>
                </template>
              </title-separator-cmp>
            </template>
          </focus-items-tab-content-cmp>
        </template>
        <template slot="tab-2-content">
          <focus-items-tab-content-cmp>
            <template slot="focus-items">
              <title-text-cmp>
                <template slot="icon">
                  <svg>
                    <use href="#icon-footprint" />
                  </svg>
                </template>
                <template slot="number">1,065</template>
                <template slot="unit">gCo2 eq/kg</template>
                <template slot="title">Carbon footprint</template>
                <template slot="text">
                  <p>
                    The total amount of greenhouse gases produced along the
                    entire life cycle of a product.
                  </p>
                </template>
              </title-text-cmp>
              <title-text-cmp>
                <template slot="icon">
                  <svg>
                    <use href="#icon-leaf" />
                  </svg>
                </template>
                <template slot="number">8.3</template>
                <template slot="unit">global m2/kg</template>
                <template slot="title">Ecological footprint</template>
                <template slot="text">
                  <p>
                    The area of land and water required to provide the resources
                    used and absorb the CO2 waste generated along the entire
                    life cycle.
                  </p>
                </template>
              </title-text-cmp>
              <title-text-cmp>
                <template slot="icon">
                  <svg>
                    <use href="#icon-drop" />
                  </svg>
                </template>
                <template slot="number">572</template>
                <template slot="unit">litres eq/kg</template>
                <template slot="title">Water scarcity</template>
                <template slot="text">
                  <p>
                    The available water remaining per unit of surface in a given
                    watershed relative to the world average.
                  </p>
                </template>
              </title-text-cmp>
            </template>
            <template slot="download">
              <title-separator-cmp>
                <template slot="cta">
                  <cta-button-cmp-extended class="btn-text" data-url="#">
                    Download the full product declaration
                    <template slot="after-icon">
                      <svg>
                        <use href="#icon-download" />
                      </svg>
                    </template>
                    <template slot="seo-link">
                      <a href="#">Download the full product declaration</a>
                    </template>
                  </cta-button-cmp-extended>
                </template>
              </title-separator-cmp>
            </template>
          </focus-items-tab-content-cmp>
        </template>
        <template slot="tab-3-content">
          <focus-items-tab-content-cmp>
            <template slot="focus-items"></template>
          </focus-items-tab-content-cmp>
        </template>
        <template slot="tab-4-content">
          <focus-items-tab-content-cmp>
            <template slot="focus-items"></template>
          </focus-items-tab-content-cmp>
        </template>
      </focus-items-cmp>
      <focus-items-cmp
        class="with-tab-menu"
        data-active-tab-id="1"
        :data-tabs="[
          { id: '1', label: 'Product Specification' },
          { id: '2', label: 'Nutrition and Allergens' },
          { id: '3', label: 'Benefits' },
          { id: '4', label: 'Cooking Time' },
        ]"
      >
        <template slot="tab-1-content"></template>
        <template slot="tab-2-content">
          <focus-items-nutrition-allergens-cmp>
            <template slot="ingredients">
              <dd>Durum wheat semolina</dd>
            </template>
            <template slot="allergens">
              This product may contain traces of soy.
            </template>
            <template slot="external-link">
              <cta-button-cmp-extended
                class="btn-text"
                data-url="#"
                data-target="_blank"
              >
                Visit allergenpasta.com
                <template slot="after-icon">
                  <svg>
                    <use href="#icon-arrow-up-right" />
                  </svg>
                </template>
                <template slot="seo-link">
                  <a href="#" data-target="_blank">Visit allergenpasta.com</a>
                </template>
              </cta-button-cmp-extended>
            </template>
            <template slot="table">
              <table-cmp class="four-cols-leading">
                <template slot="seo-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Nutritional values</th>
                        <th>Values (1 portion)</th>
                        <th>Values (100 gr)</th>
                        <th>Units</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Energy</td>
                        <td>359</td>
                        <td>359</td>
                        <td>kcal</td>
                      </tr>
                      <tr>
                        <td>Energy</td>
                        <td>1521</td>
                        <td>1521</td>
                        <td>kJ</td>
                      </tr>
                      <tr>
                        <td>Fat</td>
                        <td>2,0</td>
                        <td>2,0</td>
                        <td>g</td>
                      </tr>
                      <tr>
                        <td>of which satures</td>
                        <td>0,5</td>
                        <td>0,5</td>
                        <td>g</td>
                      </tr>
                      <tr>
                        <td>Carbohydrate</td>
                        <td>70,2</td>
                        <td>70,2</td>
                        <td>g</td>
                      </tr>
                      <tr>
                        <td>of which sugars</td>
                        <td>3,5</td>
                        <td>3,5</td>
                        <td>g</td>
                      </tr>
                      <tr>
                        <td>Fibre</td>
                        <td>3,0</td>
                        <td>3,0</td>
                        <td>g</td>
                      </tr>
                      <tr>
                        <td>Protein</td>
                        <td>13,5</td>
                        <td>13,5</td>
                        <td>g</td>
                      </tr>
                      <tr>
                        <td>Salt</td>
                        <td>0,013</td>
                        <td>0,013</td>
                        <td>g</td>
                      </tr>
                    </tbody>
                  </table>
                </template>
                <template slot="thead">
                  <table-row-cmp>
                    <table-cell-cmp data-tag="th">
                      Nutritional values
                    </table-cell-cmp>
                    <table-cell-cmp data-tag="th">
                      Values
                      <small>(1 portion)</small>
                    </table-cell-cmp>
                    <table-cell-cmp data-tag="th">
                      Values
                      <small>(100 gr)</small>
                    </table-cell-cmp>
                    <table-cell-cmp data-tag="th">Units</table-cell-cmp>
                  </table-row-cmp>
                </template>
                <template slot="tbody">
                  <table-row-cmp>
                    <table-cell-cmp>Energy</table-cell-cmp>
                    <table-cell-cmp>359</table-cell-cmp>
                    <table-cell-cmp>359</table-cell-cmp>
                    <table-cell-cmp>kcal</table-cell-cmp>
                  </table-row-cmp>
                  <table-row-cmp>
                    <table-cell-cmp>Energy</table-cell-cmp>
                    <table-cell-cmp>1521</table-cell-cmp>
                    <table-cell-cmp>1521</table-cell-cmp>
                    <table-cell-cmp>kJ</table-cell-cmp>
                  </table-row-cmp>
                  <table-row-cmp>
                    <table-cell-cmp>Fat</table-cell-cmp>
                    <table-cell-cmp>2,0</table-cell-cmp>
                    <table-cell-cmp>2,0</table-cell-cmp>
                    <table-cell-cmp>g</table-cell-cmp>
                  </table-row-cmp>
                  <table-row-cmp>
                    <table-cell-cmp class="tabbed">
                      of which satures
                    </table-cell-cmp>
                    <table-cell-cmp>0,5</table-cell-cmp>
                    <table-cell-cmp>0,5</table-cell-cmp>
                    <table-cell-cmp>g</table-cell-cmp>
                  </table-row-cmp>
                  <table-row-cmp>
                    <table-cell-cmp>Carbohydrate</table-cell-cmp>
                    <table-cell-cmp>70,2</table-cell-cmp>
                    <table-cell-cmp>70,2</table-cell-cmp>
                    <table-cell-cmp>g</table-cell-cmp>
                  </table-row-cmp>
                  <table-row-cmp>
                    <table-cell-cmp class="tabbed">
                      of which sugars
                    </table-cell-cmp>
                    <table-cell-cmp>3,5</table-cell-cmp>
                    <table-cell-cmp>3,5</table-cell-cmp>
                    <table-cell-cmp>g</table-cell-cmp>
                  </table-row-cmp>
                  <table-row-cmp>
                    <table-cell-cmp>Fibre</table-cell-cmp>
                    <table-cell-cmp>3,0</table-cell-cmp>
                    <table-cell-cmp>3,0</table-cell-cmp>
                    <table-cell-cmp>g</table-cell-cmp>
                  </table-row-cmp>
                  <table-row-cmp>
                    <table-cell-cmp>Protein</table-cell-cmp>
                    <table-cell-cmp>13,5</table-cell-cmp>
                    <table-cell-cmp>13,5</table-cell-cmp>
                    <table-cell-cmp>g</table-cell-cmp>
                  </table-row-cmp>
                  <table-row-cmp>
                    <table-cell-cmp>Salt</table-cell-cmp>
                    <table-cell-cmp>0,013</table-cell-cmp>
                    <table-cell-cmp>0,013</table-cell-cmp>
                    <table-cell-cmp>g</table-cell-cmp>
                  </table-row-cmp>
                </template>
              </table-cmp>
            </template>
          </focus-items-nutrition-allergens-cmp>
        </template>
        <template slot="tab-3-content"></template>
        <template slot="tab-4-content"></template>
      </focus-items-cmp>
      <div class="container">
        <div class="row">
          <div class="col-6">
            <dropdown-cmp-extended
              data-tag="button"
              :data-trigger-theme-classes="[{ selected: checkbox }]"
              :data-menu-theme-classes="['shadow-soft']"
            >
              fuffa
              <template v-if="checkbox">(1)</template>
              <template slot="after-icon">
                <svg class="icon-caret-down">
                  <use href="#icon-caret-down" />
                </svg>
              </template>
              <template slot="dropdown-menu">
                <input-checkbox-cmp-extended
                  v-model.trim="checkbox"
                  data-name="checkbox"
                  data-aria-label="Checkbox"
                  class="custom direction-column"
                  :data-checkbox-list="[
                    {
                      value: true,
                      label: 'Checkbox option 1',
                    },
                  ]"
                />
              </template>
            </dropdown-cmp-extended>
          </div>
          <div class="col-6">
            <dropdown-cmp-extended
              data-tag="button"
              :data-trigger-theme-classes="[
                { selected: multicheckbox.length > 0 },
              ]"
              :data-menu-theme-classes="['shadow-soft']"
            >
              fuffa
              <template v-if="multicheckbox.length > 0">
                ({{ multicheckbox.length }})
              </template>
              <template slot="after-icon">
                <svg class="icon-caret-down">
                  <use href="#icon-caret-down" />
                </svg>
              </template>
              <template slot="dropdown-menu">
                <input-checkbox-cmp-extended
                  v-model.trim="multicheckbox"
                  data-name="multicheckbox"
                  data-aria-label="Multi Checkbox"
                  class="custom direction-column"
                  :data-checkbox-list="[
                    {
                      value: 'option 1',
                      label: 'Checkbox option 1',
                    },
                    {
                      value: 'option 2',
                      label: 'Checkbox option 2',
                    },
                    {
                      value: 'option 3',
                      label: 'Checkbox option 3',
                      disabled: true,
                    },
                  ]"
                />
              </template>
            </dropdown-cmp-extended>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <title-separator-cmp>
              <template slot="title"><h3>TITOLO</h3></template>
            </title-separator-cmp>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <title-separator-cmp>
              <template slot="title"><h3>TITOLO</h3></template>
              <template slot="text">
                <p>
                  Small intro for the section content in less than ten or
                  fifteen words.
                </p>
              </template>
            </title-separator-cmp>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <title-separator-cmp>
              <template slot="title"><h3>TITOLO</h3></template>
              <template slot="social-share">
                <social-share-cmp>
                  <a href="#">
                    <svg>
                      <use href="#icon-linkedin" />
                    </svg>
                  </a>
                  <a href="#">
                    <svg>
                      <use href="#icon-youtube" />
                    </svg>
                  </a>
                  <a href="#">
                    <svg>
                      <use href="#icon-instagram" />
                    </svg>
                  </a>
                  <a href="#">
                    <svg>
                      <use href="#icon-facebook" />
                    </svg>
                  </a>
                </social-share-cmp>
              </template>
            </title-separator-cmp>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <title-separator-cmp>
              <template slot="title"><h3>TITOLO</h3></template>
              <template slot="cta">
                <cta-button-cmp-extended
                  class="btn-text"
                  data-url="#"
                  data-aria-label="Sustainability"
                >
                  Go to Sustainability
                  <template slot="after-icon">
                    <svg>
                      <use href="#icon-arrow-right" />
                    </svg>
                  </template>
                  <template slot="seo-link">
                    <a href="#">Go to Sustainability</a>
                  </template>
                </cta-button-cmp-extended>
              </template>
            </title-separator-cmp>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col">
            <accordion-cmp-extended
              :data-items="[
                {
                  title: 'How do I know which pasta to use?',
                  slot: 'accordion-item-1',
                },
                {
                  title: 'How do I know if a pasta is of good quality?',
                  slot: 'accordion-item-2',
                },
                {
                  title: 'How do I know which pasta to use?',
                  slot: 'accordion-item-3',
                },
              ]"
            >
              <p slot="accordion-item-1">
                The best way is cook pasta is to always use plenty of water, so
                be sure to use a large enough saucepan so the pasta has plenty
                of room to cook properly. The typical way to cook pasta is to
                boil water, add salt, add the pasta and then cook according to
                the instructions on the package. Feel free to use the 7-100-1000
                rule, which describes the relationship between salt, pasta and
                water. To cook 100g of pasta, you should have 7g of salt (about
                half a tablespoon) and 1000g of water. 1
              </p>
              <p slot="accordion-item-2">
                The best way is cook pasta is to always use plenty of water, so
                be sure to use a large enough saucepan so the pasta has plenty
                of room to cook properly. The typical way to cook pasta is to
                boil water, add salt, add the pasta and then cook according to
                the instructions on the package. Feel free to use the 7-100-1000
                rule, which describes the relationship between salt, pasta and
                water. To cook 100g of pasta, you should have 7g of salt (about
                half a tablespoon) and 1000g of water. 2
              </p>
              <p slot="accordion-item-3">
                The best way is cook pasta is to always use plenty of water, so
                be sure to use a large enough saucepan so the pasta has plenty
                of room to cook properly. The typical way to cook pasta is to
                boil water, add salt, add the pasta and then cook according to
                the instructions on the package. Feel free to use the 7-100-1000
                rule, which describes the relationship between salt, pasta and
                water. To cook 100g of pasta, you should have 7g of salt (about
                half a tablespoon) and 1000g of water. 3
              </p>
            </accordion-cmp-extended>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-4">
                <card-cmp data-href="#" class="normal">
                  <template slot="seo-link">
                    <a href="#"></a>
                  </template>
                  <template slot="image">
                    <image-cmp
                      width="1062"
                      height="596"
                      data-desktop-image="https://picsum.photos/1062/596"
                    />
                  </template>
                  <template slot="tags">
                    <tag-cmp>normal</tag-cmp>
                    <tag-cmp>normal</tag-cmp>
                  </template>
                  <template slot="category">
                    <h3>News</h3>
                  </template>
                  <template slot="title">
                    <h2>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </h2>
                  </template>
                  <template slot="date">January 1, 2022</template>
                  <template slot="cta">
                    <cta-button-cmp-extended class="btn-text" data-tag="button">
                      Read more
                      <template slot="after-icon">
                        <svg>
                          <use href="#icon-arrow-right" />
                        </svg>
                      </template>
                    </cta-button-cmp-extended>
                  </template>
                </card-cmp>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <card-cmp data-href="#" class="normal">
                  <template slot="seo-link">
                    <a href="#"></a>
                  </template>
                  <template slot="image">
                    <image-cmp
                      width="1062"
                      height="596"
                      data-desktop-image="https://picsum.photos/1062/596"
                    />
                  </template>
                  <template slot="tags">
                    <tag-cmp>normal</tag-cmp>
                    <tag-cmp>normal</tag-cmp>
                  </template>
                  <template slot="category">
                    <h3>News</h3>
                  </template>
                  <template slot="title">
                    <h2>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </h2>
                  </template>
                  <template slot="date">January 1, 2022</template>
                  <template slot="cta">
                    <cta-button-cmp-extended class="btn-text" data-tag="button">
                      Read more
                      <template slot="after-icon">
                        <svg>
                          <use href="#icon-arrow-right" />
                        </svg>
                      </template>
                    </cta-button-cmp-extended>
                  </template>
                </card-cmp>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <card-cmp data-href="#" class="normal">
                  <template slot="seo-link">
                    <a href="#"></a>
                  </template>
                  <template slot="image">
                    <image-cmp
                      width="1062"
                      height="596"
                      data-desktop-image="https://picsum.photos/1062/596"
                    />
                  </template>
                  <template slot="tags">
                    <tag-cmp>normal</tag-cmp>
                    <tag-cmp>normal</tag-cmp>
                  </template>
                  <template slot="category">
                    <h3>News</h3>
                  </template>
                  <template slot="title">
                    <h2>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </h2>
                  </template>
                  <template slot="date">January 1, 2022</template>
                  <template slot="cta">
                    <cta-button-cmp-extended class="btn-text" data-tag="button">
                      Read more
                      <template slot="after-icon">
                        <svg>
                          <use href="#icon-arrow-right" />
                        </svg>
                      </template>
                    </cta-button-cmp-extended>
                  </template>
                </card-cmp>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <card-cmp data-href="#" class="latest">
                  <template slot="seo-link">
                    <a href="#"></a>
                  </template>
                  <template slot="image">
                    <image-cmp
                      width="1062"
                      height="596"
                      data-desktop-image="https://picsum.photos/1062/596"
                    />
                  </template>
                  <template slot="tags">
                    <tag-cmp>latest</tag-cmp>
                    <tag-cmp>latest</tag-cmp>
                  </template>
                  <template slot="category">
                    <h3>News</h3>
                  </template>
                  <template slot="title">
                    <h2>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </h2>
                  </template>
                  <template slot="date">January 1, 2022</template>
                  <template slot="cta">
                    <cta-button-cmp-extended class="btn-text" data-tag="button">
                      Read more
                      <template slot="after-icon">
                        <svg>
                          <use href="#icon-arrow-right" />
                        </svg>
                      </template>
                    </cta-button-cmp-extended>
                  </template>
                </card-cmp>
              </div>
              <div class="col-12 col-md-6">
                <card-cmp data-href="#" class="latest">
                  <template slot="seo-link">
                    <a href="#"></a>
                  </template>
                  <template slot="image">
                    <image-cmp
                      width="1062"
                      height="596"
                      data-desktop-image="https://picsum.photos/1062/596"
                    />
                  </template>
                  <template slot="tags">
                    <tag-cmp>latest</tag-cmp>
                    <tag-cmp>latest</tag-cmp>
                  </template>
                  <template slot="category">
                    <h3>News</h3>
                  </template>
                  <template slot="title">
                    <h2>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </h2>
                  </template>
                  <template slot="date">January 1, 2022</template>
                  <template slot="cta">
                    <cta-button-cmp-extended class="btn-text" data-tag="button">
                      Read more
                      <template slot="after-icon">
                        <svg>
                          <use href="#icon-arrow-right" />
                        </svg>
                      </template>
                    </cta-button-cmp-extended>
                  </template>
                </card-cmp>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-10">
            <card-cmp data-href="#" class="highlight">
              <template slot="seo-link">
                <a href="#"></a>
              </template>
              <template slot="image">
                <image-cmp
                  width="1062"
                  height="596"
                  data-desktop-image="https://picsum.photos/1062/596"
                />
              </template>
              <template slot="tags">
                <tag-cmp>highlight</tag-cmp>
                <tag-cmp>highlight</tag-cmp>
              </template>
              <template slot="category">
                <h3>News</h3>
              </template>
              <template slot="title">
                <h2>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </h2>
              </template>
              <template slot="date">January 1, 2022</template>
              <template slot="cta">
                <cta-button-cmp-extended class="btn-text" data-tag="button">
                  Read more
                  <template slot="after-icon">
                    <svg>
                      <use href="#icon-arrow-right" />
                    </svg>
                  </template>
                </cta-button-cmp-extended>
              </template>
            </card-cmp>
          </div>
        </div>
      </div>
      <carousel-posts-cmp>
        <template slot="title-separator">
          <title-separator-cmp data-switch-breakpoint="lg">
            <template slot="title"><h3>JOIN THE CONVERSATION</h3></template>
            <template slot="cta">
              <cta-button-cmp-extended class="btn-text" data-url="#">
                Visit our LinkedIn page
                <template slot="after-icon">
                  <svg>
                    <use href="#icon-arrow-right" />
                  </svg>
                </template>
                <template slot="seo-link">
                  <a href="#">Visit our LinkedIn page</a>
                </template>
              </cta-button-cmp-extended>
            </template>
          </title-separator-cmp>
        </template>
        <template slot="carousel">
          <div
            v-for="n in 9"
            :key="`post-slide-${n}-${_uid}`"
            class="swiper-slide"
          >
            <card-cmp class="media" data-href="#">
              <template slot="seo-link">
                <a href="#"></a>
              </template>
              <template slot="image">
                <image-cmp
                  width="1062"
                  height="596"
                  data-desktop-image="https://picsum.photos/1062/596"
                />
              </template>
              <template slot="social">
                <svg>
                  <use href="#icon-linkedin" />
                </svg>
              </template>
              <template slot="text">
                <p>
                  (media) Packed with wholesome Mediterranean flavors, our
                  always al dente Integrale range made of 100% durum wheat is a
                  guaranteed natural source of fiber and a perfectly textured
                  asset for Mediterranean inspired menus.
                  <br />
                  <br />
                  Get in touch with us here for more information about the
                  range:
                  <a href="http://ow.ly/FNnz50I2BCN" target="_blank">
                    http://ow.ly/FNnz50I2BCN
                  </a>
                </p>
              </template>
              <template slot="cta">
                <cta-button-cmp-extended class="btn-text" data-tag="button">
                  View post
                  <template slot="after-icon">
                    <svg>
                      <use href="#icon-arrow-right" />
                    </svg>
                  </template>
                </cta-button-cmp-extended>
              </template>
            </card-cmp>
          </div>
        </template>
      </carousel-posts-cmp>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <card-product-cmp data-href="#">
              <template slot="seo-link">
                <a href="#"></a>
              </template>
              <template slot="title">
                <h2>LOREM</h2>
              </template>
              <template slot="image">
                <img src="https://i.imgur.com/5Fathhv.png" alt="" />
              </template>
              <template slot="text">
                <p>
                  Barilla is the brand of choice for chefs looking for products
                  that offer excellent cooking performances.
                </p>
              </template>
            </card-product-cmp>
          </div>
          <div class="col-12 col-md-6">
            <card-product-cmp data-href="#">
              <template slot="seo-link">
                <a href="#"></a>
              </template>
              <template slot="title">
                <h2>LOREM</h2>
              </template>
              <template slot="image">
                <img src="https://i.imgur.com/j7yMbBo.png" alt="" />
              </template>
              <template slot="text">
                <p>
                  Barilla is the brand of choice for chefs looking for products
                  that offer excellent cooking performances.
                </p>
              </template>
            </card-product-cmp>
          </div>
          <div class="col-12 col-md-6">
            <card-product-cmp data-href="#">
              <template slot="seo-link">
                <a href="#"></a>
              </template>
              <template slot="title">
                <h2>LOREM</h2>
              </template>
              <template slot="image">
                <img src="https://i.imgur.com/j7yMbBo.png" alt="" />
              </template>
              <template slot="text">
                <p>
                  Barilla is the brand of choice for chefs looking for products
                  that offer excellent cooking performances.
                </p>
              </template>
            </card-product-cmp>
          </div>
          <div class="col-12 col-md-6">
            <card-product-cmp class="range" data-href="#">
              <template slot="seo-link">
                <a href="#"></a>
              </template>
              <template slot="title">
                <h2>LOREM</h2>
              </template>
              <template slot="image">
                <img src="https://i.imgur.com/j7yMbBo.png" alt="" />
              </template>
              <template slot="text">
                <p>
                  Barilla is the brand of choice for chefs looking for products
                  that offer excellent cooking performances.
                </p>
              </template>
            </card-product-cmp>
          </div>
          <div class="col-12 col-md-6">
            <card-product-cmp class="range" data-href="#">
              <template slot="seo-link">
                <a href="#"></a>
              </template>
              <template slot="title">
                <h2>LOREM</h2>
              </template>
              <template slot="image">
                <img src="https://i.imgur.com/j7yMbBo.png" alt="" />
              </template>
              <template slot="text">
                <p>
                  Barilla is the brand of choice for chefs looking for products
                  that offer excellent cooking performances.
                </p>
              </template>
            </card-product-cmp>
          </div>
          <div class="col-12 col-md-6">
            <card-product-cmp
              class="product"
              data-href="#"
              data-quantity="1"
              data-measure-unit="kg"
            >
              <template slot="seo-link">
                <a href="#"></a>
              </template>
              <template slot="title">
                <h2>Spaghettini 3</h2>
              </template>
              <template slot="image">
                <image-cmp
                  width="290"
                  height="240"
                  data-desktop-image="https://picsum.photos/290/240"
                />
              </template>
            </card-product-cmp>
          </div>
          <div class="col-12 col-md-6">
            <card-product-cmp class="product" data-href="#">
              <template slot="seo-link">
                <a href="#"></a>
              </template>
              <template slot="title">
                <h2>Spaghettini 3</h2>
              </template>
              <template slot="image">
                <image-cmp
                  width="290"
                  height="240"
                  data-desktop-image="https://picsum.photos/290/240"
                />
              </template>
              <template slot="tag">
                <tag-cmp>Classic</tag-cmp>
              </template>
              <template slot="text">
                <p>1 Kg</p>
              </template>
            </card-product-cmp>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10">
            <pagination-cmp
              data-tag="a"
              :data-pages="[
                { label: '1', href: '#', target: '_blank' },
                { label: '2', href: '#', target: '_blank' },
                { label: '3', href: '#', target: '_blank' },
                { label: '4', href: '#', target: '_blank' },
              ]"
              :data-active-page-index="2"
            >
              <template slot="seo-pagination">
                <nav>
                  <a href="#" target="_blank">1</a>
                  <a href="#" target="_blank">2</a>
                  <a href="#" target="_blank">3</a>
                  <a href="#" target="_blank">4</a>
                </nav>
              </template>
            </pagination-cmp>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-8">
            <main-image-cmp>
              <image-cmp
                width="846"
                height="475"
                data-desktop-image="https://picsum.photos/846/475"
              />
              <template slot="figcaption">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Maiores deserunt voluptatem esse explicabo doloremque eum qui
                excepturi facilis rerum vitae obcaecati expedita molestiae
                blanditiis, minima in atque! Eveniet, asperiores nobis.
              </template>
            </main-image-cmp>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-8">
            <info-dropdown-cmp :data-difficulty="3">
              <template slot="dropdown">
                <dropdown-cmp-extended
                  data-tag="button"
                  :data-trigger-theme-classes="['btn-big']"
                  :data-menu-theme-classes="['shadow-soft']"
                >
                  {{ radio }}
                  <template slot="after-icon">
                    <svg class="icon-caret-down">
                      <use href="#icon-caret-down" />
                    </svg>
                  </template>
                  <template slot="dropdown-menu">
                    <input-radio-cmp-extended
                      v-model.trim="radio"
                      data-name="radio"
                      data-aria-label="Radio"
                      class="custom direction-column"
                      :data-radio-list="[
                        {
                          value: '1',
                          label: '1',
                        },
                        {
                          value: '2',
                          label: '2',
                        },
                        {
                          value: '3',
                          label: '3',
                        },
                        {
                          value: '4',
                          label: '4',
                        },
                      ]"
                    />
                  </template>
                </dropdown-cmp-extended>
              </template>
              <template slot="first-box-title">People</template>
              <template slot="first-box-copy">
                Choose the number of portions
              </template>
              <template slot="second-box-number">20’</template>
              <template slot="second-box-title">Preparation (average)</template>
              <template slot="second-box-copy">in minutes</template>
              <template slot="third-box-title">Hard</template>
              <template slot="third-box-copy">Difficulty level</template>
            </info-dropdown-cmp>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-8">
            <ingredients-steps-list-cmp>
              <template slot="ingredients">
                <ul>
                  <li>11.5 oz Spaghetti Selezione Oro Chef</li>
                  <li>6 anchovies desalted</li>
                  <li>3 garlic cloves, finely sliced</li>
                  <li>14 oz tomato peeled and cut in strips by the length</li>
                  <li>Chili pepper to taste</li>
                  <li>3 tbsp extra virgin olive oil</li>
                  <li>7 oz black olives, pitted</li>
                  <li>1 tbsp capers, desalted</li>
                  <li>1 tbsp parsley, chopped</li>
                  <li>Salt</li>
                </ul>
              </template>
              <template slot="title-separator">
                <title-separator-cmp>
                  <template slot="title"><h3>INGREDIENTS</h3></template>
                  <template slot="text">
                    <p>4 servings</p>
                  </template>
                </title-separator-cmp>
              </template>
            </ingredients-steps-list-cmp>
            <ingredients-steps-list-cmp>
              <template slot="procedure">
                <ol>
                  <li>
                    <span>1.</span>
                    oz Spaghetti Selezione Oro Chef
                  </li>
                  <li>
                    <span>2.</span>
                    6 anchovies desalted
                  </li>
                  <li>
                    <span>3.</span>
                    3 garlic cloves, finely sliced
                  </li>
                  <li>
                    <span>4.</span>
                    14 oz tomato peeled and cut in strips by the length
                  </li>
                  <li>
                    <span>5.</span>
                    Chili pepper to taste
                  </li>
                  <li>
                    <span>6.</span>
                    3 tbsp extra virgin olive oil
                  </li>
                  <li>
                    <span>7.</span>
                    7 oz black olives, pitted
                  </li>
                  <li>
                    <span>8.</span>
                    1 tbsp capers, desalted
                  </li>
                  <li>
                    <span>9.</span>
                    1 tbsp parsley, chopped
                  </li>
                  <li>
                    <span>10.</span>
                    Salt
                  </li>
                </ol>
              </template>
              <template slot="title-separator">
                <title-separator-cmp>
                  <template slot="title"><h3>PREPARATION</h3></template>
                </title-separator-cmp>
              </template>
            </ingredients-steps-list-cmp>
            <ingredients-steps-list-cmp>
              <template slot="ingredients">
                <ul>
                  <li>11.5 oz Spaghetti Selezione Oro Chef</li>
                  <li>6 anchovies desalted</li>
                  <li>3 garlic cloves, finely sliced</li>
                  <li>14 oz tomato peeled and cut in strips by the length</li>
                  <li>Chili pepper to taste</li>
                  <li>3 tbsp extra virgin olive oil</li>
                  <li>7 oz black olives, pitted</li>
                  <li>1 tbsp capers, desalted</li>
                  <li>1 tbsp parsley, chopped</li>
                  <li>Salt</li>
                </ul>
              </template>
              <template slot="title-separator">
                <title-separator-cmp>
                  <template slot="title"><h3>INGREDIENTS</h3></template>
                  <template slot="text">
                    <p>4 servings</p>
                  </template>
                </title-separator-cmp>
              </template>
              <template slot="card-product">
                <card-product-cmp
                  class="product"
                  data-href="#"
                  data-quantity="1"
                  data-measure-unit="kg"
                >
                  <template slot="seo-link">
                    <a href="#"></a>
                  </template>
                  <template slot="title">
                    <h2>Spaghettini 3</h2>
                  </template>
                  <template slot="image">
                    <image-cmp
                      width="290"
                      height="240"
                      data-desktop-image="https://picsum.photos/290/240"
                    />
                  </template>
                </card-product-cmp>
              </template>
            </ingredients-steps-list-cmp>
          </div>
        </div>
      </div>
      <chefs-note-banner-cmp class="with-picture">
        <template slot="picture">
          <image-cmp
            width="240"
            height="240"
            data-desktop-image="https://picsum.photos/240/240"
          />
        </template>
        <template slot="title-separator">
          <title-separator-cmp>
            <template slot="title"><h3>CHEF'S NOTE</h3></template>
          </title-separator-cmp>
        </template>
        <template slot="text">
          <p>For a vegetarian version, replace the anchovies with capers.</p>
        </template>
        <template slot="author">Pietro Leemann, Barilla Academy</template>
      </chefs-note-banner-cmp>
      <chefs-note-banner-cmp>
        <template slot="title-separator">
          <title-separator-cmp>
            <template slot="title"><h3>CHEF'S NOTE</h3></template>
          </title-separator-cmp>
        </template>
        <template slot="text">
          <p>For a vegetarian version, replace the anchovies with capers.</p>
        </template>
        <template slot="author">Pietro Leemann, Barilla Academy</template>
      </chefs-note-banner-cmp>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-8">
            <recipes-footer-cmp>
              <template slot="tags">
                <tag-cmp>Commercial</tag-cmp>
                <tag-cmp>Main Course</tag-cmp>
                <tag-cmp>Category 3</tag-cmp>
              </template>
              <template slot="title-separator">
                <title-separator-cmp>
                  <template slot="title"><h3>Share now</h3></template>
                  <template slot="social-share">
                    <social-share-cmp>
                      <a href="#">
                        <svg>
                          <use href="#icon-linkedin" />
                        </svg>
                      </a>
                      <a href="#">
                        <svg>
                          <use href="#icon-whatsapp" />
                        </svg>
                      </a>
                      <a href="#">
                        <svg>
                          <use href="#icon-email" />
                        </svg>
                      </a>
                      <a href="#">
                        <svg>
                          <use href="#icon-instagram" />
                        </svg>
                      </a>
                      <a href="#">
                        <svg>
                          <use href="#icon-twitter" />
                        </svg>
                      </a>
                    </social-share-cmp>
                  </template>
                </title-separator-cmp>
              </template>
            </recipes-footer-cmp>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <table-cmp class="three-cols-leading">
              <template slot="seo-table">
                <table>
                  <thead>
                    <tr>
                      <th>Nutritional values</th>
                      <th>Values (100 gr)</th>
                      <th>Units</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Energy</td>
                      <td>359</td>
                      <td>kcal</td>
                    </tr>
                    <tr>
                      <td>Energy</td>
                      <td>1521</td>
                      <td>kJ</td>
                    </tr>
                    <tr>
                      <td>Fat</td>
                      <td>2,0</td>
                      <td>g</td>
                    </tr>
                    <tr>
                      <td>of which satures</td>
                      <td>0,5</td>
                      <td>g</td>
                    </tr>
                    <tr>
                      <td>Carbohydrate</td>
                      <td>70,2</td>
                      <td>g</td>
                    </tr>
                    <tr>
                      <td>of which sugars</td>
                      <td>3,5</td>
                      <td>g</td>
                    </tr>
                    <tr>
                      <td>Fibre</td>
                      <td>3,0</td>
                      <td>g</td>
                    </tr>
                    <tr>
                      <td>Protein</td>
                      <td>13,5</td>
                      <td>g</td>
                    </tr>
                    <tr>
                      <td>Salt</td>
                      <td>0,013</td>
                      <td>g</td>
                    </tr>
                  </tbody>
                </table>
              </template>
              <template slot="thead">
                <table-row-cmp>
                  <table-cell-cmp data-tag="th">
                    Nutritional values
                  </table-cell-cmp>
                  <table-cell-cmp data-tag="th">
                    Values
                    <small>(100 gr)</small>
                  </table-cell-cmp>
                  <table-cell-cmp data-tag="th">Units</table-cell-cmp>
                </table-row-cmp>
              </template>
              <template slot="tbody">
                <table-row-cmp>
                  <table-cell-cmp>Energy</table-cell-cmp>
                  <table-cell-cmp>359</table-cell-cmp>
                  <table-cell-cmp>kcal</table-cell-cmp>
                </table-row-cmp>
                <table-row-cmp>
                  <table-cell-cmp>Energy</table-cell-cmp>
                  <table-cell-cmp>1521</table-cell-cmp>
                  <table-cell-cmp>kJ</table-cell-cmp>
                </table-row-cmp>
                <table-row-cmp>
                  <table-cell-cmp>Fat</table-cell-cmp>
                  <table-cell-cmp data-value="2,0"></table-cell-cmp>
                  <table-cell-cmp>g</table-cell-cmp>
                </table-row-cmp>
                <table-row-cmp>
                  <table-cell-cmp class="tabbed">
                    of which satures
                  </table-cell-cmp>
                  <table-cell-cmp data-value="0,5"></table-cell-cmp>
                  <table-cell-cmp>g</table-cell-cmp>
                </table-row-cmp>
                <table-row-cmp>
                  <table-cell-cmp>Carbohydrate</table-cell-cmp>
                  <table-cell-cmp data-value="70,2"></table-cell-cmp>
                  <table-cell-cmp>g</table-cell-cmp>
                </table-row-cmp>
                <table-row-cmp>
                  <table-cell-cmp class="tabbed">
                    of which sugars
                  </table-cell-cmp>
                  <table-cell-cmp data-value="3,5"></table-cell-cmp>
                  <table-cell-cmp>g</table-cell-cmp>
                </table-row-cmp>
                <table-row-cmp>
                  <table-cell-cmp>Fibre</table-cell-cmp>
                  <table-cell-cmp data-value="3,0"></table-cell-cmp>
                  <table-cell-cmp>g</table-cell-cmp>
                </table-row-cmp>
                <table-row-cmp>
                  <table-cell-cmp>Protein</table-cell-cmp>
                  <table-cell-cmp data-value="70,2"></table-cell-cmp>
                  <table-cell-cmp>g</table-cell-cmp>
                </table-row-cmp>
                <table-row-cmp>
                  <table-cell-cmp>Salt</table-cell-cmp>
                  <table-cell-cmp data-value="70,2"></table-cell-cmp>
                  <table-cell-cmp>g</table-cell-cmp>
                </table-row-cmp>
              </template>
            </table-cmp>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-8">
            <event-info-cmp>
              <template slot="when">
                <dl>
                  <dt><h2>When</h2></dt>
                  <dd>
                    <svg>
                      <use href="#icon-calendar" />
                    </svg>
                    <span>Tuesday 9 March 2021</span>
                  </dd>
                  <dd>
                    <svg>
                      <use href="#icon-clock" />
                    </svg>
                    <span>From 3.30 pm to 5.00 pm</span>
                  </dd>
                </dl>
              </template>
              <template slot="where">
                <dl>
                  <dt><h2>Where</h2></dt>
                  <dd>
                    <svg>
                      <use href="#icon-pinmap" />
                    </svg>
                    <span>
                      Federazione Italiana Cuochi
                      <br />
                      (YouTube Channel)
                    </span>
                  </dd>
                </dl>
              </template>
              <template slot="cta">
                <cta-button-cmp-extended class="btn-text" data-url="#">
                  Register
                  <template slot="after-icon">
                    <svg>
                      <use href="#icon-arrow-right" />
                    </svg>
                  </template>
                  <template slot="seo-link">
                    <a href="#">Register</a>
                  </template>
                </cta-button-cmp-extended>
              </template>
            </event-info-cmp>
          </div>
        </div>
      </div>
      <text-with-cmp>
        <template slot="text">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dignissimos
          natus quia, animi eaque nulla quo, esse dolorum consequuntur iure
          aperiam sint sequi rerum error, laudantium corporis eveniet debitis?
          Magnam, voluptatibus!
        </template>
        <template slot="video">
          <media-player-cmp data-youtube-video-id="LJzB2j6xAHs">
            <p>
              Nullam porttitor nunc id tempor scelerisque. Etiam dapibus tellus
              vel egestas tincidunt. Phasellus ut felis eleifend, interdum metus
              sit amet, maximus diam.
            </p>
          </media-player-cmp>
        </template>
      </text-with-cmp>
      <text-with-cmp>
        <template slot="text">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dignissimos
          natus quia, animi eaque nulla quo, esse dolorum consequuntur iure
          aperiam sint sequi rerum error, laudantium corporis eveniet debitis?
          Magnam, voluptatibus!
        </template>
        <template slot="images">
          <carousel-images-cmp>
            <template slot="carousel">
              <div
                v-for="n in 4"
                :key="`image-slide-${n}-${_uid}`"
                class="swiper-slide"
              >
                <main-image-cmp>
                  <template slot="zoom-in">
                    <svg>
                      <use href="#icon-zoom-in" />
                    </svg>
                  </template>
                  <image-cmp
                    width="846"
                    height="475"
                    data-desktop-image="https://picsum.photos/846/475"
                  />
                  <template slot="figcaption">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores deserunt voluptatem esse explicabo doloremque eum
                    qui excepturi facilis rerum vitae obcaecati expedita
                    molestiae blanditiis, minima in atque! Eveniet, asperiores
                    nobis.
                  </template>
                </main-image-cmp>
              </div>
            </template>
            <template slot="carousel-lightbox">
              <div
                v-for="n in 4"
                :key="`lightbox-slide-${n}-${_uid}`"
                class="swiper-slide"
              >
                <main-image-cmp>
                  <image-cmp
                    width="846"
                    height="475"
                    data-desktop-image="https://picsum.photos/846/475"
                  />
                </main-image-cmp>
              </div>
            </template>
          </carousel-images-cmp>
        </template>
      </text-with-cmp>
      <text-with-cmp>
        <template slot="text">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dignissimos
          natus quia, animi eaque nulla quo, esse dolorum consequuntur iure
          aperiam sint sequi rerum error, laudantium corporis eveniet debitis?
          Magnam, voluptatibus!
        </template>
        <template slot="quote">
          <quote-cmp>
            <template slot="photo">
              <image-cmp
                width="194"
                height="194"
                data-desktop-image="https://picsum.photos/194/194"
              />
            </template>
            <template slot="excerpt">
              “Praesent tellus eros, luctus nec consectetur nec, tincidunt a
              felis. Suspendisse potenti. Vestibulum iaculis turpis nulla, eu
              interdum odio laoreet ac. Aliquam elit justo, iaculis vitae mauris
              at, tempor eleifend nisl. Etiam ut neque sed ante ornare
              vestibulum. Nam et dolor a lorem scelerisque faucibus sed maximus
              urna. Sed non est at purus pulvinar euismod. Morbi tempus
              pellentesque leo in bibendum. Praesent semper nisi at est mattis
              placerat.”
            </template>
            <template slot="author">Pietro Barilla</template>
            <template slot="cite">
              Sed convallis tincidunt sagittis, nulla nec urna nunc
            </template>
          </quote-cmp>
        </template>
      </text-with-cmp>
      <text-with-cmp>
        <template slot="text">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dignissimos
          natus quia, animi eaque nulla quo, esse dolorum consequuntur iure
          aperiam sint sequi rerum error, laudantium corporis eveniet debitis?
          Magnam, voluptatibus!
        </template>
        <template slot="tags">
          <tag-cmp>test</tag-cmp>
          <tag-cmp>test</tag-cmp>
          <tag-cmp>test</tag-cmp>
        </template>
        <template slot="file">
          <media-download-cmp>
            <title-text-cmp>
              <template slot="overtitle">
                <span>Media</span>
              </template>
              <template slot="title">
                <h3>2021 Sustainability Report</h3>
              </template>
              <template slot="text">
                <p>
                  Vestibulum eleifend volutpat tempus. Duis aliquet augue elit,
                  non gravida elit tincidunt quis. Suspendisse ultricies eget
                  odio ac tempor. (18MB)
                </p>
              </template>
              <template slot="cta">
                <cta-button-cmp-extended
                  class="btn-text"
                  data-url="#"
                  data-aria-label="Download"
                >
                  Download
                  <template slot="after-icon">
                    <svg>
                      <use href="#icon-download" />
                    </svg>
                  </template>
                  <template slot="seo-link">
                    <a href="#">Download</a>
                  </template>
                </cta-button-cmp-extended>
              </template>
            </title-text-cmp>
          </media-download-cmp>
        </template>
      </text-with-cmp>
      <media-player-cmp class="highlighted" data-youtube-video-id="QCL5PQgqFV0">
        <p>
          Nullam porttitor nunc id tempor scelerisque. Etiam dapibus tellus vel
          egestas tincidunt. Phasellus ut felis eleifend, interdum metus sit
          amet, maximus diam.
        </p>
      </media-player-cmp>
      <podcast-link-cmp
        data-src="https://open.spotify.com/embed/episode/7gj6to2ZxFHaKR78szANJT?utm_source=generator"
      >
        <a href="#">
          <image-cmp
            width="117"
            height="32"
            data-desktop-image="https://picsum.photos/117/32"
            data-mobile-image="https://picsum.photos/44/44"
            data-switch-breakpoint="lg"
            data-image-alt="Spotify"
          />
        </a>
        <a href="#">
          <image-cmp
            width="117"
            height="32"
            data-desktop-image="https://picsum.photos/117/32"
            data-mobile-image="https://picsum.photos/44/44"
            data-switch-breakpoint="lg"
            data-image-alt="Apple Podcast"
          />
        </a>
        <a href="#">
          <image-cmp
            width="117"
            height="32"
            data-desktop-image="https://picsum.photos/117/32"
            data-mobile-image="https://picsum.photos/44/44"
            data-switch-breakpoint="lg"
            data-image-alt="Amazon Music"
          />
        </a>
        <a href="#">
          <image-cmp
            width="117"
            height="32"
            data-desktop-image="https://picsum.photos/117/32"
            data-mobile-image="https://picsum.photos/44/44"
            data-switch-breakpoint="lg"
            data-image-alt="Google Podcast"
          />
        </a>
      </podcast-link-cmp>
      <guidelines-cmp />
    </main>
    <footer-cmp>
      <template slot="columns">
        <grid-column-cmp class="col-6 col-md-3">
          <ul class="navbar-nav">
            <li>
              <a href="#">About us</a>
            </li>
            <li>
              <a href="#">Products</a>
            </li>
            <li>
              <a href="#">Services</a>
            </li>
            <li>
              <a href="#">Recipes</a>
            </li>
          </ul>
        </grid-column-cmp>
        <grid-column-cmp class="col-6 col-md-3">
          <ul class="navbar-nav">
            <li>
              <a href="#">News & Media</a>
            </li>
            <li>
              <a href="#">Sustainability</a>
            </li>
            <li>
              <a href="#">Our approach</a>
            </li>
            <li>
              <a href="#">Cooking tips & resources</a>
            </li>
          </ul>
        </grid-column-cmp>
        <grid-column-cmp class="col-6 col-md-3">
          <ul class="navbar-nav small-text-mobile">
            <li>
              <a href="#">Privacy policy</a>
            </li>
            <li>
              <a href="#">Terms & Conditions</a>
            </li>
            <li>
              <a href="#">Cookies policy</a>
            </li>
            <li>
              <a href="#">Barilla GDPR</a>
            </li>
          </ul>
        </grid-column-cmp>
      </template>
      <template slot="legal-text">
        © Copyright 2022. Barilla for Professionals is part of the Barilla
        Group.
      </template>
      <template slot="social-share">
        <social-share-cmp>
          <a href="#">
            <svg>
              <use href="#icon-linkedin" />
            </svg>
          </a>
          <a href="#">
            <svg>
              <use href="#icon-youtube" />
            </svg>
          </a>
          <a href="#">
            <svg>
              <use href="#icon-instagram" />
            </svg>
          </a>
          <a href="#">
            <svg>
              <use href="#icon-facebook" />
            </svg>
          </a>
        </social-share-cmp>
      </template>
    </footer-cmp>
    <language-modal-cmp>
      <template slot="title">Choose your Country and Language</template>
    </language-modal-cmp>
    <svg-icons-cmp />
  </div>
</template>

<script>
import { customDecimalSeparator } from '@/helpers/utils';
import PodcastLinkCmp from './podcast-link-cmp.vue';

export default {
  name: 'MySimpleExamplePage',
  components: { PodcastLinkCmp },
  setup() {
    return { customDecimalSeparator };
  },
  data() {
    return {
      radio: '4',
      checkbox: false,
      multicheckbox: [],
    };
  },
  watch: {
    $currentScrollPosition: {
      handler() {
        document.documentElement.style.setProperty(
          '--scroll-y',
          `${window.scrollY}px`
        );
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.MySimpleExamplePage {
  .main-content {
    min-height: calc(100vh - var(--header-height));
  }
}
</style>
