/* Mutations, actions and getters */
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    hero_header_label: '',
    filter_by_label: '',
    noProductsLabel: '',
    isSelectCountryVisibleInFirstLevel: false,
    isSelectCountryVisibleInSecondLevel: false,
    countriesFilterLabel: '',
    net_weight_label: '',
    related_products_title: '',
    related_recipes_title: '',
    products_view_all_label: '',
    products_view_all_url: '',
    recipes_view_all_label: '',
    recipes_view_all_url: '',
    contact_us_url: '',
    contact_us_label_detail: '',
    contact_us_label_category: '',
    all_label: 'All',
    override_cta_contact_us: '',
  },
  actions,
  mutations,
  getters,
};
