var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.active)?_c('div',{class:[
    'GigyaScreen',
    {
      loading: !_vm.loaded,
    } ]},[(_vm.loaded)?_c('div',[(_vm.screenHasTitleFormat)?_c('title-format-cmp',[_c('title-text-cmp',[(_vm.hasTitle)?_c('template',{slot:"title"},[_c(_vm.title.tag,{tag:"component",staticClass:"n-heading-2",domProps:{"innerHTML":_vm._s(_vm.title.content)}})],1):_vm._e(),(_vm.hasText)?_c('template',{slot:"text"},[_c(_vm.text.tag,{tag:"component",staticClass:"a-paragraph-2",domProps:{"innerHTML":_vm._s(_vm.text.content)}})],1):_vm._e()],2)],1):_vm._e(),(_vm.dataStartScreen === 'gigya-update-profile-screen')?_c('div',{class:[
        {
          'pt-m': _vm.screenHasTitleFormat,
        } ]},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{class:[
                'tabs-btns',
                {
                  'd-flex': _vm.$viewportWidth >= 768,
                  'justify-content-center': _vm.$viewportWidth >= 768,
                } ]},[_c('div',[_c('cta-button-cmp-extended',{class:[
                    'btn-block',
                    {
                      'btn-primary':
                        _vm.currentScreen === 'gigya-update-profile-screen',
                      'btn-secondary':
                        _vm.currentScreen !== 'gigya-update-profile-screen',
                    } ],on:{"click":function($event){$event.preventDefault();_vm.currentScreen !== 'gigya-update-profile-screen'
                      ? _vm.switchScreen('gigya-update-profile-screen')
                      : null}}},[_vm._v(" "+_vm._s(_vm.edit_profile_label)+" ")])],1),_c('div',{class:[{ 'pt-m': _vm.$viewportWidth < 768 }]},[_c('cta-button-cmp-extended',{class:[
                    'btn-block',
                    {
                      'btn-primary': _vm.currentScreen === 'gigya-privacy-screen',
                      'btn-secondary':
                        _vm.currentScreen !== 'gigya-privacy-screen',
                    } ],on:{"click":function($event){$event.preventDefault();_vm.currentScreen !== 'gigya-privacy-screen'
                      ? _vm.switchScreen('gigya-privacy-screen')
                      : null}}},[_vm._v(" "+_vm._s(_vm.edit_privacy_label)+" ")])],1)])])])])]):_vm._e(),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-10 col-xl-8"},[_c('div',{class:[
              {
                'pt-m':
                  _vm.dataStartScreen === 'gigya-update-profile-screen' ||
                  _vm.screenHasTitleFormat,
              } ],attrs:{"id":_vm.relatedId}})])])])],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }