<template>
  <div :class="['SideBySideCmp', { intersected: intersected }]">
    <div class="container">
      <div
        :class="[
          'row flex-column flex-md-row align-items-md-center position-relative',
        ]"
      >
        <div
          v-if="themeVariant.includes('image-left') || xsOnly"
          :class="[
            'col-12',
            {
              'col-md-5': !themeVariant.includes('with-carousel'),
              'col-md-6 col-lg-5': themeVariant.includes('with-carousel'),
              'col-lg-6': themeVariant.includes('image-sticky'),
              'offset-md-1':
                !themeVariant.includes('image-sticky') &&
                !themeVariant.includes('with-carousel'),
              'offset-lg-1': themeVariant.includes('with-carousel'),
            },
          ]"
        >
          <div class="image-container">
            <slider-cmp-extended
              v-if="themeVariant.includes('with-carousel')"
              :data-bootstrap-container-classes="['container-fluid', 'px-0']"
              :data-component-slides="true"
              :data-options="{
                observer: true,
                slidesPerView: 1,
                navigation: false,
                enabled: $slots.carousel.length === 1 ? false : true,
              }"
            >
              <template slot="custom-swipe-icon">
                <svg>
                  <use href="#icon-scroller" />
                </svg>
              </template>
              <template slot="components">
                <slot name="carousel" />
              </template>
            </slider-cmp-extended>
            <figure v-else :class="['side-image', ...dataImageThemeClasses]">
              <slot name="image" />
            </figure>
          </div>
        </div>
        <div
          :class="[
            'col-12',
            {
              'col-md-6 col-lg-5': themeVariant.includes('image-sticky'),
              'col-md-5':
                !themeVariant.includes('image-sticky') &&
                !themeVariant.includes('with-carousel'),
              'col-md-6 col-lg-5 col-xl-4 offset-xl-1':
                themeVariant.includes('with-carousel'),
              'offset-md-1':
                themeVariant.includes('image-right') &&
                !themeVariant.includes('with-carousel'),
              'offset-lg-1':
                themeVariant.includes('image-right') &&
                themeVariant.includes('with-carousel'),
            },
          ]"
        >
          <div class="text-container">
            <slot />
          </div>
        </div>
        <div
          v-if="themeVariant.includes('image-right') && mdAndUp"
          :class="[
            {
              'col-md-5': !themeVariant.includes('with-carousel'),
              'col-md-6 col-lg-5 offset-xl-1':
                themeVariant.includes('with-carousel'),
              'col-lg-6': themeVariant.includes('image-sticky'),
            },
          ]"
        >
          <div class="image-container">
            <slider-cmp-extended
              v-if="themeVariant.includes('with-carousel')"
              :data-bootstrap-container-classes="['container-fluid', 'px-0']"
              :data-component-slides="true"
              :data-options="{
                observer: true,
                slidesPerView: 1,
                navigation: false,
                enabled: $slots.carousel.length === 1 ? false : true,
              }"
            >
              <template slot="custom-swipe-icon">
                <svg>
                  <use href="#icon-scroller" />
                </svg>
              </template>
              <template slot="components">
                <slot name="carousel" />
              </template>
            </slider-cmp-extended>
            <figure v-else :class="['side-image', ...dataImageThemeClasses]">
              <slot name="image" />
            </figure>
          </div>
        </div>
        <div
          v-if="themeVariant.includes('animated')"
          v-intersect.once="onIntersect"
          class="intersect"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SliderCmpExtended from './slider-cmp-extended';
import { xsOnly, mdAndUp } from '@/helpers/breakpoints.js';
import Intersect from '@frankhoodbs/lib/src/directives/intersect';

export default {
  name: 'SideBySideCmp',
  components: { SliderCmpExtended },
  directives: {
    Intersect,
  },
  props: {
    dataImageThemeClasses: {
      type: Array,
      default: null,
    },
  },
  setup() {
    return { xsOnly, mdAndUp };
  },
  data() {
    return {
      themeVariant: [],
      intersected: false,
    };
  },
  mounted() {
    if (this.$el.classList.contains('animated')) {
      this.themeVariant.push('animated');
    }
    if (this.$el.classList.contains('image-left')) {
      this.themeVariant.push('image-left');
    } else if (this.$el.classList.contains('image-right')) {
      this.themeVariant.push('image-right');
    } else {
      this.themeVariant.push('image-left');
    }
    if (this.$el.classList.contains('image-sticky')) {
      this.themeVariant.push('image-sticky');
    } else if (this.$el.classList.contains('with-carousel')) {
      this.themeVariant.push('with-carousel');
    }

    if (
      this.$el.classList.contains('image-sticky') &&
      (this.$el.classList.contains('image-right') ||
        this.$el.classList.contains('image-left'))
    ) {
      this.$el.classList.add('animationForTransparentsImgs');
    }
  },
  methods: {
    onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.intersected = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.SideBySideCmp {
  .side-image {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      width: calc(
        100% + var(--#{$variable-prefix}gutter-x, #{$grid-gutter-width})
      );
    }

    img {
      width: 100%;
      height: auto;
      transition: transform 1s;
      transform: scale(1.2);
    }

    &:hover {
      img {
        transform: scale(1);
      }
    }
  }

  .text-container {
    padding-top: 24px;

    @include media-breakpoint-up(md) {
      padding-top: 0;
    }

    .TitleTextCmp {
      --title-text-text-align: left;
      --title-text-icon-wrapper-padding: 0 0 8px;
      --title-text-overtitle-wrapper-padding: 0 0 8px;
      @include override-responsive-typography-properties(
        $font-secondary,
        n-heading-3,
        --title-text-title-wrapper
      );

      @include media-breakpoint-up(md) {
        --title-text-icon-wrapper-padding: 0 0 24px;
      }
    }
  }

  &.image-left {
    .text-container {
      @include media-breakpoint-up(md) {
        padding-left: 16px;
      }

      @include media-breakpoint-up(lg) {
        padding-left: 80px;
      }
    }
  }

  &.image-right {
    .side-image {
      @include media-breakpoint-up(lg) {
        margin-left: calc(
          var(--#{$variable-prefix}gutter-x, #{$grid-gutter-width}) * -1
        );
      }
    }

    .text-container {
      @include media-breakpoint-up(md) {
        padding-right: 16px;
      }

      @include media-breakpoint-up(lg) {
        padding-right: 80px;
      }
    }
  }

  &.image-sticky {
    .image-container {
      @include media-breakpoint-up(md) {
        position: relative;
        height: 238px;

        .side-image {
          position: absolute;
          top: 0;
          width: 50vw !important;
          height: 100% !important;

          img {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        height: 358px;
      }

      @include media-breakpoint-up(xl) {
        height: 530px;
      }
    }

    &.image-left {
      .image-container {
        @include media-breakpoint-up(md) {
          left: 0;

          .side-image {
            border-radius: 0 8px 8px 0;
            right: 0;
          }
        }
      }

      .text-container {
        @include media-breakpoint-up(lg) {
          padding-left: 40px;
        }
      }
    }

    &.image-right {
      .image-container {
        @include media-breakpoint-up(md) {
          right: 0;

          .side-image {
            border-radius: 8px 0 0 8px;
            left: 0;
          }
        }

        @include media-breakpoint-up(lg) {
          .side-image {
            margin-left: 0;
          }
        }
      }

      .text-container {
        @include media-breakpoint-up(lg) {
          padding-right: 40px;
        }
      }
    }

    .TitleTextCmp {
      --title-text-overtitle-wrapper-padding: 0;

      @include media-breakpoint-up(md) {
        @include override-responsive-typography-properties(
          $font-secondary,
          n-heading-2,
          --title-text-title-wrapper
        );
      }
    }
  }

  &.with-carousel {
    .text-container {
      padding: 40px 0 0 !important;

      @include media-breakpoint-up(md) {
        padding: 0 !important;
      }

      .TitleTextCmp {
        @include override-responsive-typography-properties(
          $font-secondary,
          n-heading-2,
          --title-text-title-wrapper
        );

        ::v-deep .CtaButtonCmpExtended:not(.btn-text):not(.btn-text-reversed) {
          @include media-breakpoint-down(md) {
            // btn-small
            --cta-button-padding: 12px 30px 11px;
          }

          span.text {
            @include media-breakpoint-up(lg) {
              white-space: nowrap;
            }
          }
        }
      }
    }

    ::v-deep .SliderCmpExtended {
      .slider-content {
        padding-bottom: 82px;

        @include media-breakpoint-up(md) {
          padding-bottom: 59px;
        }

        @include media-breakpoint-up(lg) {
          padding-bottom: 67px;
        }

        @include media-breakpoint-up(xl) {
          padding-bottom: 82px;
        }

        .swiper-container {
          .swiper-slide {
            height: auto;
            text-align: center;

            figure {
              display: inline-block;
              overflow: hidden;

              .ImageCmp {
                width: auto;
                max-width: 100%;
                height: auto;
                transition: transform 1s;
                transform: scale(1.2);
              }

              &:hover {
                .ImageCmp {
                  transform: scale(1);
                }
              }
            }
          }

          .swiper-pagination {
            padding: 40px 0 30px;

            @include media-breakpoint-up(md) {
              padding: 32px 0 15px;
            }

            @include media-breakpoint-up(lg) {
              padding: 40px 0 15px;
            }

            @include media-breakpoint-up(xl) {
              padding: 55px 0 15px;
            }
          }
        }
      }
    }
  }

  &.animationForTransparentsImgs {
    .side-image {
      img {
        transform: scale(1);
      }
      &:hover {
        img {
          transform: scale(0.9);
        }
      }
    }
    &.with-carousel {
      ::v-deep .SliderCmpExtended {
        .slider-content {
          .swiper-container {
            .swiper-slide {
              figure {
                .ImageCmp {
                  transform: scale(1);
                }

                &:hover {
                  .ImageCmp {
                    transform: scale(0.9);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /* Animation */
  &.animated {
    .intersect {
      position: absolute;
      top: 33.333%;
      right: 0;
      left: 0;
      height: 1px;
    }

    .image-container,
    .text-container {
      opacity: 0;
      transition: opacity 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955),
        transform 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }

    &.image-left {
      .image-container {
        transform: translateX(-50vw);
      }

      .text-container {
        transform: translateX(50vw);
      }
    }

    &.image-right {
      .image-container {
        transform: translateX(50vw);
      }

      .text-container {
        transform: translateX(-50vw);
      }
    }

    &.intersected {
      .image-container,
      .text-container {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
}
</style>
