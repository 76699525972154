<template>
  <js-loader
    v-if="canInit"
    class="GigyaInitializer"
    data-id="gigya-initializer"
    :data-src="`//cdns.${dataCenter}/js/gigya.js?apiKey=${apiKey}&lang=${lang}`"
  />
</template>

<script>
import {
  debug,
  qsObj,
  colorLog,
  zzz,
  prohibited,
  ok,
  rightArrow,
  info,
  brain,
  wait,
  comet,
  bell,
} from '@/helpers/utils';
import { mapState } from 'vuex';

export default {
  name: 'GigyaInitializer',
  data() {
    return {
      canInit: false,
    };
  },
  computed: {
    ...mapState('gigya', [
      'active',
      'dataCenter',
      'apiKey',
      'lang',
      'goTo',
      'home_page_url',
      'gigya_profile_page_url',
    ]),
    redirect() {
      return qsObj && 'goTo' in qsObj && qsObj.goTo ? qsObj.goTo : null;
    },
    errorCode() {
      return qsObj && 'errorCode' in qsObj && qsObj.errorCode
        ? qsObj.errorCode
        : null;
    },
  },
  watch: {
    apiKey: {
      handler(val) {
        if (val) {
          this.canInit = true;
        }
      },
      immediate: true,
    },
  },
  created() {
    debug &&
      colorLog({
        icon: wait,
        label: 'cdc',
        message: `Initializer component created`,
        themeName: 'blue',
      });
    document.addEventListener('screensetGigyaReady', () => {
      this.screensetGigyaReady();
    });
    document.addEventListener('screensetGlobalOnLogin', (event) => {
      debug &&
        colorLog({
          icon: bell,
          label: 'cdc',
          message: `screensetGlobalOnLogin`,
          themeName: 'vue',
        });
      this.screensetGlobalOnLogin(event);
    });
    document.addEventListener('screensetGlobalOnLogout', (event) => {
      debug &&
        colorLog({
          icon: bell,
          label: 'cdc',
          message: `screensetGlobalOnLogout`,
          themeName: 'vue',
        });
      this.screensetGlobalOnLogout(event);
    });
    document.addEventListener('screensetOnAfterScreenLoad', (event) => {
      debug &&
        colorLog({
          icon: bell,
          label: 'cdc',
          message: `screensetOnAfterScreenLoad`,
          themeName: 'vue',
        });
      this.$store.dispatch(
        'gigya/setGigyaCurrentScreen',
        event.detail.currentScreen
      );
    });
    document.addEventListener('screensetOnHide', (event) => {
      debug &&
        colorLog({
          icon: bell,
          label: 'cdc',
          message: `screensetOnHide`,
          themeName: 'vue',
        });
    });
    document.addEventListener('screensetOnError', (event) => {
      debug &&
        colorLog({
          icon: bell,
          label: 'cdc',
          message: `screensetOnError`,
          themeName: 'vue',
        });
    });
  },
  mounted() {
    debug &&
      colorLog({
        icon: wait,
        label: 'cdc',
        message: `Initializer component mounted`,
        themeName: 'blue',
      });
  },
  methods: {
    async screensetGlobalOnLogin(event) {
      /*
        document.addEventListener('screensetGlobalOnLogin', function (event) {
          if(event.detail.newUser) {
            //New user just logged in after a social registration
          } else if (window.location.search.indexOf('errorCode=206005') !== -1) {
            //New user just logged in after verifying his email (standard registration)
            //"errorCode 206005" is not an actual error, 2xxxxx indicates a success
          } else {
            //Standard Login
          }
        });
      */

      try {
        let result = await this.$store.dispatch('gigya/login', {
          guid: event.UID || event.detail.UID,
          uid_signature: event.UIDSignature || event.detail.UIDSignature,
          signature_timestamp:
            event.signatureTimestamp || event.detail.signatureTimestamp,
        });

        debug &&
          colorLog({
            icon: brain,
            label: 'cdc',
            message: `redirect ${this.redirect}`,
            themeName: 'blue',
          });
        debug &&
          colorLog({
            icon: brain,
            label: 'cdc',
            message: `errorCode  ${this.errorCode}`,
            themeName: 'blue',
          });

        if (!this.errorCode) {
          if (result.status === 200) {
            window.location.href = this.redirect
              ? this.redirect
              : this.goTo
              ? this.goTo
              : this.gigya_profile_page_url;
          }
        }
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
      }
    },
    async screensetGlobalOnLogout(event) {
      debug &&
        colorLog({
          icon: bell,
          label: 'cdc',
          message: `EVENT onLogout`,
          themeName: 'blue',
        });

      try {
        let result = await this.$store.dispatch('gigya/logout');

        debug &&
          colorLog({
            icon: brain,
            label: 'cdc',
            message: `redirect ${this.redirect}`,
            themeName: 'blue',
          });
        debug &&
          colorLog({
            icon: brain,
            label: 'cdc',
            message: `errorCode  ${this.errorCode}`,
            themeName: 'blue',
          });

        if (!this.errorCode) {
          if (result.status === 200) {
            window.location.href = this.redirect
              ? this.redirect
              : this.goTo
              ? this.goTo
              : this.home_page_url;
          }
        }
      } catch (e) {
        debug &&
          colorLog({
            icon: prohibited,
            label: 'cdc',
            message: `Silent Fail logout because no user is authenticated`,
            themeName: 'blue',
          });
      }
    },
    screensetGigyaReady() {
      const redirect =
        qsObj && 'goTo' in qsObj && qsObj.goTo ? qsObj.goTo : null;
      const errorCode =
        qsObj && 'errorCode' in qsObj && qsObj.errorCode
          ? qsObj.errorCode
          : null;
      const me = this;

      debug &&
        colorLog({
          icon: ok,
          label: 'cdc',
          message: `Initializer component screensetGigyaReady event received`,
          themeName: 'blue',
        });

      debug &&
        colorLog({
          icon: wait,
          label: 'cdc',
          message: `Call to getAccountInfo`,
          themeName: 'blue',
        });
      window.gigya.accounts.getAccountInfo({
        include: 'id_token,data,profile,preferences',
        callback: async (data) => {
          debug &&
            colorLog({
              icon: ok,
              label: 'cdc',
              message: `getAccountInfo callback`,
              themeName: 'blue',
            });
          // eslint-disable-next-line
          debug && console.groupCollapsed('CALLBACK DATA');
          // eslint-disable-next-line
          debug && console.log('data', data);
          // eslint-disable-next-line
          debug && console.log('data profile', data.profile);
          // eslint-disable-next-line
          debug && console.groupEnd();

          // if (response.errorCode == 0) {
          //   //User is logged in!
          // } else {
          //   if (response.errorCode == 403005) {
          //     //User not logged in, 403005 = Unauthorized
          //   } else {
          //     //Error in the API call
          //   }
          // }

          if (data.profile) {
            try {
              let result = await me.$store.dispatch('gigya/login', {
                guid: data.UID,
                uid_signature: data.UIDSignature,
                signature_timestamp: data.signatureTimestamp,
              });

              // eslint-disable-next-line
              debug && console.groupCollapsed('DATA PROFILE');
              // eslint-disable-next-line
              debug && console.log(result);
              // eslint-disable-next-line
              debug && console.groupEnd();

              if (result.status === 200) {
                await me.$store.dispatch('gigya/setGigyaUser', data.profile);
                await me.$store.dispatch('gigya/setGigyaUserData', data.data);
                await me.$store.dispatch(
                  'gigya/setGigyaPreferences',
                  data.preferences
                );
              }
            } catch (e) {
              debug &&
                colorLog({
                  icon: prohibited,
                  label: 'cdc',
                  message: `Catch getAccountInfo callback something wrong with login and set data on store`,
                  themeName: 'blue',
                });
            } finally {
              await me.onGigyaLoaded();
            }
          } else {
            // eslint-disable-next-line
            debug && console.groupCollapsed('DATA PROFILE');
            // eslint-disable-next-line
            debug && console.log('no profile data');
            // eslint-disable-next-line
            debug && console.groupEnd();

            try {
              await me.$store.dispatch('gigya/logout');
            } catch (e) {
              debug &&
                colorLog({
                  icon: prohibited,
                  label: 'cdc',
                  message: `Logout fail because no user logged`,
                  themeName: 'blue',
                });
            } finally {
              await me.onGigyaLoaded();
            }
          }
        },
      });
    },
    async onGigyaLoaded() {
      await this.$store.dispatch('gigya/setGigyaLoaded', true);
    },
  },
};
</script>

<style scoped></style>
