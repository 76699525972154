<template>
  <div class="StepCardCmp">
    <div ref="$card" :class="['card', ...cardClasses]">
      <div class="title-wrapper">
        <slot name="title" />
      </div>
      <div class="text-wrapper">
        <slot name="text" />
      </div>
    </div>
    <div v-if="themeVariant === 'with-separator'" class="separator">
      <svg :style="separatorStyle">
        <use href="#icon-polygon-separator" />
      </svg>
    </div>
  </div>
</template>

<script>
import { castPropToType } from '@/helpers/cms-support';

export default {
  name: 'StepCardCmp',
  props: {
    dataCardClasses: {
      type: [Array, String],
      default: null,
    },
  },
  setup() {
    return {
      castPropToType,
    };
  },
  data() {
    return {
      themeVariant: null,
      separatorStyle: null,
    };
  },
  computed: {
    cardClasses() {
      return this.dataCardClasses
        ? this.castPropToType(this.dataCardClasses, 'array')
        : null;
    },
  },
  mounted() {
    this.themeVariant = this.$el.classList.contains('with-separator')
      ? 'with-separator'
      : 'default';

    (() => {
      let doCheck = () => {
        if (this.$refs.$card) {
          this.separatorStyle = {
            color: getComputedStyle(this.$refs.$card).getPropertyValue(
              'background-color'
            ),
          };
        } else {
          setTimeout(doCheck, 50);
        }
      };
      doCheck();
    })();
  },
};
</script>

<style lang="scss" scoped>
.StepCardCmp {
  display: flex;

  .card {
    width: 194px;
    padding: 16px;
    background-color: $color-blue-10;
    border-radius: 8px;

    @include media-breakpoint-up(md) {
      width: 309px;
      padding: 24px;
    }

    .title-wrapper {
      @include responsive-typography-properties($font-secondary, n-paragraph-1);
      color: $color-blue;
    }

    .text-wrapper {
      @include responsive-typography-properties($font-primary, a-paragraph-3);
      color: inherit;
      padding-top: 16px;

      @include media-breakpoint-up(md) {
        padding-top: 24px;
      }
    }
  }

  &.with-separator {
    .card {
      border-radius: 8px 0 0 8px;
    }

    .separator {
      width: 32px;
      height: 100%;
      margin-left: -1px;

      @include media-breakpoint-up(md) {
        width: 55px;
      }

      svg {
        width: 100%;
        height: 100%;
        color: $color-blue-10;
      }
    }
  }
}
</style>
