<template>
  <footer class="FooterCmp">
    <nav class="navbar">
      <div class="container">
        <div class="row">
          <slot name="columns" />
          <div class="col-12 col-md-3">
            <button
              class="language-chooser"
              type="button"
              aria-label="Open language selection modal"
              @click="onShowModal"
            >
              <span>{{ localeLabel }}</span>
              <svg>
                <use href="#icon-country-selection" />
              </svg>
            </button>
          </div>
        </div>
        <div
          v-if="
            hasSlot($slots['legal-text'], $scopedSlots['legal-text']) ||
            hasSlot($slots['social-share'], $scopedSlots['social-share'])
          "
          class="row bottom-row align-items-center"
        >
          <div class="col-12 col-md-9">
            <span class="legal-text">
              <slot name="legal-text" />
            </span>
          </div>
          <div class="col-12 col-md-3 social-share-container">
            <slot name="social-share" />
          </div>
        </div>
      </div>
    </nav>
  </footer>
</template>

<script>
import { hasSlot } from '@/helpers/cms-support';
import { debug, currentLang } from '@/helpers/utils';
/**country_label:"Global"
href:"#"
language_code:"global"
site_code:"en"
tongue_label:"Engli */
export default {
  name: 'FooterCmp',
  setup() {
    return { hasSlot };
  },
  computed: {
    localeLabel() {
      if (!this.$store.state.config.ordered_languages.length) {
        return '';
      }
      const langObj = currentLang(this.$store.state.config.ordered_languages);

      return `${
        langObj.country_label
      } (${langObj.language_code.toUpperCase()})`;
    },
  },
  methods: {
    onShowModal() {
      this.$modal.show('language-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.FooterCmp {
  background-color: $color-blue-10;
  padding-block: 15px 32px;

  @include media-breakpoint-up(md) {
    padding-block: 23px 40px;
  }

  .navbar {
    ::v-deep ul.navbar-nav {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 15px;

      & > li {
        font-size: 0;
        margin-top: 17px;

        & > a {
          display: block;
          color: $color-blue;
          @include responsive-typography-properties($font-primary, a-support);

          &:hover {
            text-decoration: underline;
          }
        }
      }

      &.small-text-mobile {
        @include media-breakpoint-down(md) {
          & > li {
            & > a {
              @include responsive-typography-properties(
                $font-primary,
                a-caption
              );
            }
          }
        }
      }
    }

    .language-chooser {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 34px auto 25px;

      @include media-breakpoint-up(md) {
        margin: 17px 0 0 auto;
      }

      span {
        display: inline-block;
        padding-right: 8px;
        color: $color-blue;
        @include responsive-typography-properties($font-primary, a-support);
      }

      svg {
        width: 16px;
        height: 16px;
        color: $color-blue;
      }

      &:hover {
        span {
          text-decoration: underline;
        }
      }
    }

    .bottom-row {
      position: relative;
      padding-top: 56px;

      @include media-breakpoint-up(md) {
        padding-top: 42px;
      }

      @include media-breakpoint-up(xl) {
        padding-top: 58px;
      }

      .social-share-container {
        text-align: right;

        @include media-breakpoint-down(md) {
          position: absolute;
          top: 0;
          left: 0;
          text-align: center;
        }
      }

      .legal-text {
        display: block;
        text-align: center;
        @include responsive-typography-properties($font-primary, a-caption);

        @include media-breakpoint-up(md) {
          text-align: left;
        }
      }
    }
  }
}
</style>
