<template functional>
  <div
    :ref="data.ref"
    :class="['SocialShareCmp', data.class, data.staticClass]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SocialShareCmp',
  functional: true,
};
</script>

<style lang="scss" scoped>
.SocialShareCmp {
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(md) {
    justify-content: flex-end;
  }

  ::v-deep a {
    display: block;
    margin-left: 16px;
    color: $color-blue;

    svg {
      width: 24px;
      height: 24px;
      color: inherit;
    }

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      color: $color-light-blue;
    }
  }
}
</style>
