var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"FaceToFaceCmp"},[(_vm.themeVariant === 'image-bg')?_c('figure',{staticClass:"background"},[_vm._t("image")],2):_vm._e(),_c('div',{staticClass:"container"},[_c('div',{class:[
        'row',
        'justify-content-center',
        {
          'flex-wrap-reverse': _vm.themeVariant === 'with-image',
          'flex-md-wrap': _vm.themeVariant === 'with-image',
        } ]},[(_vm.hasSlotSlim(_vm._self, 'default'))?_c('div',{class:[
          'col-12 col-md-10',
          {
            'col-lg-8': _vm.themeVariant === 'card-list',
            'col-lg-10 col-xl-8': _vm.themeVariant !== 'card-list',
          } ]},[_vm._t("default")],2):_vm._e(),(_vm.themeVariant === 'with-image')?_c('div',{staticClass:"col-12 col-md-10 col-lg-10 col-xl-8"},[_c('figure',{staticClass:"image-wrapper shadow-hard"},[_vm._t("image")],2)]):_vm._e()]),(_vm.themeVariant === 'with-list' || _vm.themeVariant === 'with-carousel')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('aspect-ratio-box-cmp',{staticClass:"image-below",style:(_vm.computedImageStyle),attrs:{"data-aspect-ratio":_vm.xlAndUp
              ? '32:15'
              : _vm.lgAndUp
              ? '113:60'
              : _vm.mdAndUp
              ? '81:40'
              : '32:25',"data-padding-orientation":_vm.themeVariant === 'with-list' ? 'bottom' : 'top'}},[_c('div',{staticClass:"row"},[_c('div',{class:[
                'col-12 offset-md-1',
                {
                  'col-md-10': _vm.themeVariant === 'with-list',
                  'col-md-11': _vm.themeVariant === 'with-carousel',
                } ]},[(
                  _vm.themeVariant === 'with-list' &&
                  _vm.hasSlotSlim(_vm._self, 'list-items')
                )?_c('ul',{ref:"$list",staticClass:"list",style:(_vm.computedListStyle)},[_vm._t("list-items")],2):_vm._e(),(
                  _vm.themeVariant === 'with-carousel' &&
                  _vm.hasSlotSlim(_vm._self, 'carousel')
                )?_c('div',{ref:"$carousel",staticClass:"carousel"},[_c('slider-cmp-extended',{staticClass:"slides-fit-content",attrs:{"data-bootstrap-container-classes":[
                    'container-fluid',
                    'px-0' ],"data-no-gutter":true,"data-component-slides":true,"data-options":{
                    observer: true,
                    navigation: false,
                  }}},[_c('template',{slot:"custom-swipe-icon"},[_c('svg',[_c('use',{attrs:{"href":"#icon-scroller"}})])]),_c('template',{slot:"components"},[_vm._t("carousel")],2)],2)],1):_vm._e()])]),_vm._t("image")],2)],1)]):_vm._e(),(_vm.themeVariant === 'card-list')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-10 offset-md-1 col-lg-12 offset-lg-0"},[_c('div',{staticClass:"row row-cols-2 row-cols-md-3 row-cols-lg-6 row-cols-xl-6 justify-content-center"},[_vm._t("columns")],2)])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }