import * as types from '../mutation_types';
import axios from 'axios';
export default {
  setConfig({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        if (
          'target' in payload &&
          'payload' in payload &&
          Object.keys(payload).length === 2
        ) {
          Object.keys(payload.payload).forEach((k) => {
            commit(types.SET_CONFIG_PROPERTY_WITH_TARGET, {
              target: payload.target,
              key: k,
              value: payload.payload[k],
            });
          });
        } else {
          Object.keys(payload).forEach((k) => {
            commit(types.SET_CONFIG_PROPERTY, {
              key: k,
              value: payload[k],
            });
          });
        }

        resolve();
      } catch (e) {
        // console.log(e);
        reject(e);
      }
    });
  },
};
