<template>
  <div class="RecaptchaCmp form-group__wrapper">
    <div :class="['form-group', { 'form-group--error': dataErrorOccurred }]">
      <label
        v-if="$_formElementsSupport_hasLabel"
        class="form__label"
        :for="`${dataId}-${_uid}`"
      >
        <slot name="label" />
        <template
          v-if="
            $_formElementsSupport_hasRequiredValidator &&
            dataHasRequiredAsterisk
          "
        >
          *
        </template>
      </label>
      <div class="form-control__wrapper">
        <vue-recaptcha
          :id="`${dataId}-${_uid}`"
          :aria-label="!$_formElementsSupport_hasLabel ? dataAriaLabel : null"
          :sitekey="dataSitekey"
          :load-recaptcha-script="true"
          @verify="verifyMethod"
          @expired="expiredMethod"
          @render="renderMethod"
          @error="errorMethod"
        />
      </div>
    </div>
    <span
      v-for="(error, i) in $_formElementsSupport_hasErrors"
      :key="`error-${i}-${_uid}`"
      class="error"
      :style="[!error.message ? { display: 'none' } : null]"
      v-html="error.message"
    />
    <span v-if="$_formElementsSupport_hasHelper" class="form-helper">
      <slot name="helper" />
    </span>
  </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import FormElementsSupport from '@frankhoodbs/lib/src/mixins/form-elements-support';

export default {
  name: 'RecaptchaCmp',
  components: { VueRecaptcha },
  mixins: [FormElementsSupport],
  props: {
    dataSitekey: {
      type: String,
      required: true,
    },
    dataHasRequiredAsterisk: {
      type: Boolean,
      default: true,
    },
    dataId: {
      type: String,
      default: 'RecaptchaCmp',
    },
    dataAriaLabel: {
      type: String,
      default: null,
    },
    dataErrorOccurred: {
      type: Boolean,
      default: null,
    },
    dataErrors: {
      type: Array,
      default: null,
    },
  },
  methods: {
    verifyMethod(response) {
      this.$emit('verify', response);
    },
    expiredMethod() {
      this.$emit('expire');
    },
    renderMethod(id) {
      this.$emit('render', id);
    },
    errorMethod() {
      this.$emit('error');
    },
  },
};
</script>

<style lang="scss" scoped></style>
