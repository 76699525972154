<template>
  <section class="BannerCmp">
    <div class="wrapper">
      <figure
        v-if="themeVariant === 'section' || themeVariant === 'contact'"
        class="background"
      >
        <slot name="image" />
      </figure>
      <div class="container h-100">
        <div class="row h-100">
          <div
            v-if="
              themeVariant === 'contact' ||
              themeVariant === 'product-story' ||
              themeVariant === 'quote' ||
              themeVariant === 'section'
            "
            :class="[
              {
                'col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2':
                  themeVariant === 'contact',
                'col-12 col-md-8 offset-md-2': themeVariant === 'quote',
                'col-12 col-md-10 offset-md-1':
                  themeVariant === 'product-story' ||
                  themeVariant === 'section',
                'd-flex align-items-end': themeVariant === 'section',
              },
            ]"
          >
            <div class="content">
              <figure
                v-if="themeVariant === 'product-story'"
                class="background"
              >
                <slot name="image" />
              </figure>
              <slot />
            </div>
          </div>
          <template v-else-if="themeVariant === 'newsletter'">
            <div class="col-12 col-lg-5 col-xl-6">
              <slot />
            </div>
            <div class="col-12 col-lg-7 col-xl-6">
              <div class="newsletter-text-wrapper">
                <slot name="newsletter-text" />
              </div>
              <newsletter-form-cmp data-api-url="" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import NewsletterFormCmp from './newsletter-form-cmp';

export default {
  name: 'BannerCmp',
  components: { NewsletterFormCmp },
  data() {
    return {
      themeVariant: null,
    };
  },
  mounted() {
    this.themeVariant = this.$el.classList.contains('contact')
      ? 'contact'
      : this.$el.classList.contains('newsletter')
      ? 'newsletter'
      : this.$el.classList.contains('product-story')
      ? 'product-story'
      : this.$el.classList.contains('quote')
      ? 'quote'
      : this.$el.classList.contains('section')
      ? 'section'
      : 'contact';
  },
};
</script>

<style lang="scss" scoped>
.BannerCmp {
  figure.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 568px;
    overflow: hidden;
    z-index: map-get($z, under);

    @include media-breakpoint-up(md) {
      height: 330px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  &.contact {
    .wrapper {
      position: relative;
      background-color: rgba($color-blue, 0.8);
      height: 568px;

      @include media-breakpoint-up(md) {
        height: 330px;
      }

      .content {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        .TitleTextCmp {
          --title-text-title-wrapper-color: #{$color-white};
          --title-text-text-wrapper-color: #{$color-white};
          --title-text-text-wrapper-padding: 16px 0 0;

          @include media-breakpoint-down(md) {
            @include override-responsive-typography-properties(
              $font-secondary,
              n-heading-3,
              --title-text-title-wrapper
            );
          }

          .CtaButtonCmpExtended {
            @include media-breakpoint-down(md) {
              --cta-button-padding: 14px 32px 13px; // equal to .btn-small
            }
          }
        }
      }
    }
  }

  &.newsletter {
    .wrapper {
      background-color: $color-grey-200-50;
      padding-block: 32px 48px;

      @include media-breakpoint-up(md) {
        padding-block: 42px 60.5px;
      }

      @include media-breakpoint-up(lg) {
        padding-block: 33px 51.5px;
      }

      @include media-breakpoint-up(xl) {
        padding-block: 48px 66.5px;
      }

      .TitleTextCmp {
        --title-text-title-wrapper-padding: 0 0 8px;
        @include override-responsive-typography-properties(
          $font-secondary,
          n-heading-3,
          --title-text-title-wrapper
        );

        @include media-breakpoint-up(md) {
          --title-text-text-align: left;
        }
      }

      .newsletter-text-wrapper {
        padding-bottom: 18.5px;

        @include media-breakpoint-down(md) {
          text-align: center;
        }
      }
    }
  }

  &.product-story {
    .content {
      position: relative;
      border-radius: 8px;
      background-size: cover;
      padding: 24px 16px;
      overflow: hidden;
      flex-grow: 1;

      &::after {
        content: '';
        position: absolute;
        inset: 0;
        background-color: rgba($color-blue-10, 0.8);
      }

      @include media-breakpoint-up(md) {
        padding: 48px 56px;
      }

      @include media-breakpoint-up(lg) {
        padding: 64px 153px;
      }

      @include media-breakpoint-up(xl) {
        padding: 64px 216px;
      }

      figure.background {
        height: 100% !important;
      }

      .TitleTextCmp {
        position: relative;
        z-index: map-get($z, base);
        --title-text-overtitle-wrapper-padding: 0 0 24px;
      }
    }
  }

  &.quote {
    .content {
      flex-grow: 1;
      padding-block: 40px;

      @include media-breakpoint-up(md) {
        padding-block: 80px;
      }

      @include media-breakpoint-up(lg) {
        padding-block: 120px;
      }
    }
  }

  &.section {
    .wrapper {
      position: relative;
      display: flex;
      align-items: flex-end;
      min-height: 688px;
      background-color: $color-blue-10;
      padding-top: 240px;
      padding-bottom: 40px;

      @include media-breakpoint-up(md) {
        min-height: 490px;
        padding-top: 160px;
      }

      @include media-breakpoint-up(lg) {
        padding-top: 190px;
      }

      figure.background {
        z-index: auto;

        img {
          transition: transform 1s;
          transform: scale(1.2);
        }

        &:hover {
          img {
            transform: scale(1);
          }
        }
      }

      .content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 32px;
        border-radius: 8px;
        background-color: $color-white;
        z-index: map-get($z, base);

        .TitleTextCmp {
          --title-text-text-align: left;
          --title-text-text-wrapper-padding: 16px 0 0;
          @include override-responsive-typography-properties(
            $font-secondary,
            n-heading-3,
            --title-text-title-wrapper
          );
        }
      }
    }
  }
}
</style>
