<template>
  <div :class="['RecipesListPage', { loading }]">
    <hero-cmp ref="$targetScroll" class="colorful-bg mb-9">
      <template #type>
        <slot name="recipe-type" />
      </template>
      <template #title>
        <slot name="recipe-hero-title" />
      </template>
    </hero-cmp>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <title-text-cmp class="mb-9">
            <template #title>
              <slot name="recipe-title" />
            </template>
            <template #text>
              <slot name="recipe-description" />
            </template>
          </title-text-cmp>
        </div>
      </div>
    </div>

    <div class="container pb-xl">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <div class="row">
            <!-- filter section -->
            <div class="col-12 text-color-grey-800 a-support mb-1">
              {{ list_filter_by_label }}
            </div>
            <div
              v-for="category of filters"
              :key="`category_col_${category.slug}`"
              class="col-auto"
            >
              <dropdown-cmp-extended
                class="mt-3"
                data-tag="button"
                :data-trigger-theme-classes="[
                  {
                    selected:
                      category.options.active === true ||
                      category.options.active.length > 0,
                  },
                ]"
                :data-menu-theme-classes="['shadow-soft']"
              >
                {{ category.name }}
                <template
                  v-if="
                    category.options.active &&
                    category.options.active.length > 0
                  "
                >
                  ({{ category.options.active.length }})
                </template>
                <template v-if="category.options.active === true">(1)</template>
                <template #after-icon>
                  <svg class="icon-caret-down">
                    <use href="#icon-caret-down" />
                  </svg>
                </template>
                <template #dropdown-menu>
                  <input-checkbox-cmp-extended
                    v-model.trim="category.options.active"
                    data-name="checkbox"
                    class="custom direction-column"
                    :data-checkbox-list="category.options.list"
                  />
                </template>
              </dropdown-cmp-extended>
            </div>
          </div>
          <div
            v-if="activeFilters.length > 0"
            class="row justify-content-center"
          >
            <div class="col-12">
              <cta-button-cmp-extended
                class="btn-text mt-3"
                @click="
                  activeFilters = [];
                  initializeFilters(true);
                "
              >
                {{ list_reset_filters_label }}
              </cta-button-cmp-extended>
            </div>
          </div>
          <div v-if="list.length === 0" class="row justify-content-center">
            <div class="col-12">
              <div class="a-paragraph-2 text-color-blue text-center mt-12">
                {{ list_no_results_label }}
              </div>
            </div>
          </div>
          <div class="row justify-content-start">
            <!-- UN-filtered card section (given from BE paginated in querystring)-->
            <slot v-if="!setupStoreCompleted" name="recipes-pages"></slot>
            <!-- filtered card section -->
            <template v-else>
              <div
                v-for="(recipe, index) of list"
                :key="`recipe_col_${index}`"
                class="col-12 col-md-6 col-lg-4"
              >
                <card-cmp :data-href="recipe.href" class="normal mt-9">
                  <template #seo-link>
                    <a :href="recipe.slug"></a>
                  </template>
                  <template #image>
                    <image-cmp
                      width="1062"
                      height="596"
                      :data-desktop-image="recipe.list_image"
                      :data-image-alt="recipe.list_image_alt"
                    />
                  </template>
                  <template #tags>
                    <a
                      v-for="tag in recipe.categories"
                      :key="'tag-' + tag.slug"
                      :href="tag.href"
                    >
                      <tag-cmp>
                        {{ tag.name }}
                      </tag-cmp>
                    </a>
                  </template>
                  <template #category>
                    <template v-if="recipe.list_short_title">
                      {{ recipe.list_short_title }}
                    </template>
                    <template v-else>
                      <div>&nbsp;</div>
                    </template>
                  </template>
                  <template #title>
                    <h2>{{ recipe.list_title }}</h2>
                  </template>
                </card-cmp>
              </div>
            </template>
          </div>
          <!-- pagination section (only if no filters are active) -->
          <div v-if="!(list.length === 0)" class="row justify-content-center">
            <div class="col-12">
              <pagination-cmp
                class="mt-10"
                data-tag="a"
                :data-pages="recipesPages"
                :data-emit="true"
                :data-active-page-index="activePageIndex"
                @click="handlePagination"
              >
                <template #seo-pagination>
                  <slot name="recipe-seo-pagination" />
                </template>
              </pagination-cmp>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { castPropToType } from '@/helpers/cms-support';
import { mapState, mapActions } from 'vuex';
import anime from 'animejs/lib/anime.es.js';

export default {
  name: 'RecipesListPage',
  async beforeRouteUpdate(to, from, next) {
    this.loading = true;
    try {
      const payload = {
        page_size: this.page_size,
      };
      if (this.activeFilters.length) {
        payload.categories = this.activeFilters;
      }
      if (to.query.page) {
        payload.page_number = to.query.page;
      }
      await this.loadRecipes(payload);
    } catch (e) {
      // eslint-disable-next-line
      console.warn(e);
    }
    this.loading = false;
    next();
  },
  props: {
    dataCategories: { type: String, required: true },
    dataPagination: { type: String, required: true },
  },
  setup() {
    return { castPropToType };
  },
  data() {
    return {
      loading: false,
      filters: null,
      activeFilters: [],
      page_size: 12,
      page_size_start: 12,
    };
  },
  computed: {
    list() {
      return (
        (this.pages &&
          this.pagination.current_page &&
          this.pages.find((p) => {
            return p.page_number === this.pagination.current_page;
          })?.data) ||
        []
      );
    },
    setupStoreCompleted() {
      return this.list_api !== '';
    },
    recipesRetrieved() {
      return this.backendData.results;
    },
    ...mapState('recipes', [
      'list_hero_label',
      'list_hero_title',
      'list_body_title',
      'list_body_description',
      'list_filter_by_label',
      'list_reset_filters_label',
      'list_load_more_label',
      'list_no_results_label',
      'list_api',
      'recipes',
      'backendData',
      'pages',
    ]),
    activePageIndex() {
      return this.recipesPages.findIndex(
        (page) => page.pageNumber === this.pagination.current_page
      );
    },
    recipesPages() {
      const range = 8;
      const start = 1;
      let pages = this.pagination.final_page;
      let current = this.pagination.current_page;

      const paging = [];
      let i = Math.min(
        pages + start - range,
        Math.max(start, current - ((range / 2) | 0))
      );
      const end = i + range;
      while (i < end) {
        if (i > 0) {
          paging.push({
            label: i,
            pageNumber: +i,
            href: '?page=' + i,
            target: '_self',
          });
        }
        i++;
      }

      return paging;
    },
    pagination() {
      return this.backendData;
    },
    categories() {
      return this.dataCategories
        ? this.castPropToType(this.dataCategories, 'array')
        : null;
    },
  },
  watch: {
    setupStoreCompleted: {
      async handler(completed) {
        if (completed) {
          this.loading = true;
          try {
            const payload = {
              page_size: this.page_size,
            };
            if (this.activeFilters.length) {
              payload.categories = this.activeFilters;
            }
            if (this.$route?.query?.page) {
              payload.page_number = this.$route?.query.page;
            }
            await this.loadRecipes(payload);
          } catch (e) {
            // eslint-disable-next-line
            console.warn(e);
          }
          this.loading = false;
        }
      },
      immediate: true,
    },
    // watch for change in filters (variable used to keep track of selected checkboxes)
    // on every change search for the "active" fields, wich may be:
    // true, if the only value of the checkboxlist is selected
    // array of string, containing slugs of the selected filters
    // in case of empty array or false valuem, no value is selected in that checkbox list
    filters: {
      async handler(val) {
        this.activeFilters = [];
        this.page_size = this.page_size_start;
        this.filters.forEach((e) => {
          if (e.options.active !== [] && e.options.active !== false) {
            if (e.options.active === true) {
              this.activeFilters = [
                ...this.activeFilters,
                e.options.list[0].id,
              ];
            } else {
              this.activeFilters = [...this.activeFilters, ...e.options.active];
            }
          }
        });
        if (this.setupStoreCompleted) {
          this.loading = true;
          try {
            this.$nextTick(() => {
              this.$router.push({
                name: 'index',
                query: {
                  page: 1,
                  categories: this.activeFilters,
                },
              });
            });
          } catch (e) {
            // eslint-disable-next-line
            console.warn(e);
          }
          this.loading = false;
          // if (this.$route?.query?.page) {
          //   this.$router.push({
          //     name: 'index',
          //   });
          // }
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.initializeFilters();
  },

  methods: {
    ...mapActions('recipes', ['loadRecipes', 'activateFilter']),
    handlePagination(payload) {
      if (+payload.pageNumber === +this.backendData.current_page) return;
      const targetAnchor =
        this.$refs.$targetScroll?.$el || this.$refs.$targetScroll;
      const elemRect = targetAnchor.getBoundingClientRect();
      const bodyRect = document.body.getBoundingClientRect();
      const offsetMargin =
        +window.getComputedStyle(targetAnchor).height.split('px')[0] -
        +getComputedStyle(document.documentElement)
          .getPropertyValue('--header-height')
          .trim()
          .split('px')[0];
      const offset = elemRect.top - bodyRect.top;
      let internalPadding = window.getComputedStyle(targetAnchor).paddingTop;

      const scrollElement =
        window.document.scrollingElement ||
        window.document.body ||
        window.document.documentElement;

      if (internalPadding.indexOf('px')) {
        internalPadding = parseInt(internalPadding.split('px')[0], 10);
      } else {
        internalPadding = 0;
      }

      anime({
        targets: scrollElement,
        scrollTop: offset + internalPadding + offsetMargin,
        duration: 350,
        easing: 'easeInOutQuad',
        complete: () => {
          anime({
            targets: scrollElement,
            scrollTop: offset + internalPadding + offsetMargin + 1,
            duration: 1,
            complete: () => {
              this.$router.push({
                name: 'index',
                query: {
                  page: payload.pageNumber,
                  categories: this.activeFilters,
                },
              });
            },
          });
        },
      });
    },
    initializeFilters(reset) {
      // clone received category in orer to create an editable structure and keep track of selected values
      const fromQs = reset
        ? []
        : this.$route?.query?.categories
        ? Array.isArray(this.$route?.query?.categories)
          ? this.$route?.query?.categories
          : [this.$route?.query?.categories]
        : [];
      this.filters = this.categories.map((e) => {
        const values = e.typology_recipe_categories.map((s) => s.slug);
        const activeValues = fromQs.filter((ac) => values.includes(ac));
        return {
          ...e,
          options: {
            list: e.typology_recipe_categories.map((s) => {
              if (e.typology_recipe_categories.length === 1) {
                return { value: true, label: s.name, id: s.slug };
              } else {
                return { value: s.slug, label: s.name, id: s.slug };
              }
            }),
            active: activeValues,
          },
        };
      });
      this.activateFilter = fromQs;
    },
  },
};
</script>

<style lang="scss" scoped>
.RecipesListPage {
  &.loading {
    &:before {
      content: '';
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw !important;
      height: 100vh !important;
      background: rgba(0, 0, 0, 0.2);
    }

    &:after {
      content: '';
      display: block;
      position: fixed;
      top: 50%;
      left: 50%;
      width: 48px !important;
      height: 48px !important;
      min-width: 0;
      min-height: 0;
      color: rgba(255, 255, 255, 1);
      border: 5px solid;
      border-right-color: rgba(0, 0, 0, 0.8);
      border-radius: 50%;
      background: none;
      transform: translateX(-50%) translateY(-50%);
      animation: rotate 1s linear infinite;
    }
  }
}

.loadmore {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>
