<template>
  <form-cmp class="NewsletterFormCmp">
    <div
      class="d-flex flex-column flex-md-row align-items-center align-items-md-baseline justify-content-center"
    >
      <input-text-cmp-extended
        v-model.trim="$v.email.$model"
        data-name="email"
        data-placeholder="Email"
        data-aria-label="Email"
        :data-error-occurred="$v.email.$error"
        :data-errors="[
          {
            condition: !$v.email.required,
            message: 'L\'indirizzo email è obbligatorio',
          },
          {
            condition: !$v.email.email,
            message: 'Inserisci un indirizzo email valido',
          },
          {
            condition: !$v.email.serverError,
            message: serverErrors.email,
          },
        ]"
      />
      <submit-cmp-extended
        :data-theme-classes="[{ 'btn-small': xsOnly }]"
        data-aria-label="Sign up"
        :data-loading="loading"
        :data-disabled="disableSubmit || submitted"
        :data-error-occurred="
          !!globalErrors.length || !!Object.keys(serverErrors).length
        "
        :data-errors="globalErrors"
        @click-submit="onSubmitForm"
      >
        Sign up
      </submit-cmp-extended>
    </div>
  </form-cmp>
</template>

<script>
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { FormCmp } from '@frankhoodbs/lib';
import FormElementsSupport from '@frankhoodbs/lib/src/mixins/form-elements-support';
import InputTextCmpExtended from './input-text-cmp-extended';
import SubmitCmpExtended from './submit-cmp-extended';
import { xsOnly } from '@/helpers/breakpoints.js';
import axios from 'axios';

Vue.use(Vuelidate);

const checkServerErrors = (param) => {
  return (value, vm) => {
    return Object.keys(vm.serverErrors).indexOf(param) === -1;
  };
};

export default {
  name: 'NewsletterFormCmp',
  components: { InputTextCmpExtended, FormCmp, SubmitCmpExtended },
  mixins: [FormElementsSupport],
  props: {
    dataSubmitEnabledOnMounted: {
      type: Boolean,
      default: false,
    },
    dataApiUrl: {
      type: String,
      required: true,
    },
    dataRequestMethod: {
      type: String,
      default: 'post',
      validator: function (value) {
        // The value must match one of these strings
        return (
          [
            'get',
            'head',
            'post',
            'put',
            'delete',
            'connect',
            'options',
            'trace',
            'patch',
          ].indexOf(value) !== -1
        );
      },
    },
    dataRequestData: {
      type: Object,
      default: null,
    },
    dataRequestConfig: {
      type: Object,
      default: null,
    },
    dataHandledStatusCodeErrors: {
      type: Array,
      default: () => [400, 401, 403],
    },
  },
  setup() {
    return { xsOnly };
  },
  data() {
    return {
      /* form field */
      email: null,
    };
  },
  computed: {
    disableSubmit() {
      if (this.dataSubmitEnabledOnMounted) {
        return false;
      } else {
        if (this.$v && (this.$v.$anyError || this.$v.$invalid)) {
          return true;
        }

        return false;
      }
    },
  },
  validations() {
    return {
      email: {
        required,
        email,
        serverError: checkServerErrors('email'),
      },
    };
  },
  methods: {
    async onSubmitForm() {
      if (this.dataSubmitEnabledOnMounted) {
        this.$v.$touch();
      }
      if (!this.submitted && !this.disableSubmit && !this.$v.$invalid) {
        this.submitted = true;
        this.loading = true;
        this.globalErrors = [];
        this.toastErrors = [];

        try {
          const formData = {
            email: this.email,
          };

          let axiosConfig = {
            method: this.dataRequestMethod,
            url: this.dataApiUrl,
            data:
              this.dataRequestData !== null
                ? { ...this.dataRequestData, ...formData }
                : formData,
          };
          if (this.dataRequestConfig !== null) {
            axiosConfig = {
              ...axiosConfig,
              ...this.dataRequestConfig,
            };
          }
          const response = await axios(axiosConfig);

          // Implement 200
          // Effettuare  nell'if tutti i controlli del caso per verificare che il payload di risposta
          // ricevuto sia conforme a quello che ci aspettiamo
          if (response.data) {
            this.handleSuccessCall(response);
          } else {
            this.$_formElementsSupport_handleApiError(response);
          }
        } catch (e) {
          // Controlliamo se siamo entrati nella catch per una risposta del server non di successo
          // se response non è incluso o non abbiamo lo status code aspettato allora si è rotto
          // qualcosa lato codice nostro lo gestiamo subito
          const hasResponse =
            Object.getOwnPropertyNames(e).includes('response');
          if (
            !hasResponse ||
            (hasResponse &&
              !this.dataHandledStatusCodeErrors.includes(e.response.status))
          ) {
            // 500 or unknown status code
            this.$_formElementsSupport_handleUnknownError(e);
          } else {
            this.$_formElementsSupport_handleErrors(e);
          }
        } finally {
          this.loading = false;
        }
      }
    },
    handleSuccessCall(response) {
      // eslint-disable-next-line
      //console.log(response);
      alert(200);
    },
  },
};
</script>

<style lang="scss" scoped>
.NewsletterFormCmp {
  .form-group__wrapper {
    --form-element-padding: 0;
  }

  .SubmitCmpExtended {
    --form-element-display: inline-flex;
    --form-element-width: auto;
    margin-top: 32px;

    @include media-breakpoint-up(md) {
      margin: 0 0 0 32px;
      flex-shrink: 0;
    }
  }
}
</style>
