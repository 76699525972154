<template>
  <div class="QuoteCmp">
    <div v-if="hasSlot($slots.photo, $scopedSlots.photo)" class="photo-wrapper">
      <slot name="photo" />
    </div>
    <figure
      :class="[{ 'with-photo': hasSlot($slots.photo, $scopedSlots.photo) }]"
    >
      <blockquote :cite="dataQuoteSrc">
        <!-- text not inside a tag expected, or text inside a <p> tag if you want customize it with typography/color classes -->
        <slot name="excerpt" />
      </blockquote>
      <figcaption class="author-wrapper">
        <!-- text not inside a tag expected, or text inside a <span> tag if you want customize it with typography/color classes -->
        <slot name="author" />
        <cite
          v-if="hasSlot($slots.cite, $scopedSlots.cite)"
          class="cite-wrapper"
        >
          <!-- text not inside a tag expected, or text inside a <span> tag if you want customize it with typography/color classes -->
          <slot name="cite" />
        </cite>
      </figcaption>
    </figure>
  </div>
</template>

<script>
import { hasSlot } from '@/helpers/cms-support';

export default {
  name: 'QuoteCmp',
  props: {
    dataQuoteSrc: {
      type: String,
      default: null,
    },
  },
  setup() {
    return { hasSlot };
  },
};
</script>

<style lang="scss" scoped>
.QuoteCmp {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  .photo-wrapper {
    padding: 0 0 16px;

    @include media-breakpoint-up(md) {
      padding: 0 24px 0 0;
    }

    img {
      width: 118px;
      height: 118px;
      border-radius: 8px;

      @include media-breakpoint-up(lg) {
        width: 194px;
        height: 194px;
      }
    }
  }

  figure {
    display: flex;
    flex-direction: column;

    &:not(.with-photo) {
      align-items: center;

      blockquote {
        @include responsive-typography-properties(
          $font-primary,
          a-heading,
          --quote-excerpt
        );
        color: $color-blue;
        text-align: center;
      }

      .author-wrapper {
        position: relative;
        @include responsive-typography-properties(
          $font-secondary,
          n-paragraph-1,
          --quote-author
        );
        color: $color-blue;
        text-align: center;
        padding-inline: 0;
        margin-top: 40px;

        @include media-breakpoint-up(md) {
          padding-inline: 56px; // 40 + 16 of margin
        }

        @include media-breakpoint-up(lg) {
          padding-inline: 61px; // 45 + 16 of margin
        }

        @include media-breakpoint-up(xl) {
          padding-inline: 171px; // 155 + 16 of margin
        }

        @include media-breakpoint-up(md) {
          &::before,
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 40px;
            height: 1px;
            background-color: currentColor;
            transform: translateY(-50%);

            @include media-breakpoint-up(lg) {
              width: 45px;
            }

            @include media-breakpoint-up(xl) {
              width: 155px;
            }
          }

          &::after {
            left: auto;
            right: 0;
          }
        }
      }

      .cite-wrapper {
        @include responsive-typography-properties(
          $font-secondary,
          n-paragraph-1,
          --quote-cite
        );
        color: $color-blue;

        &::before {
          content: ',';
          padding-right: 6px;
        }
      }
    }

    &.with-photo {
      border-top: 1px solid $color-blue;
      padding: 16px 0 0;

      @include media-breakpoint-up(md) {
        border-top: 0;
        border-left: 1px solid $color-blue;
        padding: 0 0 0 24px;
      }

      blockquote {
        @include responsive-typography-properties(
          $font-primary,
          a-paragraph-3,
          --quote-excerpt
        );
        color: $color-black;
      }

      .author-wrapper {
        display: flex;
        flex-direction: column;
        @include responsive-typography-properties(
          $font-primary,
          a-paragraph-2,
          --quote-author
        );
        color: $color-blue;
        margin-top: 24px;
      }

      .cite-wrapper {
        margin-top: 8px;
        @include responsive-typography-properties(
          $font-primary,
          a-paragraph-3,
          --quote-cite
        );
        color: $color-grey-600;
      }
    }
  }
}
</style>
