<template>
  <nav v-if="+dataItemsNumber > 1" class="BreadcrumbsCmp">
    <ol
      class="breadcrumbs-nav"
      itemscope
      itemtype="https://schema.org/BreadcrumbList"
    >
      <li
        v-for="n in +dataItemsNumber"
        :key="`item-${n}-${_uid}`"
        :class="['item', { 'prev-item': n === +dataItemsNumber - 1 }]"
        itemprop="itemListElement"
        itemscope
        itemtype="https://schema.org/ListItem"
      >
        <slot :name="`item-${n}`" />
        <svg v-if="n < +dataItemsNumber" class="separator-icon">
          <use :href="`#${dataSeparatorIconId}`" />
        </svg>
        <meta itemprop="position" :content="n" />
      </li>
    </ol>
  </nav>
</template>

<script>
import { xsOnly, mdAndUp } from '@/helpers/breakpoints.js';

export default {
  name: 'BreadcrumbsCmp',
  props: {
    dataItemsNumber: {
      Type: String,
      default: '2',
    },
    dataSeparatorIconId: {
      type: String,
      default: 'icon-caret-right',
    },
    dataPrevIconId: {
      type: String,
      default: 'icon-caret-left',
    },
  },
  setup() {
    return { xsOnly, mdAndUp };
  },
};
</script>

<style lang="scss" scoped>
.BreadcrumbsCmp {
  color: $color-grey-600;

  .breadcrumbs-nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 5px;
    margin-bottom: -12px;

    .item {
      display: flex;
      align-items: center;
      padding-bottom: 12px;

      @include media-breakpoint-up(md) {
        display: flex;
      }

      &.prev-item {
        display: flex;
      }

      .separator-icon {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        margin: 0 3px;
      }

      a {
        color: inherit;
        @include responsive-typography-properties(
          $font-primary,
          a-caption,
          --breadcrumb-item
        );
      }
    }
  }
}
</style>
