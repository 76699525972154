import * as types from '../mutation_types';

export default {
  [types.SET_GIGYA_INIT_DATA](state, payload) {
    state[payload.key] = payload.value;
  },
  [types.SET_GIGYA_GO_TO](state, payload) {
    state.goTo = payload;
  },
  [types.SET_GIGYA_LOADED](state, payload) {
    state.loaded = payload;
  },
  [types.SET_GIGYA_USER](state, payload) {
    state.user = payload;
  },
  [types.SET_GIGYA_USER_DATA](state, payload) {
    state.data = payload;
  },
  [types.SET_GIGYA_PREFERENCES](state, payload) {
    state.preferences = payload;
  },
  [types.SET_OT_ACTIVE_GROUPS](state, payload) {
    state.otActiveGroups = payload;
  },
  [types.SET_CURRENT_SCREEN](state, screenName) {
    state.currentScreen = screenName;
  },
};
