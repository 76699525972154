<template>
  <cta-button-cmp
    class="CtaButtonCmpExtended"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </cta-button-cmp>
</template>

<script>
import { CtaButtonCmp } from '@frankhoodbs/lib';

export default {
  name: 'CtaButtonCmpExtended',
  components: { CtaButtonCmp },
};
</script>

<style lang="scss" scoped>
.CtaButtonCmpExtended {
  @include override-responsive-typography-properties(
    $font-primary,
    a-cta,
    --cta-button
  );
  --cta-button-padding: 15px 62px 14px;
  --cta-button-border-width: 2px;
  --cta-button-hover-border-width: 2px;
  --cta-button-disabled-border-width: 2px;
  --cta-button-border-radius: 4px;
  --cta-button-icon-width: 24px;
  --cta-button-icon-height: 24px;
  --cta-button-disabled-opacity: 1;

  &.btn-small {
    --cta-button-padding: 12px 30px 11px;
  }

  &.btn-primary {
    --cta-button-bg-color: #{$color-blue};
    --cta-button-border-color: #{$color-blue};
    --cta-button-hover-bg-color: #{$color-light-blue};
    --cta-button-hover-border-color: #{$color-light-blue};
    --cta-button-disabled-bg-color: #{$color-grey-200};
    --cta-button-disabled-color: #{$color-grey-400};
    --cta-button-disabled-border-color: #{$color-grey-400};
  }

  &.btn-primary-reversed {
    --cta-button-bg-color: transparent;
    --cta-button-color: #{$color-white};
    --cta-button-border-color: #{$color-white};
    --cta-button-hover-bg-color: #{$color-grey-800-50};
    --cta-button-hover-color: #{$color-white};
    --cta-button-hover-border-color: #{$color-white};
    --cta-button-disabled-bg-color: transparent;
    --cta-button-disabled-color: #{$color-grey-600-50};
    --cta-button-disabled-border-color: #{$color-grey-400-50};
  }

  &.btn-secondary {
    --cta-button-bg-color: #{$color-white};
    --cta-button-color: #{$color-blue};
    --cta-button-border-color: #{$color-blue};
    --cta-button-hover-bg-color: #{$color-grey-400-50};
    --cta-button-hover-color: #{$color-light-blue};
    --cta-button-hover-border-color: #{$color-light-blue};
    --cta-button-disabled-bg-color: #{$color-white};
    --cta-button-disabled-color: #{$color-grey-400};
    --cta-button-disabled-border-color: #{$color-grey-400};
  }

  &.btn-text {
    padding-inline: 0 !important;
    --cta-button-border-radius: 0;
    --cta-button-bg-color: transparent;
    --cta-button-color: #{$color-blue};
    --cta-button-border-color: transparent;
    --cta-button-hover-bg-color: transparent;
    --cta-button-hover-color: #{$color-light-blue};
    --cta-button-hover-border-color: transparent;
    --cta-button-disabled-bg-color: transparent;
    --cta-button-disabled-color: #{$color-grey-400};
    --cta-button-disabled-border-color: transparent;
    --cta-button-justify-content: flex-start;
    --cta-button-text-align: left;
  }

  &.btn-text-reversed {
    padding-inline: 0 !important;
    --cta-button-border-radius: 0;
    --cta-button-bg-color: transparent;
    --cta-button-color: #{$color-white};
    --cta-button-border-color: transparent;
    --cta-button-hover-bg-color: transparent;
    --cta-button-hover-color: #{$color-white};
    --cta-button-hover-border-color: transparent;
    --cta-button-disabled-bg-color: transparent;
    --cta-button-disabled-color: #{$color-grey-600-50};
    --cta-button-disabled-border-color: transparent;
    --cta-button-justify-content: flex-start;
    --cta-button-text-align: left;
  }

  /* Animation */
  &.btn-text,
  &.btn-text-reversed {
    ::v-deep span.text {
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &::after {
        content: '';
        position: absolute;
        bottom: 4px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: currentColor;
        opacity: 0;
        transition: opacity 0.3s, transform 0.3s;
      }
    }

    ::v-deep .icon {
      transform: translateX(0);
      transition: transform 0.3s;
    }

    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):hover {
      ::v-deep span.text {
        &::after {
          opacity: 1;
          transform: translate3d(0, 1px, 0);
        }
      }

      ::v-deep .icon.before-icon {
        transform: translateX(-5px);
      }

      ::v-deep .icon.after-icon {
        transform: translateX(5px);
      }
    }
  }
}
</style>
