/* Mutations, actions and getters */
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    backendData: {
      count: null,
      current_page: null,
      final_page: null,
      next: null,
      previous: null,
    },
    pages: [],
    //----HUB-PAGE
    highlight_recipe: null,
    latest_recipes: null,
    recipes: null,
    hub_hero_image: '',
    hub_hero_image_alt: '',
    hub_hero_label: '',
    hub_hero_title: '',
    hub_description: '',
    recipe_list_cta_label: '',
    recipe_list_cta_url: '',
    recipe_view_all_cta_label: '',
    //----HUB-PAGE

    //----LIST-PAGE
    list_hero_label: '',
    list_hero_title: '',
    list_body_title: '',
    list_body_description: '',
    list_filter_by_label: '',
    list_reset_filters_label: '',
    list_load_more_label: '',
    list_no_results_label: '',
    list_api: '',
    //----LIST-PAGE

    //----DETAIL-PAGE
    detail_hero_label: '',
    detail_ingredients_label: '',
    detail_servings_label: '',
    detail_preparation_label: '',
    detail_social_share_label: '',
    detail_related_recipes_label: '',
    detail_related_recipes_cta_label: '',
    detail_chef_note_label: '',
    detail_related_recipes_url: '',
    //----DETAIL-PAGE
  },
  actions,
  mutations,
  getters,
};
