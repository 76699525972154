<template>
  <nav v-if="propPages" class="PaginationCmp">
    <component
      :is="dataTag"
      v-if="prevPage"
      class="sx-arrow"
      :href="dataEmit ? null : dataTag === 'a' ? prevPage.href : null"
      :to="dataTag === 'router-link' ? prevPage.href : null"
      :target="dataTag === 'a' ? prevPage.target : null"
      @click="
        dataEmit
          ? $emit('click', prevPage)
          : dataTag === 'router-link'
          ? activePageIndex--
          : null
      "
    >
      <svg>
        <use href="#icon-arrow-left" />
      </svg>
    </component>
    <span v-else class="sx-arrow text-color-grey-400">
      <svg>
        <use href="#icon-arrow-left" />
      </svg>
    </span>
    <hr class="separator" />
    <div class="pages a-cta">
      <component
        :is="dataTag"
        v-for="(item, index) in propPages"
        :key="`page-${index}-${_uid}`"
        :class="['pageItem', { active: activePageIndex === index }]"
        :href="dataEmit ? null : dataTag === 'a' ? item.href : null"
        :to="dataTag === 'router-link' ? item.href : null"
        :target="dataTag === 'a' ? item.target : null"
        @click="dataEmit ? $emit('click', item) : (activePageIndex = index)"
      >
        {{ item.label }}
      </component>
    </div>
    <hr class="separator" />
    <component
      :is="dataTag"
      v-if="nextPage"
      class="dx-arrow"
      :href="dataEmit ? null : dataTag === 'a' ? nextPage.href : null"
      :to="dataTag === 'router-link' ? nextPage.href : null"
      :target="dataTag === 'a' ? nextPage.target : null"
      @click="
        dataEmit
          ? $emit('click', nextPage)
          : dataTag === 'router-link'
          ? activePageIndex++
          : null
      "
    >
      <svg>
        <use href="#icon-arrow-right" />
      </svg>
    </component>
    <span v-else class="dx-arrow text-color-grey-400">
      <svg>
        <use href="#icon-arrow-right" />
      </svg>
    </span>
  </nav>
</template>

<script>
import { castPropToType } from '@/helpers/cms-support';
export default {
  name: 'PaginationCmp',
  props: {
    dataEmit: {
      type: Boolean,
      default: false,
    },
    dataPages: {
      type: [Array, String],
      required: true,
    },
    dataActivePageIndex: {
      type: [Number, String],
      default: 0,
    },
    dataTag: {
      type: String,
      default: 'a',
      validator: function (value) {
        return ['a', 'router-link'].indexOf(value) !== -1;
      },
    },
  },
  setup() {
    return { castPropToType };
  },
  data() {
    return {
      activePageIndex: '',
    };
  },
  computed: {
    propPages() {
      return this.dataPages
        ? this.castPropToType(this.dataPages, 'array')
        : null;
    },
    propActivePageIndex() {
      return this.dataActivePageIndex || this.dataActivePageIndex === 0
        ? this.castPropToType(this.dataActivePageIndex, 'number')
        : null;
    },
    nextPage() {
      return this.propPages[this.activePageIndex + 1];
    },
    prevPage() {
      return this.propPages[this.activePageIndex - 1];
    },
    safeActiveIndexProp() {
      return this.propActivePageIndex >= this.propPages.length
        ? this.propPages.length - 1
        : this.propActivePageIndex < 0
        ? 0
        : this.propActivePageIndex;
    },
  },
  watch: {
    propActivePageIndex: {
      handler() {
        this.activePageIndex = this.safeActiveIndexProp;
      },
      immediate: true,
    },
  },
  mounted() {
    this.activePageIndex = this.safeActiveIndexProp;
  },
};
</script>

<style lang="scss" scoped>
.PaginationCmp {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;

  .sx-arrow {
    margin-right: 24px;
  }

  .dx-arrow {
    margin-left: 24px;
  }

  .sx-arrow,
  .dx-arrow {
    display: inline-block;
    color: $color-blue;

    svg {
      width: 24px;
      height: 24px;
    }
  }
  .pageItem {
    cursor: pointer;
    &.active {
      cursor: default;
    }
  }

  .separator {
    flex-grow: 1;
    border-color: $color-blue;
  }

  .pages {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 24px;
    color: $color-grey-600;

    a,
    router-link {
      display: none;

      @include media-breakpoint-up(md) {
        display: inline-block;
      }

      &:not(:last-child) {
        margin-right: 24px;
      }

      &.active {
        display: inline-block;
        color: $color-blue;

        @include media-breakpoint-down(md) {
          margin: 0 !important;
        }
      }
    }
  }
}
</style>
