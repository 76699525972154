/* Mutations, actions and getters */
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    // Hub
    categories: [],
    categories_all: null,
    highlight_article: {},
    latest_articles: [],
    news_and_media_latest_articles_label: '',
    news_and_media_view_all_cta_label: '',
    // Hub and List
    hub_hero_overtitle: '',
    hub_hero_title: '',
    // List
    list_filter_by_label: '',
    list_topic_label: '',
    news_and_media_list_api: '',
    category: {
      news_and_media_type: null,
      slug: null,
      title: null,
      visible_in_hub_page: false,
    },
    pagination: {
      count: null,
      current_page: null,
      final_page: null,
      next: null,
      page: null,
      page_size: null,
      previous: null,
    },
    topics: [],
    filters: [],
    pages: null,
    pages_loading: [],
    // Detail
    has_small_cover: false,
    detail_explore_all_events_label: '',
    detail_explore_all_news_label: '',
    detail_related_events_label: '',
    detail_related_events_url: '',
    detail_related_news_label: '',
    detail_related_news_url: '',
    detail_time_label: '',
    detail_when_event_label: '',
    detail_where_event_label: '',
    // Global
    news_and_media_read_more_label: '',
  },
  actions,
  mutations,
  getters,
};
