<template>
  <section class="CarouselProductCardsCmp">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <slider-cmp-extended
            :style="{
              '--slider-container-overflow': overflowVisible
                ? 'visible'
                : 'hidden',
            }"
            :data-bootstrap-container-classes="['container-fluid', 'px-0']"
            :data-component-slides="true"
            :data-enabled-from="enabledFrom"
            :data-options="{
              observer: true,
              slidesPerView: 1,
              slidesPerGroup: 1,
              navigation: false,
              breakpoints: {
                768: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                },
                1024: {
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                },
              },
            }"
          >
            <template slot="custom-swipe-icon">
              <svg>
                <use href="#icon-scroller" />
              </svg>
            </template>
            <template slot="components">
              <slot name="carousel" />
            </template>
          </slider-cmp-extended>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SliderCmpExtended from './slider-cmp-extended';
import { castPropToType } from '@/helpers/cms-support';

export default {
  name: 'CarouselProductCardsCmp',
  components: { SliderCmpExtended },
  props: {
    dataEnabledFrom: {
      type: [Number, String],
      default: null,
    },
    dataOverflowVisible: {
      type: [Boolean, String],
      default: false,
    },
  },
  setup() {
    return {
      castPropToType,
    };
  },
  computed: {
    enabledFrom() {
      return this.dataEnabledFrom
        ? this.castPropToType(this.dataEnabledFrom, 'number')
        : null;
    },
    overflowVisible() {
      return this.dataOverflowVisible
        ? this.castPropToType(this.dataOverflowVisible, 'boolean')
        : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.CarouselProductCardsCmp {
  padding-top: 40px;

  @include media-breakpoint-up(md) {
    padding-top: 80px;
  }

  ::v-deep .SliderCmpExtended {
    .slider-content:not(.disabled) {
      padding-bottom: 107px;

      @include media-breakpoint-up(md) {
        padding-bottom: 122px;
      }

      .swiper-container {
        .swiper-pagination {
          padding: 40px 0 55px;

          @include media-breakpoint-up(md) {
            padding: 55px 0 55px;
          }
        }
      }
      .swiper-slide {
        height: auto;
      }
    }
  }
}
</style>
