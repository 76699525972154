<template functional>
  <div
    :ref="data.ref"
    :class="['InfoDropdownCmp', data.class, data.staticClass]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <div class="first-box">
      <slot name="dropdown" />
      <div class="title-wrapper a-paragraph-3">
        <slot name="first-box-title" />
      </div>
      <div class="copy-wrapper a-support">
        <slot name="first-box-copy" />
      </div>
    </div>
    <div class="second-box">
      <div class="number-wrapper n-heading-2">
        <slot name="second-box-number" />
      </div>
      <div class="title-wrapper a-paragraph-3">
        <slot name="second-box-title" />
      </div>
      <div class="copy-wrapper a-support">
        <slot name="second-box-copy" />
      </div>
    </div>
    <div class="third-box">
      <div class="difficulty-wrapper">
        <svg
          v-for="n in parseInt(props.dataDifficulty, 10)"
          :key="`chef-hat-${n}`"
        >
          <use href="#icon-chef-hat" />
        </svg>
      </div>
      <div class="title-wrapper a-paragraph-3">
        <slot name="third-box-title" />
      </div>
      <div class="copy-wrapper a-support">
        <slot name="third-box-copy" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoDropdownCmp',
  functional: true,
  props: {
    dataDifficulty: {
      type: [Number, String],
      default: 1,
    },
  },
};
</script>

<style lang="scss" scoped>
.InfoDropdownCmp {
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: $color-light-blue;
  color: $color-white;
  text-align: center;
  border-radius: 8px;

  .first-box,
  .second-box,
  .third-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: 1 0 100%;
  }

  .first-box {
    padding: 40px;
    background-color: $color-blue;
    border-radius: 8px 8px 0 0;

    @include media-breakpoint-up(md) {
      padding: 24px 40px 40px;
    }

    @include media-breakpoint-up(lg) {
      flex: 1 0 0;
      border-radius: 8px 0 0 8px;
    }
  }
  .second-box,
  .third-box {
    padding: 40px;

    @include media-breakpoint-up(md) {
      flex: 1 0 0;
    }

    @include media-breakpoint-up(lg) {
      padding: 48px 40px 40px;
    }
  }

  &.twoCols {
    .second-box {
      display: none;
    }
    .third-box {
      padding: 40px;
      @include media-breakpoint-up(md) {
      }

      @include media-breakpoint-up(lg) {
        padding-left: 0;
        padding-top: 48px;
      }
    }
  }

  &:not(.twoCols) {
    .third-box {
      padding-top: 0;

      @include media-breakpoint-up(md) {
        padding-top: 40px;
        padding-left: 0;
      }

      @include media-breakpoint-up(lg) {
        padding-top: 48px;
      }
    }
  }

  .third-box {
    .difficulty-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      svg {
        width: 38px;
        height: 38px;
        margin: 3px 0;
        color: $color-white;

        @include media-breakpoint-up(md) {
          margin: 7.41px 0;
        }

        @include media-breakpoint-up(xl) {
          width: 48px;
          height: 48px;
          margin: 6.8px 0;
        }
      }
    }
  }

  .title-wrapper {
    padding-top: 16px;
  }

  ::v-deep .DropdownCmpExtended {
    .CtaButtonCmp {
      --cta-button-border-color: #{$color-blue-75};
      --cta-button-hover-border-color: #{$color-blue-75};
    }
  }
}
</style>
