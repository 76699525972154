<template>
  <figure class="FigureCardCmp">
    <slot />
    <div
      v-if="hasSlot($slots.figcaption, $scopedSlots.figcaption)"
      class="container-fluid px-0"
    >
      <div class="row justify-content-center">
        <div class="col-12 col-md-7 col-lg-8 col-xl-7">
          <figcaption class="a-paragraph-3">
            <slot name="figcaption" />
            <div
              v-if="hasSlot($slots.cta, $scopedSlots.cta)"
              class="cta-wrapper"
            >
              <slot name="cta" />
            </div>
          </figcaption>
        </div>
      </div>
    </div>
  </figure>
</template>

<script>
import { hasSlot } from '@/helpers/cms-support';

export default {
  name: 'FigureCardCmp',
  setup() {
    return { hasSlot };
  },
};
</script>

<style lang="scss" scoped>
.FigureCardCmp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid $color-grey-600-50;
  border-radius: 8px;

  ::v-deep img {
    width: auto;
    max-height: 80%;
  }

  figcaption {
    padding-top: 16px;
    text-align: center;

    .cta-wrapper {
      max-width: 100%;
      padding: 8px 0 0;
    }
  }
}
</style>
