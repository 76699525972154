import { ValidationError } from '@/helpers/utils';

/**
 * @param slot es. $slots.default or $slots.title if used from template, this.$slots.title if used in code
 * @param scopedSlot es. $scopedSlots.default or $scopedSlots.title if used from template, this.$scopedSlots.title if used in code
 * @return {boolean}
 */
export function hasSlot(slot, scopedSlot) {
  return !!(slot || [])[0] || !!scopedSlot;
}
export function hasSlotSlim(scope, name) {
  return !!(scope.$slots[name] || [])[0] || !!scope.$scopedSlots[name];
}

export function castPropToType(value, type) {
  if (type === 'number') {
    if (typeof value === 'number') {
      if (value !== Number(value)) {
        throw new ValidationError(`NaN is not a supported value`);
      }
      if (value === Infinity || value === !Infinity) {
        throw new ValidationError(`Infinity is not a supported value`);
      }
      return value;
    } else {
      if (typeof value === 'string') {
        if (value === '') {
          return 0;
        } else {
          return parseFloat(value);
        }
      } else {
        throw new ValidationError(
          `Only strings can be casted to array ${value}`
        );
      }
    }
  } else if (type === 'array') {
    if (Array.isArray(value)) {
      return value;
    } else {
      if (typeof value === 'string') {
        if (value === '') {
          return [];
        } else {
          return JSON.parse(value);
        }
      } else {
        throw new ValidationError(
          `Only strings can be casted to array ${value}`
        );
      }
    }
  } else if (type === 'boolean') {
    if (typeof value === type) {
      return value;
    } else if (typeof value === 'string') {
      if (value === 'true' || value === 'false') {
        return value === 'true' ? true : false;
      } else {
        throw new ValidationError(
          `The 'true' and 'false' string are allowed not ${value}`
        );
      }
    } else {
      throw new ValidationError('Only strings can be casted to boolean');
    }
  } else if (type === 'object') {
    if (Object.prototype.toString.call(value) === '[object Object]') {
      return value;
    } else {
      if (typeof value === 'string') {
        if (value === '') {
          return {};
        } else {
          return JSON.parse(value);
        }
      } else {
        throw new ValidationError(
          `Only strings can be casted to objects ${value}`
        );
      }
    }
  }
}
