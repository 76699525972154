<template>
  <div class="GlobalConfig d-none" aria-hidden="true" />
</template>

<script>
import { castPropToType } from '@/helpers/cms-support';

export default {
  name: 'GlobalConfig',
  props: {
    dataConfig: {
      type: [Object, String],
      required: true,
    } /*
    dataLanguages: {
      type: [Object, String],
      required: true,
    },
    dataApi: {
      type: [Object, String],
      required: true,
    },
    dataHideRecaptcha: {
      type: [Boolean, String],
      default: false,
    },
    dataIcons: {
      type: [Object, String],
      default: null,
    },
    dataPlantATreeContest: {
      type: [Object, String],
      required: true,
    },
    dataLeisureAndFunContest: {
      type: [Object, String],
      required: true,
    },
    dataBrunchInNatureContest: {
      type: [Object, String],
      required: true,
    },
    dataSandwichSummerCampaignContest: {
      type: [Object, String],
      required: true,
    },
    dataExtraInstagramContest: {
      type: [Object, String],
      required: true,
    },
    dataExtraNorwaySandwichContest: {
      type: [Object, String],
      required: true,
    },
    dataSaveTheInsects: {
      type: [Object, String],
      required: true,
    },
    dataSandwichSurf: {
      type: [Object, String],
      required: true,
    },
    dataNewsConfig: {
      type: [Object, String],
      required: true,
    },
    dataSmcIntegration: {
      type: [Object, String],
      required: true,
    },
    dataJekoIntegration: {
      type: [Object, String],
      required: true,
    },*/,
  },
  setup() {
    return { castPropToType };
  },
  computed: {
    /*hideRecaptcha() {
      return this.dataHideRecaptcha
        ? this.$_cmsSupport_castPropToType(this.dataHideRecaptcha, 'boolean')
        : null;
    },*/
    conf() {
      return this.dataConfig
        ? this.castPropToType(this.dataConfig, 'object')
        : null;
    } /*
    langs() {
      return this.dataLanguages
        ? this.$_cmsSupport_castPropToType(this.dataLanguages, 'object')
        : null;
    },
    apis() {
      return this.dataApi
        ? this.$_cmsSupport_castPropToType(this.dataApi, 'object')
        : null;
    },
    icons() {
      return this.dataIcons
        ? this.$_cmsSupport_castPropToType(this.dataIcons, 'object')
        : null;
    }
    plantATreeContest() {
      return this.dataPlantATreeContest
        ? this.$_cmsSupport_castPropToType(this.dataPlantATreeContest, 'object')
        : null;
    },
    leisureAndFunContest() {
      return this.dataLeisureAndFunContest
        ? this.$_cmsSupport_castPropToType(
            this.dataLeisureAndFunContest,
            'object'
          )
        : null;
    },
    brunchInNatureContest() {
      return this.dataBrunchInNatureContest
        ? this.$_cmsSupport_castPropToType(
            this.dataBrunchInNatureContest,
            'object'
          )
        : null;
    },
    sandwichSummerCampaignContest() {
      return this.dataSandwichSummerCampaignContest
        ? this.$_cmsSupport_castPropToType(
            this.dataSandwichSummerCampaignContest,
            'object'
          )
        : null;
    },
    extraInstagramContest() {
      return this.dataExtraInstagramContest
        ? this.$_cmsSupport_castPropToType(
            this.dataExtraInstagramContest,
            'object'
          )
        : null;
    },
    extraNorwaySandwichContest() {
      return this.dataExtraNorwaySandwichContest
        ? this.$_cmsSupport_castPropToType(
            this.dataExtraNorwaySandwichContest,
            'object'
          )
        : null;
    },
    saveTheInsects() {
      return this.dataSaveTheInsects
        ? this.$_cmsSupport_castPropToType(this.dataSaveTheInsects, 'object')
        : null;
    },
    sandwichSurf() {
      return this.dataSandwichSurf
        ? this.$_cmsSupport_castPropToType(this.dataSandwichSurf, 'object')
        : null;
    },
    newsConfig() {
      return this.dataNewsConfig
        ? this.$_cmsSupport_castPropToType(this.dataNewsConfig, 'object')
        : null;
    },
    smcIntegration() {
      return this.dataSmcIntegration
        ? this.$_cmsSupport_castPropToType(this.dataSmcIntegration, 'object')
        : null;
    },
    jekoIntegration() {
      return this.dataJekoIntegration
        ? this.$_cmsSupport_castPropToType(this.dataJekoIntegration, 'object')
        : null;
    },*/,
  },
  mounted() {
    const promises = [];
    if (this.conf) {
      promises.push(this.$store.dispatch('config/setConfig', this.conf));
    } /*
    if (this.langs) {
      promises.push(
        this.$store.dispatch('config/setConfig', { languages: this.langs })
      );
      promises.push(
        this.$store.dispatch('config/setConfig', {
          language: this.langs[0].site_code.split('-')[0],
        })
      );
    }
    if (this.apis) {
      promises.push(
        this.$store.dispatch('config/setConfig', { apis: this.apis })
      );
    }
    if (this.hideRecaptcha) {
      promises.push(
        this.$store.dispatch('config/setConfig', {
          hideRecaptcha: this.hideRecaptcha,
        })
      );
    }
    if (this.newsConfig) {
      promises.push(this.$store.dispatch('config/setConfig', this.newsConfig));
    }
    if (this.icons) {
      promises.push(
        this.$store.dispatch('icons/setConfig', { icons: this.icons })
      );
    }
    if (this.smcIntegration) {
      promises.push(
        this.$store.dispatch('smc/setSmcInitData', this.smcIntegration)
      );
    }
    if (this.plantATreeContest) {
      promises.push(
        this.$store.dispatch(
          'contestPlantATree/setContestPlantATreeInitData',
          this.plantATreeContest
        )
      );
    }
    if (this.leisureAndFunContest) {
      promises.push(
        this.$store.dispatch(
          'pm9LeisureAndFun/setPromoCeInitData',
          this.leisureAndFunContest
        )
      );
    }
    if (this.brunchInNatureContest) {
      promises.push(
        this.$store.dispatch(
          'pm56BrunchInNature/setPm56BrunchInNatureInitData',
          this.brunchInNatureContest
        )
      );
    }
    if (this.sandwichSummerCampaignContest) {
      promises.push(
        this.$store.dispatch(
          'pm56SandwichSummerCampaign/setPm56SandwichSummerCampaignInitData',
          this.sandwichSummerCampaignContest
        )
      );
    }
    if (this.extraInstagramContest) {
      promises.push(
        this.$store.dispatch(
          'pmextraInstagram/setPmextraInstagramInitData',
          this.extraInstagramContest
        )
      );
    }
    if (this.extraNorwaySandwichContest) {
      promises.push(
        this.$store.dispatch(
          'pmextraNorwaySandwich/setPmextraNorwaySandwichInitData',
          this.extraNorwaySandwichContest
        )
      );
    }
    if (this.saveTheInsects) {
      promises.push(
        this.$store.dispatch(
          'pm9SaveTheInsects/setPm9SaveTheInsectsInitData',
          this.saveTheInsects
        )
      );
    }
    if (this.sandwichSurf) {
      promises.push(
        this.$store.dispatch(
          'pm56SandwichSurf/setPm56SandwichSurfInitData',
          this.sandwichSurf
        )
      );
    }

    if (this.jekoIntegration) {
      promises.push(
        this.$store.dispatch('contact/setJekoIntegration', this.jekoIntegration)
      );
    }*/
    Promise.all(promises)
      .then(() => {
        this.$store.dispatch('config/setStoreSetupCompleted', true);
      })
      .catch((e) => {
        // eslint-disable-next-line
        console.error(e);
      });
  },
};
</script>

<style scoped></style>
