<template>
  <component :is="dataTag" :class="['TableCellCmp', 'a-paragraph-2']">
    <template v-if="dataValue">
      {{
        customDecimalSeparator(dataValue, $store.state.config.decimal_separator)
      }}
    </template>
    <template v-else>
      <slot />
    </template>
  </component>
</template>

<script>
import { customDecimalSeparator } from '@/helpers/utils';
export default {
  name: 'TableCellCmp',
  props: {
    dataTag: {
      type: String,
      default: 'td',
    },
    dataValue: {
      type: [String, Number],
      default: '',
    },
  },
  setup() {
    return { customDecimalSeparator };
  },
};
</script>

<style lang="scss" scoped>
th.TableCellCmp {
  color: $color-blue;
  background-color: $color-blue-10;
}

td.TableCellCmp {
  color: $color_black;
}

.TableCellCmp {
  padding: 16px 32px;
  vertical-align: middle;

  ::v-deep small {
    display: block;
    @include responsive-typography-properties($font-primary, a-caption);
  }

  &:not(:first-child) {
    text-align: right;
  }

  &.tabbed {
    padding-left: 45px;
  }
}
</style>
