<template>
  <div class="FirstLevelCategoryPage pb-l">
    <hero-cmp
      :class="[
        { 'image': heroWithImage, 'colorful-bg': !heroWithImage },
        conf.category_color,
      ]"
    >
      <template v-if="heroWithImage" #image>
        <slot name="product-image" />
      </template>
      <template #type>
        <slot name="product-type" />
      </template>
      <template #title>
        <slot name="product-title" />
      </template>
      <template v-if="heroWithImage" #breadcrumbs>
        <slot name="product-breadcrumbs" />
      </template>
    </hero-cmp>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-8 pt-xs">
          <div v-if="!heroWithImage" class="d-flex justify-content-center pt-s">
            <slot name="product-breadcrumbs" />
          </div>
        </div>
      </div>
    </div>
    <div class="container pt-l pb-m">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <title-text-cmp>
            <template #title>
              <slot name="product-subtitle" />
            </template>
            <template #text>
              <slot name="product-description" />
            </template>
            <template #cta>
              <cta-button-cmp-extended
                class="btn-text"
                :data-url="contact_us_href"
                :data-aria-label="contact_us_label_category"
              >
                {{ contact_us_label_category }}
                <template #after-icon>
                  <svg>
                    <use href="#icon-arrow-right" />
                  </svg>
                </template>
                <template #seo-link>
                  <slot name="product-seo-link" />
                </template>
              </cta-button-cmp-extended>
            </template>
          </title-text-cmp>
        </div>
      </div>
    </div>
    <div class="container">
      <div
        v-if="conf.sub_categories && conf.sub_categories.length > 0"
        class="row justify-content-start"
      >
        <slot name="product-categories" />
      </div>
      <div v-else class="row mt-nr">
        <div
          v-if="
            isSelectCountryVisibleInFirstLevel && thereIsAtLeastOneProduct /*&&
            atLeastOneProductHasAtLeastOneCountry*/
          "
          :class="[
            {
              'pt-l':
                conf.category_properties && conf.category_properties.length,
            },
          ]"
        >
          <p class="a-support text-color-grey-800 pb-xs">
            {{ filter_by_label }}
          </p>
          <div class="d-flex" style="gap: 50px">
            <dropdown-cmp-extended
              data-tag="button"
              :data-trigger-theme-classes="[
                { selected: selectedCountries.length > 0 },
              ]"
              :data-menu-theme-classes="[
                'shadow-soft',
                { scrollable: countriesList.length > 8 },
              ]"
            >
              {{ countriesFilterLabel }}
              <template v-if="selectedCountries.length > 0">
                ({{ selectedCountries.length }})
              </template>
              <template #after-icon>
                <svg class="icon-caret-down">
                  <use href="#icon-caret-down" />
                </svg>
              </template>
              <template #dropdown-menu>
                <div class="scroll-container">
                  <input-checkbox-cmp-extended
                    v-model.trim="selectedCountries"
                    data-name="checkbox"
                    data-aria-label="Checkbox"
                    class="custom direction-column"
                    :data-checkbox-list="
                      countriesList.map((w) => ({
                        value: w.value,
                        label: w.label,
                      }))
                    "
                  />
                </div>
              </template>
            </dropdown-cmp-extended>
          </div>
        </div>
        <div
          v-for="(product, index) of products"
          :key="`product_${product.slug}_${index}`"
          class="col-md-6 col-lg-4 pt-r"
        >
          <card-product-cmp
            class="product animationForTransparentsImgs"
            :data-href="product.href"
            :data-quantity="product.quantity_parsed"
            :data-measure-unit-value="product.measure_unit_value"
            :data-measure-unit="product.measure_unit"
          >
            <template #seo-link>
              <a :href="product.href"></a>
            </template>
            <template #title>
              <h3>{{ product.title }}</h3>
            </template>
            <template #image>
              <img :src="product.image" :alt="product.title" />
            </template>
          </card-product-cmp>
        </div>
        <div v-if="showEmptyMessage" class="empty-message pt-r">
          {{ noProductsLabel }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { castPropToType } from '@/helpers/cms-support';
import { mapState } from 'vuex';

export default {
  name: 'FirstLevelCategoryPage',
  props: {
    dataConfig: { type: String, required: true },
  },
  setup() {
    return { castPropToType };
  },
  data() {
    return {
      selectedCountries: [],
    };
  },
  computed: {
    ...mapState('config', ['contact_us_href']),
    ...mapState('products', [
      'filter_by_label',
      'hero_header_label',
      'contact_us_label_category',
      'noProductsLabel',
      'countriesFilterLabel',
      'isSelectCountryVisibleInFirstLevel',
    ]),
    conf() {
      return this.dataConfig
        ? this.castPropToType(this.dataConfig, 'object')
        : null;
    },
    thereIsAtLeastOneProduct() {
      return this.products.length;
    },
    countriesList() {
      return this.conf.countries ? this.conf.countries : [];
    },
    heroWithImage() {
      return !!this.conf.hero_img;
    },
    showEmptyMessage() {
      return (
        this.products.length === 0 &&
        this.conf.products &&
        this.conf.products.length
      );
    },
    atLeastOneProductHasAtLeastOneCountry() {
      if (!this.conf.products) {
        return [];
      }
      return this.mergeArrays(
        this.conf.products.map((product) => {
          return product.available_countries;
        })
      ).length;
    },
    // #fe-point-1
    products() {
      if (!this.conf.products) {
        return [];
      }
      return this.mergeArrays(
        this.conf.products.map((product) => {
          if (!product.product_weights) {
            return [];
          }
          return product.product_weights
            .map((weight) => {
              return {
                href: product.href,
                slug: product.slug,
                category: product.category_slug,
                title: product.list_description,
                image: product.list_image,
                weightId: `${weight.id}`,
                quantity_parsed: weight.quantity_parsed,
                measure_unit_value: weight.measure_unit_value,
                measure_unit: weight.measure_unit,
                available_countries: product.available_countries,
              };
            })
            .filter((product) => {
              return this.selectedCountries.length
                ? product.available_countries
                    .map((countryObject) => countryObject.value)
                    .some((countrySlug) =>
                      this.selectedCountries.includes(countrySlug)
                    )
                : true;
            });
        })
      );
    },
  },
  methods: {
    mergeArrays(items) {
      return [].concat.apply([], items);
    },
  },
};
</script>
