<template>
  <div class="FocusItemsTabContentCmp">
    <div
      v-if="hasSlot($slots.heading, $scopedSlots.heading)"
      class="row justify-content-center"
    >
      <div class="col-12 col-custom-md-8-10 heading-wrapper a-paragraph-2">
        <slot name="heading" />
      </div>
    </div>
    <div
      v-if="hasSlot($slots['focus-items'], $scopedSlots['focus-items'])"
      class="items-wrapper"
    >
      <slot name="focus-items" />
    </div>
    <div
      v-if="hasSlot($slots.footer, $scopedSlots.footer)"
      class="row justify-content-center"
    >
      <div class="col-12 col-custom-md-8-10 footer-wrapper a-paragraph-2">
        <slot name="footer" />
      </div>
    </div>
    <div
      v-if="hasSlot($slots.download, $scopedSlots.download)"
      class="download-wrapper"
    >
      <slot name="download" />
    </div>
  </div>
</template>

<script>
import { hasSlot } from '@/helpers/cms-support';

export default {
  name: 'FocusItemsTabContentCmp',
  setup() {
    return {
      hasSlot,
    };
  },
};
</script>

<style lang="scss" scoped>
.FocusItemsTabContentCmp {
  .heading-wrapper {
    color: $color-black;
    text-align: center;
    padding-bottom: 80px;
  }

  .footer-wrapper {
    color: $color-black;
    text-align: center;
    padding-bottom: 80px;
  }

  &:not(.with-grid) {
    .items-wrapper {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: flex-start;
      gap: 16px;
      padding: 0 16px 16px;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        padding: 0 24px 24px;
      }

      @include media-breakpoint-up(lg) {
        padding: 0 40px 40px;
      }

      .TitleTextCmp {
        --title-text-text-align: left;
        --title-text-icon-wrapper-padding: 0 0 24px;
        --title-text-text-wrapper-padding: 16px 0 0;
        --title-text-icon-wrapper-icon-width: 72px !important;
        --title-text-icon-wrapper-icon-height: 72px !important;
        @include override-responsive-typography-properties(
          $font-secondary,
          n-paragraph-1,
          --title-text-title-wrapper
        );
        @include override-responsive-typography-properties(
          $font-primary,
          a-paragraph-3,
          --title-text-text-wrapper
        );
        flex: 1 0 0;
      }
    }
  }

  &.with-grid {
    .items-wrapper {
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px;
      padding-bottom: 16px;

      @include media-breakpoint-up(md) {
        grid-template-columns: 1fr 1fr;
        padding-bottom: 24px;
      }

      @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr 1fr 1fr;
        padding-bottom: 40px;
      }
    }
  }
}
</style>
