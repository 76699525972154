<template>
  <div
    :class="[
      'AspectRatioBoxCmp',
      `${dataPositionX}-${dataPositionY}`,
      dataCrop,
    ]"
    :style="computedStyle"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AspectRatioBoxCmp',
  props: {
    dataAspectRatio: {
      type: String,
      default: '16:9',
    },
    dataPaddingOrientation: {
      type: String,
      default: 'top',
      validator: function (value) {
        // The value must match one of these strings
        return ['top', 'bottom'].indexOf(value) !== -1;
      },
    },
    dataPositionX: {
      type: String,
      default: 'center',
      validator: function (value) {
        // The value must match one of these strings
        return ['left', 'center', 'right'].indexOf(value) !== -1;
      },
    },
    dataPositionY: {
      type: String,
      default: 'center',
      validator: function (value) {
        // The value must match one of these strings
        return ['top', 'center', 'bottom'].indexOf(value) !== -1;
      },
    },
    dataCrop: {
      type: String,
      default: 'cover',
      validator: function (value) {
        // The value must match one of these strings
        return (
          ['fill', 'contain', 'cover', 'none', 'scale-down'].indexOf(value) !==
          -1
        );
      },
    },
  },
  computed: {
    computedStyle() {
      let ratio = this.dataAspectRatio.split(':');
      ratio = +ratio[0] / +ratio[1];
      return {
        [`padding-${this.dataPaddingOrientation}`]: `${(
          (1 / ratio) *
          100
        ).toFixed(8)}%`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.AspectRatioBoxCmp {
  position: relative;
  width: 100%;
  height: 0;

  > img {
    position: absolute;
    inset: 0;
    max-width: 100%;
    height: 100%;
  }

  &.left-top {
    > img {
      object-position: left top;
    }
  }

  &.left-center {
    > img {
      object-position: left center;
    }
  }

  &.left-bottom {
    > img {
      object-position: left bottom;
    }
  }

  &.center-top {
    > img {
      object-position: center top;
    }
  }

  &.center-center {
    > img {
      object-position: center center;
    }
  }

  &.center-bottom {
    > img {
      object-position: center bottom;
    }
  }

  &.right-top {
    > img {
      object-position: right top;
    }
  }

  &.right-center {
    > img {
      object-position: right center;
    }
  }

  &.right-bottom {
    > img {
      object-position: right bottom;
    }
  }

  &.fill {
    > img {
      object-fit: fill;
    }
  }

  &.contain {
    > img {
      object-fit: contain;
    }
  }

  &.cover {
    > img {
      object-fit: cover;
    }
  }

  &.none {
    > img {
      object-fit: none;
    }
  }

  &.scale-down {
    > img {
      object-fit: scale-down;
    }
  }
}
</style>
