<template>
  <input-text-cmp
    class="InputTextCmpExtended"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </input-text-cmp>
</template>

<script>
import { InputTextCmp } from '@frankhoodbs/lib';

export default {
  name: 'InputTextCmpExtended',
  components: { InputTextCmp },
};
</script>

<style lang="scss" scoped>
.InputTextCmpExtended.form-group__wrapper {
  --form-element-padding: 0;
  --form-element-input-border-radius: 4px;
  --form-element-input-border-width: 2px;
  --form-element-input-border-color: #{$color-grey-600-50};
  --form-element-input-padding-top: 12px;
  --form-element-input-padding-right: 22px;
  --form-element-input-padding-bottom: 12px;
  --form-element-input-padding-left: 22px;
  --form-element-placeholder-color: #{$color-grey-800-50};
  --form-element-input-hover-border-color: #{$color-grey-800-50};
  --form-element-placeholder-hover-color: #{$color-grey-900-50};
  --form-element-input-focus-border-color: #{$color-blue};
  --form-element-disabled-bg-color: #{$color-grey-400-50};
  --form-element-input-disabled-border-color: #{$color-grey-600-50};
  --form-element-input-disabled-color: #{$color-grey-600-50};
  --form-element-placeholder-disabled-color: #{$color-grey-600-50};
  --form-element-error-color: #{$color-informative-red};
  --form-element-helper-color: #{$color-grey-600};

  ::v-deep .form-group {
    .form__label {
      @include override-responsive-typography-properties(
        $font-primary,
        a-support,
        --form-element-label
      );
      --form-element-label-color: #{$color-grey-800};
    }

    .form-control__wrapper {
      .form-control {
        @include override-responsive-typography-properties(
          $font-primary,
          a-body,
          --form-element-input
        );
      }
    }
  }

  ::v-deep .error {
    @include override-responsive-typography-properties(
      $font-primary,
      a-caption,
      --form-element-error
    );
  }
}
</style>
