<template functional>
  <figure
    :ref="data.ref"
    :class="['SimpleCardCmp', data.class, data.staticClass]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <img
      class="shadow-hard"
      :src="props.dataImage"
      width="192"
      height="192"
      :alt="props.dataImageAlt"
    />
    <figcaption class="text-wrapper">
      <slot />
    </figcaption>
  </figure>
</template>

<script>
export default {
  name: 'SimpleCardCmp',
  functional: true,
  props: {
    dataImage: {
      type: String,
      default: null,
    },
    dataImageAlt: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.SimpleCardCmp {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
  margin: 0 auto;

  @include media-breakpoint-up(md) {
    width: 160px;
  }

  @include media-breakpoint-up(lg) {
    width: 130px;
  }

  @include media-breakpoint-up(xl) {
    width: 192px;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }

  .text-wrapper {
    padding-top: 24px;
    @include responsive-typography-properties($font-primary, a-paragraph-2);
    color: $color-blue;
    text-align: center;
  }
}
</style>
