<template>
  <div class="MediaPlayerCmp">
    <lightbox-cmp :data-fullscreen="fullscreen">
      <template>
        <youtube-media-player-cmp-extended
          v-if="themeVariant !== 'highlighted'"
          :data-background-image-src="dataBackgroundImageSrc"
          :data-youtube-video-id="dataYoutubeVideoId"
          :data-bootstrap-container-classes="bootstrapContainerClasses"
          :data-bootstrap-row-classes="bootstrapRowClasses"
          :data-bootstrap-col-classes="bootstrapColClasses"
          :data-zoom-in="true"
          @zoom-in="fullscreen = true"
        >
          <template slot="icon-play">
            <svg>
              <use href="#icon-play" />
            </svg>
          </template>
          <template slot="caption">
            <slot />
          </template>
        </youtube-media-player-cmp-extended>
        <highlighted-video-cmp
          v-else
          :data-bootstrap-container-classes="bootstrapContainerClasses"
        >
          <youtube-media-player-cmp-extended
            :data-background-image-src="dataBackgroundImageSrc"
            :data-youtube-video-id="dataYoutubeVideoId"
            :data-zoom-in="true"
            @zoom-in="fullscreen = true"
          >
            <template slot="icon-play">
              <svg>
                <use href="#icon-play" />
              </svg>
            </template>
            <template slot="caption">
              <slot />
            </template>
          </youtube-media-player-cmp-extended>
        </highlighted-video-cmp>
      </template>
      <template slot="lightbox">
        <button type="button" class="zoom-out" @click="onDismiss">
          <svg>
            <use href="#icon-zoom-out" />
          </svg>
        </button>
        <youtube-media-player-cmp-extended
          class="fullscreen-mode"
          :data-dismissible="true"
          :data-background-image-src="dataBackgroundImageSrc"
          :data-youtube-video-id="dataYoutubeVideoId"
          :data-bootstrap-row-classes="['row']"
          :data-bootstrap-col-classes="['col-12']"
          @zoom-out="fullscreen = false"
        >
          <template slot="icon-play">
            <svg>
              <use href="#icon-play" />
            </svg>
          </template>
        </youtube-media-player-cmp-extended>
      </template>
    </lightbox-cmp>
  </div>
</template>

<script>
import { LightboxCmp } from '@frankhoodbs/lib';
import HighlightedVideoCmp from './highlighted-video-cmp';
import YoutubeMediaPlayerCmpExtended from './youtube-media-player-cmp-extended';
import { castPropToType } from '@/helpers/cms-support';

export default {
  name: 'MediaPlayerCmp',
  components: {
    LightboxCmp,
    HighlightedVideoCmp,
    YoutubeMediaPlayerCmpExtended,
  },
  props: {
    dataBackgroundImageSrc: {
      type: String,
      default: '',
    },
    dataYoutubeVideoId: {
      type: String,
      required: true,
    },
    dataBootstrapContainerClasses: {
      type: [String, Array],
      default: () => ['container'],
    },
    dataBootstrapRowClasses: {
      type: [String, Array],
      default: () => ['row justify-content-center'],
    },
    dataBootstrapColClasses: {
      type: [String, Array],
      default: () => ['col-12 col-md-8'],
    },
  },
  setup() {
    return {
      castPropToType,
    };
  },
  data() {
    return {
      fullscreen: false,
      themeVariant: null,
    };
  },
  computed: {
    bootstrapContainerClasses() {
      return this.dataBootstrapContainerClasses
        ? this.castPropToType(this.dataBootstrapContainerClasses, 'array')
        : [];
    },
    bootstrapRowClasses() {
      return this.dataBootstrapRowClasses
        ? this.castPropToType(this.dataBootstrapRowClasses, 'array')
        : [];
    },
    bootstrapColClasses() {
      return this.dataBootstrapColClasses
        ? this.castPropToType(this.dataBootstrapColClasses, 'array')
        : [];
    },
  },
  mounted() {
    this.themeVariant = this.$el.classList.contains('highlighted')
      ? 'highlighted'
      : 'default';
  },
  methods: {
    onDismiss() {
      document.body.classList.remove('modal-open');
      this.fullscreen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.MediaPlayerCmp {
  @include media-breakpoint-up(md) {
    --lightbox-overlay-outline: calc(
      #{$size-outline} + #{$size-outline-offset}
    );
  }

  ::v-deep .LightboxCmp {
    .zoom-out {
      position: absolute;
      top: 24px;
      right: 24px;
      z-index: map_get($z, above);

      svg {
        width: 32px;
        height: 32px;
        color: #ffffff;
      }
    }
  }
}
</style>
