import Vue from 'vue';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import Hidden from 'ally.js/esm/maintain/hidden.js';
import TabFocus from 'ally.js/esm/maintain/tab-focus.js';

const A11Support = Vue.extend({
  methods: {
    $_a11Support_setVoiceOverFocus(element) {
      (function check() {
        if (document.activeElement !== element) {
          setTimeout(() => {
            element.setAttribute('tabindex', '0');
            setTimeout(() => {
              element.blur();
              setTimeout(() => {
                element.focus();
                setTimeout(() => {
                  setTimeout(check, 10);
                }, 10);
              }, 10);
            }, 10);
          }, 10);
        } else {
          //console.log(`Focus impostato su`, element)
        }
      })();
    },
    $_a11Support_trapScreenReader({ $el, _uid }) {
      _uid = `screenReaderTrap-${_uid}`;

      window[`hidden-${_uid}`] = Hidden({
        filter: $el,
      });

      window[`focus-${_uid}`] = TabFocus({
        context: $el,
      });

      return {
        hidden: window[`hidden-${_uid}`],
        focus: window[`focus-${_uid}`],
      };
    },
    $_a11Support_untrapScreenReader({ _uid }) {
      _uid = `screenReaderTrap-${_uid}`;
      const types = ['focus', 'trap', 'hidden'];

      types.forEach((type) => {
        if (window[`${type}-${_uid}`] && window[`${type}-${_uid}`].disengage) {
          window[`${type}-${_uid}`].disengage();
          delete window[`${type}-${_uid}`];
        }
      });
    },
    $_a11Support_clearAllBodyScrollLocks() {
      document.documentElement.style.overflowX = '';
      return clearAllBodyScrollLocks();
    },
    $_a11Support_disableScrollOuterElement({
      disableUp = false,
      $el = null,
      enable = false,
    }) {
      if (disableUp && window.innerWidth > disableUp) {
        document.documentElement.style.overflowX = '';
        return clearAllBodyScrollLocks();
      }

      if (enable) {
        document.documentElement.style.overflowX = 'visible';
        return disableBodyScroll($el);
      }

      document.documentElement.style.overflowX = '';
      return clearAllBodyScrollLocks();
    },
  },
});

export default A11Support;
