import Vue from 'vue';
import store from '@/store';
import axios from 'axios';
import qs from 'qs';
import {
  debug,
  colorLog,
  brain,
  pop,
  prohibited,
  getMountPointId,
} from '@/helpers/utils.js';
import SentryInitializer, {
  canLogOnSentry,
} from '@/helpers/sentry_initializer';

// Add all plugins import HERE
/* EXAMPLE
import VModal from 'vue-js-modal';
*/
import VModal from 'vue-js-modal';
import VueBootstrapper from 'vue-bootstrapper';
import VueViewportManager from 'vue-viewport-manager';

// Add all plugins application HERE
/* EXAMPLE
Vue.use(VModal);
*/
Vue.use(VModal);
Vue.use(VueBootstrapper, { store });
Vue.use(VueViewportManager, {
  store,
  breakpoints: { xs: 0, md: 768, lg: 1024, xl: 1440 },
});

import '@/helpers/load-components.js';
import A11Support from '@/mixins/a11-support';
import VueCompositionAPI from '@vue/composition-api';

Vue.mixin(A11Support);
Vue.use(VueCompositionAPI);

axios.interceptors.request.use(function (config) {
  config.paramsSerializer = (params) => {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  };
  return config;
});

/* eslint-disable */
colorLog({
  icon: pop,
  label: 'Welcome',
  message: `Barilla For Professionals`,
  theme: {
    backgroundColorLeft: '#1c3775',
    backgroundColorRight: '#ba0c2f',
    colorLeft: '#ffffff',
    colorRight: '#ffffff',
  },
});
if (debug) {

  colorLog({
    icon: brain,
    label: 'environment',
    message: `${process.env.VUE_APP_ENV}`,
    themeName: 'orange',
  });

  colorLog({
    icon: brain,
    label: 'environment',
    message: `Vue added to window `,
    themeName: 'orange',
  });

  colorLog({
    icon: brain,
    label: 'environment',
    message: `Devtools enabled `,
    themeName: 'orange',
  });

  window.Vue = Vue;
  Vue.config.devtools = true;
}

if (!canLogOnSentry) {
  colorLog({
    icon: prohibited,
    label: 'environment',
    message: `Sentry disabled`,
    themeName: 'red2',
  });
}
/* eslint-enable */

/*
  https://4c26933f1a5e40349ea3f5ca6aba1ef9@sentry.frankhood.dev/28
  https://sentry.frankhood.dev/organizations/frankhood/issues/?project=28
*/

SentryInitializer(
  Vue,
  process.env.VUE_APP_SENTRY,
  process.env.VUE_APP_ENV,
  process.env.VUE_APP_SITENAME
);
const app = document.getElementById(getMountPointId());

if (app && app.hasAttribute('data-v-cloak')) {
  app.removeAttribute('data-v-cloak');
  app.setAttribute('v-cloak', '');
}
