<template functional>
  <div
    :ref="data.ref"
    :class="['RecipesFooterCmp', data.class, data.staticClass]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <div class="tags-wrapper">
      <slot name="tags" />
    </div>
    <div class="title-separator-wrapper">
      <slot name="title-separator" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecipesFooterCmp',
  functional: true,
};
</script>

<style lang="scss" scoped>
.RecipesFooterCmp {
  .tags-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 8px;
  }

  .title-separator-wrapper {
    @include override-responsive-typography-properties(
      $font-secondary,
      n-paragraph-1,
      --title-separator-title-wrapper
    );
    padding-top: 40px;

    @include media-breakpoint-up(lg) {
      padding-top: 80px;
    }
  }
}
</style>
