<template functional>
  <a
    :class="['GigyaMenuItem', data.class, data.staticClass]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    :href="props.dataGigyaProfileUrl"
    v-on="listeners"
  >
    <slot />
  </a>
</template>

<script>
export default {
  name: 'GigyaMenuItem',
  functional: true,
  props: {
    dataGigyaProfileUrl: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped></style>
