<template>
  <div class="CarouselImagesCmp">
    <lightbox-cmp :data-fullscreen="fullscreen">
      <template>
        <slider-cmp-extended
          ref="$sliderInPage"
          :data-bootstrap-row-classes="['row justify-content-center']"
          :data-bootstrap-col-classes="['col-12 col-md-6']"
          :data-component-slides="true"
          :data-disabled-from="dataDisabledFrom >= 0 ? dataDisabledFrom : null"
          :data-options="{
            observer: true,
            slidesPerView: 1,
            navigation: false,
          }"
          @active-index-change="onIndexChange"
          @zoom-in="fullscreen = true"
        >
          <template slot="custom-swipe-icon">
            <svg>
              <use href="#icon-scroller" />
            </svg>
          </template>
          <template slot="components">
            <slot name="carousel" />
          </template>
        </slider-cmp-extended>
      </template>
      <template slot="lightbox">
        <slider-cmp-extended
          ref="$sliderInOverlay"
          class="fullscreen-mode"
          :data-bootstrap-row-classes="['row justify-content-center']"
          :data-bootstrap-col-classes="['col-12 col-md-10']"
          :data-component-slides="true"
          :data-disabled-from="dataDisabledFrom >= 0 ? dataDisabledFrom : null"
          :data-options="{
            observer: true,
            slidesPerView: 1,
            initialSlide: currentIndex,
          }"
          @active-index-change="onIndexChange"
          @zoom-out="fullscreen = false"
        >
          <template slot="custom-swipe-icon">
            <svg>
              <use href="#icon-scroller" />
            </svg>
          </template>
          <template slot="zoom-out">
            <svg>
              <use href="#icon-zoom-out" />
            </svg>
          </template>
          <template slot="components">
            <slot name="carousel-lightbox" />
          </template>
        </slider-cmp-extended>
      </template>
    </lightbox-cmp>
  </div>
</template>

<script>
import { LightboxCmp } from '@frankhoodbs/lib';
import SliderCmpExtended from './slider-cmp-extended';

export default {
  name: 'CarouselImagesCmp',
  components: { LightboxCmp, SliderCmpExtended },
  props: {
    dataDisabledFrom: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      currentIndex: 0,
      fullscreen: false,
    };
  },
  mounted() {},
  methods: {
    onIndexChange(newIndex) {
      this.currentIndex = newIndex;
      if (this.$refs.$sliderInPage?.$children[0]?.swiper) {
        this.$refs.$sliderInPage?.$children[0]?.swiper.slideTo(
          this.currentIndex,
          0,
          false
        );
      }
      if (this.$refs.$sliderInOverlay?.$children[0]?.swiper) {
        this.$refs.$sliderInOverlay?.$children[0]?.swiper.slideTo(
          this.currentIndex,
          0,
          false
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.CarouselImagesCmp {
  ::v-deep .SliderCmpExtended {
    .slider-content {
      padding-bottom: 66px;

      @include media-breakpoint-up(xl) {
        padding-bottom: 82px;
      }

      .swiper-container {
        .swiper-pagination {
          padding: 39px 0 15px;

          @include media-breakpoint-up(xl) {
            padding: 55px 0 15px;
          }
        }
      }
    }
  }

  ::v-deep .lightbox {
    .SliderCmpExtended {
      --slider-zoom-out-color: #{$color-blue-10};

      .swiper-button-prev,
      .swiper-button-next {
        width: 38px;
        height: 38px;
        background: transparent;
        color: $color-blue-10;
        border: 2px solid $color-blue-10;
        cursor: pointer;

        &::after {
          transform: scale(0.33333333);
          font-weight: bolder;
        }
      }

      .swiper-button-prev {
        right: calc(100% + 24px);
        left: auto;

        &::after {
          padding-right: 5px;
        }
      }

      .swiper-button-next {
        right: auto;
        left: calc(100% + 24px);

        &::after {
          padding-left: 5px;
        }
      }

      .slider-container {
        @include media-breakpoint-down(md) {
          width: 100%;
          max-width: none;
          padding: 0;
        }

        .slider-content {
          overflow: visible;

          .swiper-slide {
            width: 100% !important;
          }
        }
      }
    }
  }
}
</style>
