<template functional>
  <div
    :ref="data.ref"
    :class="['GridRowCmp', data.class, data.staticClass]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'GridRowCmp',
  functional: true,
};
</script>

<style scoped></style>
