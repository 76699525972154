<template>
  <section class="CarouselCertificationsCmp">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <slider-cmp-extended
            :data-bootstrap-container-classes="['container-fluid', 'px-0']"
            :data-component-slides="true"
            :data-options="{
              observer: true,
              navigation: false,
              watchOverflow: true,
            }"
          >
            <template slot="custom-swipe-icon">
              <svg>
                <use href="#icon-scroller" />
              </svg>
            </template>
            <template slot="components">
              <slot name="carousel" />
            </template>
          </slider-cmp-extended>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SliderCmpExtended from './slider-cmp-extended';

export default {
  name: 'CarouselCertificationsCmp',
  components: { SliderCmpExtended },
};
</script>

<style lang="scss" scoped>
.CarouselCertificationsCmp {
  ::v-deep .SliderCmpExtended {
    --slider-container-overflow: visible;

    .slider-content {
      @include media-breakpoint-up(xl) {
        padding-bottom: 67px;
      }

      .swiper-container {
        .swiper-wrapper {
          .FigureCardCmp {
            justify-content: flex-start;
            height: 316px;
            padding: 24px 22px 0;

            @include media-breakpoint-up(md) {
              height: 335px;
              padding: 40px 0 0;
            }

            @include media-breakpoint-up(lg) {
              height: 325px;
              padding: 48px 0 0;
            }

            @include media-breakpoint-up(xl) {
              padding: 48px 0 0;
            }
          }
        }

        .swiper-pagination {
          @include media-breakpoint-up(xl) {
            padding: 40px 0 15px;
          }
        }
      }
    }

    .swiper-pagination-lock {
      display: none !important;
    }
  }
}
</style>
