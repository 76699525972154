import * as types from '../mutation_types';
import axios from 'axios';

export default {
  async loadRecipes(
    { state, commit, rootState },
    { page_size = null, page_number = null, categories = null }
  ) {
    const params = {};
    if (page_size) {
      params.page_size = page_size;
    }
    if (page_number) {
      params.page = page_number;
    }
    if (categories) {
      params.categories = categories;
    }

    // store not ready
    const response = await axios.get(state.list_api, {
      params,
    });
    const { count, current_page, final_page, next, previous, results } =
      response.data;
    const page = {
      page_number: current_page,
      data: results,
    };
    //we reset just if is a new load; for example in landing or for example when we add a filter
    //if (!page_number) {
    commit(types.RESET_PAGE);
    //}

    commit(types.UPDATE_PAGINATION, {
      count,
      current_page,
      final_page,
      next,
      previous,
      page_size,
    });
    commit(types.ADD_PAGE, page);
  },
  setConfig({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        if (
          'target' in payload &&
          'payload' in payload &&
          Object.keys(payload).length === 2
        ) {
          Object.keys(payload.payload).forEach((k) => {
            commit(types.SET_CONFIG_PROPERTY_WITH_TARGET, {
              target: payload.target,
              key: k,
              value: payload.payload[k],
            });
          });
        } else {
          Object.keys(payload).forEach((k) => {
            commit(types.SET_CONFIG_PROPERTY, {
              key: k,
              value: payload[k],
            });
          });
        }

        resolve();
      } catch (e) {
        // console.log(e);
        reject(e);
      }
    });
  },
};
