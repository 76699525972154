import { render, staticRenderFns } from "./cooking-tips-best-practices-page.vue?vue&type=template&id=0795d25f&scoped=true"
import script from "./cooking-tips-best-practices-page.vue?vue&type=script&lang=js"
export * from "./cooking-tips-best-practices-page.vue?vue&type=script&lang=js"
import style0 from "./cooking-tips-best-practices-page.vue?vue&type=style&index=0&id=0795d25f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0795d25f",
  null
  
)

export default component.exports