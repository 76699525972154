import * as types from '../mutation_types';
import { colorLog } from '@/helpers/utils.js';
import axios from 'axios';

export default {
  setReady({ commit }, value) {
    commit(types.SET_READY, value);
  },
  setCurrentStep({ commit }, currentStep) {
    commit(types.SET_CURRENT_STEP, currentStep);
  },
  async setReachedView({ commit }, value) {
    commit(types.SET_REACHED_VIEW, value);
  },
  async getCustomers({ state, commit }) {
    try {
      const response = await axios.get(
        state.contentData.home.customers_api_url
      );

      if (response.data) {
        commit(types.GET_CUSTOMERS, response.data);
      } else {
        colorLog({
          label: 'Store error',
          message: 'Api error',
          themeName: 'orange',
        });
      }
    } catch (e) {
      colorLog({
        label: 'Store error',
        message: `${e}`,
        themeName: 'blue',
      });
    }
  },
  setCustomerType({ commit }, value) {
    commit(types.SET_CUSTOMER_TYPE, value);
  },
  async getReasons({ state, commit }) {
    try {
      const response = await axios.get(
        state.contentData['contact-reasons'].reasons_api_url
      );

      if (response.data) {
        commit(types.GET_REASONS, response.data);
      } else {
        colorLog({
          label: 'Store error',
          message: 'Api error',
          themeName: 'orange',
        });
      }
    } catch (e) {
      colorLog({
        label: 'Store error',
        message: `${e}`,
        themeName: 'blue',
      });
    }
  },
  setReasonType({ commit }, value) {
    //console.log(value.code, value.ga4id);
    commit(types.SET_REASON_TYPE, value.code);
    commit(types.SET_GA4ID, value.ga4id);
  },
  setStepData({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        if (Array.isArray(payload)) {
          payload.forEach((el) => {
            commit(types.SET_STEP_DATA, el);
          });
        } else {
          commit(types.SET_STEP_DATA, payload);
        }
        resolve();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        reject(e);
      }
    });
  },
};
