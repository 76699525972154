<template functional>
  <span
    :ref="data.ref"
    :class="['TagCmp', data.class, data.staticClass]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: 'TagCmp',
  functional: true,
};
</script>

<style lang="scss" scoped>
.TagCmp {
  display: inline-block;
  color: $color-blue;
  border: 1px solid $color-blue;
  border-radius: 40px;
  padding: 3px 11px;
  @include responsive-typography-properties($font-primary, a-caption);
}
</style>
