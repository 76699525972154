<template>
  <form-cmp class="ContactFormMessageInfoCmp">
    <div
      v-if="
        dataCurrentStep.content.product &&
        dataCurrentStep.content.product.active
      "
      class="row"
    >
      <div v-if="!hideProduct" class="col-12 col-md-5 offset-md-2 col-lg-4">
        <select-cmp-extended
          v-model.trim="$v.product.$model"
          data-name="product"
          :data-placeholder="dataCurrentStep.content.product.placeholder"
          :data-option-api-url="dataCurrentStep.content.product.apiUrl"
          :data-error-occurred="$v.product.$error"
          :data-errors="[
            ...dataCurrentStep.content.product.error.map((e) => ({
              condition: !$v.product[e.condition],
              message: e.message,
            })),
            {
              condition: !$v.product.serverError,
              message: serverErrors.product,
            },
          ]"
        >
          <template #label>
            {{ dataCurrentStep.content.product.label }}
          </template>
          <template #icon>
            <svg>
              <use href="#icon-caret-down" />
            </svg>
          </template>
        </select-cmp-extended>
      </div>
    </div>
    <div class="row justify-content-center">
      <div
        v-if="
          dataCurrentStep.content.file && dataCurrentStep.content.file.active
        "
        class="col-12 col-md-8"
      >
        <input-file-cmp-extended
          ref="$file"
          v-model.trim="$v.file.$model"
          class="dropbox"
          data-name="file"
          :data-error-occurred="$v.file.$error"
          :data-errors="[
            ...dataCurrentStep.content.file.error.map((e) => ({
              condition: !$v.file[e.condition],
              message: e.message,
            })),
            {
              condition: !$v.file.serverError,
              message: serverErrors.file,
            },
          ]"
        >
          <template #label>{{ dataCurrentStep.content.file.label }}</template>
          <template #icon>
            <svg>
              <use href="#icon-upload" />
            </svg>
          </template>
          <template #drag-text>
            {{ dataCurrentStep.content.file.dragText }}
            <!-- not use data-tag="button" here because it cause issues with the input-file-cmp -->
            <cta-button-cmp-extended class="btn-text">
              {{ dataCurrentStep.content.file.dragTextCta }}
            </cta-button-cmp-extended>
          </template>
          <template #drop-text>
            {{ dataCurrentStep.content.file.dropText }}
          </template>
          <template>{{ dataCurrentStep.content.file.placeholder }}</template>
        </input-file-cmp-extended>
      </div>
      <div
        v-if="
          dataCurrentStep.content.message &&
          dataCurrentStep.content.message.active
        "
        class="col-12 col-md-8"
      >
        <textarea-cmp-extended
          v-model.trim="$v.message.$model"
          data-name="message"
          :data-placeholder="dataCurrentStep.content.message.placeholder"
          :data-rows="7"
          :data-resizable="false"
          :data-error-occurred="$v.message.$error"
          :data-errors="[
            ...dataCurrentStep.content.message.error.map((e) => ({
              condition: !$v.message[e.condition],
              message: e.message,
            })),
            {
              condition: !$v.message.serverError,
              message: serverErrors.message,
            },
          ]"
        >
          <template #label>
            {{ dataCurrentStep.content.message.label }}
          </template>
        </textarea-cmp-extended>
      </div>
      <div class="col-12 col-md-8 text-center">
        <submit-cmp-extended
          :data-theme-classes="['reversed']"
          :data-aria-label="dataCurrentStep.content.submitCtaAriaLabel"
          :data-loading="loading"
          :data-disabled="disableSubmit || submitted"
          :data-error-occurred="
            !!globalErrors.length || !!Object.keys(serverErrors).length
          "
          :data-errors="globalErrors"
          @click-submit="onSubmitForm"
        >
          <template>{{ dataCurrentStep.content.submitCtaText }}</template>
          <template #after-icon>
            <svg>
              <use href="#icon-arrow-right" />
            </svg>
          </template>
        </submit-cmp-extended>
      </div>
      <div v-if="dataCurrentStep.content.footnote" class="col-12 col-md-8">
        <span class="a-caption text-color-grey-600">
          {{ dataCurrentStep.content.footnote }}
        </span>
      </div>
    </div>
  </form-cmp>
</template>

<script>
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import { helpers, required, maxLength } from 'vuelidate/lib/validators';
import { FormCmp } from '@frankhoodbs/lib';
import FormElementsSupport from '@frankhoodbs/lib/src/mixins/form-elements-support';
import { mapActions, mapState } from 'vuex';

Vue.use(Vuelidate);

const checkServerErrors = (param) => {
  return (value, vm) => {
    return Object.keys(vm.serverErrors).indexOf(param) === -1;
  };
};

export default {
  name: 'ContactFormMessageInfoCmp',
  components: { FormCmp },
  mixins: [FormElementsSupport],
  props: {
    dataCurrentStep: {
      type: Object,
      required: true,
    },
    dataSubmitEnabledOnMounted: {
      type: Boolean,
      default: true,
    },
    dataApiUrl: {
      type: String,
      default: null,
    },
    dataRequestMethod: {
      type: String,
      default: 'post',
      validator: function (value) {
        // The value must match one of these strings
        return (
          [
            'get',
            'head',
            'post',
            'put',
            'delete',
            'connect',
            'options',
            'trace',
            'patch',
          ].indexOf(value) !== -1
        );
      },
    },
    dataRequestData: {
      type: Object,
      default: null,
    },
    dataRequestConfig: {
      type: Object,
      default: null,
    },
    dataHandledStatusCodeErrors: {
      type: Array,
      default: () => [400, 401, 403],
    },
  },

  data() {
    return {
      fields: ['product', 'file', 'message'],
    };
  },
  computed: {
    ...mapState('contact', ['chosenData', 'contentData']),
    hideProduct() {
      try {
        return this.contentData['contact-reasons'].reasons.find(
          (r) => r.code === this.chosenData.reasonType
        ).hide_products_in_flow;
      } catch (e) {
        // eslint-disable-next-line
        console.warn(e);
        return false;
      }
    },

    /* form field */
    product: {
      get() {
        return this.valueMapper('product');
      },
      set(value) {
        this.valueMapper('product', value);
      },
    },
    file: {
      get() {
        return this.valueMapper('file');
      },
      set(value) {
        this.valueMapper('file', value);
      },
    },
    message: {
      get() {
        return this.valueMapper('message');
      },
      set(value) {
        this.valueMapper('message', value);
      },
    },
    disableSubmit() {
      if (this.dataSubmitEnabledOnMounted) {
        return false;
      } else {
        if (this.$v && (this.$v.$anyError || this.$v.$invalid)) {
          return true;
        }

        return false;
      }
    },
  },
  mounted() {
    if (this.file) {
      this.$refs.$file.$children[0].setFile(this.file);
    }
  },
  validations() {
    let activeFields = [];

    this.fields.forEach((e) => {
      if (
        this.dataCurrentStep.content[e] &&
        this.dataCurrentStep.content[e].active
      ) {
        activeFields.push(e);
      }
    });

    return this.setValidations(activeFields);
  },
  methods: {
    ...mapActions('contact', ['setStepData']),
    valueMapper(key, value) {
      if (arguments.length === 2) {
        this.setStepData({ key, value });
      } else {
        return this.chosenData[key];
      }
    },
    setValidations(fields) {
      let validations = {};

      fields.forEach((prop) => {
        validations[prop] = {};

        this.dataCurrentStep.content[prop].error.forEach((e) => {
          if (e.condition === 'required') {
            validations[prop] = {
              ...validations[prop],
              required,
            };
          }
          if (e.condition === 'validExt') {
            validations[prop] = {
              ...validations[prop],
              validExt: (val) =>
                !helpers.req(val) ||
                val.type === 'image/jpeg' ||
                val.type === 'image/jpg' ||
                val.type === 'image/png' ||
                val.type === 'application/pdf',
            };
          }
          if (e.condition === 'validSize') {
            validations[prop] = {
              ...validations[prop],
              validSize: (val) =>
                !helpers.req(val) || val.size <= 3 * 1024 * 1024,
            };
          }
          if (e.condition === 'maxLength') {
            validations[prop] = {
              ...validations[prop],
              maxLength: maxLength(e.length),
            };
          }
        });
        validations[prop] = {
          ...validations[prop],
          serverError: checkServerErrors(prop),
        };
      });

      return validations;
    },
    async onSubmitForm() {
      if (this.dataSubmitEnabledOnMounted) {
        this.$v.$touch();
      }
      if (!this.submitted && !this.disableSubmit && !this.$v.$invalid) {
        this.submitted = true;
        this.globalErrors = [];
        this.toastErrors = [];

        this.$emit('submit');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ContactFormMessageInfoCmp.FormCmp {
  .form-group__wrapper {
    --form-element-padding: 0 0 80px;

    &.SelectCmp {
      --form-element-padding: 0 0 40px;
    }
  }
}
</style>
