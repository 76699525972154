<template>
  <dropdown-cmp class="DropdownCmpExtended" v-bind="$attrs" v-on="$listeners">
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </dropdown-cmp>
</template>

<script>
import { DropdownCmp } from '@frankhoodbs/lib';

export default {
  name: 'DropdownCmpExtended',
  components: { DropdownCmp },
};
</script>

<style lang="scss" scoped>
.DropdownCmpExtended {
  --dropdown-menu-width: 'auto';
  --dropdown-menu-padding: 24px 80px 24px 24px;
  --dropdown-menu-margin: 16px 0 0;
  --dropdown-menu-border-color: #{$color-grey-200};
  --dropdown-menu-border-radius: 8px;
  --dropdown-menu-z-index: #{map-get($z, content)};

  &.opened {
    ::v-deep .CtaButtonCmp {
      --cta-button-bg-color: #{$color-dark-blue};

      .icon-caret-down {
        transform: rotate(180deg);
      }
    }
  }

  ::v-deep .CtaButtonCmp {
    @include override-responsive-typography-properties(
      $font-primary,
      a-cta,
      --cta-button
    );
    --cta-button-padding: 10.5px 30px;
    --cta-button-border-width: 2px;
    --cta-button-hover-border-width: 2px;
    --cta-button-disabled-border-width: 2px;
    --cta-button-border-radius: 40px;
    --cta-button-icon-width: 24px;
    --cta-button-icon-height: 24px;
    --cta-button-icon-distance: 8px;
    --cta-button-disabled-opacity: 1;
    --cta-button-bg-color: #{$color-blue};
    --cta-button-border-color: #{$color-blue};
    --cta-button-hover-bg-color: #{$color-light-blue};
    --cta-button-hover-border-color: #{$color-light-blue};
    --cta-button-disabled-bg-color: #{$color-grey-200};
    --cta-button-disabled-color: #{$color-grey-400};
    --cta-button-disabled-border-color: #{$color-grey-400};

    .icon-caret-down {
      transition: transform 0.3s ease-in-out;
    }

    &.btn-big {
      @include override-responsive-typography-properties(
        $font-secondary,
        n-heading-2,
        --cta-button
      );
      --cta-button-padding: 10px 38px;
      --cta-button-icon-distance: 24px;
    }

    &.selected {
      --cta-button-bg-color: #{$color-white};
      --cta-button-color: #{$color-blue};
      --cta-button-border-color: #{$color-blue-10};
      --cta-button-hover-bg-color: #{$color-light-blue};
      --cta-button-hover-border-color: #{$color-light-blue};
      --cta-button-disabled-bg-color: #{$color-grey-200};
      --cta-button-disabled-color: #{$color-grey-400};
      --cta-button-disabled-border-color: #{$color-grey-400};
    }
  }

  ::v-deep .form-group__wrapper {
    --form-element-padding: 0;
  }
}

//TODO: bordo nascosto primo elemento quando in focus
.DropdownCmpExtended:has(.scrollable) {
  --dropdown-menu-padding: 24px 12px 24px 24px;

  ::v-deep .scrollable {
    height: 320px;
    /* width */
    .form-group {
      --form-check-margin-top: calc(
        (270px - (20px * 8)) / 8
      ); // TODO: capire se possibile rendere dinamico lo spacing per inserire sempre 8 elementi

      // .form-check:has(label:focus) {
      //   position: relative !important;
      //   z-index: 99999999;
      // }
    }

    .scroll-container {
      height: 100%;
      padding-right: 68px;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    ::-webkit-scrollbar {
      width: 8px;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #{$color-frozen-pasta};
      border-radius: 8px;
    }
  }
}
</style>
