<script>
import { mapState } from 'vuex';
import axios from 'axios';

export default {
  name: 'GigyaGtmHandler',
  data() {
    return {
      pushUserInfoPending: false,
    };
  },
  computed: {
    ...mapState('gigya', [
      'loaded',
      'user',
      'preferences',
      'otActiveGroups',
      'hashGuidApi',
    ]),
  },
  watch: {
    loaded(value) {
      if (value) {
        this.pushUserInfo();
      }
    },
    otActiveGroups() {
      this.pushUserInfo();
    },
    $route(to, from) {
      this.pushUserInfo();
    },
  },
  async mounted() {
    const otReady = await this.isOneTrustReady();
    if (otReady) {
      window.OneTrust.OnConsentChanged(() => {
        this.$store.dispatch(
          'gigya/setOnetrustActiveGroups',
          window.OnetrustActiveGroups
        );
      });
    }
  },
  methods: {
    async isOneTrustReady() {
      return new Promise((resolve, reject) => {
        (() => {
          let checkOneTrust = () => {
            if (window.OneTrust) {
              resolve(true);
            } else {
              setTimeout(checkOneTrust, 2000);
            }
          };
          checkOneTrust();
        })();
      });
    },
    async isGigyaReady() {
      return new Promise((resolve, reject) => {
        (() => {
          let checkGigya = () => {
            if (this.loaded) {
              resolve(true);
            } else {
              setTimeout(checkGigya, 2000);
            }
          };
          checkGigya();
        })();
      });
    },
    canPushUserInfo() {
      // console.log("c0004:", window.OnetrustActiveGroups && window.OnetrustActiveGroups.includes("C0004"));
      // console.log("isConsentGranted:", this.preferences?.privacy_profiling?.isConsentGranted);
      return (
        this.user &&
        (this.otActiveGroups?.includes('C0004') ||
          (window.OnetrustActiveGroups &&
            window.OnetrustActiveGroups.includes('C0004'))) &&
        this.preferences?.privacy_profiling?.isConsentGranted
      );
    },
    async pushUserInfo() {
      //console.log("dataLayer Push: Starting");
      if (!this.pushUserInfoPending) {
        this.pushUserInfoPending = true;
        // console.log("LOADING OT & GIGYA...");
        const otReady = await this.isOneTrustReady();
        // facciamo questo ulteriore controllo perche il metodo può essere chiamato anche prima che gigya sia loaded
        const gigyaReady = await this.isGigyaReady();
        if (otReady && gigyaReady) {
          // console.log("LOADED OT & GIGYA");
          if (this.canPushUserInfo()) {
            // console.log("dataLayer Push: Authorized");
            try {
              let resultHashGuidApi = await axios.get(this['hashGuidApi']);
              if (resultHashGuidApi?.data?.hashed_guid) {
                window.dataLayer.push({
                  event: 'userInfo',
                  userId: resultHashGuidApi.data.hashed_guid.toLowerCase(),
                });
                // console.log("dataLayer Push: Done");
              }
            } catch (e) {
              // eslint-disable-next-line
              console.error(`%c[Catch hashGuidApi something wrong with hash guid api]`, e);
            }
          }
          this.pushUserInfoPending = false;
        } else {
          // eslint-disable-next-line
          console.warning('otReady && gigyaReady', otReady, gigyaReady);
        }
      }
    },
  },

  render: () => null,
};
</script>
