var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"CardProductCmp h-100"},[_c('a',{staticClass:"card-link h-100",attrs:{"href":_vm.dataHref,"target":_vm.dataTarget}},[(
        _vm.hasSlot(_vm.$slots.title, _vm.$scopedSlots.title) &&
        !(_vm.themeVariant === 'range' || _vm.themeVariant === 'product')
      )?_c('div',{staticClass:"title-wrapper"},[_vm._t("title")],2):_vm._e(),(_vm.hasSlot(_vm.$slots.image, _vm.$scopedSlots.image))?_c('figure',{class:['image-wrapper']},[_vm._t("image")],2):_vm._e(),(_vm.hasSlot(_vm.$slots.tag, _vm.$scopedSlots.tag))?_c('div',{staticClass:"tag-wrapper"},[_vm._t("tag")],2):_vm._e(),(
        _vm.hasSlot(_vm.$slots.title, _vm.$scopedSlots.title) &&
        (_vm.themeVariant === 'range' || _vm.themeVariant === 'product')
      )?_c('div',{class:[
        'title-wrapper ',
        { withTag: _vm.hasSlot(_vm.$slots.tag, _vm.$scopedSlots.tag) } ]},[_vm._t("title")],2):_vm._e(),(
        _vm.hasSlot(_vm.$slots.text, _vm.$scopedSlots.text) || _vm.themeVariant === 'range'
      )?_c('div',{staticClass:"text-wrapper a-paragraph-3"},[_vm._t("text")],2):_vm._e(),(
        !_vm.hasSlot(_vm.$slots.text, _vm.$scopedSlots.text) &&
        (_vm.dataQuantity || _vm.dataMeasureUnitValue || _vm.dataMeasureUnit)
      )?_c('div',{staticClass:"text-wrapper a-paragraph-3"},[_c('p',[_vm._v(" "+_vm._s(_vm.customDecimalSeparator( _vm.dataQuantity, _vm.$store.state.config.decimal_separator ))+" "),(_vm.dataMeasureUnitValue)?_c('span',[_vm._v(" x"+_vm._s(_vm.customDecimalSeparator( _vm.dataMeasureUnitValue, _vm.$store.state.config.decimal_separator ))+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.dataMeasureUnit)+" ")])]):_vm._e(),(_vm.themeVariant !== 'product')?_c('div',{staticClass:"half-bg"}):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }