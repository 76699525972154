<template>
  <div class="CookingTipsBestPracticesDetailPage">
    <hero-cmp class="colorful-bg">
      <template #type>
        <slot name="service-type" />
      </template>
      <template #title>
        <slot name="service-hero-title" />
      </template>
    </hero-cmp>
    <div class="container pt-r">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <slot name="service-breadcrumbs" />
        </div>
      </div>
    </div>
    <focus-items-cmp
      :class="[
        'pb-l',
        {
          'with-tab-menu': !config.is_detailed_tabs,
          'with-tab-menu-detailed': config.is_detailed_tabs,
        },
      ]"
      data-active-tab-id="1"
      :data-tabs="
        config.tabs.map((e, index) => ({
          id: '' + (index + 1),
          label: e.tab_label,
        }))
      "
    >
      <template
        v-for="(tab, j) in config.tabs.map((e, index) => ({
          ...e,
          id: '' + (index + 1),
        }))"
        #[`tab-${tab.id}-content`]
      >
        <div :key="`tab-content-${tab.id}-${_uid}`">
          <focus-items-tab-content-cmp class="with-grid">
            <template v-if="tab.description" #heading>
              <slot :name="`tab-description-${j + 1}`" />
            </template>
            <template #focus-items>
              <icon-text-info-cmp
                v-for="(item, i) in tab.items_attribute"
                :key="`focus-item-${i}-${tab.id}-${_uid}`"
                :class="[{ detailed: config.is_detailed_tabs }]"
                :data-unit-value="item.value"
              >
                <template #icon>
                  <svg>
                    <use :href="`#${item.icon}`" />
                  </svg>
                </template>
                <template #unit>
                  {{ item.value_measure_unit }}
                </template>
                <template #text>
                  {{ item.value_description }}
                </template>
              </icon-text-info-cmp>
            </template>
            <template v-if="tab.lower_description" #footer>
              <slot :name="`tab-lower-description-${j + 1}`" />
            </template>
          </focus-items-tab-content-cmp>
          <media-player-cmp
            v-if="tab.youtube_video_link"
            class="highlighted pt-l"
            :data-bootstrap-container-classes="['container-fluid', 'px-0']"
            :data-youtube-video-id="tab.youtube_video_link"
            :data-background-image-src="tab.video_poster"
          >
            <template v-if="tab.video_description">
              <slot :name="`tab-video-description-${j + 1}`" />
            </template>
          </media-player-cmp>
          <div v-if="tab.image" class="container-fluid pt-l px-0">
            <div class="row justify-content-center">
              <div class="col-12 col-md-8">
                <main-image-cmp>
                  <slot :name="`tab-image-${j + 1}`" />
                  <template v-if="tab.image_description" #figcaption>
                    <slot :name="`tab-image-description-${j + 1}`" />
                  </template>
                </main-image-cmp>
              </div>
            </div>
          </div>
        </div>
      </template>
    </focus-items-cmp>
  </div>
</template>

<script>
import { castPropToType } from '@/helpers/cms-support';
import { mapState } from 'vuex';

export default {
  name: 'CookingTipsBestPracticesDetailPage',
  props: {
    dataConfig: { type: String, required: true },
  },
  setup() {
    return { castPropToType };
  },
  computed: {
    ...mapState('services', ['best_practices_detail_overtitle']),
    config() {
      return this.dataConfig
        ? this.castPropToType(this.dataConfig, 'object')
        : null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
