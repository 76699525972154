/* Import dependencies */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

/* Mutations, actions and getters */
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

/* Modules */
// Import modules here
import config from './config';
import contact from './contact';
import gigya from './gigya';
import newsAndMedia from './news-and-media';
import products from './products';
import services from './services';
import recipes from './recipes';

export default new Vuex.Store({
  state: {
    appReady: false,
    loading: [true],
    unlockedView: null,
  },
  actions,
  mutations,
  getters,
  modules: {
    // Register modules here
    config,
    contact,
    gigya,
    newsAndMedia,
    products,
    services,
    recipes,
  },
});
