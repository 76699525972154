<template>
  <section class="CarouselPostsCmp">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <div class="title-separator-wrapper">
            <slot name="title-separator" />
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <slider-cmp-extended
            :class="[
              {
                hideCursor: disabledCursor,
              },
            ]"
            :data-bootstrap-container-classes="['container-fluid', 'px-0']"
            :data-component-slides="true"
            :data-disabled-from="computedDisabled"
            :data-options="{
              observer: true,
              slidesPerView: 1,
              slidesPerGroup: 1,
              navigation: false,
              watchOverflow: true,
              breakpoints: {
                768: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                },
                1024: {
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                },
              },
            }"
          >
            <template slot="custom-swipe-icon">
              <svg>
                <use href="#icon-scroller" />
              </svg>
            </template>
            <template slot="components">
              <slot name="carousel" />
            </template>
          </slider-cmp-extended>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SliderCmpExtended from './slider-cmp-extended';
import { xsOnly, mdOnly, lgOnly, xlAndUp } from '@/helpers/breakpoints';
import { hasSlotSlim } from '@/helpers/cms-support';
export default {
  name: 'CarouselPostsCmp',
  components: { SliderCmpExtended },
  props: {
    dataDisabledFrom: {
      type: Number,
      default: null,
    },
  },
  setup() {
    return { xsOnly, mdOnly, lgOnly, xlAndUp, hasSlotSlim };
  },
  computed: {
    disabledCursor() {
      return this.computedDisabled !== null;
    },
    computedDisabled() {
      try {
        const items = this.hasSlotSlim(this, 'carousel')
          ? this.$slots['carousel'].length
          : 0;

        return this.xsOnly && items <= 1
          ? 0
          : this.mdOnly && items <= 2
          ? 768
          : this.lgOnly && items <= 3
          ? 1024
          : this.xlAndUp && items <= 3
          ? 1440
          : null;
      } catch (e) {
        return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.CarouselPostsCmp {
  .title-separator-wrapper {
    @include override-responsive-typography-properties(
      $font-secondary,
      n-heading-3,
      --title-separator-title-wrapper
    );
    padding-bottom: 40px;
  }

  ::v-deep .SliderCmpExtended {
    .hideCursor {
      .custom-swipe-icon {
        display: none !important;
      }
    }
    .slider-content {
      padding-bottom: 66px;

      @include media-breakpoint-up(lg) {
        padding-bottom: 82px;
      }

      .swiper-container {
        .swiper-slide {
          height: auto; //should not be 100% in product detail carousels or there is an height issue
        }
        &.disabled {
          .swiper-slide {
            flex: 1 0 33.3333%;
          }
        }

        .swiper-pagination {
          padding: 39px 0 15px;

          @include media-breakpoint-up(lg) {
            padding: 55px 0 15px;
          }
        }
      }
    }
  }
}
</style>
