<template>
  <div :class="['TableCmp']">
    <div ref="$scroller" class="table-wrapper swiper-container">
      <div class="swiper-wrapper">
        <div class="table-container swiper-slide">
          <table>
            <thead>
              <table-row-cmp v-if="dataRelatedProductData">
                <table-cell-cmp
                  v-for="(col, i) in dataRelatedProductData.cols"
                  :key="`${col.slug}-${i}-${_uid}`"
                  data-tag="th"
                >
                  {{ col.label }}
                </table-cell-cmp>
              </table-row-cmp>
              <slot v-else name="thead" />
            </thead>
            <tbody>
              <template v-if="dataRelatedProductData">
                <table-row-cmp
                  v-for="(row, i) in dataRelatedProductData.rows"
                  :key="`table-row-${i}-${_uid}`"
                >
                  <table-cell-cmp
                    v-for="(col, j) in dataRelatedProductData.cols"
                    :key="`${col.slug}-${j}-${_uid}`"
                  >
                    <template v-if="row[col.slug]">
                      {{
                        customDecimalSeparator(
                          row[col.slug],
                          $store.state.config.decimal_separator
                        )
                      }}
                    </template>
                  </table-cell-cmp>
                </table-row-cmp>
              </template>
              <slot v-else name="tbody" />
            </tbody>
          </table>
        </div>
      </div>
      <div ref="$scrollbar" class="swiper-scrollbar" />
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper/js/swiper';
import TableRowCmp from './table-row-cmp.vue';
import TableCellCmp from './table-cell-cmp.vue';
import { customDecimalSeparator } from '@/helpers/utils';

export default {
  name: 'TableCmp',
  components: { TableRowCmp, TableCellCmp },
  props: {
    dataRelatedProductData: {
      type: Object,
      default: null,
    },
  },
  setup() {
    return { customDecimalSeparator };
  },
  data() {
    return {
      swiper: null,
    };
  },
  mounted() {
    this.swiper = new Swiper(this.$refs.$scroller, {
      slidesPerView: 'auto',
      freeMode: true,
      watchOverflow: true,
      scrollbar: {
        el: this.$refs.$scrollbar,
        draggable: true,
        hide: false,
        snapOnRelease: false,
      },
      breakpoints: {
        768: {
          allowTouchMove: false,
        },
      },
    });
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.TableCmp {
  .table-wrapper {
    margin: 0;

    .table-container {
      min-width: 690px;

      table {
        width: 100%;
        table-layout: fixed;

        tbody {
          .TableRowCmp:nth-child(even) {
            background: $color-grey-200-50;
          }
        }
      }
    }
  }

  &.three-cols-leading {
    ::v-deep {
      td,
      th {
        &:first-child {
          width: 50%;
        }
      }
    }
  }

  &.four-cols-leading {
    ::v-deep {
      td,
      th {
        &:first-child {
          width: 34%;
        }
      }
    }
  }

  .swiper-scrollbar {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8px;
    margin: 80px 0 16px;
    padding: 0;
    border-radius: 4px;
    background-color: $color-grey-200;

    &.swiper-scrollbar-lock {
      display: none;
    }

    ::v-deep {
      .swiper-scrollbar-drag {
        height: 8px;
        border-radius: 4px;
        background: $color-grey-800;
      }
    }
  }
}
</style>
