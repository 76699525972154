<template>
  <div class="FocusItemsNutritionAllergensCmp">
    <div
      v-if="nutritionAndAllergensCount"
      class="ingredients-allergens-wrapper"
    >
      <div
        v-for="index in nutritionAndAllergensCount"
        :key="index"
        class="allergens"
      >
        <span class="category a-paragraph-2">
          <slot :name="`label-${index}`"></slot>
          <hr />
        </span>
        <div class="content">
          <icon-text-info-cmp>
            <template
              v-if="
                hasSlot($slots[`icon-${index}`], $scopedSlots[`icon-${index}`])
              "
            >
              <template slot="icon">
                <slot :name="`icon-${index}`"></slot>
              </template>
            </template>
            <template slot="title">
              <slot :name="`description-${index}`"></slot>
            </template>
          </icon-text-info-cmp>
          <slot :name="`link-${index}`"></slot>
        </div>
      </div>
    </div>
    <div
      v-if="hasSlot($slots.table, $scopedSlots.table)"
      class="nutrition-wrapper"
    >
      <slot name="table" />
    </div>
  </div>
</template>

<script>
import IconTextInfoCmp from './icon-text-info-cmp';
import { hasSlot } from '@/helpers/cms-support';
import { castPropToType } from '@/helpers/cms-support';
export default {
  name: 'FocusItemsNutritionAllergensCmp',
  components: { IconTextInfoCmp },
  props: {
    dataNutritionAndAllergensCount: {
      type: [String, Number],
      default: 0,
    },
  },
  setup() {
    return { hasSlot, castPropToType };
  },
  computed: {
    nutritionAndAllergensCount() {
      return this.dataNutritionAndAllergensCount
        ? this.castPropToType(this.dataNutritionAndAllergensCount, 'number')
        : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.FocusItemsNutritionAllergensCmp {
  display: flex;
  flex-direction: column;
  gap: 40px;

  @include media-breakpoint-up(xl) {
    flex-direction: row;
    align-items: flex-start;
    gap: 56px;
  }

  .ingredients-allergens-wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    @include media-breakpoint-up(xl) {
      flex-direction: column;
      gap: 64px;
    }

    .ingredients {
      flex: 1 0 0;

      ::v-deep dd {
        padding: 16px;
      }
    }

    .allergens {
      flex: 1 0 0;

      .content {
        padding: 16px;

        .IconTextInfoCmp {
          --icon-text-info-padding: 0 0 16px;
          --icon-text-info-title-wrapper-color: #{$color-black};

          ::v-deep .wrapper .info-wrapper.title-only .title-wrapper {
            @include override-responsive-typography-properties(
              $font-primary,
              a-paragraph-3,
              --icon-text-info-title-wrapper
            );
          }
        }
      }
    }

    .category {
      display: flex;
      color: $color-blue;
      align-items: center;

      hr {
        flex-grow: 1;
        margin-left: 24px;
      }
    }
  }

  .nutrition-wrapper {
    flex: 1 0 0;
  }
}
</style>
