<template>
  <div class="LandingBannerContentCmp shadow-soft">
    <div class="image-wrapper">
      <aspect-ratio-box-cmp data-aspect-ratio="10:9">
        <slot name="image" />
      </aspect-ratio-box-cmp>
    </div>
    <div class="text-start text-color-blue flex-grow-1">
      <div class="title-wrapper a-cta">
        <slot name="title" />
      </div>
      <div class="text-wrapper a-support">
        <slot name="text" />
      </div>
    </div>
    <svg class="icon">
      <use href="#icon-arrow-right" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'LandingBannerContentCmp',
};
</script>

<style lang="scss" scoped>
.LandingBannerContentCmp {
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 24px 8px;
  background-color: $color-white;
  border: 1px solid $color-blue-10;
  border-radius: 8px;
  transition: border-color 300ms ease;

  &:hover {
    border-color: $color-blue;
  }

  @include media-breakpoint-up(md) {
    column-gap: 16px;
    padding: 24px 16px;
  }

  @include media-breakpoint-up(xl) {
    column-gap: 24px;
    padding: 42.5px 24px;
  }

  .image-wrapper {
    flex-shrink: 0;
    width: 56px;

    @include media-breakpoint-up(md) {
      width: 64px;
    }

    @include media-breakpoint-up(xl) {
      width: 80px;
    }
  }

  .text-wrapper {
    padding-top: 8px;

    @include media-breakpoint-up(xl) {
      padding-top: 16px;
    }
  }

  .icon {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    color: $color-blue;
  }
}
</style>
