var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'TabsCmp',
    {
      'with-tab-pic':
        _vm.dataTabs.find(function (e) { return e.desktopImage || e.mobileImage; }) !== undefined,
    } ]},[(_vm.dataTabs.length > 1 && _vm.showHeaders)?_c('scroll-x-navbar-cmp',{attrs:{"data-active-tab-id":'tab-' + _vm.activeTabId},on:{"navigation":function($event){_vm.activeTabId = +$event.replace('tab-', '')}}},[_vm._l((_vm.dataTabs),function(tab){return _c('li',{key:("tab-" + (tab.id) + "-" + _vm._uid),class:['tab', { active: tab.id === _vm.activeTabId }],on:{"click":function($event){tab.id !== _vm.activeTabId ? _vm.onTabChange(tab.id) : null}}},[(tab.desktopImage || tab.mobileImage)?_c('figure',[_c('image-cmp',{attrs:{"width":"135","height":"144","data-desktop-image":tab.desktopImage,"data-mobile-image":tab.mobileImage,"data-image-alt":tab.desktopImageAlt
                ? tab.desktopImageAlt
                : tab.mobileImageAlt
                ? tab.mobileImageAlt
                : ''}}),_c('figcaption',{staticClass:"tab-label a-paragraph-3"},[_vm._v(" "+_vm._s(tab.label)+" ")])],1):_c('span',{staticClass:"tab-label a-paragraph-3"},[_vm._v(_vm._s(tab.label))])])})],2):_vm._e(),(!_vm.dataNoContent)?_c('div',{staticClass:"tabs-content"},[_c('transition',{attrs:{"name":"tab-content"}},_vm._l((_vm.activeTab),function(tab){return _c('div',{key:("tab-" + (tab.id) + "-content-" + _vm._uid),staticClass:"tab-content"},[_vm._t(("tab-" + (tab.id) + "-content"))],2)}),0)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }