<template>
  <div class="IngredientsStepsListCmp">
    <div class="title-wrapper">
      <slot name="title-separator" />
    </div>
    <div class="content-wrapper">
      <div
        v-if="hasSlot($slots['ingredients'], $scopedSlots['ingredients'])"
        :class="[
          'ingredients',
          'a-paragraph-2',
          {
            twoCols: !hasSlot(
              $slots['card-product'],
              $scopedSlots['card-product']
            ),
          },
        ]"
      >
        <slot name="ingredients" />
      </div>
      <div
        v-if="hasSlot($slots['procedure'], $scopedSlots['procedure'])"
        class="howToList"
      >
        <slot name="procedure" />
      </div>

      <div
        v-if="hasSlot($slots['card-product'], $scopedSlots['card-product'])"
        class="card-wrapper"
      >
        <slot name="card-product" />
      </div>
    </div>
  </div>
</template>

<script>
import { hasSlot } from '@/helpers/cms-support';

export default {
  name: 'IngredientsStepsListCmp',
  setup() {
    return { hasSlot };
  },
};
</script>

<style lang="scss" scoped>
.IngredientsStepsListCmp {
  .title-wrapper {
    width: 100%;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 24px;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }

    .card-wrapper {
      flex-shrink: 0;
      margin: 40px auto;
      width: 256px;

      @include media-breakpoint-up(md) {
        width: 261px;
      }

      @include media-breakpoint-up(lg) {
        width: 234px;
        margin: 0 0 0 80px;
      }

      @include media-breakpoint-up(xl) {
        width: 338px;
        margin: 0 0 0 96px;
      }
    }
  }

  li {
    position: relative;
    display: block;
    padding-left: 15px;

    &::before {
      position: absolute;
      top: 9px;
      left: 0;
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $color-black;

      @include media-breakpoint-up(md) {
        top: 10.5px;
      }
    }
  }

  .ingredients {
    ul {
      list-style: none;
    }

    &.twoCols {
      ul {
        @include media-breakpoint-up(lg) {
          -moz-column-count: 2;
          -moz-column-gap: 80px;
          -webkit-column-count: 2;
          -webkit-column-gap: 80px;
          column-count: 2;
          column-gap: 80px;
        }

        @include media-breakpoint-up(xl) {
          -moz-column-gap: 96px;
          -webkit-column-gap: 96px;
          column-gap: 96px;
        }
      }
    }
  }

  .howToList {
    ol {
      list-style: none;

      li {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;

        &:not(:first-child) {
          margin-top: 16px;
        }

        &::after,
        &::before {
          display: none;
        }

        @include media-breakpoint-up(xl) {
          align-items: center;
        }

        span {
          min-width: 48px;
          margin-right: 8px;

          @include responsive-typography-properties(
            $font-secondary,
            n-paragraph-1
          );
        }
      }
    }
  }
}
</style>
