var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"FirstLevelCategoryPage pb-l"},[_c('hero-cmp',{class:[
      { 'image': _vm.heroWithImage, 'colorful-bg': !_vm.heroWithImage },
      _vm.conf.category_color ],scopedSlots:_vm._u([(_vm.heroWithImage)?{key:"image",fn:function(){return [_vm._t("product-image")]},proxy:true}:null,{key:"type",fn:function(){return [_vm._t("product-type")]},proxy:true},{key:"title",fn:function(){return [_vm._t("product-title")]},proxy:true},(_vm.heroWithImage)?{key:"breadcrumbs",fn:function(){return [_vm._t("product-breadcrumbs")]},proxy:true}:null],null,true)}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-xl-8 pt-xs"},[(!_vm.heroWithImage)?_c('div',{staticClass:"d-flex justify-content-center pt-s"},[_vm._t("product-breadcrumbs")],2):_vm._e()])])]),_c('div',{staticClass:"container pt-l pb-m"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-8"},[_c('title-text-cmp',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._t("product-subtitle")]},proxy:true},{key:"text",fn:function(){return [_vm._t("product-description")]},proxy:true},{key:"cta",fn:function(){return [_c('cta-button-cmp-extended',{staticClass:"btn-text",attrs:{"data-url":_vm.contact_us_href,"data-aria-label":_vm.contact_us_label_category},scopedSlots:_vm._u([{key:"after-icon",fn:function(){return [_c('svg',[_c('use',{attrs:{"href":"#icon-arrow-right"}})])]},proxy:true},{key:"seo-link",fn:function(){return [_vm._t("product-seo-link")]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.contact_us_label_category)+" ")])]},proxy:true}],null,true)})],1)])]),_c('div',{staticClass:"container"},[(_vm.conf.sub_categories && _vm.conf.sub_categories.length > 0)?_c('div',{staticClass:"row justify-content-start"},[_vm._t("product-categories")],2):_c('div',{staticClass:"row mt-nr"},[(
          _vm.isSelectCountryVisibleInFirstLevel && _vm.thereIsAtLeastOneProduct /*&&
          atLeastOneProductHasAtLeastOneCountry*/
        )?_c('div',{class:[
          {
            'pt-l':
              _vm.conf.category_properties && _vm.conf.category_properties.length,
          } ]},[_c('p',{staticClass:"a-support text-color-grey-800 pb-xs"},[_vm._v(" "+_vm._s(_vm.filter_by_label)+" ")]),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"50px"}},[_c('dropdown-cmp-extended',{attrs:{"data-tag":"button","data-trigger-theme-classes":[
              { selected: _vm.selectedCountries.length > 0 } ],"data-menu-theme-classes":[
              'shadow-soft',
              { scrollable: _vm.countriesList.length > 8 } ]},scopedSlots:_vm._u([{key:"after-icon",fn:function(){return [_c('svg',{staticClass:"icon-caret-down"},[_c('use',{attrs:{"href":"#icon-caret-down"}})])]},proxy:true},{key:"dropdown-menu",fn:function(){return [_c('div',{staticClass:"scroll-container"},[_c('input-checkbox-cmp-extended',{staticClass:"custom direction-column",attrs:{"data-name":"checkbox","data-aria-label":"Checkbox","data-checkbox-list":_vm.countriesList.map(function (w) { return ({
                      value: w.value,
                      label: w.label,
                    }); })},model:{value:(_vm.selectedCountries),callback:function ($$v) {_vm.selectedCountries=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"selectedCountries"}})],1)]},proxy:true}],null,false,803993730)},[_vm._v(" "+_vm._s(_vm.countriesFilterLabel)+" "),(_vm.selectedCountries.length > 0)?[_vm._v(" ("+_vm._s(_vm.selectedCountries.length)+") ")]:_vm._e()],2)],1)]):_vm._e(),_vm._l((_vm.products),function(product,index){return _c('div',{key:("product_" + (product.slug) + "_" + index),staticClass:"col-md-6 col-lg-4 pt-r"},[_c('card-product-cmp',{staticClass:"product animationForTransparentsImgs",attrs:{"data-href":product.href,"data-quantity":product.quantity_parsed,"data-measure-unit-value":product.measure_unit_value,"data-measure-unit":product.measure_unit},scopedSlots:_vm._u([{key:"seo-link",fn:function(){return [_c('a',{attrs:{"href":product.href}})]},proxy:true},{key:"title",fn:function(){return [_c('h3',[_vm._v(_vm._s(product.title))])]},proxy:true},{key:"image",fn:function(){return [_c('img',{attrs:{"src":product.image,"alt":product.title}})]},proxy:true}],null,true)})],1)}),(_vm.showEmptyMessage)?_c('div',{staticClass:"empty-message pt-r"},[_vm._v(" "+_vm._s(_vm.noProductsLabel)+" ")]):_vm._e()],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }