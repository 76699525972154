<template>
  <banner-cmp class="contact">
    <template slot="image">
      <image-cmp
        width="1440"
        height="330"
        :data-desktop-image="bannerData.banner_desktop_image"
        :data-mobile-image="bannerData.banner_mobile_image"
        :data-image-alt="
          bannerData.banner_desktop_image_alt
            ? bannerData.banner_desktop_image_alt
            : bannerData.banner_mobile_image_alt
            ? bannerData.banner_mobile_image_alt
            : ''
        "
      />
    </template>
    <template>
      <title-text-cmp>
        <template slot="title">
          <div>{{ bannerData.banner_title }}</div>
        </template>
        <template slot="text">
          <p>{{ bannerData.banner_text }}</p>
        </template>
        <template slot="cta">
          <cta-button-cmp-extended
            class="btn-primary-reversed"
            :data-url="bannerData.banner_link.href"
            :data-target="bannerData.banner_link.target || null"
          >
            {{ bannerData.banner_link.label }}
          </cta-button-cmp-extended>
        </template>
      </title-text-cmp>
    </template>
  </banner-cmp>
</template>

<script>
export default {
  name: 'FooterBanner',
  computed: {
    bannerData() {
      const myKey = this.$context.isNewsAndMediaAppHook
        ? 'news_and_media'
        : this.$context.isProductAppHook
        ? 'products'
        : this.$context.isRecipesAppHook
        ? 'recipes'
        : this.$context.isServicesAppHook
        ? 'services'
        : 'global';
      const isOverride =
        !!this.$store.state.config.banner_override.override.banner_text &&
        !!this.$store.state.config.banner_override.override.banner_title &&
        !!this.$store.state.config.banner_override.override.banner_link?.href &&
        !!this.$store.state.config.banner_override.override.banner_link?.label;

      // console.log(
      //   'this.$context.isProductAppHook',
      //   this.$context.isProductAppHook
      // );
      // console.log(
      //   'this.$context.isRecipesAppHook',
      //   this.$context.isRecipesAppHook
      // );
      // console.log(
      //   'this.$context.isServicesAppHook',
      //   this.$context.isServicesAppHook
      // );
      // console.log(
      //   'this.$context.isNewsAndMediaAppHook',
      //   this.$context.isNewsAndMediaAppHook
      // );
      // console.log('isOverride', isOverride);

      return isOverride
        ? this.$store.state.config.banner_override.override
        : this.$store.state.config.banner[myKey];
    },
  },
};
</script>
