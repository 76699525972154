var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-cmp',{staticClass:"ContactFormPersonalInfoCmp"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-10 col-xl-8"},[_c('div',{staticClass:"row"},[(
            _vm.dataCurrentStep.content.name &&
            _vm.dataCurrentStep.content.name.active
          )?_c('div',{staticClass:"col-12 col-md-6"},[_c('input-text-cmp-extended',{attrs:{"data-name":"name","data-placeholder":_vm.dataCurrentStep.content.name.placeholder,"data-error-occurred":_vm.$v.name.$error,"data-errors":_vm.dataCurrentStep.content.name.error.map(function (e) { return ({
                condition: !_vm.$v.name[e.condition],
                message: e.message,
              }); }).concat( [{
                condition: !_vm.$v.name.serverError,
                message: _vm.serverErrors.name,
              }] )},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.dataCurrentStep.content.name.label)+" ")]},proxy:true}],null,false,3508836411),model:{value:(_vm.$v.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.name, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.name.$model"}})],1):_vm._e(),(
            _vm.dataCurrentStep.content.surname &&
            _vm.dataCurrentStep.content.surname.active
          )?_c('div',{staticClass:"col-12 col-md-6"},[_c('input-text-cmp-extended',{attrs:{"data-name":"surname","data-placeholder":_vm.dataCurrentStep.content.surname.placeholder,"data-error-occurred":_vm.$v.surname.$error,"data-errors":_vm.dataCurrentStep.content.surname.error.map(function (e) { return ({
                condition: !_vm.$v.surname[e.condition],
                message: e.message,
              }); }).concat( [{
                condition: !_vm.$v.surname.serverError,
                message: _vm.serverErrors.surname,
              }] )},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.dataCurrentStep.content.surname.label)+" ")]},proxy:true}],null,false,4079339631),model:{value:(_vm.$v.surname.$model),callback:function ($$v) {_vm.$set(_vm.$v.surname, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.surname.$model"}})],1):_vm._e(),(
            _vm.dataCurrentStep.content.email &&
            _vm.dataCurrentStep.content.email.active
          )?_c('div',{staticClass:"col-12 col-md-6"},[_c('input-text-cmp-extended',{attrs:{"data-name":"email","data-placeholder":_vm.dataCurrentStep.content.email.placeholder,"data-error-occurred":_vm.$v.email.$error,"data-errors":_vm.dataCurrentStep.content.email.error.map(function (e) { return ({
                condition: !_vm.$v.email[e.condition],
                message: e.message,
              }); }).concat( [{
                condition: !_vm.$v.email.serverError,
                message: _vm.serverErrors.email,
              }] )},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.dataCurrentStep.content.email.label)+" ")]},proxy:true}],null,false,3531853648),model:{value:(_vm.$v.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.email, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.email.$model"}})],1):_vm._e(),(
            _vm.dataCurrentStep.content.telephone &&
            _vm.dataCurrentStep.content.telephone.active
          )?_c('div',{staticClass:"col-12 col-md-6"},[_c('input-text-cmp-extended',{attrs:{"data-name":"telephone","data-placeholder":_vm.dataCurrentStep.content.telephone.placeholder,"data-error-occurred":_vm.$v.telephone.$error,"data-errors":_vm.dataCurrentStep.content.telephone.error.map(function (e) { return ({
                condition: !_vm.$v.telephone[e.condition],
                message: e.message,
              }); }).concat( [{
                condition: !_vm.$v.telephone.serverError,
                message: _vm.serverErrors.telephone,
              }] )},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.dataCurrentStep.content.telephone.label)+" ")]},proxy:true}],null,false,579091288),model:{value:(_vm.$v.telephone.$model),callback:function ($$v) {_vm.$set(_vm.$v.telephone, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.telephone.$model"}})],1):_vm._e(),(
            _vm.dataCurrentStep.content.interest &&
            _vm.dataCurrentStep.content.interest.active
          )?_c('div',{staticClass:"col-12 col-md-6"},[_c('select-cmp-extended',{attrs:{"data-name":"interest","data-option-list":_vm.interestList,"data-placeholder":_vm.dataCurrentStep.content.interest.placeholder,"data-error-occurred":_vm.$v.interest.$error,"data-errors":_vm.dataCurrentStep.content.interest.error.map(function (e) { return ({
                condition: !_vm.$v.interest[e.condition],
                message: e.message,
              }); }).concat( [{
                condition: !_vm.$v.interest.serverError,
                message: _vm.serverErrors.interest,
              }] )},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.dataCurrentStep.content.interest.label)+" ")]},proxy:true},{key:"icon",fn:function(){return [_c('svg',[_c('use',{attrs:{"href":"#icon-caret-down"}})])]},proxy:true}],null,false,3716824104),model:{value:(_vm.$v.interest.$model),callback:function ($$v) {_vm.$set(_vm.$v.interest, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.interest.$model"}})],1):_vm._e(),(
            _vm.dataCurrentStep.content.work &&
            _vm.dataCurrentStep.content.work.active
          )?_c('div',{staticClass:"col-12 col-md-6"},[_c('select-cmp-extended',{attrs:{"data-name":"work","data-option-list":_vm.workList,"data-placeholder":_vm.dataCurrentStep.content.work.placeholder,"data-error-occurred":_vm.$v.work.$error,"data-errors":_vm.dataCurrentStep.content.work.error.map(function (e) { return ({
                condition: !_vm.$v.work[e.condition],
                message: e.message,
              }); }).concat( [{
                condition: !_vm.$v.work.serverError,
                message: _vm.serverErrors.work,
              }] )},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.dataCurrentStep.content.work.label)+" ")]},proxy:true},{key:"icon",fn:function(){return [_c('svg',[_c('use',{attrs:{"href":"#icon-caret-down"}})])]},proxy:true}],null,false,2686255951),model:{value:(_vm.$v.work.$model),callback:function ($$v) {_vm.$set(_vm.$v.work, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.work.$model"}})],1):_vm._e()]),_c('div',{staticClass:"col-12 text-center"},[_c('submit-cmp-extended',{attrs:{"data-theme-classes":['reversed'],"data-aria-label":_vm.dataCurrentStep.content.submitCtaAriaLabel,"data-loading":_vm.loading,"data-disabled":_vm.disableSubmit || _vm.submitted,"data-error-occurred":!!_vm.globalErrors.length || !!Object.keys(_vm.serverErrors).length,"data-errors":_vm.globalErrors},on:{"click-submit":_vm.onSubmitForm},scopedSlots:_vm._u([{key:"after-icon",fn:function(){return [_c('svg',[_c('use',{attrs:{"href":"#icon-arrow-right"}})])]},proxy:true}])},[[_vm._v(_vm._s(_vm.dataCurrentStep.content.submitCtaText))]],2)],1),(_vm.dataCurrentStep.content.footnote)?_c('div',{staticClass:"col-12"},[_c('span',{staticClass:"a-caption text-color-grey-600"},[_vm._v(" "+_vm._s(_vm.dataCurrentStep.content.footnote)+" ")])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }