var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"CarouselProductCardsCmp"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-10"},[_c('slider-cmp-extended',{style:({
            '--slider-container-overflow': _vm.overflowVisible
              ? 'visible'
              : 'hidden',
          }),attrs:{"data-bootstrap-container-classes":['container-fluid', 'px-0'],"data-component-slides":true,"data-enabled-from":_vm.enabledFrom,"data-options":{
            observer: true,
            slidesPerView: 1,
            slidesPerGroup: 1,
            navigation: false,
            breakpoints: {
              768: {
                slidesPerView: 2,
                slidesPerGroup: 2,
              },
              1024: {
                slidesPerView: 3,
                slidesPerGroup: 3,
              },
            },
          }}},[_c('template',{slot:"custom-swipe-icon"},[_c('svg',[_c('use',{attrs:{"href":"#icon-scroller"}})])]),_c('template',{slot:"components"},[_vm._t("carousel")],2)],2)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }