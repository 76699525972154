<template>
  <div class="CookingTipsMaterialsPage">
    <hero-cmp class="colorful-bg">
      <template #type>
        <slot name="service-type" />
      </template>
      <template #title>
        <slot name="service-hero-title" />
      </template>
    </hero-cmp>
    <div class="container pt-r pb-l">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <slot name="service-breadcrumbs" />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <div class="row">
            <div
              v-for="(material, i) in config"
              :key="material.slug"
              class="col-12 col-md-6 col-lg-4"
            >
              <card-cmp
                :data-href="material.file"
                data-target="_blank"
                class="normal pb-l"
              >
                <template #seo-link>
                  <slot :name="`service-seo-link-${i + 1}`" />
                </template>
                <template #image>
                  <slot :name="`service-image-${i + 1}`" />
                </template>
                <template #category>
                  <slot :name="`service-category-${i + 1}`" />
                </template>
                <template #title>
                  <slot :name="`service-title-${i + 1}`" />
                </template>
                <template #cta>
                  <cta-button-cmp-extended class="btn-text" data-tag="button">
                    {{ download_label }}
                    <template #after-icon>
                      <svg>
                        <use href="#icon-download" />
                      </svg>
                    </template>
                  </cta-button-cmp-extended>
                </template>
              </card-cmp>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { castPropToType } from '@/helpers/cms-support';
import { mapState } from 'vuex';

export default {
  name: 'CookingTipsMaterialsPage',
  props: {
    dataConfig: { type: String, required: true },
  },
  setup() {
    return { castPropToType };
  },

  computed: {
    config() {
      return this.dataConfig
        ? this.castPropToType(this.dataConfig, 'object')
        : null;
    },
    ...mapState('services', [
      'materials_overtitle',
      'materials_title',
      'download_label',
      'breadcrumbs',
    ]),
  },
};
</script>

<style lang="scss" scoped></style>
