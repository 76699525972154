export default {
  currentStepNavigation: (state) => {
    return state.currentStep && state.currentStep.num
      ? +state.currentStep.num
      : null;
  },
  totalStepNavigation: (state) => {
    return +`${state.views.filter((e) => e.num).length}`;
  },
  prev: (state) => {
    if (state.views && state.currentStep) {
      const index = state.views.findIndex(
        (e) => e.name === state.currentStep.name
      );
      if (
        index !== -1 &&
        index - 1 >= 0 &&
        index - 1 < state.views.length - 1
      ) {
        return state.views[index - 1].name;
      }
    }

    return 'home';
  },
  next: (state) => {
    if (state.views && state.currentStep) {
      const index = state.views.findIndex(
        (e) => e.name === state.currentStep.name
      );
      if (index !== -1 && index + 1 > 0 && index + 1 < state.views.length) {
        return state.views[index + 1].name;
      }
    }

    return 'home';
  },
};
