<template>
  <submit-cmp class="SubmitCmpExtended" v-bind="$attrs" v-on="$listeners">
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </submit-cmp>
</template>

<script>
import { SubmitCmp } from '@frankhoodbs/lib';

export default {
  name: 'SubmitCmpExtended',
  components: { SubmitCmp },
};
</script>

<style lang="scss" scoped>
.SubmitCmpExtended {
  ::v-deep .CtaButtonCmp {
    @include override-responsive-typography-properties(
      $font-primary,
      a-cta,
      --cta-button
    );
    --cta-button-padding: 15px 62px 14px;
    --cta-button-border-width: 2px;
    --cta-button-hover-border-width: 2px;
    --cta-button-disabled-border-width: 2px;
    --cta-button-border-radius: 4px;
    --cta-button-icon-width: 24px;
    --cta-button-icon-height: 24px;
    --cta-button-disabled-opacity: 1;
    --cta-button-bg-color: #{$color-white};
    --cta-button-color: #{$color-blue};
    --cta-button-border-color: #{$color-blue};
    --cta-button-hover-bg-color: #{$color-grey-400-50};
    --cta-button-hover-color: #{$color-light-blue};
    --cta-button-hover-border-color: #{$color-light-blue};
    --cta-button-disabled-bg-color: #{$color-white};
    --cta-button-disabled-color: #{$color-grey-400};
    --cta-button-disabled-border-color: #{$color-grey-400};

    &.reversed {
      --cta-button-bg-color: #{$color-blue};
      --cta-button-color: #{$color-white};
      --cta-button-border-color: #{$color-blue};
      --cta-button-hover-bg-color: #{$color-light-blue};
      --cta-button-hover-color: #{$color-white};
      --cta-button-hover-border-color: #{$color-light-blue};
      --cta-button-disabled-bg-color: #{$color-grey-200};
      --cta-button-disabled-color: #{$color-grey-400};
      --cta-button-disabled-border-color: #{$color-grey-400};
    }

    &.btn-small {
      --cta-button-padding: 12px 30px 11px;
    }
  }
}
</style>
