<template>
  <div class="TextWithCmp">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <div
            v-if="hasSlot($slots.text, $scopedSlots.text)"
            class="text-wrapper"
          >
            <slot name="text" />
          </div>
          <div
            v-if="hasSlot($slots.tags, $scopedSlots.tags)"
            class="tags-wrapper"
          >
            <slot name="tags" />
          </div>
        </div>
      </div>
      <div
        v-if="hasSlot($slots.quote, $scopedSlots.quote)"
        class="row justify-content-center"
      >
        <div class="col-12 col-md-8">
          <div class="quote-wrapper">
            <slot name="quote" />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="hasSlot($slots.images, $scopedSlots.images)"
      class="images-wrapper"
    >
      <slot name="images" />
    </div>
    <div v-if="hasSlot($slots.video, $scopedSlots.video)" class="video-wrapper">
      <slot name="video" />
    </div>
    <div v-if="hasSlot($slots.file, $scopedSlots.file)" class="file-wrapper">
      <slot name="file" />
    </div>
  </div>
</template>

<script>
import { hasSlot } from '@/helpers/cms-support';
export default {
  name: 'TextWithCmp',
  setup() {
    return { hasSlot };
  },
};
</script>

<style lang="scss" scoped>
.TextWithCmp {
  .tags-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }

  .tags-wrapper,
  .video-wrapper,
  .quote-wrapper,
  .images-wrapper,
  .file-wrapper {
    padding: 40px 0 0;

    @include media-breakpoint-up(lg) {
      padding: 80px 0 0;
    }
  }

  .file-wrapper {
    @include media-breakpoint-up(md) {
      padding: 80px 0 0;
    }
  }
}
</style>
