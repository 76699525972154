<template>
  <form-cmp class="ContactFormFiscalInfoCmp">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10 col-xl-8">
        <div class="row">
          <div
            v-if="
              dataCurrentStep.content.businessName &&
              dataCurrentStep.content.businessName.active
            "
            class="col-12 col-md-6"
          >
            <input-text-cmp-extended
              v-model.trim="$v.businessName.$model"
              data-name="businessName"
              :data-placeholder="
                dataCurrentStep.content.businessName.placeholder
              "
              :data-error-occurred="$v.businessName.$error"
              :data-errors="[
                ...dataCurrentStep.content.businessName.error.map((e) => ({
                  condition: !$v.businessName[e.condition],
                  message: e.message,
                })),
                {
                  condition: !$v.businessName.serverError,
                  message: serverErrors.businessName,
                },
              ]"
            >
              <template #label>
                {{ dataCurrentStep.content.businessName.label }}
              </template>
            </input-text-cmp-extended>
          </div>
          <div
            v-if="
              dataCurrentStep.content.vatNumber &&
              dataCurrentStep.content.vatNumber.active
            "
            class="col-12 col-md-6"
          >
            <input-text-cmp-extended
              v-model.trim="$v.vatNumber.$model"
              data-name="vatNumber"
              :data-placeholder="dataCurrentStep.content.vatNumber.placeholder"
              :data-error-occurred="$v.vatNumber.$error"
              :data-errors="[
                ...dataCurrentStep.content.vatNumber.error.map((e) => ({
                  condition: !$v.vatNumber[e.condition],
                  message: e.message,
                })),
                {
                  condition: !$v.vatNumber.serverError,
                  message: serverErrors.vatNumber,
                },
              ]"
            >
              <template #label>
                {{ dataCurrentStep.content.vatNumber.label }}
              </template>
            </input-text-cmp-extended>
          </div>
          <div
            v-if="
              dataCurrentStep.content.city &&
              dataCurrentStep.content.city.active
            "
            class="col-12 col-md-4"
          >
            <input-text-cmp-extended
              v-model.trim="$v.city.$model"
              data-name="city"
              :data-placeholder="dataCurrentStep.content.city.placeholder"
              :data-error-occurred="$v.city.$error"
              :data-errors="[
                ...dataCurrentStep.content.city.error.map((e) => ({
                  condition: !$v.city[e.condition],
                  message: e.message,
                })),
                {
                  condition: !$v.city.serverError,
                  message: serverErrors.city,
                },
              ]"
            >
              <template #label>
                {{ dataCurrentStep.content.city.label }}
              </template>
            </input-text-cmp-extended>
          </div>
          <div
            v-if="
              dataCurrentStep.content.address &&
              dataCurrentStep.content.address.active
            "
            class="col-12 col-md-6"
          >
            <input-text-cmp-extended
              v-model.trim="$v.address.$model"
              data-name="address"
              :data-placeholder="dataCurrentStep.content.address.placeholder"
              :data-error-occurred="$v.address.$error"
              :data-errors="[
                ...dataCurrentStep.content.address.error.map((e) => ({
                  condition: !$v.address[e.condition],
                  message: e.message,
                })),
                {
                  condition: !$v.address.serverError,
                  message: serverErrors.address,
                },
              ]"
            >
              <template #label>
                {{ dataCurrentStep.content.address.label }}
              </template>
            </input-text-cmp-extended>
          </div>
          <div
            v-if="
              dataCurrentStep.content.houseNumber &&
              dataCurrentStep.content.houseNumber.active
            "
            class="col-6 col-md-2"
          >
            <input-text-cmp-extended
              v-model.trim="$v.houseNumber.$model"
              data-name="houseNumber"
              :data-placeholder="
                dataCurrentStep.content.houseNumber.placeholder
              "
              :data-error-occurred="$v.houseNumber.$error"
              :data-errors="[
                ...dataCurrentStep.content.houseNumber.error.map((e) => ({
                  condition: !$v.houseNumber[e.condition],
                  message: e.message,
                })),
                {
                  condition: !$v.houseNumber.serverError,
                  message: serverErrors.houseNumber,
                },
              ]"
            >
              <template #label>
                {{ dataCurrentStep.content.houseNumber.label }}
              </template>
            </input-text-cmp-extended>
          </div>
          <div
            v-if="
              dataCurrentStep.content.postalCode &&
              dataCurrentStep.content.postalCode.active
            "
            class="col-6"
          >
            <input-text-cmp-extended
              v-model.trim="$v.postalCode.$model"
              data-name="postalCode"
              :data-placeholder="dataCurrentStep.content.postalCode.placeholder"
              :data-error-occurred="$v.postalCode.$error"
              :data-errors="[
                ...dataCurrentStep.content.postalCode.error.map((e) => ({
                  condition: !$v.postalCode[e.condition],
                  message: e.message,
                })),
                {
                  condition: !$v.postalCode.serverError,
                  message: serverErrors.postalCode,
                },
              ]"
            >
              <template #label>
                {{ dataCurrentStep.content.postalCode.label }}
              </template>
            </input-text-cmp-extended>
          </div>
          <div
            v-if="
              dataCurrentStep.content.country &&
              dataCurrentStep.content.country.active
            "
            class="col-12 col-md-6"
          >
            <select-cmp-extended
              v-model.trim="$v.country.$model"
              data-name="country"
              :data-option-list="countryList"
              :data-placeholder="dataCurrentStep.content.country.placeholder"
              :data-error-occurred="$v.country.$error"
              :data-errors="[
                ...dataCurrentStep.content.country.error.map((e) => ({
                  condition: !$v.country[e.condition],
                  message: e.message,
                })),
                {
                  condition: !$v.country.serverError,
                  message: serverErrors.country,
                },
              ]"
              @change="province = ''"
            >
              <template #label>
                {{ dataCurrentStep.content.country.label }}
              </template>
              <template #icon>
                <svg>
                  <use href="#icon-caret-down" />
                </svg>
              </template>
            </select-cmp-extended>
          </div>
          <div
            v-if="
              dataCurrentStep.content.province &&
              dataCurrentStep.content.province.active
            "
            class="col-12 col-md-6"
          >
            <template
              v-if="country === 'IT' || country === 'FR' || country === 'DE'"
            >
              <select-cmp-extended
                v-model.trim="$v.province.$model"
                data-name="province"
                :data-option-list="
                  country === 'IT'
                    ? provinceItList
                    : country === 'FR'
                    ? provinceFrList
                    : country === 'DE'
                    ? provinceDeList
                    : []
                "
                :data-placeholder="dataCurrentStep.content.province.placeholder"
                :data-error-occurred="$v.province.$error"
                :data-errors="[
                  ...dataCurrentStep.content.province.error.map((e) => ({
                    condition: !$v.province[e.condition],
                    message: e.message,
                  })),
                  {
                    condition: !$v.province.serverError,
                    message: serverErrors.province,
                  },
                ]"
              >
                <template #label>
                  {{ dataCurrentStep.content.province.label }}
                </template>
                <template #icon>
                  <svg>
                    <use href="#icon-caret-down" />
                  </svg>
                </template>
              </select-cmp-extended>
            </template>
            <template v-else>
              <input-text-cmp-extended
                v-model.trim="$v.province.$model"
                data-name="province"
                :data-placeholder="dataCurrentStep.content.province.placeholder"
                :data-error-occurred="$v.province.$error"
                :data-errors="[
                  ...dataCurrentStep.content.province.error.map((e) => ({
                    condition: !$v.province[e.condition],
                    message: e.message,
                  })),
                  {
                    condition: !$v.province.serverError,
                    message: serverErrors.province,
                  },
                ]"
              >
                <template #label>
                  {{ dataCurrentStep.content.province.label }}
                </template>
              </input-text-cmp-extended>
            </template>
          </div>
          <div
            v-if="
              dataCurrentStep.content.distributorName &&
              dataCurrentStep.content.distributorName.active
            "
            class="col-12 col-md-6"
          >
            <input-text-cmp-extended
              v-model.trim="$v.distributorName.$model"
              data-name="distributorName"
              :data-placeholder="
                dataCurrentStep.content.distributorName.placeholder
              "
              :data-error-occurred="$v.distributorName.$error"
              :data-errors="[
                ...dataCurrentStep.content.distributorName.error.map((e) => ({
                  condition: !$v.distributorName[e.condition],
                  message: e.message,
                })),
                {
                  condition: !$v.distributorName.serverError,
                  message: serverErrors.distributorName,
                },
              ]"
            >
              <template #label>
                {{ dataCurrentStep.content.distributorName.label }}
              </template>
            </input-text-cmp-extended>
          </div>
          <div
            v-if="
              dataCurrentStep.content.distributorCountry &&
              dataCurrentStep.content.distributorCountry.active
            "
            class="col-12 col-md-6"
          >
            <select-cmp-extended
              v-model.trim="$v.distributorCountry.$model"
              data-name="distributorCountry"
              :data-option-list="countryList"
              :data-placeholder="
                dataCurrentStep.content.distributorCountry.placeholder
              "
              :data-error-occurred="$v.distributorCountry.$error"
              :data-errors="[
                ...dataCurrentStep.content.distributorCountry.error.map(
                  (e) => ({
                    condition: !$v.distributorCountry[e.condition],
                    message: e.message,
                  })
                ),
                {
                  condition: !$v.distributorCountry.serverError,
                  message: serverErrors.distributorCountry,
                },
              ]"
            >
              <template #label>
                {{ dataCurrentStep.content.distributorCountry.label }}
              </template>
              <template #icon>
                <svg>
                  <use href="#icon-caret-down" />
                </svg>
              </template>
            </select-cmp-extended>
          </div>
          <div
            v-if="
              dataCurrentStep.content.distributorCity &&
              dataCurrentStep.content.distributorCity.active
            "
            class="col-12 col-md-6"
          >
            <input-text-cmp-extended
              v-model.trim="$v.distributorCity.$model"
              data-name="distributorCity"
              :data-placeholder="
                dataCurrentStep.content.distributorCity.placeholder
              "
              :data-error-occurred="$v.distributorCity.$error"
              :data-errors="[
                ...dataCurrentStep.content.distributorCity.error.map((e) => ({
                  condition: !$v.distributorCity[e.condition],
                  message: e.message,
                })),
                {
                  condition: !$v.distributorCity.serverError,
                  message: serverErrors.distributorCity,
                },
              ]"
            >
              <template #label>
                {{ dataCurrentStep.content.distributorCity.label }}
              </template>
            </input-text-cmp-extended>
          </div>
          <div
            v-if="
              dataCurrentStep.content.privacy &&
              dataCurrentStep.content.privacy.active
            "
            class="col-12"
          >
            <div
              class="privacy-wrapper"
              v-html="dataCurrentStep.content.privacy.label"
            />
            <input-radio-cmp-extended
              v-model.trim="$v.privacy.$model"
              data-name="privacy"
              data-aria-label="Privacy treatment"
              class="custom direction-column"
              :data-radio-list="dataCurrentStep.content.privacy.radioList"
              :data-error-occurred="$v.privacy.$error"
              :data-errors="[
                ...dataCurrentStep.content.privacy.error.map((e) => ({
                  condition: !$v.privacy[e.condition],
                  message: e.message,
                })),
                {
                  condition: !$v.privacy.serverError,
                  message: serverErrors.privacy,
                },
              ]"
            />
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-auto">
            <recaptcha-cmp
              :data-sitekey="recaptchaApiKey"
              data-aria-label="recaptcha"
              :data-error-occurred="$v.recaptcha.$error"
              :data-errors="[
                ...dataCurrentStep.content.recaptcha.error.map((e) => ({
                  condition: !$v.recaptcha[e.condition],
                  message: e.message,
                })),
                {
                  condition: !$v.recaptcha.serverError,
                  message: serverErrors.recaptcha,
                },
              ]"
              @verify="recaptchaVerify"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <submit-cmp-extended
              :data-theme-classes="['reversed']"
              :data-aria-label="dataCurrentStep.content.submitCtaAriaLabel"
              :data-loading="loading"
              :data-disabled="disableSubmit || submitted"
              :data-error-occurred="
                !!globalErrors.length || !!Object.keys(serverErrors).length
              "
              :data-errors="globalErrors"
              @click-submit="onSubmitForm"
            >
              <template>{{ dataCurrentStep.content.submitCtaText }}</template>
              <template #after-icon>
                <svg>
                  <use href="#icon-arrow-right" />
                </svg>
              </template>
            </submit-cmp-extended>
          </div>
          <div v-if="dataCurrentStep.content.footnote" class="col-12">
            <span class="a-caption text-color-grey-600">
              {{ dataCurrentStep.content.footnote }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </form-cmp>
</template>

<script>
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import { maxLength, maxValue, required } from 'vuelidate/lib/validators';
import { FormCmp } from '@frankhoodbs/lib';
import FormElementsSupport from '@frankhoodbs/lib/src/mixins/form-elements-support';
import { mapActions, mapGetters, mapState } from 'vuex';
import { debug, currentLang } from '@/helpers/utils';
import axios from 'axios';

Vue.use(Vuelidate);

const checkServerErrors = (param) => {
  return (value, vm) => {
    return Object.keys(vm.serverErrors).indexOf(param) === -1;
  };
};

export default {
  name: 'ContactFormFiscalInfoCmp',
  components: { FormCmp },
  mixins: [FormElementsSupport],
  props: {
    dataCurrentStep: {
      type: Object,
      required: true,
    },
    dataSubmitEnabledOnMounted: {
      type: Boolean,
      default: true,
    },
    dataApiUrl: {
      type: String,
      default: null,
    },
    dataRequestMethod: {
      type: String,
      default: 'post',
      validator: function (value) {
        // The value must match one of these strings
        return (
          [
            'get',
            'head',
            'post',
            'put',
            'delete',
            'connect',
            'options',
            'trace',
            'patch',
          ].indexOf(value) !== -1
        );
      },
    },
    dataRequestData: {
      type: Object,
      default: null,
    },
    dataRequestConfig: {
      type: Object,
      default: null,
    },
    dataHandledStatusCodeErrors: {
      type: Array,
      default: () => [400, 401, 403],
    },
  },
  data() {
    return {
      fields: [
        'businessName',
        'vatNumber',
        'city',
        'address',
        'houseNumber',
        'postalCode',
        'province',
        'country',
        'distributorName',
        'distributorCity',
        'distributorCountry',
        'privacy',
        'recaptcha',
      ],
      countryList: [],
      provinceItList: [],
      provinceFrList: [],
      provinceDeList: [],
      recaptcha: undefined,
    };
  },
  computed: {
    ...mapState('contact', ['chosenData', 'recaptchaApiKey']),
    ...mapState('gigya', ['data']),
    ...mapGetters('gigya', ['loggedIn']),
    langObj() {
      return currentLang(this.$store.state.config.ordered_languages);
    },
    /* form field */
    businessName: {
      get() {
        return this.valueMapper('businessName');
      },
      set(value) {
        this.valueMapper('businessName', value);
      },
    },
    vatNumber: {
      get() {
        return this.valueMapper('vatNumber');
      },
      set(value) {
        this.valueMapper('vatNumber', value);
      },
    },
    city: {
      get() {
        return this.valueMapper('city');
      },
      set(value) {
        this.valueMapper('city', value);
      },
    },
    address: {
      get() {
        return this.valueMapper('address');
      },
      set(value) {
        this.valueMapper('address', value);
      },
    },
    houseNumber: {
      get() {
        return this.valueMapper('houseNumber');
      },
      set(value) {
        this.valueMapper('houseNumber', value);
      },
    },
    postalCode: {
      get() {
        return this.valueMapper('postalCode');
      },
      set(value) {
        this.valueMapper('postalCode', value);
      },
    },
    province: {
      get() {
        return this.valueMapper('province');
      },
      set(value) {
        this.valueMapper('province', value);
      },
    },
    country: {
      get() {
        return this.valueMapper('country');
      },
      set(value) {
        this.valueMapper('country', value);
      },
    },
    distributorName: {
      get() {
        return this.valueMapper('distributorName');
      },
      set(value) {
        this.valueMapper('distributorName', value);
      },
    },
    distributorCity: {
      get() {
        return this.valueMapper('distributorCity');
      },
      set(value) {
        this.valueMapper('distributorCity', value);
      },
    },
    distributorCountry: {
      get() {
        return this.valueMapper('distributorCountry');
      },
      set(value) {
        this.valueMapper('distributorCountry', value);
      },
    },
    privacy: {
      get() {
        return this.valueMapper('privacy');
      },
      set(value) {
        this.valueMapper('privacy', value);
      },
    },
    disableSubmit() {
      if (this.dataSubmitEnabledOnMounted) {
        return false;
      } else {
        if (this.$v && (this.$v.$anyError || this.$v.$invalid)) {
          return true;
        }

        return false;
      }
    },
  },
  watch: {
    serverErrors: {
      handler(val) {
        // Check if an error on not-visible form field occurred
        Object.keys(val).every((key) => {
          if (!this.fields.includes(key)) {
            this.globalErrors.push({
              condition: true,
              message: this.dataCurrentStep.content.globalError,
            });

            return false;
          }

          return true;
        });
      },
    },
  },
  async mounted() {
    if (
      (this.dataCurrentStep.content.country &&
        this.dataCurrentStep.content.country.active) ||
      (this.dataCurrentStep.content.distributorCountry &&
        this.dataCurrentStep.content.distributorCountry.active)
    ) {
      const countryFixture =
        this.langObj.site_code === 'sv-se'
          ? await import('@/fixtures/country-se')
          : this.langObj.site_code === 'el-gr'
          ? await import('@/fixtures/country-gr')
          : this.langObj.site_code === 'en-us'
          ? await import('@/fixtures/country-us')
          : await import('@/fixtures/country');
      this.countryList = countryFixture.data;

      if (this.langObj.site_code.includes('-')) {
        const country = this.langObj.site_code.split('-')[1].toUpperCase();
        const isCountryInList = this.countryList.some(
          (a) => a.value === country
        );
        if (isCountryInList) this.country = country;
      }
    }
    if (
      this.dataCurrentStep.content.province &&
      this.dataCurrentStep.content.province.active
    ) {
      const provinceItFixture = await import('@/fixtures/province-it');
      this.provinceItList = provinceItFixture.data;
      const provinceFrFixture = await import('@/fixtures/province-fr');
      this.provinceFrList = provinceFrFixture.data;
      const provinceDeFixture = await import('@/fixtures/province-de');
      this.provinceDeList = provinceDeFixture.data;
      const provinceUsFixture = await import('@/fixtures/province-us');
      this.provinceUsList = provinceUsFixture.data;
    }

    if (this.loggedIn) {
      if (
        !this.chosenData.businessName &&
        this.dataCurrentStep.content.businessName &&
        this.dataCurrentStep.content.businessName.active &&
        this.data.company.name
      ) {
        this.setStepData({
          key: 'businessName',
          value: this.data.company.name,
        });
      }
      if (
        !this.chosenData.vatNumber &&
        this.dataCurrentStep.content.vatNumber &&
        this.dataCurrentStep.content.vatNumber.active &&
        this.data.company.VAT
      ) {
        this.setStepData({ key: 'vatNumber', value: this.data.company.VAT });
      }
      if (
        !this.chosenData.city &&
        this.dataCurrentStep.content.city &&
        this.dataCurrentStep.content.city.active &&
        this.data.address.city
      ) {
        this.setStepData({ key: 'city', value: this.data.address.city });
      }
      if (
        !this.chosenData.address &&
        this.dataCurrentStep.content.address &&
        this.dataCurrentStep.content.address.active &&
        this.data.address.street
      ) {
        this.setStepData({ key: 'address', value: this.data.address.street });
      }
      if (
        !this.chosenData.houseNumber &&
        this.dataCurrentStep.content.houseNumber &&
        this.dataCurrentStep.content.houseNumber.active &&
        this.data.address.houseNumber
      ) {
        this.setStepData({
          key: 'houseNumber',
          value: this.data.address.houseNumber,
        });
      }
      if (
        !this.chosenData.postalCode &&
        this.dataCurrentStep.content.postalCode &&
        this.dataCurrentStep.content.postalCode.active &&
        this.data.address.zip
      ) {
        this.setStepData({ key: 'postalCode', value: this.data.address.zip });
      }
      if (
        !this.chosenData.country &&
        this.dataCurrentStep.content.country &&
        this.dataCurrentStep.content.country.active &&
        this.data.address.country
      ) {
        this.setStepData({
          key: 'country',
          value: this.data.address.country,
        }).then(() => {
          if (
            !this.chosenData.province &&
            this.dataCurrentStep.content.province &&
            this.dataCurrentStep.content.province.active
          ) {
            let gigyaProvince = this.data.address.state
              ? this.data.address.state
              : '';
            if (
              this.country === 'IT' ||
              this.country === 'FR' ||
              this.country === 'DE' ||
              this.langObj.site_code === 'en-us'
            ) {
              if (this.country === 'IT') {
                let provinceObj = this.provinceItList.find(
                  (e) => e.value === gigyaProvince || e.text === gigyaProvince
                );
                if (!provinceObj) {
                  gigyaProvince = '';
                } else {
                  gigyaProvince = provinceObj.value;
                }
              }
              if (this.country === 'FR') {
                let provinceObj = this.provinceFrList.find(
                  (e) => e.value === gigyaProvince || e.text === gigyaProvince
                );
                if (!provinceObj) {
                  gigyaProvince = '';
                } else {
                  gigyaProvince = provinceObj.value;
                }
              }
              if (this.country === 'DE') {
                let provinceObj = this.provinceDeList.find(
                  (e) => e.value === gigyaProvince || e.text === gigyaProvince
                );
                if (!provinceObj) {
                  gigyaProvince = '';
                } else {
                  gigyaProvince = provinceObj.value;
                }
              }
              if (this.langObj.site_code === 'en-us') {
                let provinceObj = this.provinceUsList.find(
                  (e) => e.value === gigyaProvince || e.text === gigyaProvince
                );
                if (!provinceObj) {
                  gigyaProvince = '';
                } else {
                  gigyaProvince = provinceObj.value;
                }
              }
            }

            this.setStepData({ key: 'province', value: gigyaProvince });
          }
        });
      }
      if (
        !(
          this.dataCurrentStep.content.country &&
          this.dataCurrentStep.content.country.active
        ) &&
        !this.chosenData.province &&
        this.dataCurrentStep.content.province &&
        this.dataCurrentStep.content.province.active &&
        this.data.address.state
      ) {
        this.setStepData({ key: 'province', value: this.data.address.state });
      }
      if (
        !this.chosenData.distributorName &&
        this.dataCurrentStep.content.distributorName &&
        this.dataCurrentStep.content.distributorName.active &&
        this.data.distributor.name
      ) {
        this.setStepData({
          key: 'distributorName',
          value: this.data.distributor.name,
        });
      }
      if (
        !this.chosenData.distributorCountry &&
        this.dataCurrentStep.content.distributorCountry &&
        this.dataCurrentStep.content.distributorCountry.active &&
        this.data.distributor.state
      ) {
        let gigyaDistributorCountry = this.data.distributor.state;
        let distributorCountryObj = this.countryList.find(
          (e) =>
            e.value === gigyaDistributorCountry ||
            e.text === gigyaDistributorCountry
        );
        if (!distributorCountryObj) {
          gigyaDistributorCountry = '';
        } else {
          gigyaDistributorCountry = distributorCountryObj.value;
        }
        this.setStepData({
          key: 'distributorCountry',
          value: gigyaDistributorCountry,
        });
      }
      if (
        !this.chosenData.distributorCity &&
        this.dataCurrentStep.content.distributorCity &&
        this.dataCurrentStep.content.distributorCity.active &&
        this.data.distributor.city
      ) {
        this.setStepData({
          key: 'distributorCity',
          value: this.data.distributor.city,
        });
      }
    }
  },
  validations() {
    let activeFields = [];
    this.fields.forEach((e) => {
      if (
        this.dataCurrentStep.content[e] &&
        this.dataCurrentStep.content[e].active
      ) {
        activeFields.push(e);
      }
    });

    return this.setValidations(activeFields);
  },
  methods: {
    ...mapActions('contact', ['setStepData']),
    recaptchaVerify(response) {
      this.recaptcha = response;
    },
    valueMapper(key, value) {
      if (arguments.length === 2) {
        this.setStepData({ key, value });
      } else {
        return this.chosenData[key];
      }
    },
    setValidations(fields) {
      let validations = {};

      fields.forEach((prop) => {
        validations[prop] = {};

        this.dataCurrentStep.content[prop].error.forEach((e) => {
          if (e.condition === 'required') {
            validations[prop] = {
              ...validations[prop],
              required,
            };
          }
          if (e.condition === 'maxLength') {
            validations[prop] = {
              ...validations[prop],
              maxLength: maxLength(e.length),
            };
          }
          if (e.condition === 'maxValue') {
            validations[prop] = {
              ...validations[prop],
              maxValue: maxValue(e.length),
            };
          }
        });
        validations[prop] = {
          ...validations[prop],
          serverError: checkServerErrors(prop),
        };
      });

      return validations;
    },
    async onSubmitForm() {
      if (this.dataSubmitEnabledOnMounted) {
        this.$v.$touch();
      }
      if (!this.submitted && !this.disableSubmit && !this.$v.$invalid) {
        this.submitted = true;
        this.loading = true;
        this.globalErrors = [];
        this.toastErrors = [];

        try {
          let formData = new FormData();
          formData.set('customerType', this.chosenData.customerType);
          formData.set('reasonType', this.chosenData.reasonType);
          formData.set('product', this.chosenData.product);
          const file = this.chosenData.file ?? '';
          formData.set('file', file);
          formData.set('message', this.chosenData.message);
          formData.set('name', this.chosenData.name);
          formData.set('surname', this.chosenData.surname);
          formData.set('email', this.chosenData.email);
          let telephone = this.chosenData.telephone ?? '';
          telephone =
            this.chosenData.telephone.charAt(0) === '+'
              ? this.chosenData.telephone.replace('+', '00')
              : this.chosenData.telephone;
          formData.set('telephone', telephone);
          formData.set('interest', this.chosenData.interest);
          formData.set('work', this.chosenData.work);
          formData.set('businessName', this.chosenData.businessName);
          formData.set('vatNumber', this.chosenData.vatNumber);
          formData.set('city', this.chosenData.city);
          formData.set('address', this.chosenData.address);
          formData.set('houseNumber', this.chosenData.houseNumber);
          formData.set('postalCode', this.chosenData.postalCode);
          formData.set('country', this.chosenData.country);
          formData.set('province', this.chosenData.province);
          formData.set('distributorName', this.chosenData.distributorName);
          formData.set(
            'distributorCountry',
            this.chosenData.distributorCountry
          );
          formData.set('distributorCity', this.chosenData.distributorCity);
          formData.set('privacy', this.chosenData.privacy);
          formData.set('recaptcha', this.recaptcha);
          if (this.loggedIn) {
            const UID = await this.getUID();
            formData.set('contact_id_gigya', UID);
          } else {
            formData.set('contact_id_gigya', '');
          }

          let axiosConfig = {
            method: this.dataRequestMethod,
            url: this.dataApiUrl,
            data:
              this.dataRequestData !== null
                ? { ...this.dataRequestData, ...formData }
                : formData,
          };
          if (this.dataRequestConfig !== null) {
            axiosConfig = {
              ...axiosConfig,
              ...this.dataRequestConfig,
            };
          }
          const response = await axios(axiosConfig);

          // Implement 200
          // Effettuare  nell'if tutti i controlli del caso per verificare che il payload di risposta
          // ricevuto sia conforme a quello che ci aspettiamo
          if (response.data) {
            this.handleSuccessCall(response);
          } else {
            this.$_formElementsSupport_handleApiError(response);
          }
        } catch (e) {
          // Controlliamo se siamo entrati nella catch per una risposta del server non di successo
          // se response non è incluso o non abbiamo lo status code aspettato allora si è rotto
          // qualcosa lato codice nostro lo gestiamo subito
          const hasResponse =
            Object.getOwnPropertyNames(e).includes('response');
          if (
            !hasResponse ||
            (hasResponse &&
              !this.dataHandledStatusCodeErrors.includes(e.response.status))
          ) {
            // 500 or unknown status code
            this.$_formElementsSupport_handleUnknownError(e);
          } else {
            this.$_formElementsSupport_handleErrors(e);
          }
        } finally {
          this.loading = false;
        }
      }
    },
    handleSuccessCall(response) {
      // eslint-disable-next-line
      debug && console.log(response);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'form_submitted',
        'data-form-id': this.chosenData.ga4id,
        'data-form-name': 'contact_form',
      });
      this.$emit('submit');
    },
    getUID() {
      return new Promise((resolve) => {
        window.gigya.accounts.getAccountInfo({
          include: 'id_token,data,profile',
          callback: (data) => {
            resolve(data.UID);
          },
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ContactFormFiscalInfoCmp.FormCmp {
  .form-group__wrapper {
    --form-element-padding: 0 0 40px;

    &.SubmitCmp {
      --form-element-padding: 40px 0 80px;
    }
  }

  .privacy-wrapper {
    display: flex;
    align-items: center;
    padding: 0 0 16px;

    @include media-breakpoint-up(md) {
      padding: 40px 0 16px;
    }

    ::v-deep a {
      color: $color_blue;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
