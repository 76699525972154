var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-cmp',{staticClass:"ContactFormMessageInfoCmp"},[(
      _vm.dataCurrentStep.content.product &&
      _vm.dataCurrentStep.content.product.active
    )?_c('div',{staticClass:"row"},[(!_vm.hideProduct)?_c('div',{staticClass:"col-12 col-md-5 offset-md-2 col-lg-4"},[_c('select-cmp-extended',{attrs:{"data-name":"product","data-placeholder":_vm.dataCurrentStep.content.product.placeholder,"data-option-api-url":_vm.dataCurrentStep.content.product.apiUrl,"data-error-occurred":_vm.$v.product.$error,"data-errors":_vm.dataCurrentStep.content.product.error.map(function (e) { return ({
            condition: !_vm.$v.product[e.condition],
            message: e.message,
          }); }).concat( [{
            condition: !_vm.$v.product.serverError,
            message: _vm.serverErrors.product,
          }] )},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.dataCurrentStep.content.product.label)+" ")]},proxy:true},{key:"icon",fn:function(){return [_c('svg',[_c('use',{attrs:{"href":"#icon-caret-down"}})])]},proxy:true}],null,false,1664346405),model:{value:(_vm.$v.product.$model),callback:function ($$v) {_vm.$set(_vm.$v.product, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.product.$model"}})],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"row justify-content-center"},[(
        _vm.dataCurrentStep.content.file && _vm.dataCurrentStep.content.file.active
      )?_c('div',{staticClass:"col-12 col-md-8"},[_c('input-file-cmp-extended',{ref:"$file",staticClass:"dropbox",attrs:{"data-name":"file","data-error-occurred":_vm.$v.file.$error,"data-errors":_vm.dataCurrentStep.content.file.error.map(function (e) { return ({
            condition: !_vm.$v.file[e.condition],
            message: e.message,
          }); }).concat( [{
            condition: !_vm.$v.file.serverError,
            message: _vm.serverErrors.file,
          }] )},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.dataCurrentStep.content.file.label))]},proxy:true},{key:"icon",fn:function(){return [_c('svg',[_c('use',{attrs:{"href":"#icon-upload"}})])]},proxy:true},{key:"drag-text",fn:function(){return [_vm._v(" "+_vm._s(_vm.dataCurrentStep.content.file.dragText)+" "),_c('cta-button-cmp-extended',{staticClass:"btn-text"},[_vm._v(" "+_vm._s(_vm.dataCurrentStep.content.file.dragTextCta)+" ")])]},proxy:true},{key:"drop-text",fn:function(){return [_vm._v(" "+_vm._s(_vm.dataCurrentStep.content.file.dropText)+" ")]},proxy:true}],null,false,3475169888),model:{value:(_vm.$v.file.$model),callback:function ($$v) {_vm.$set(_vm.$v.file, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.file.$model"}},[[_vm._v(_vm._s(_vm.dataCurrentStep.content.file.placeholder))]],2)],1):_vm._e(),(
        _vm.dataCurrentStep.content.message &&
        _vm.dataCurrentStep.content.message.active
      )?_c('div',{staticClass:"col-12 col-md-8"},[_c('textarea-cmp-extended',{attrs:{"data-name":"message","data-placeholder":_vm.dataCurrentStep.content.message.placeholder,"data-rows":7,"data-resizable":false,"data-error-occurred":_vm.$v.message.$error,"data-errors":_vm.dataCurrentStep.content.message.error.map(function (e) { return ({
            condition: !_vm.$v.message[e.condition],
            message: e.message,
          }); }).concat( [{
            condition: !_vm.$v.message.serverError,
            message: _vm.serverErrors.message,
          }] )},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.dataCurrentStep.content.message.label)+" ")]},proxy:true}],null,false,3177106967),model:{value:(_vm.$v.message.$model),callback:function ($$v) {_vm.$set(_vm.$v.message, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.message.$model"}})],1):_vm._e(),_c('div',{staticClass:"col-12 col-md-8 text-center"},[_c('submit-cmp-extended',{attrs:{"data-theme-classes":['reversed'],"data-aria-label":_vm.dataCurrentStep.content.submitCtaAriaLabel,"data-loading":_vm.loading,"data-disabled":_vm.disableSubmit || _vm.submitted,"data-error-occurred":!!_vm.globalErrors.length || !!Object.keys(_vm.serverErrors).length,"data-errors":_vm.globalErrors},on:{"click-submit":_vm.onSubmitForm},scopedSlots:_vm._u([{key:"after-icon",fn:function(){return [_c('svg',[_c('use',{attrs:{"href":"#icon-arrow-right"}})])]},proxy:true}])},[[_vm._v(_vm._s(_vm.dataCurrentStep.content.submitCtaText))]],2)],1),(_vm.dataCurrentStep.content.footnote)?_c('div',{staticClass:"col-12 col-md-8"},[_c('span',{staticClass:"a-caption text-color-grey-600"},[_vm._v(" "+_vm._s(_vm.dataCurrentStep.content.footnote)+" ")])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }