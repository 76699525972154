var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['RecipesListPage', { loading: _vm.loading }]},[_c('hero-cmp',{ref:"$targetScroll",staticClass:"colorful-bg mb-9",scopedSlots:_vm._u([{key:"type",fn:function(){return [_vm._t("recipe-type")]},proxy:true},{key:"title",fn:function(){return [_vm._t("recipe-hero-title")]},proxy:true}],null,true)}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-10"},[_c('title-text-cmp',{staticClass:"mb-9",scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._t("recipe-title")]},proxy:true},{key:"text",fn:function(){return [_vm._t("recipe-description")]},proxy:true}],null,true)})],1)])]),_c('div',{staticClass:"container pb-xl"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-10"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-color-grey-800 a-support mb-1"},[_vm._v(" "+_vm._s(_vm.list_filter_by_label)+" ")]),_vm._l((_vm.filters),function(category){return _c('div',{key:("category_col_" + (category.slug)),staticClass:"col-auto"},[_c('dropdown-cmp-extended',{staticClass:"mt-3",attrs:{"data-tag":"button","data-trigger-theme-classes":[
                {
                  selected:
                    category.options.active === true ||
                    category.options.active.length > 0,
                } ],"data-menu-theme-classes":['shadow-soft']},scopedSlots:_vm._u([{key:"after-icon",fn:function(){return [_c('svg',{staticClass:"icon-caret-down"},[_c('use',{attrs:{"href":"#icon-caret-down"}})])]},proxy:true},{key:"dropdown-menu",fn:function(){return [_c('input-checkbox-cmp-extended',{staticClass:"custom direction-column",attrs:{"data-name":"checkbox","data-checkbox-list":category.options.list},model:{value:(category.options.active),callback:function ($$v) {_vm.$set(category.options, "active", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"category.options.active"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(category.name)+" "),(
                  category.options.active &&
                  category.options.active.length > 0
                )?[_vm._v(" ("+_vm._s(category.options.active.length)+") ")]:_vm._e(),(category.options.active === true)?[_vm._v("(1)")]:_vm._e()],2)],1)})],2),(_vm.activeFilters.length > 0)?_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('cta-button-cmp-extended',{staticClass:"btn-text mt-3",on:{"click":function($event){_vm.activeFilters = [];
                _vm.initializeFilters(true);}}},[_vm._v(" "+_vm._s(_vm.list_reset_filters_label)+" ")])],1)]):_vm._e(),(_vm.list.length === 0)?_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"a-paragraph-2 text-color-blue text-center mt-12"},[_vm._v(" "+_vm._s(_vm.list_no_results_label)+" ")])])]):_vm._e(),_c('div',{staticClass:"row justify-content-start"},[(!_vm.setupStoreCompleted)?_vm._t("recipes-pages"):_vm._l((_vm.list),function(recipe,index){return _c('div',{key:("recipe_col_" + index),staticClass:"col-12 col-md-6 col-lg-4"},[_c('card-cmp',{staticClass:"normal mt-9",attrs:{"data-href":recipe.href},scopedSlots:_vm._u([{key:"seo-link",fn:function(){return [_c('a',{attrs:{"href":recipe.slug}})]},proxy:true},{key:"image",fn:function(){return [_c('image-cmp',{attrs:{"width":"1062","height":"596","data-desktop-image":recipe.list_image,"data-image-alt":recipe.list_image_alt}})]},proxy:true},{key:"tags",fn:function(){return _vm._l((recipe.categories),function(tag){return _c('a',{key:'tag-' + tag.slug,attrs:{"href":tag.href}},[_c('tag-cmp',[_vm._v(" "+_vm._s(tag.name)+" ")])],1)})},proxy:true},{key:"category",fn:function(){return [(recipe.list_short_title)?[_vm._v(" "+_vm._s(recipe.list_short_title)+" ")]:[_c('div')]]},proxy:true},{key:"title",fn:function(){return [_c('h2',[_vm._v(_vm._s(recipe.list_title))])]},proxy:true}],null,true)})],1)})],2),(!(_vm.list.length === 0))?_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('pagination-cmp',{staticClass:"mt-10",attrs:{"data-tag":"a","data-pages":_vm.recipesPages,"data-emit":true,"data-active-page-index":_vm.activePageIndex},on:{"click":_vm.handlePagination},scopedSlots:_vm._u([{key:"seo-pagination",fn:function(){return [_vm._t("recipe-seo-pagination")]},proxy:true}],null,true)})],1)]):_vm._e()])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }