var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"SecondLevelCategoryPage"},[_c('hero-cmp',{class:[
      {
        'colorful-bg-with-img': _vm.lgAndUp && _vm.conf.hero_side_img,
        'colorful-bg': !_vm.lgAndUp || !_vm.conf.hero_side_img,
      },
      _vm.conf.category_color ],scopedSlots:_vm._u([(_vm.lgAndUp)?{key:"image",fn:function(){return [_vm._t("product-image")]},proxy:true}:null,{key:"type",fn:function(){return [_vm._t("product-type")]},proxy:true},{key:"title",fn:function(){return [_vm._t("product-hero-title")]},proxy:true}],null,true)}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-xl-8 py-xs"},[_vm._t("product-breadcrumbs"),_c('title-text-cmp',{staticClass:"pt-r pb-l",scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._t("product-title")]},proxy:true},{key:"text",fn:function(){return [_vm._t("product-description")]},proxy:true}],null,true)})],2)])]),(_vm.conf.category_properties && _vm.conf.category_properties.length)?_c('focus-items-cmp',{staticClass:"with-icons",attrs:{"data-box-color":_vm.conf.category_color},scopedSlots:_vm._u([{key:"icons",fn:function(){return [_vm._t("category-properties")]},proxy:true}],null,true)}):_vm._e(),_c('div',{class:[
      'container pb-l',
      {
        'pt-xl': _vm.conf.category_properties && _vm.conf.category_properties.length,
      } ]},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-10"},[(_vm.hasTabs)?_c('tabs-cmp',{attrs:{"data-active-tab-id":_vm.currentTab,"data-tabs":_vm.tabs,"data-no-content":true},on:{"update:dataActiveTabId":function($event){_vm.currentTab=$event},"update:data-active-tab-id":function($event){_vm.currentTab=$event}}}):_vm._e(),(
            (_vm.weights.length && _vm.weights.length > 1) ||
            _vm.isSelectCountryVisibleInSecondLevel /*&&
      atLeastOneProductHasAtLeastOneCountry*/
          )?_c('div',{class:[
            {
              'pt-l':
                (_vm.conf.category_properties &&
                  _vm.conf.category_properties.length) ||
                _vm.hasTabs,
            } ]},[_c('p',{staticClass:"a-support text-color-grey-800 pb-xs"},[_vm._v(" "+_vm._s(_vm.filter_by_label)+" ")]),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"50px"}},[(_vm.weights.length && _vm.weights.length > 1)?_c('dropdown-cmp-extended',{attrs:{"data-tag":"button","data-trigger-theme-classes":[
                { selected: _vm.selectedWeights.length > 0 } ],"data-menu-theme-classes":[
                'shadow-soft',
                { scrollable: _vm.weights.length > 8 } ]},scopedSlots:_vm._u([{key:"after-icon",fn:function(){return [_c('svg',{staticClass:"icon-caret-down"},[_c('use',{attrs:{"href":"#icon-caret-down"}})])]},proxy:true},{key:"dropdown-menu",fn:function(){return [_c('div',{staticClass:"scroll-container"},[_c('input-checkbox-cmp-extended',{staticClass:"custom direction-column",attrs:{"data-override-boolean-behaviour":true,"data-name":"checkbox","data-aria-label":"Checkbox","data-checkbox-list":_vm.weights.map(function (w) { return ({
                        value: w.value,
                        label: _vm.customDecimalSeparator(
                          w.label,
                          _vm.$store.state.config.decimal_separator
                        ),
                      }); })},model:{value:(_vm.selectedWeights),callback:function ($$v) {_vm.selectedWeights=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"selectedWeights"}})],1)]},proxy:true}],null,false,3513534647)},[_vm._v(" "+_vm._s(_vm.net_weight_label)+" "),(_vm.selectedWeights.length > 0)?[_vm._v(" ("+_vm._s(_vm.selectedWeights.length)+") ")]:_vm._e()],2):_vm._e(),(
                _vm.isSelectCountryVisibleInSecondLevel /*&&
                atLeastOneProductHasAtLeastOneCountry*/
              )?_c('dropdown-cmp-extended',{attrs:{"data-tag":"button","data-trigger-theme-classes":[
                { selected: _vm.selectedCountries.length > 0 } ],"data-menu-theme-classes":[
                'shadow-soft',
                { scrollable: _vm.countriesList.length > 8 } ]},scopedSlots:_vm._u([{key:"after-icon",fn:function(){return [_c('svg',{staticClass:"icon-caret-down"},[_c('use',{attrs:{"href":"#icon-caret-down"}})])]},proxy:true},{key:"dropdown-menu",fn:function(){return [_c('div',{staticClass:"scroll-container"},[_c('input-checkbox-cmp-extended',{staticClass:"custom direction-column",attrs:{"data-override-boolean-behaviour":true,"data-name":"checkbox","data-aria-label":"Checkbox","data-checkbox-list":_vm.countriesList.map(function (w) { return ({
                        value: w.value,
                        label: w.label,
                      }); })},model:{value:(_vm.selectedCountries),callback:function ($$v) {_vm.selectedCountries=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"selectedCountries"}})],1)]},proxy:true}],null,false,575719830)},[_vm._v(" "+_vm._s(_vm.countriesFilterLabel)+" "),(_vm.selectedCountries.length > 0)?[_vm._v(" ("+_vm._s(_vm.selectedCountries.length)+") ")]:_vm._e()],2):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"pt-l"},[_c('div',{staticClass:"row mt-nr"},[_vm._l((_vm.filteredProducts),function(product,index){return _c('div',{key:("product_" + (product.slug) + "_" + index),staticClass:"col-md-6 col-lg-4 pt-r"},[_c('card-product-cmp',{staticClass:"product animationForTransparentsImgs",attrs:{"data-href":product.href,"data-quantity":product.quantity_parsed,"data-measure-unit-value":product.measure_unit_value,"data-measure-unit":product.measure_unit},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h3',[_vm._v(_vm._s(product.title))])]},proxy:true},{key:"image",fn:function(){return [_c('img',{attrs:{"src":product.image,"alt":product.image_alt}})]},proxy:true}],null,true)})],1)}),(_vm.showEmptyMessage)?_c('div',{staticClass:"empty-message"},[_vm._v(" "+_vm._s(_vm.noProductsLabel)+" ")]):_vm._e()],2)])],1)])]),(_vm.conf.info_boxes && _vm.conf.info_boxes.length)?_c('carousel-certifications-cmp',{staticClass:"pb-l",scopedSlots:_vm._u([{key:"carousel",fn:function(){return [_vm._t("product-certifications")]},proxy:true}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }