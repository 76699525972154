<template functional>
  <div
    :ref="data.ref"
    :class="['HighlightedVideoCmp', data.class, data.staticClass]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <div :class="[...props.dataBootstrapContainerClasses]">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <div class="video-wrapper">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HighlightedVideoCmp',
  functional: true,
  props: {
    dataBootstrapContainerClasses: {
      type: Array,
      default: () => ['container'],
    },
  },
};
</script>

<style lang="scss" scoped>
.HighlightedVideoCmp {
  .video-wrapper {
    padding: 40px 16px;
    border-radius: 8px;
    background-color: $color-blue-10;

    @include media-breakpoint-up(md) {
      padding: 40px 0;
    }

    @include media-breakpoint-up(lg) {
      padding: 80px 0;
    }

    ::v-deep .YoutubeMediaPlayerCmpExtended {
      &:not(.playing) {
        .media-container {
          max-width: none;
          padding: 0;

          .media-col {
            width: 100%;

            @include media-breakpoint-up(md) {
              width: percentage(8 * 0.1);
            }
          }
        }
      }
    }
  }
}
</style>
