import { render, staticRenderFns } from "./recaptcha-cmp.vue?vue&type=template&id=07aa9d98&scoped=true"
import script from "./recaptcha-cmp.vue?vue&type=script&lang=js"
export * from "./recaptcha-cmp.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07aa9d98",
  null
  
)

export default component.exports