<template>
  <span>{{ formattedDate }}</span>
</template>

<script>
import { DateTime } from 'luxon';
export default {
  name: 'BeautifulDate',
  props: {
    dataDatetime: {
      required: true,
      type: String,
    },
    dataFormat: {
      required: true,
      type: String,
    },
    dataLocale: {
      required: true,
      type: String,
    },
  },
  computed: {
    formattedDate() {
      try {
        return DateTime.fromISO(this.dataDatetime)
          .setLocale(this.dataLocale)
          .toFormat(this.dataFormat);
      } catch (e) {
        return '...';
      }
    },
  },
};
</script>
