import * as types from '../mutation_types';
import { debug } from '@/helpers/utils';
import axios from 'axios';

export default {
  setGigyaInitData({ commit }, payload) {
    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
      try {
        Object.keys(payload).forEach((k) => {
          commit(types.SET_GIGYA_INIT_DATA, { key: k, value: payload[k] });
        });
        resolve();
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
        reject(e);
      }
    });
  },
  setGigyaCurrentScreen({ commit }, screenName) {
    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_CURRENT_SCREEN, screenName);
        resolve();
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
        reject(e);
      }
    });
  },
  setGigyaGoTo({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_GIGYA_GO_TO, payload);
        resolve();
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
        reject(e);
      }
    });
  },
  setGigyaLoaded({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_GIGYA_LOADED, payload);
        resolve();
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
        reject(e);
      }
    });
  },
  setGigyaUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_GIGYA_USER, payload);
        resolve();
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
        reject(e);
      }
    });
  },
  setGigyaUserData({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_GIGYA_USER_DATA, payload);
        resolve();
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
        reject(e);
      }
    });
  },
  setGigyaPreferences({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_GIGYA_PREFERENCES, payload);
        resolve();
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
        reject(e);
      }
    });
  },
  setOnetrustActiveGroups({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_OT_ACTIVE_GROUPS, payload);

        resolve();
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
        reject(e);
      }
    });
  },
  pushGenericCh(_, chName) {
    return new Promise((resolve, reject) => {
      try {
        if (window.gigya) {
          let gigya = window.gigya;
          let params = {
            data: {
              FLG: 'M',
            },
            callback: () => {
              // eslint-disable-next-line
              debug && console.log('setAccountInfo callback', arguments);
              resolve();
            },
          };
          params.data[chName] = new Date().toISOString().split('T').shift();

          // eslint-disable-next-line
          debug && console.log(`PUSHO CH ${chName} -> ${params.data[chName]}`);

          gigya.accounts.getAccountInfo({
            include: 'data',
            callback: (response) => {
              if (!response.data[chName]) {
                gigya.accounts.setAccountInfo(params);
              } else {
                resolve();
              }
            },
          });
        } else {
          resolve();
        }
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
        reject(e);
      }
    });
  },
  login({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      dispatch('setLoading', true, { root: true });
      axios
        .post(state.loginApi, payload)
        .then((response) => {
          resolve(response);
          dispatch('setLoading', false, { root: true });
        })
        .catch((err) => {
          reject(err);
          dispatch('setLoading', false, { root: true });
        });
    });
  },
  logout({ state, dispatch }) {
    return new Promise((resolve, reject) => {
      dispatch('setLoading', true, { root: true });

      axios
        .post(state.logoutApi)
        .then((response) => {
          dispatch('setGigyaUser', null);
          dispatch('setGigyaUserData', null);
          resolve(response);
          dispatch('setLoading', false, { root: true });
        })
        .catch((err) => {
          reject(err);
          dispatch('setLoading', false, { root: true });
        });
    });
  },
};
