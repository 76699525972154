<template>
  <youtube-media-player-cmp
    class="YoutubeMediaPlayerCmpExtended"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </youtube-media-player-cmp>
</template>

<script>
import { YoutubeMediaPlayerCmp } from '@frankhoodbs/lib';

export default {
  name: 'YoutubeMediaPlayerCmpExtended',
  components: { YoutubeMediaPlayerCmp },
};
</script>

<style lang="scss" scoped>
.YoutubeMediaPlayerCmpExtended {
  --youtube-media-player-play-width: 80px;
  --youtube-media-player-play-height: 80px;
  --youtube-media-player-caption-padding: 24px 0 0;
  --youtube-media-player-play-color: #{rgba($color-white, 0.8)};
  --youtube-media-player-play-hover-color: #{$color-blue-10};

  ::v-deep .caption {
    @include responsive-typography-properties($font-primary, a-paragraph-2);
  }
}
</style>
