/* Mutations, actions and getters */
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    best_practices_overtitle: '',
    best_practices_detail_overtitle: '',
    best_practices_title: '',
    title: '',
    rte: '',
    materials_overtitle: '',
    materials_title: '',
    download_label: '',
    videos_overtitle: '',
    videos_title: '',
    cta_links: [],
    breadcrumbs: [],
  },
  actions,
  mutations,
  getters,
};
