var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.propPages)?_c('nav',{staticClass:"PaginationCmp"},[(_vm.prevPage)?_c(_vm.dataTag,{tag:"component",staticClass:"sx-arrow",attrs:{"href":_vm.dataEmit ? null : _vm.dataTag === 'a' ? _vm.prevPage.href : null,"to":_vm.dataTag === 'router-link' ? _vm.prevPage.href : null,"target":_vm.dataTag === 'a' ? _vm.prevPage.target : null},on:{"click":function($event){_vm.dataEmit
        ? _vm.$emit('click', _vm.prevPage)
        : _vm.dataTag === 'router-link'
        ? _vm.activePageIndex--
        : null}}},[_c('svg',[_c('use',{attrs:{"href":"#icon-arrow-left"}})])]):_c('span',{staticClass:"sx-arrow text-color-grey-400"},[_c('svg',[_c('use',{attrs:{"href":"#icon-arrow-left"}})])]),_c('hr',{staticClass:"separator"}),_c('div',{staticClass:"pages a-cta"},_vm._l((_vm.propPages),function(item,index){return _c(_vm.dataTag,{key:("page-" + index + "-" + _vm._uid),tag:"component",class:['pageItem', { active: _vm.activePageIndex === index }],attrs:{"href":_vm.dataEmit ? null : _vm.dataTag === 'a' ? item.href : null,"to":_vm.dataTag === 'router-link' ? item.href : null,"target":_vm.dataTag === 'a' ? item.target : null},on:{"click":function($event){_vm.dataEmit ? _vm.$emit('click', item) : (_vm.activePageIndex = index)}}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1),_c('hr',{staticClass:"separator"}),(_vm.nextPage)?_c(_vm.dataTag,{tag:"component",staticClass:"dx-arrow",attrs:{"href":_vm.dataEmit ? null : _vm.dataTag === 'a' ? _vm.nextPage.href : null,"to":_vm.dataTag === 'router-link' ? _vm.nextPage.href : null,"target":_vm.dataTag === 'a' ? _vm.nextPage.target : null},on:{"click":function($event){_vm.dataEmit
        ? _vm.$emit('click', _vm.nextPage)
        : _vm.dataTag === 'router-link'
        ? _vm.activePageIndex++
        : null}}},[_c('svg',[_c('use',{attrs:{"href":"#icon-arrow-right"}})])]):_c('span',{staticClass:"dx-arrow text-color-grey-400"},[_c('svg',[_c('use',{attrs:{"href":"#icon-arrow-right"}})])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }