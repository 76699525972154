<template>
  <section class="CarouselBrandsCmp">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <div class="title-wrapper n-heading-2 text-color-blue text-center">
            <slot name="title" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <slider-cmp-extended
            :data-bootstrap-container-classes="['container-fluid', 'px-0']"
            :data-component-slides="true"
            :data-options="{
              threshold: 5,
              observer: true,
              slidesPerView: 2,
              slidesPerGroup: 2,
              navigation: false,
              breakpoints: {
                768: {
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                },
                1440: {
                  slidesPerView: 4,
                  slidesPerGroup: 4,
                },
              },
            }"
          >
            <slot slot="custom-swipe-icon" name="custom-swipe-icon" />
            <template slot="components">
              <slot name="carousel" />
            </template>
          </slider-cmp-extended>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SliderCmpExtended from './slider-cmp-extended';

export default {
  name: 'CarouselBrandsCmp',
  components: { SliderCmpExtended },
};
</script>

<style lang="scss" scoped>
.CarouselBrandsCmp {
  .title-wrapper {
    padding-bottom: 40px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 80px;
    }
  }

  ::v-deep .SliderCmpExtended {
    --slider-container-overflow: visible;

    .slider-content {
      padding-bottom: 82px;

      @include media-breakpoint-up(lg) {
        padding-bottom: 122px;
      }

      .swiper-container {
        .swiper-wrapper {
          .FigureCardCmp {
            height: 53px;

            @include media-breakpoint-up(md) {
              height: 93px;
            }

            @include media-breakpoint-up(lg) {
              height: 133px;
            }

            @include media-breakpoint-up(xl) {
              height: 140px;
            }
          }
        }

        .swiper-pagination {
          padding: 55px 0 15px;

          @include media-breakpoint-up(lg) {
            padding: 95px 0 15px;
          }
        }
      }
    }
  }
}
</style>
