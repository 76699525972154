var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-cmp',{staticClass:"ContactFormFiscalInfoCmp"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-10 col-xl-8"},[_c('div',{staticClass:"row"},[(
            _vm.dataCurrentStep.content.businessName &&
            _vm.dataCurrentStep.content.businessName.active
          )?_c('div',{staticClass:"col-12 col-md-6"},[_c('input-text-cmp-extended',{attrs:{"data-name":"businessName","data-placeholder":_vm.dataCurrentStep.content.businessName.placeholder,"data-error-occurred":_vm.$v.businessName.$error,"data-errors":_vm.dataCurrentStep.content.businessName.error.map(function (e) { return ({
                condition: !_vm.$v.businessName[e.condition],
                message: e.message,
              }); }).concat( [{
                condition: !_vm.$v.businessName.serverError,
                message: _vm.serverErrors.businessName,
              }] )},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.dataCurrentStep.content.businessName.label)+" ")]},proxy:true}],null,false,233237085),model:{value:(_vm.$v.businessName.$model),callback:function ($$v) {_vm.$set(_vm.$v.businessName, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.businessName.$model"}})],1):_vm._e(),(
            _vm.dataCurrentStep.content.vatNumber &&
            _vm.dataCurrentStep.content.vatNumber.active
          )?_c('div',{staticClass:"col-12 col-md-6"},[_c('input-text-cmp-extended',{attrs:{"data-name":"vatNumber","data-placeholder":_vm.dataCurrentStep.content.vatNumber.placeholder,"data-error-occurred":_vm.$v.vatNumber.$error,"data-errors":_vm.dataCurrentStep.content.vatNumber.error.map(function (e) { return ({
                condition: !_vm.$v.vatNumber[e.condition],
                message: e.message,
              }); }).concat( [{
                condition: !_vm.$v.vatNumber.serverError,
                message: _vm.serverErrors.vatNumber,
              }] )},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.dataCurrentStep.content.vatNumber.label)+" ")]},proxy:true}],null,false,1252462300),model:{value:(_vm.$v.vatNumber.$model),callback:function ($$v) {_vm.$set(_vm.$v.vatNumber, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.vatNumber.$model"}})],1):_vm._e(),(
            _vm.dataCurrentStep.content.city &&
            _vm.dataCurrentStep.content.city.active
          )?_c('div',{staticClass:"col-12 col-md-4"},[_c('input-text-cmp-extended',{attrs:{"data-name":"city","data-placeholder":_vm.dataCurrentStep.content.city.placeholder,"data-error-occurred":_vm.$v.city.$error,"data-errors":_vm.dataCurrentStep.content.city.error.map(function (e) { return ({
                condition: !_vm.$v.city[e.condition],
                message: e.message,
              }); }).concat( [{
                condition: !_vm.$v.city.serverError,
                message: _vm.serverErrors.city,
              }] )},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.dataCurrentStep.content.city.label)+" ")]},proxy:true}],null,false,1056198139),model:{value:(_vm.$v.city.$model),callback:function ($$v) {_vm.$set(_vm.$v.city, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.city.$model"}})],1):_vm._e(),(
            _vm.dataCurrentStep.content.address &&
            _vm.dataCurrentStep.content.address.active
          )?_c('div',{staticClass:"col-12 col-md-6"},[_c('input-text-cmp-extended',{attrs:{"data-name":"address","data-placeholder":_vm.dataCurrentStep.content.address.placeholder,"data-error-occurred":_vm.$v.address.$error,"data-errors":_vm.dataCurrentStep.content.address.error.map(function (e) { return ({
                condition: !_vm.$v.address[e.condition],
                message: e.message,
              }); }).concat( [{
                condition: !_vm.$v.address.serverError,
                message: _vm.serverErrors.address,
              }] )},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.dataCurrentStep.content.address.label)+" ")]},proxy:true}],null,false,3920194858),model:{value:(_vm.$v.address.$model),callback:function ($$v) {_vm.$set(_vm.$v.address, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.address.$model"}})],1):_vm._e(),(
            _vm.dataCurrentStep.content.houseNumber &&
            _vm.dataCurrentStep.content.houseNumber.active
          )?_c('div',{staticClass:"col-6 col-md-2"},[_c('input-text-cmp-extended',{attrs:{"data-name":"houseNumber","data-placeholder":_vm.dataCurrentStep.content.houseNumber.placeholder,"data-error-occurred":_vm.$v.houseNumber.$error,"data-errors":_vm.dataCurrentStep.content.houseNumber.error.map(function (e) { return ({
                condition: !_vm.$v.houseNumber[e.condition],
                message: e.message,
              }); }).concat( [{
                condition: !_vm.$v.houseNumber.serverError,
                message: _vm.serverErrors.houseNumber,
              }] )},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.dataCurrentStep.content.houseNumber.label)+" ")]},proxy:true}],null,false,1923640475),model:{value:(_vm.$v.houseNumber.$model),callback:function ($$v) {_vm.$set(_vm.$v.houseNumber, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.houseNumber.$model"}})],1):_vm._e(),(
            _vm.dataCurrentStep.content.postalCode &&
            _vm.dataCurrentStep.content.postalCode.active
          )?_c('div',{staticClass:"col-6"},[_c('input-text-cmp-extended',{attrs:{"data-name":"postalCode","data-placeholder":_vm.dataCurrentStep.content.postalCode.placeholder,"data-error-occurred":_vm.$v.postalCode.$error,"data-errors":_vm.dataCurrentStep.content.postalCode.error.map(function (e) { return ({
                condition: !_vm.$v.postalCode[e.condition],
                message: e.message,
              }); }).concat( [{
                condition: !_vm.$v.postalCode.serverError,
                message: _vm.serverErrors.postalCode,
              }] )},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.dataCurrentStep.content.postalCode.label)+" ")]},proxy:true}],null,false,1039488644),model:{value:(_vm.$v.postalCode.$model),callback:function ($$v) {_vm.$set(_vm.$v.postalCode, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.postalCode.$model"}})],1):_vm._e(),(
            _vm.dataCurrentStep.content.country &&
            _vm.dataCurrentStep.content.country.active
          )?_c('div',{staticClass:"col-12 col-md-6"},[_c('select-cmp-extended',{attrs:{"data-name":"country","data-option-list":_vm.countryList,"data-placeholder":_vm.dataCurrentStep.content.country.placeholder,"data-error-occurred":_vm.$v.country.$error,"data-errors":_vm.dataCurrentStep.content.country.error.map(function (e) { return ({
                condition: !_vm.$v.country[e.condition],
                message: e.message,
              }); }).concat( [{
                condition: !_vm.$v.country.serverError,
                message: _vm.serverErrors.country,
              }] )},on:{"change":function($event){_vm.province = ''}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.dataCurrentStep.content.country.label)+" ")]},proxy:true},{key:"icon",fn:function(){return [_c('svg',[_c('use',{attrs:{"href":"#icon-caret-down"}})])]},proxy:true}],null,false,3637507974),model:{value:(_vm.$v.country.$model),callback:function ($$v) {_vm.$set(_vm.$v.country, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.country.$model"}})],1):_vm._e(),(
            _vm.dataCurrentStep.content.province &&
            _vm.dataCurrentStep.content.province.active
          )?_c('div',{staticClass:"col-12 col-md-6"},[(_vm.country === 'IT' || _vm.country === 'FR' || _vm.country === 'DE')?[_c('select-cmp-extended',{attrs:{"data-name":"province","data-option-list":_vm.country === 'IT'
                  ? _vm.provinceItList
                  : _vm.country === 'FR'
                  ? _vm.provinceFrList
                  : _vm.country === 'DE'
                  ? _vm.provinceDeList
                  : [],"data-placeholder":_vm.dataCurrentStep.content.province.placeholder,"data-error-occurred":_vm.$v.province.$error,"data-errors":_vm.dataCurrentStep.content.province.error.map(function (e) { return ({
                  condition: !_vm.$v.province[e.condition],
                  message: e.message,
                }); }).concat( [{
                  condition: !_vm.$v.province.serverError,
                  message: _vm.serverErrors.province,
                }] )},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.dataCurrentStep.content.province.label)+" ")]},proxy:true},{key:"icon",fn:function(){return [_c('svg',[_c('use',{attrs:{"href":"#icon-caret-down"}})])]},proxy:true}],null,false,2680366548),model:{value:(_vm.$v.province.$model),callback:function ($$v) {_vm.$set(_vm.$v.province, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.province.$model"}})]:[_c('input-text-cmp-extended',{attrs:{"data-name":"province","data-placeholder":_vm.dataCurrentStep.content.province.placeholder,"data-error-occurred":_vm.$v.province.$error,"data-errors":_vm.dataCurrentStep.content.province.error.map(function (e) { return ({
                  condition: !_vm.$v.province[e.condition],
                  message: e.message,
                }); }).concat( [{
                  condition: !_vm.$v.province.serverError,
                  message: _vm.serverErrors.province,
                }] )},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.dataCurrentStep.content.province.label)+" ")]},proxy:true}],null,false,3356181926),model:{value:(_vm.$v.province.$model),callback:function ($$v) {_vm.$set(_vm.$v.province, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.province.$model"}})]],2):_vm._e(),(
            _vm.dataCurrentStep.content.distributorName &&
            _vm.dataCurrentStep.content.distributorName.active
          )?_c('div',{staticClass:"col-12 col-md-6"},[_c('input-text-cmp-extended',{attrs:{"data-name":"distributorName","data-placeholder":_vm.dataCurrentStep.content.distributorName.placeholder,"data-error-occurred":_vm.$v.distributorName.$error,"data-errors":_vm.dataCurrentStep.content.distributorName.error.map(function (e) { return ({
                condition: !_vm.$v.distributorName[e.condition],
                message: e.message,
              }); }).concat( [{
                condition: !_vm.$v.distributorName.serverError,
                message: _vm.serverErrors.distributorName,
              }] )},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.dataCurrentStep.content.distributorName.label)+" ")]},proxy:true}],null,false,3218788884),model:{value:(_vm.$v.distributorName.$model),callback:function ($$v) {_vm.$set(_vm.$v.distributorName, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.distributorName.$model"}})],1):_vm._e(),(
            _vm.dataCurrentStep.content.distributorCountry &&
            _vm.dataCurrentStep.content.distributorCountry.active
          )?_c('div',{staticClass:"col-12 col-md-6"},[_c('select-cmp-extended',{attrs:{"data-name":"distributorCountry","data-option-list":_vm.countryList,"data-placeholder":_vm.dataCurrentStep.content.distributorCountry.placeholder,"data-error-occurred":_vm.$v.distributorCountry.$error,"data-errors":_vm.dataCurrentStep.content.distributorCountry.error.map(
                function (e) { return ({
                  condition: !_vm.$v.distributorCountry[e.condition],
                  message: e.message,
                }); }
              ).concat( [{
                condition: !_vm.$v.distributorCountry.serverError,
                message: _vm.serverErrors.distributorCountry,
              }] )},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.dataCurrentStep.content.distributorCountry.label)+" ")]},proxy:true},{key:"icon",fn:function(){return [_c('svg',[_c('use',{attrs:{"href":"#icon-caret-down"}})])]},proxy:true}],null,false,1233143177),model:{value:(_vm.$v.distributorCountry.$model),callback:function ($$v) {_vm.$set(_vm.$v.distributorCountry, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.distributorCountry.$model"}})],1):_vm._e(),(
            _vm.dataCurrentStep.content.distributorCity &&
            _vm.dataCurrentStep.content.distributorCity.active
          )?_c('div',{staticClass:"col-12 col-md-6"},[_c('input-text-cmp-extended',{attrs:{"data-name":"distributorCity","data-placeholder":_vm.dataCurrentStep.content.distributorCity.placeholder,"data-error-occurred":_vm.$v.distributorCity.$error,"data-errors":_vm.dataCurrentStep.content.distributorCity.error.map(function (e) { return ({
                condition: !_vm.$v.distributorCity[e.condition],
                message: e.message,
              }); }).concat( [{
                condition: !_vm.$v.distributorCity.serverError,
                message: _vm.serverErrors.distributorCity,
              }] )},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.dataCurrentStep.content.distributorCity.label)+" ")]},proxy:true}],null,false,110116948),model:{value:(_vm.$v.distributorCity.$model),callback:function ($$v) {_vm.$set(_vm.$v.distributorCity, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.distributorCity.$model"}})],1):_vm._e(),(
            _vm.dataCurrentStep.content.privacy &&
            _vm.dataCurrentStep.content.privacy.active
          )?_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"privacy-wrapper",domProps:{"innerHTML":_vm._s(_vm.dataCurrentStep.content.privacy.label)}}),_c('input-radio-cmp-extended',{staticClass:"custom direction-column",attrs:{"data-name":"privacy","data-aria-label":"Privacy treatment","data-radio-list":_vm.dataCurrentStep.content.privacy.radioList,"data-error-occurred":_vm.$v.privacy.$error,"data-errors":_vm.dataCurrentStep.content.privacy.error.map(function (e) { return ({
                condition: !_vm.$v.privacy[e.condition],
                message: e.message,
              }); }).concat( [{
                condition: !_vm.$v.privacy.serverError,
                message: _vm.serverErrors.privacy,
              }] )},model:{value:(_vm.$v.privacy.$model),callback:function ($$v) {_vm.$set(_vm.$v.privacy, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.privacy.$model"}})],1):_vm._e()]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-auto"},[_c('recaptcha-cmp',{attrs:{"data-sitekey":_vm.recaptchaApiKey,"data-aria-label":"recaptcha","data-error-occurred":_vm.$v.recaptcha.$error,"data-errors":_vm.dataCurrentStep.content.recaptcha.error.map(function (e) { return ({
                condition: !_vm.$v.recaptcha[e.condition],
                message: e.message,
              }); }).concat( [{
                condition: !_vm.$v.recaptcha.serverError,
                message: _vm.serverErrors.recaptcha,
              }] )},on:{"verify":_vm.recaptchaVerify}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-center"},[_c('submit-cmp-extended',{attrs:{"data-theme-classes":['reversed'],"data-aria-label":_vm.dataCurrentStep.content.submitCtaAriaLabel,"data-loading":_vm.loading,"data-disabled":_vm.disableSubmit || _vm.submitted,"data-error-occurred":!!_vm.globalErrors.length || !!Object.keys(_vm.serverErrors).length,"data-errors":_vm.globalErrors},on:{"click-submit":_vm.onSubmitForm},scopedSlots:_vm._u([{key:"after-icon",fn:function(){return [_c('svg',[_c('use',{attrs:{"href":"#icon-arrow-right"}})])]},proxy:true}])},[[_vm._v(_vm._s(_vm.dataCurrentStep.content.submitCtaText))]],2)],1),(_vm.dataCurrentStep.content.footnote)?_c('div',{staticClass:"col-12"},[_c('span',{staticClass:"a-caption text-color-grey-600"},[_vm._v(" "+_vm._s(_vm.dataCurrentStep.content.footnote)+" ")])]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }