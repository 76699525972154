<template>
  <div class="MySpaExamplePage">
    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
export default {
  name: 'MySpaExamplePage',
  components: {},
  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch('setLoading', false);
    });
  },
};
</script>

<style lang="scss" scoped></style>
