<template>
  <div class="ContactFormBackCtaCmp text-center pb-l">
    <cta-button-cmp-extended
      class="btn-text"
      data-tag="router-link"
      :data-to="{ name: dataPrev }"
      data-aria-label=""
    >
      <template slot="before-icon">
        <svg>
          <use href="#icon-caret-left" />
        </svg>
      </template>
      <template>
        <slot />
      </template>
    </cta-button-cmp-extended>
  </div>
</template>

<script>
export default {
  name: 'ContactFormBackCtaCmp',
  props: {
    dataPrev: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped></style>
