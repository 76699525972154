/* Store initialization */
export const SET_APP_READY = 'SET_APP_READY';
export const SET_LOADING = 'SET_LOADING';
export const SET_UNLOCKED_VIEW = 'SET_UNLOCKED_VIEW';
export const SET_PROPERTIES = 'SET_PROPERTIES';
export const SET_STORE_SETUP_COMPLETED = 'SET_STORE_SETUP_COMPLETED';

/* Config */
export const SET_CONFIG_PROPERTY = 'SET_CONFIG_PROPERTY';
export const SET_CONFIG_PROPERTY_WITH_TARGET =
  'SET_CONFIG_PROPERTY_WITH_TARGET';

/* Contact */
export const SET_READY = 'SET_READY';
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const SET_REACHED_VIEW = 'SET_REACHED_VIEW';
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const SET_CUSTOMER_TYPE = 'SET_CUSTOMER_TYPE';
export const GET_REASONS = 'GET_REASONS';
export const SET_REASON_TYPE = 'SET_REASON_TYPE';
export const SET_GA4ID = 'SET_GA4ID';
export const SET_STEP_DATA = 'SET_STEP_DATA';

/* Gigya */
export const SET_GIGYA_INIT_DATA = 'SET_GIGYA_INIT_DATA';
export const SET_GIGYA_GO_TO = 'SET_GIGYA_GO_TO';
export const SET_GIGYA_LOADED = 'SET_GIGYA_LOADED';
export const SET_GIGYA_USER = 'SET_GIGYA_USER';
export const SET_GIGYA_USER_DATA = 'SET_GIGYA_USER_DATA';
export const SET_GIGYA_PREFERENCES = 'SET_GIGYA_PREFERENCES';
export const SET_OT_ACTIVE_GROUPS = 'SET_OT_ACTIVE_GROUPS';
export const SET_CURRENT_SCREEN = 'SET_CURRENT_SCREEN';

/* Recipes */
export const ADD_PAGE = 'ADD_PAGE';
export const RESET_PAGE = 'RESET_PAGE';
export const UPDATE_PAGINATION = 'UPDATE_PAGINATION';

/* News and Media */
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_TOPICS = 'SET_TOPICS';
export const SET_FILTERS = 'SET_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';
export const CONFIG_PAGES = 'CONFIG_PAGES';
export const SET_PAGES_LOADING = 'SET_PAGES_LOADING';
export const SET_PAGES = 'SET_PAGES';
export const RESET_PAGES = 'RESET_PAGES';
