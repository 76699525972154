import * as types from '../mutation_types';

export default {
  [types.SET_READY](state, payload) {
    state.ready = payload;
  },
  [types.SET_CURRENT_STEP](state, payload) {
    state.currentStep = {
      ...payload,
      content: state.contentData[payload.content],
    };
  },
  [types.SET_REACHED_VIEW](state, payload) {
    state.reachedView = payload;
  },
  [types.GET_CUSTOMERS](state, payload) {
    state.contentData.home.customers = payload;
  },
  [types.SET_CUSTOMER_TYPE](state, payload) {
    state.chosenData.customerType = payload;
  },
  [types.GET_REASONS](state, payload) {
    state.contentData['contact-reasons'].reasons = payload;
  },
  [types.SET_REASON_TYPE](state, payload) {
    state.chosenData.reasonType = payload;
  },
  [types.SET_GA4ID](state, payload) {
    state.chosenData.ga4id = payload;
  },
  [types.SET_STEP_DATA](state, payload) {
    state.chosenData[payload.key] = payload.value;
  },
};
