import '../assets';
import Vue from 'vue';
import store from '@/store';
import { getMountPointId } from '@/helpers/utils.js';
import '@/helpers/common-views-initializer.js';
import { CmsEntrypoints } from '@/mixins/cms-entrypoints.js';

if (window.CMS) {
  if (window.VueVM) {
    delete window.VueVM;
  }
}

// DO NOT DEFINE TEMPLATE OR RENDER HERE!
// YOU MUST DEFINE THE TEMPLATE INSIDE THE TEMPLATE FILE
const VM = new Vue({
  mixins: [CmsEntrypoints],
  store: store,
}).$mount(`#${getMountPointId()}`);

if (window.CMS) {
  window.VueVM = VM;
  //console.log('[window.VueVM OK]');
}
