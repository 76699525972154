var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['SideBySideCmp', { intersected: _vm.intersected }]},[_c('div',{staticClass:"container"},[_c('div',{class:[
        'row flex-column flex-md-row align-items-md-center position-relative' ]},[(_vm.themeVariant.includes('image-left') || _vm.xsOnly)?_c('div',{class:[
          'col-12',
          {
            'col-md-5': !_vm.themeVariant.includes('with-carousel'),
            'col-md-6 col-lg-5': _vm.themeVariant.includes('with-carousel'),
            'col-lg-6': _vm.themeVariant.includes('image-sticky'),
            'offset-md-1':
              !_vm.themeVariant.includes('image-sticky') &&
              !_vm.themeVariant.includes('with-carousel'),
            'offset-lg-1': _vm.themeVariant.includes('with-carousel'),
          } ]},[_c('div',{staticClass:"image-container"},[(_vm.themeVariant.includes('with-carousel'))?_c('slider-cmp-extended',{attrs:{"data-bootstrap-container-classes":['container-fluid', 'px-0'],"data-component-slides":true,"data-options":{
              observer: true,
              slidesPerView: 1,
              navigation: false,
              enabled: _vm.$slots.carousel.length === 1 ? false : true,
            }}},[_c('template',{slot:"custom-swipe-icon"},[_c('svg',[_c('use',{attrs:{"href":"#icon-scroller"}})])]),_c('template',{slot:"components"},[_vm._t("carousel")],2)],2):_c('figure',{class:['side-image' ].concat( _vm.dataImageThemeClasses)},[_vm._t("image")],2)],1)]):_vm._e(),_c('div',{class:[
          'col-12',
          {
            'col-md-6 col-lg-5': _vm.themeVariant.includes('image-sticky'),
            'col-md-5':
              !_vm.themeVariant.includes('image-sticky') &&
              !_vm.themeVariant.includes('with-carousel'),
            'col-md-6 col-lg-5 col-xl-4 offset-xl-1':
              _vm.themeVariant.includes('with-carousel'),
            'offset-md-1':
              _vm.themeVariant.includes('image-right') &&
              !_vm.themeVariant.includes('with-carousel'),
            'offset-lg-1':
              _vm.themeVariant.includes('image-right') &&
              _vm.themeVariant.includes('with-carousel'),
          } ]},[_c('div',{staticClass:"text-container"},[_vm._t("default")],2)]),(_vm.themeVariant.includes('image-right') && _vm.mdAndUp)?_c('div',{class:[
          {
            'col-md-5': !_vm.themeVariant.includes('with-carousel'),
            'col-md-6 col-lg-5 offset-xl-1':
              _vm.themeVariant.includes('with-carousel'),
            'col-lg-6': _vm.themeVariant.includes('image-sticky'),
          } ]},[_c('div',{staticClass:"image-container"},[(_vm.themeVariant.includes('with-carousel'))?_c('slider-cmp-extended',{attrs:{"data-bootstrap-container-classes":['container-fluid', 'px-0'],"data-component-slides":true,"data-options":{
              observer: true,
              slidesPerView: 1,
              navigation: false,
              enabled: _vm.$slots.carousel.length === 1 ? false : true,
            }}},[_c('template',{slot:"custom-swipe-icon"},[_c('svg',[_c('use',{attrs:{"href":"#icon-scroller"}})])]),_c('template',{slot:"components"},[_vm._t("carousel")],2)],2):_c('figure',{class:['side-image' ].concat( _vm.dataImageThemeClasses)},[_vm._t("image")],2)],1)]):_vm._e(),(_vm.themeVariant.includes('animated'))?_c('div',{directives:[{name:"intersect",rawName:"v-intersect.once",value:(_vm.onIntersect),expression:"onIntersect",modifiers:{"once":true}}],staticClass:"intersect"}):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }