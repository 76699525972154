import { render, staticRenderFns } from "./recipes-footer-cmp.vue?vue&type=template&id=d13f7e5e&scoped=true&functional=true"
import script from "./recipes-footer-cmp.vue?vue&type=script&lang=js"
export * from "./recipes-footer-cmp.vue?vue&type=script&lang=js"
import style0 from "./recipes-footer-cmp.vue?vue&type=style&index=0&id=d13f7e5e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "d13f7e5e",
  null
  
)

export default component.exports