<template>
  <section class="CarouselProductBannersCmp">
    <div :class="['container', { 'px-0': xsOnly }]">
      <div class="row justify-content-center">
        <div class="col-12">
          <slider-cmp-extended
            :data-bootstrap-container-classes="['container-fluid', 'px-0']"
            :data-component-slides="true"
            :data-options="{
              observer: true,
              navigation: false,
            }"
          >
            <slot slot="custom-swipe-icon" name="custom-swipe-icon" />
            <template slot="components">
              <slot name="carousel" />
            </template>
          </slider-cmp-extended>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SliderCmpExtended from './slider-cmp-extended';
import { xsOnly } from '@/helpers/breakpoints';

export default {
  name: 'CarouselProductBannersCmp',
  components: { SliderCmpExtended },
  setup() {
    return { xsOnly };
  },
};
</script>

<style lang="scss" scoped>
.CarouselProductBannersCmp {
  ::v-deep .SliderCmpExtended {
    .slider-content {
      padding-bottom: 82px;

      .swiper-container {
        background-color: $color-blue-10;

        @include media-breakpoint-up(md) {
          background-color: transparent;
        }

        .swiper-wrapper {
          .swiper-slide {
            height: auto;

            .SideBySideCmp {
              height: 100%;
              padding-top: 40px;
              padding-bottom: 40px;
              background-color: $color-blue-10;

              @include media-breakpoint-up(md) {
                border-radius: 8px;
              }

              .container {
                @include media-breakpoint-up(md) {
                  width: 100%;
                  max-width: none;
                  padding: 0;
                }
              }
            }
          }
        }

        .swiper-pagination {
          padding: 55px 0 15px;
        }
      }
    }
  }
}
</style>
