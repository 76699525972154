import { render, staticRenderFns } from "./cooking-tips-materials-page.vue?vue&type=template&id=22595901&scoped=true"
import script from "./cooking-tips-materials-page.vue?vue&type=script&lang=js"
export * from "./cooking-tips-materials-page.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22595901",
  null
  
)

export default component.exports