<template>
  <title-format-cmp class="ContactFormStepperCmp pb-l">
    <title-text-cmp>
      <template slot="overtitle">
        <span>
          <slot name="step-label" />
          {{ currentStepNavigation }}/{{ totalStepNavigation }}
        </span>
      </template>
      <template slot="title">
        <slot name="title" />
      </template>
      <template slot="text">
        <slot name="text" />
      </template>
    </title-text-cmp>
  </title-format-cmp>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ContactFormStepperCmp',
  computed: {
    ...mapGetters('contact', ['currentStepNavigation', 'totalStepNavigation']),
  },
};
</script>

<style scoped></style>
