var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"CarouselImagesCmp"},[_c('lightbox-cmp',{attrs:{"data-fullscreen":_vm.fullscreen}},[[_c('slider-cmp-extended',{ref:"$sliderInPage",attrs:{"data-bootstrap-row-classes":['row justify-content-center'],"data-bootstrap-col-classes":['col-12 col-md-6'],"data-component-slides":true,"data-disabled-from":_vm.dataDisabledFrom >= 0 ? _vm.dataDisabledFrom : null,"data-options":{
          observer: true,
          slidesPerView: 1,
          navigation: false,
        }},on:{"active-index-change":_vm.onIndexChange,"zoom-in":function($event){_vm.fullscreen = true}}},[_c('template',{slot:"custom-swipe-icon"},[_c('svg',[_c('use',{attrs:{"href":"#icon-scroller"}})])]),_c('template',{slot:"components"},[_vm._t("carousel")],2)],2)],_c('template',{slot:"lightbox"},[_c('slider-cmp-extended',{ref:"$sliderInOverlay",staticClass:"fullscreen-mode",attrs:{"data-bootstrap-row-classes":['row justify-content-center'],"data-bootstrap-col-classes":['col-12 col-md-10'],"data-component-slides":true,"data-disabled-from":_vm.dataDisabledFrom >= 0 ? _vm.dataDisabledFrom : null,"data-options":{
          observer: true,
          slidesPerView: 1,
          initialSlide: _vm.currentIndex,
        }},on:{"active-index-change":_vm.onIndexChange,"zoom-out":function($event){_vm.fullscreen = false}}},[_c('template',{slot:"custom-swipe-icon"},[_c('svg',[_c('use',{attrs:{"href":"#icon-scroller"}})])]),_c('template',{slot:"zoom-out"},[_c('svg',[_c('use',{attrs:{"href":"#icon-zoom-out"}})])]),_c('template',{slot:"components"},[_vm._t("carousel-lightbox")],2)],2)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }