<template>
  <div class="IconTextInfoCmp">
    <div class="wrapper">
      <div v-if="hasSlot($slots.icon, $scopedSlots.icon)" class="icon-wrapper">
        <slot name="icon" />
      </div>
      <div
        :class="[
          'info-wrapper',
          {
            'title-only':
              !hasSlot($slots.number, $scopedSlots.number) &&
              !hasSlot($slots.text, $scopedSlots.text),
          },
        ]"
      >
        <div
          v-if="hasSlot($slots.title, $scopedSlots.title)"
          class="title-wrapper"
        >
          <slot name="title" />
        </div>
        <div v-if="!!dataUnitValue" class="number-unit-wrapper">
          <div class="number-wrapper">
            {{
              customDecimalSeparator(
                dataUnitValue,
                $store.state.config.decimal_separator
              )
            }}
          </div>
          <div
            v-if="hasSlot($slots.unit, $scopedSlots.unit)"
            class="unit-wrapper"
          >
            <slot name="unit" />
          </div>
        </div>
        <div
          v-if="hasSlot($slots.text, $scopedSlots.text)"
          class="text-wrapper"
        >
          <slot name="text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { hasSlot } from '@/helpers/cms-support';
import { customDecimalSeparator } from '@/helpers/utils';

export default {
  name: 'IconTextInfoCmp',
  props: {
    dataUnitValue: {
      Type: [String, Number],
      default: null,
    },
  },
  setup() {
    return { hasSlot, customDecimalSeparator };
  },
};
</script>

<style lang="scss" scoped>
.IconTextInfoCmp {
  .wrapper {
    display: flex;
    flex-direction: var(--icon-text-info-flex-direction, row);
    padding: var(--icon-text-info-padding, 24px);

    .icon-wrapper {
      align-self: var(--icon-text-icon-wrapper-align-self, auto);
      color: $color-blue;
      padding: var(--icon-text-icon-wrapper-padding, 0 16px 0 0);

      ::v-deep svg,
      ::v-deep img {
        width: var(--icon-text-icon-wrapper-icon-width, 48px);
        height: var(--icon-text-icon-wrapper-icon-height, 48px);
      }
    }

    .info-wrapper {
      display: flex;
      flex-direction: column;

      .title-wrapper {
        color: var(--icon-text-info-title-wrapper-color, $color-blue);
        @include responsive-typography-properties(
          $font-primary,
          a-paragraph-3,
          --icon-text-info-title-wrapper
        );
      }

      &.title-only {
        justify-content: center;

        .title-wrapper {
          @include override-responsive-typography-properties(
            $font-primary,
            a-paragraph-2,
            --icon-text-info-title-wrapper
          );
        }
      }

      .number-unit-wrapper {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        color: $color-black;

        .number-wrapper {
          padding: 0 4px 0 0;
          white-space: nowrap;
          @include responsive-typography-properties(
            $font-secondary,
            n-paragraph-1
          );
        }

        .unit-wrapper {
          flex-shrink: 0;
          white-space: nowrap;
          @include responsive-typography-properties(
            $font-primary,
            a-paragraph-2
          );
        }
      }

      .text-wrapper {
        @include responsive-typography-properties($font-primary, a-paragraph-3);
        padding: var(--icon-text-info-text-wrapper-padding, 0);
        color: var(--icon-text-info-text-wrapper-color, $color-grey-800);
      }
    }
  }

  &.detailed {
    .number-unit-wrapper {
      flex-direction: column;
      align-items: flex-start !important;

      .unit-wrapper {
        padding: 4px 0 0;
      }
    }
  }
}
</style>
