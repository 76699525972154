import * as types from './mutation_types';

export default {
  [types.SET_APP_READY](state, value) {
    state.appReady = value;
  },
  [types.SET_LOADING](state, value) {
    if (value) {
      state.loading.push(value);
    } else {
      state.loading.pop();
    }
  },
  [types.SET_UNLOCKED_VIEW](state, value) {
    state.unlockedView = value;
  },
  [types.SET_PROPERTIES](state, payload) {
    if (payload.module) {
      state[payload.module][payload.property] = payload.value;
    } else {
      state[payload.property] = payload.value;
    }
  },
};
