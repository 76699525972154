<template>
  <figure class="MainImageCmp">
    <button
      v-if="hasSlot($slots['zoom-in'], $scopedSlots['zoom-in'])"
      type="button"
      class="zoom-in"
    >
      <slot name="zoom-in"></slot>
    </button>
    <slot />
    <figcaption
      v-if="hasSlot($slots.figcaption, $scopedSlots.figcaption)"
      class="a-paragraph-3"
    >
      <slot name="figcaption"></slot>
    </figcaption>
  </figure>
</template>

<script>
import { hasSlot } from '@/helpers/cms-support';

export default {
  name: 'MainImageCmp',
  setup() {
    return { hasSlot };
  },
};
</script>

<style lang="scss" scoped>
.MainImageCmp {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  ::v-deep .zoom-in {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 1;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;

    svg {
      width: 80px;
      height: 80px;
      color: $color-blue;
    }
  }

  ::v-deep img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }

  figcaption {
    color: $color-grey-900-75;
    padding-top: 24px;
    opacity: 1;
    transition: opacity 0.6s ease-in-out, height 0.3s ease 0.6s,
      padding-top 0.3s ease 0.6s;

    @include media-breakpoint-up(md) {
      padding-top: 40px;
    }
  }
}
</style>
