<template>
  <div class="ChefsNoteBannerCmp">
    <div class="banner">
      <div class="row justify-content-center">
        <div class="col-12 col-custom-md-8-10 content-wrapper">
          <figure
            v-if="themeVariant === 'with-picture'"
            class="picture-wrapper"
          >
            <slot name="picture" />
          </figure>
          <div class="note-wrapper">
            <div class="title-separator-wrapper">
              <slot name="title-separator" />
            </div>
            <div class="text-wrapper a-paragraph-2">
              <slot name="text" />
            </div>
            <div class="author-wrapper a-paragraph-3">
              <slot name="author" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChefsNoteBannerCmp',
  data() {
    return {
      themeVariant: null,
    };
  },
  mounted() {
    this.themeVariant = this.$el.classList.contains('with-picture')
      ? 'with-picture'
      : 'default';
  },
};
</script>

<style lang="scss" scoped>
.ChefsNoteBannerCmp {
  .banner {
    padding: 16px;
    background-color: $color-blue;
    border-radius: 8px;

    @include media-breakpoint-up(md) {
      padding: 56px 0;
    }

    @include media-breakpoint-up(lg) {
      padding: 80px 0;
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;

      .note-wrapper {
        .title-separator-wrapper {
          @include override-responsive-typography-properties(
            $font-secondary,
            n-heading-3,
            --title-separator-title-wrapper
          );
          --title-separator-color: #{$color-white};
        }

        .text-wrapper {
          color: $color-white;
          padding-top: 16px;
        }

        .author-wrapper {
          color: $color-blue-10;
          padding-top: 24px;
        }
      }
    }
  }

  &.with-picture {
    .banner {
      .content-wrapper {
        gap: 16px;

        @include media-breakpoint-up(md) {
          gap: 40px;
        }

        @include media-breakpoint-up(lg) {
          display: flex;
          flex-direction: row;
        }

        .picture-wrapper {
          flex: 0;

          @include media-breakpoint-up(lg) {
            order: 1;
          }

          img {
            width: 120px;
            height: 120px;
            border-radius: 8px;

            @include media-breakpoint-up(md) {
              width: 160px;
              height: 160px;
            }

            @include media-breakpoint-up(lg) {
              width: 240px;
              height: 240px;
            }
          }
        }

        .note-wrapper {
          flex: 1 0 0;
        }
      }
    }
  }
}
</style>
