import { render, staticRenderFns } from "./aspect-ratio-box-cmp.vue?vue&type=template&id=6cad2a94&scoped=true"
import script from "./aspect-ratio-box-cmp.vue?vue&type=script&lang=js"
export * from "./aspect-ratio-box-cmp.vue?vue&type=script&lang=js"
import style0 from "./aspect-ratio-box-cmp.vue?vue&type=style&index=0&id=6cad2a94&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cad2a94",
  null
  
)

export default component.exports