import { render, staticRenderFns } from "./gigya-menu-item.vue?vue&type=template&id=343493a1&scoped=true&functional=true"
import script from "./gigya-menu-item.vue?vue&type=script&lang=js"
export * from "./gigya-menu-item.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "343493a1",
  null
  
)

export default component.exports