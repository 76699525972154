<template>
  <div class="PodcastLinkCmp">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-xl-8">
          <div class="iframe-wrapper">
            <iframe
              width="100%"
              :src="dataSrc"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-xl-8">
          <div class="logos-wrapper">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PodcastLinkCmp',
  props: {
    dataSrc: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.PodcastLinkCmp {
  .iframe-wrapper {
    display: flex;
    width: 100%;
    height: 152px;
    flex-direction: column;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      height: 152px;
    }

    @include media-breakpoint-up(lg) {
      height: 152px;
    }

    @include media-breakpoint-up(xl) {
      height: 352px;
    }

    iframe {
      flex-grow: 1;
      border: 0;
      margin: 0;
      padding: 0;
    }
  }

  .logos-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 76px;
    padding: 16px 0;
    margin-top: 24px;
    border: 2px solid $color-blue-10;
    border-radius: 8px;

    @include media-breakpoint-up(md) {
      height: 92px;
      padding: 24px 0;
      margin-top: 32px;
    }

    @include media-breakpoint-up(lg) {
      height: 96px;
      padding: 32px 0;
      margin-top: 48px;
    }

    ::v-deep .ImageCmp {
      flex-shrink: 0;
      width: auto;
      height: 44px;

      @include media-breakpoint-up(lg) {
        height: 32px;
      }
    }
  }
}
</style>
