<template functional>
  <div
    :ref="data.ref"
    :class="['ColoredBox', data.class, data.staticClass]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ColoredBox',
  functional: true,
};
</script>

<style lang="scss" scoped>
.ColoredBox {
  margin-inline: calc(var(--#{$variable-prefix}-gutter-x, 30px) * -1);
  @include media-breakpoint-up(md) {
    margin-inline: 0;
  }
  ::v-deep .rounded {
    border-radius: 8px;
  }

  ::v-deep .container {
    padding-inline: var(--bfp-gutter-x, 24px);
    @include media-breakpoint-up(md) {
      padding-inline: 0;
    }
  }

  /* TODO reset border-radius of children */
}
</style>
