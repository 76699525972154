import * as types from '../mutation_types';

export default {
  [types.SET_CATEGORY](state, { news_and_media_type, slug, title }) {
    state.category = { news_and_media_type, slug, title };
  },
  [types.SET_PAGINATION](
    state,
    { count, current_page, final_page, next, page, previous }
  ) {
    state.pagination = {
      count,
      current_page,
      final_page,
      next,
      page,
      previous,
    };
    state.pagination.page_size = 12;
  },
  [types.SET_TOPICS](state, payload) {
    state.topics = payload;
  },
  [types.SET_FILTERS](state, filters) {
    state.filters = filters;
  },
  [types.RESET_FILTERS](state) {
    state.filters = [];
  },
  [types.CONFIG_PAGES](state, tabs) {
    state.pages = tabs.reduce((o, key) => ({ ...o, [key]: [] }), {});
  },
  [types.SET_PAGES_LOADING](state, value) {
    if (value) {
      state.pages_loading.push(value);
    } else {
      state.pages_loading.pop();
    }
  },
  [types.SET_PAGES](state, { category_slug, page_number, data }) {
    state.pages[category_slug] = [
      ...state.pages[category_slug],
      { page_number, data },
    ];
  },
  [types.RESET_PAGES](state, category_slug) {
    state.pages[category_slug] = [];
  },
};
