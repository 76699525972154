<template>
  <div class="CookingTipsBestPracticesPage">
    <hero-cmp class="colorful-bg">
      <template #type>
        <slot name="service-type" />
      </template>
      <template #title>
        <slot name="service-hero-title" />
      </template>
    </hero-cmp>
    <div class="container pt-r pb-l">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <slot name="service-breadcrumbs" />
        </div>
      </div>
    </div>
    <title-format-cmp class="pb-l">
      <title-text-cmp class="multiple-cta-inline">
        <template #title>
          <slot name="service-title" />
        </template>
        <template #text>
          <slot name="service-rte" />
        </template>
        <template #cta>
          <cta-button-cmp-extended
            v-for="(cta, i) in cta_links"
            :key="`cta-${i}-${_uid}`"
            class="btn-text"
            :data-url="cta.href"
            :data-target="cta.target || null"
          >
            {{ cta.name }}
            <template #after-icon>
              <svg>
                <use
                  :href="`#${
                    cta.cta_icon ? cta.cta_icon : 'icon-arrow-up-right'
                  }`"
                />
              </svg>
            </template>
            <template #seo-link>
              <slot :name="`service-cta-seo-link-${i + 1}`" />
            </template>
          </cta-button-cmp-extended>
        </template>
      </title-text-cmp>
    </title-format-cmp>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <div class="row">
            <div
              v-for="(tip, i) in config"
              :key="tip.slug"
              class="col-12 col-md-6 col-lg-4"
            >
              <card-cmp :data-href="tip.href" class="normal pb-l">
                <template #seo-link>
                  <slot :name="`service-seo-link-${i + 1}`" />
                </template>
                <template #image>
                  <slot :name="`service-image-${i + 1}`" />
                </template>
                <template #title>
                  <slot :name="`service-title-${i + 1}`" />
                </template>
              </card-cmp>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { castPropToType } from '@/helpers/cms-support';
import { mapState } from 'vuex';

export default {
  name: 'CookingTipsBestPracticesPage',
  props: {
    dataConfig: { type: String, required: true },
  },
  setup() {
    return { castPropToType };
  },
  computed: {
    config() {
      return this.dataConfig
        ? this.castPropToType(this.dataConfig, 'object')
        : null;
    },
    ...mapState('services', [
      'best_practices_overtitle',
      'best_practices_title',
      'title',
      'rte',
      'breadcrumbs',
      'cta_links',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.CookingTipsBestPracticesPage {
  ::v-deep .TitleFormatCmp {
    .cta-wrapper {
      @include media-breakpoint-up(lg) {
        --title-text-cta-wrapper-padding: 24px 20px 0;
      }

      @include media-breakpoint-up(xl) {
        --title-text-cta-wrapper-padding: 24px 140px 0;
      }
    }
  }
}
</style>
