<template>
  <div class="swiper-slide CarouselBrandsBrandCmp">
    <component
      :is="dataHref !== '' ? 'a' : 'div'"
      :href="dataHref !== '' ? dataHref : null"
      :target="dataHref !== '' ? dataTarget : null"
      :rel="dataHref !== '' ? dataRel : null"
      :aria-label="dataHref !== '' ? dataAriaLabel : null"
      @click="onClick"
    >
      <figure-card-cmp>
        <image-cmp
          width="114"
          height="99"
          :data-desktop-image="dataSrc"
          :alt="dataAlt"
        />
      </figure-card-cmp>
    </component>
  </div>
</template>

<script>
import { castPropToType } from '@/helpers/cms-support';
export default {
  name: 'CarouselBrandsBrandCmp',
  props: {
    dataIsGa4Enabled: {
      type: [Boolean, String],
      default: false,
    },
    dataHref: {
      type: String,
      default: '',
    },
    dataTarget: {
      type: String,
      default: '_blank',
    },
    dataRel: {
      type: String,
      default: 'nofollow',
    },
    dataAriaLabel: {
      type: String,
      default: '',
    },
    dataSrc: {
      type: String,
      required: true,
    },
    dataAlt: {
      type: String,
      default: '',
    },
    dataStoreName: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      castPropToType,
    };
  },
  computed: {
    isGa4Enabled() {
      return this.dataIsGa4Enabled
        ? this.castPropToType(this.dataIsGa4Enabled, 'boolean')
        : null;
    },
  },
  methods: {
    // we preferred a tracking inside because we didn't wanto to put an event capture on the template of the
    // component inside the cms bscause if we do like that we should put the handler called in the entry point
    onClick() {
      if (this.isGa4Enabled) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'buybutton_clicked',
          'data-store-name': this.dataStoreName,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.CarouselBrandsBrandCmp {
}
</style>
