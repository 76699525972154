<template>
  <input-radio-cmp
    class="InputRadioCmpExtended"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </input-radio-cmp>
</template>

<script>
import { InputRadioCmp } from '@frankhoodbs/lib';

export default {
  name: 'InputRadioCmpExtended',
  components: { InputRadioCmp },
};
</script>

<style lang="scss" scoped>
.InputRadioCmpExtended.form-group__wrapper {
  --form-check-label-padding-left: 16px;
  --form-check-label-color: #{$color-blue};
  --form-check-radio-dim: 20px;
  --form-check-radio-border-color: #{$color-grey-600};
  --form-check-checked-color: #{$color-blue};
  --form-check-checked-bullet-distance: 6px;
  --form-element-disabled-bg-color: #{$color-grey-200};
  --form-element-label-disabled-color: #{$color-grey-400};
  --form-check-radio-hover-border-color: #{$color-blue};
  --form-check-radio-hover-bg-color: #{$color-grey-200-50};
  --form-check-label-hover-color: #{$color-blue};

  ::v-deep .form-group {
    .form-check__wrapper {
      .form-check {
        .form-check-label {
          @include override-responsive-typography-properties(
            $font-primary,
            a-support,
            --form-check-label
          );
        }
      }
    }
  }
}
</style>
