<template>
  <form-cmp class="ContactFormPersonalInfoCmp">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10 col-xl-8">
        <div class="row">
          <div
            v-if="
              dataCurrentStep.content.name &&
              dataCurrentStep.content.name.active
            "
            class="col-12 col-md-6"
          >
            <input-text-cmp-extended
              v-model.trim="$v.name.$model"
              data-name="name"
              :data-placeholder="dataCurrentStep.content.name.placeholder"
              :data-error-occurred="$v.name.$error"
              :data-errors="[
                ...dataCurrentStep.content.name.error.map((e) => ({
                  condition: !$v.name[e.condition],
                  message: e.message,
                })),
                {
                  condition: !$v.name.serverError,
                  message: serverErrors.name,
                },
              ]"
            >
              <template #label>
                {{ dataCurrentStep.content.name.label }}
              </template>
            </input-text-cmp-extended>
          </div>
          <div
            v-if="
              dataCurrentStep.content.surname &&
              dataCurrentStep.content.surname.active
            "
            class="col-12 col-md-6"
          >
            <input-text-cmp-extended
              v-model.trim="$v.surname.$model"
              data-name="surname"
              :data-placeholder="dataCurrentStep.content.surname.placeholder"
              :data-error-occurred="$v.surname.$error"
              :data-errors="[
                ...dataCurrentStep.content.surname.error.map((e) => ({
                  condition: !$v.surname[e.condition],
                  message: e.message,
                })),
                {
                  condition: !$v.surname.serverError,
                  message: serverErrors.surname,
                },
              ]"
            >
              <template #label>
                {{ dataCurrentStep.content.surname.label }}
              </template>
            </input-text-cmp-extended>
          </div>
          <div
            v-if="
              dataCurrentStep.content.email &&
              dataCurrentStep.content.email.active
            "
            class="col-12 col-md-6"
          >
            <input-text-cmp-extended
              v-model.trim="$v.email.$model"
              data-name="email"
              :data-placeholder="dataCurrentStep.content.email.placeholder"
              :data-error-occurred="$v.email.$error"
              :data-errors="[
                ...dataCurrentStep.content.email.error.map((e) => ({
                  condition: !$v.email[e.condition],
                  message: e.message,
                })),
                {
                  condition: !$v.email.serverError,
                  message: serverErrors.email,
                },
              ]"
            >
              <template #label>
                {{ dataCurrentStep.content.email.label }}
              </template>
            </input-text-cmp-extended>
          </div>
          <div
            v-if="
              dataCurrentStep.content.telephone &&
              dataCurrentStep.content.telephone.active
            "
            class="col-12 col-md-6"
          >
            <input-text-cmp-extended
              v-model.trim="$v.telephone.$model"
              data-name="telephone"
              :data-placeholder="dataCurrentStep.content.telephone.placeholder"
              :data-error-occurred="$v.telephone.$error"
              :data-errors="[
                ...dataCurrentStep.content.telephone.error.map((e) => ({
                  condition: !$v.telephone[e.condition],
                  message: e.message,
                })),
                {
                  condition: !$v.telephone.serverError,
                  message: serverErrors.telephone,
                },
              ]"
            >
              <template #label>
                {{ dataCurrentStep.content.telephone.label }}
              </template>
            </input-text-cmp-extended>
          </div>
          <div
            v-if="
              dataCurrentStep.content.interest &&
              dataCurrentStep.content.interest.active
            "
            class="col-12 col-md-6"
          >
            <select-cmp-extended
              v-model.trim="$v.interest.$model"
              data-name="interest"
              :data-option-list="interestList"
              :data-placeholder="dataCurrentStep.content.interest.placeholder"
              :data-error-occurred="$v.interest.$error"
              :data-errors="[
                ...dataCurrentStep.content.interest.error.map((e) => ({
                  condition: !$v.interest[e.condition],
                  message: e.message,
                })),
                {
                  condition: !$v.interest.serverError,
                  message: serverErrors.interest,
                },
              ]"
            >
              <template #label>
                {{ dataCurrentStep.content.interest.label }}
              </template>
              <template #icon>
                <svg>
                  <use href="#icon-caret-down" />
                </svg>
              </template>
            </select-cmp-extended>
          </div>
          <div
            v-if="
              dataCurrentStep.content.work &&
              dataCurrentStep.content.work.active
            "
            class="col-12 col-md-6"
          >
            <select-cmp-extended
              v-model.trim="$v.work.$model"
              data-name="work"
              :data-option-list="workList"
              :data-placeholder="dataCurrentStep.content.work.placeholder"
              :data-error-occurred="$v.work.$error"
              :data-errors="[
                ...dataCurrentStep.content.work.error.map((e) => ({
                  condition: !$v.work[e.condition],
                  message: e.message,
                })),
                {
                  condition: !$v.work.serverError,
                  message: serverErrors.work,
                },
              ]"
            >
              <template #label>
                {{ dataCurrentStep.content.work.label }}
              </template>
              <template #icon>
                <svg>
                  <use href="#icon-caret-down" />
                </svg>
              </template>
            </select-cmp-extended>
          </div>
        </div>
        <div class="col-12 text-center">
          <submit-cmp-extended
            :data-theme-classes="['reversed']"
            :data-aria-label="dataCurrentStep.content.submitCtaAriaLabel"
            :data-loading="loading"
            :data-disabled="disableSubmit || submitted"
            :data-error-occurred="
              !!globalErrors.length || !!Object.keys(serverErrors).length
            "
            :data-errors="globalErrors"
            @click-submit="onSubmitForm"
          >
            <template>{{ dataCurrentStep.content.submitCtaText }}</template>
            <template #after-icon>
              <svg>
                <use href="#icon-arrow-right" />
              </svg>
            </template>
          </submit-cmp-extended>
        </div>
        <div v-if="dataCurrentStep.content.footnote" class="col-12">
          <span class="a-caption text-color-grey-600">
            {{ dataCurrentStep.content.footnote }}
          </span>
        </div>
      </div>
    </div>
  </form-cmp>
</template>

<script>
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import { email, maxLength, helpers, required } from 'vuelidate/lib/validators';
import { FormCmp } from '@frankhoodbs/lib';
import FormElementsSupport from '@frankhoodbs/lib/src/mixins/form-elements-support';
import { mapActions, mapGetters, mapState } from 'vuex';
import axios from 'axios';

Vue.use(Vuelidate);

const checkServerErrors = (param) => {
  return (value, vm) => {
    return Object.keys(vm.serverErrors).indexOf(param) === -1;
  };
};

export default {
  name: 'ContactFormPersonalInfoCmp',
  components: { FormCmp },
  mixins: [FormElementsSupport],
  props: {
    dataCurrentStep: {
      type: Object,
      required: true,
    },
    dataSubmitEnabledOnMounted: {
      type: Boolean,
      default: true,
    },
    dataApiUrl: {
      type: String,
      default: null,
    },
    dataRequestMethod: {
      type: String,
      default: 'post',
      validator: function (value) {
        // The value must match one of these strings
        return (
          [
            'get',
            'head',
            'post',
            'put',
            'delete',
            'connect',
            'options',
            'trace',
            'patch',
          ].indexOf(value) !== -1
        );
      },
    },
    dataRequestData: {
      type: Object,
      default: null,
    },
    dataRequestConfig: {
      type: Object,
      default: null,
    },
    dataHandledStatusCodeErrors: {
      type: Array,
      default: () => [400, 401, 403],
    },
  },
  data() {
    return {
      fields: ['name', 'surname', 'email', 'telephone', 'interest', 'work'],
      interestList: [],
      workList: [],
    };
  },
  computed: {
    ...mapState('contact', ['chosenData']),
    ...mapState('gigya', ['user', 'data']),
    ...mapGetters('gigya', ['loggedIn']),
    /* form field */
    name: {
      get() {
        return this.valueMapper('name');
      },
      set(value) {
        this.valueMapper('name', value);
      },
    },
    surname: {
      get() {
        return this.valueMapper('surname');
      },
      set(value) {
        this.valueMapper('surname', value);
      },
    },
    email: {
      get() {
        return this.valueMapper('email');
      },
      set(value) {
        this.valueMapper('email', value);
      },
    },
    telephone: {
      get() {
        return this.valueMapper('telephone');
      },
      set(value) {
        this.valueMapper('telephone', value);
      },
    },
    interest: {
      get() {
        return this.valueMapper('interest');
      },
      set(value) {
        this.valueMapper('interest', value);
      },
    },
    work: {
      get() {
        return this.valueMapper('work');
      },
      set(value) {
        this.valueMapper('work', value);
      },
    },
    disableSubmit() {
      if (this.dataSubmitEnabledOnMounted) {
        return false;
      } else {
        if (this.$v && (this.$v.$anyError || this.$v.$invalid)) {
          return true;
        }

        return false;
      }
    },
  },
  validations() {
    let activeFields = [];

    this.fields.forEach((e) => {
      if (
        this.dataCurrentStep.content[e] &&
        this.dataCurrentStep.content[e].active
      ) {
        activeFields.push(e);
      }
    });

    return this.setValidations(activeFields);
  },
  async mounted() {
    if (
      this.dataCurrentStep.content.interest &&
      this.dataCurrentStep.content.interest.active
    ) {
      const interestResponse = await axios.get(
        this.dataCurrentStep.content.interest.apiUrl
      );
      if (interestResponse) {
        this.interestList = interestResponse.data;
      }
    }
    if (
      this.dataCurrentStep.content.work &&
      this.dataCurrentStep.content.work.active
    ) {
      const workResponse = await axios.get(
        this.dataCurrentStep.content.work.apiUrl
      );
      if (workResponse) {
        this.workList = workResponse.data;
      }
    }

    if (this.loggedIn) {
      if (
        !this.chosenData.name &&
        this.dataCurrentStep.content.name &&
        this.dataCurrentStep.content.name.active &&
        this.user.firstName
      ) {
        this.setStepData({ key: 'name', value: this.user.firstName });
      }
      if (
        !this.chosenData.surname &&
        this.dataCurrentStep.content.surname &&
        this.dataCurrentStep.content.surname.active &&
        this.user.lastName
      ) {
        this.setStepData({ key: 'surname', value: this.user.lastName });
      }
      if (
        !this.chosenData.email &&
        this.dataCurrentStep.content.email &&
        this.dataCurrentStep.content.email.active &&
        this.user.email
      ) {
        this.setStepData({ key: 'email', value: this.user.email });
      }
      if (
        !this.chosenData.telephone &&
        this.dataCurrentStep.content.telephone &&
        this.dataCurrentStep.content.telephone.active &&
        this.data.phone.mobile
      ) {
        this.setStepData({ key: 'telephone', value: this.data.phone.mobile });
      }
      if (
        !this.chosenData.interest &&
        this.dataCurrentStep.content.interest &&
        this.dataCurrentStep.content.interest.active &&
        this.data.company.business
      ) {
        const gigyaInterest = this.interestList.find(
          (e) =>
            e.cdc_label_for_autocomplete_match === this.data.company.business
        );
        if (gigyaInterest) {
          this.setStepData({
            key: 'interest',
            value: gigyaInterest.value,
          });
        }
      }
      if (
        !this.chosenData.work &&
        this.dataCurrentStep.content.work &&
        this.dataCurrentStep.content.work.active &&
        this.data.company.jobTitle
      ) {
        const gigyaWork = this.workList.find(
          (e) =>
            e.cdc_label_for_autocomplete_match === this.data.company.jobTitle
        );
        if (gigyaWork) {
          this.setStepData({
            key: 'work',
            value: gigyaWork.value,
          });
        }
      }
    }
  },
  methods: {
    ...mapActions('contact', ['setStepData']),
    valueMapper(key, value) {
      if (arguments.length === 2) {
        this.setStepData({ key, value });
      } else {
        return this.chosenData[key];
      }
    },
    setValidations(fields) {
      let validations = {};

      fields.forEach((prop) => {
        validations[prop] = {};

        this.dataCurrentStep.content[prop].error.forEach((e) => {
          if (e.condition === 'required') {
            validations[prop] = {
              ...validations[prop],
              required,
            };
          }
          if (e.condition === 'email') {
            validations[prop] = {
              ...validations[prop],
              email,
            };
          }
          if (e.condition === 'maxLength') {
            validations[prop] = {
              ...validations[prop],
              maxLength: maxLength(e.length),
            };
          }
          if (prop === 'telephone' && e.condition === 'regex') {
            validations[prop] = {
              ...validations[prop],
              regex: (val) =>
                !helpers.req(val) || new RegExp(/^([+])?\d+$/g, 'i').test(val),
            };
          }
        });
        validations[prop] = {
          ...validations[prop],
          serverError: checkServerErrors(prop),
        };
      });

      return validations;
    },
    async onSubmitForm() {
      if (this.dataSubmitEnabledOnMounted) {
        this.$v.$touch();
      }
      if (!this.submitted && !this.disableSubmit && !this.$v.$invalid) {
        this.submitted = true;
        this.globalErrors = [];
        this.toastErrors = [];

        this.$emit('submit');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ContactFormPersonalInfoCmp.FormCmp {
  .form-group__wrapper {
    --form-element-padding: 0 0 40px;

    &.SubmitCmp {
      --form-element-padding: 40px 0 80px;
    }
  }
}
</style>
