var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-cmp',{staticClass:"NewsletterFormCmp"},[_c('div',{staticClass:"d-flex flex-column flex-md-row align-items-center align-items-md-baseline justify-content-center"},[_c('input-text-cmp-extended',{attrs:{"data-name":"email","data-placeholder":"Email","data-aria-label":"Email","data-error-occurred":_vm.$v.email.$error,"data-errors":[
        {
          condition: !_vm.$v.email.required,
          message: 'L\'indirizzo email è obbligatorio',
        },
        {
          condition: !_vm.$v.email.email,
          message: 'Inserisci un indirizzo email valido',
        },
        {
          condition: !_vm.$v.email.serverError,
          message: _vm.serverErrors.email,
        } ]},model:{value:(_vm.$v.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.email, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.email.$model"}}),_c('submit-cmp-extended',{attrs:{"data-theme-classes":[{ 'btn-small': _vm.xsOnly }],"data-aria-label":"Sign up","data-loading":_vm.loading,"data-disabled":_vm.disableSubmit || _vm.submitted,"data-error-occurred":!!_vm.globalErrors.length || !!Object.keys(_vm.serverErrors).length,"data-errors":_vm.globalErrors},on:{"click-submit":_vm.onSubmitForm}},[_vm._v(" Sign up ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }