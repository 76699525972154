<template>
  <div class="CookingTipsStaticCmp LocalConfig d-none" aria-hidden="true" />
</template>

<script>
import { castPropToType } from '@/helpers/cms-support';

export default {
  name: 'CookingTipsStaticCmp',
  props: {
    dataConfig: {
      type: [Object, String],
      required: true,
    },
  },
  setup() {
    return { castPropToType };
  },
  computed: {
    config() {
      return this.dataConfig
        ? this.castPropToType(this.dataConfig, 'object')
        : null;
    },
  },
  mounted() {
    this.$store.dispatch('setProperties', {
      module: 'services',
      payload: this.config,
    });
  },
};
</script>

<style lang="scss" scoped></style>
