import { render, staticRenderFns } from "./simple-card-cmp.vue?vue&type=template&id=7fa46a78&scoped=true&functional=true"
import script from "./simple-card-cmp.vue?vue&type=script&lang=js"
export * from "./simple-card-cmp.vue?vue&type=script&lang=js"
import style0 from "./simple-card-cmp.vue?vue&type=style&index=0&id=7fa46a78&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "7fa46a78",
  null
  
)

export default component.exports