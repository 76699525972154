<template>
  <div class="RecipesHubPage">
    <hero-cmp class="image">
      <template #image>
        <slot name="recipe-image" />
      </template>
      <template #type>
        <slot name="recipe-type" />
      </template>
      <template #title>
        <slot name="recipe-hero-title" />
      </template>
    </hero-cmp>
    <div class="container pt-l pb-m">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <title-text-cmp>
            <template #text>
              <slot name="recipe-description" />
            </template>
            <template v-if="recipe_list_cta_url" #cta>
              <cta-button-cmp-extended
                class="btn-text"
                :data-url="recipe_list_cta_url"
                :data-aria-label="recipe_list_cta_label"
              >
                {{ recipe_list_cta_label }}
                <template #after-icon>
                  <svg>
                    <use href="#icon-arrow-right" />
                  </svg>
                </template>
                <template #seo-link>
                  <slot name="recipe-list-seo-link" />
                </template>
              </cta-button-cmp-extended>
            </template>
          </title-text-cmp>
        </div>
      </div>
    </div>
    <div class="container pb-xl">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10">
          <div v-if="highlight_recipe" class="row">
            <div class="col-12">
              <card-cmp
                :data-href="highlight_recipe.href"
                class="highlight mt-10"
              >
                <template #seo-link>
                  <slot name="recipe-highlight-seo-link" />
                </template>
                <template #image>
                  <slot name="recipe-highlight-image" />
                </template>
                <template #tags>
                  <slot name="recipe-highlight-tags" />
                </template>
                <template #category>
                  <slot name="recipe-highlight-category" />
                </template>
                <template #title>
                  <slot name="recipe-highlight-title" />
                </template>
              </card-cmp>
            </div>
          </div>
          <div v-if="latest_recipes" class="row">
            <slot name="recipe-latest" />
          </div>
          <div v-if="recipes" class="row mt-7">
            <slot name="recipes" />
          </div>
          <div class="row">
            <div class="col-12">
              <title-separator-cmp class="mt-10">
                <template #cta>
                  <cta-button-cmp-extended
                    class="btn-text"
                    :data-url="recipe_list_cta_url"
                    :data-aria-label="recipe_view_all_cta_label"
                  >
                    {{ recipe_view_all_cta_label }}
                    <template #after-icon>
                      <svg>
                        <use href="#icon-arrow-right" />
                      </svg>
                    </template>
                    <template #seo-link>
                      <slot name="recipe-view-all-seo-link" />
                    </template>
                  </cta-button-cmp-extended>
                </template>
              </title-separator-cmp>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'RecipesHubPage',
  computed: {
    ...mapState('recipes', [
      'hub_hero_image',
      'hub_hero_image_alt',
      'hub_hero_label',
      'hub_hero_title',
      'hub_description',
      'recipe_list_cta_label',
      'recipe_list_cta_url',
      'recipe_view_all_cta_label',
      'highlight_recipe',
      'latest_recipes',
      'recipes',
    ]),
  },
};
</script>
