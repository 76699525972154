<template>
  <div class="FocusItemsCmp">
    <div class="inner">
      <figure
        v-if="themeVariant === 'with-tab-menu-with-image-bg'"
        class="background"
      >
        <slot name="image" />
        <figcaption>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 col-md-10">
                <div class="content">
                  <div class="figcaption-wrapper">
                    <slot name="figcaption" />
                  </div>
                  <div class="logo-wrapper">
                    <slot name="logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </figcaption>
      </figure>
      <div class="container">
        <div class="row justify-content-center">
          <div
            :class="[
              {
                'col-12 col-md-8 col-lg-12': themeVariant === 'with-stepper',
                'col-12 col-md-10':
                  themeVariant === 'with-icons' ||
                  themeVariant === 'with-numbers' ||
                  themeVariant === 'with-images',
                'col-12':
                  themeVariant === 'with-tab-menu' ||
                  themeVariant === 'with-tab-menu-detailed' ||
                  themeVariant === 'with-tab-menu-with-image-bg',
              },
            ]"
          >
            <div
              ref="$wrapper"
              :class="['wrapper', { [dataBoxColor]: !boxColor }]"
              :style="{ '--box-bg-color': boxColor }"
            >
              <slot v-if="themeVariant === 'with-stepper'" name="steps" />
              <template v-if="themeVariant === 'with-numbers'">
                <slot name="heading" />
                <slider-cmp-extended
                  :data-bootstrap-container-classes="[
                    'container-fluid',
                    'px-0',
                  ]"
                  :data-component-slides="true"
                  :data-options="{
                    observer: true,
                    navigation: false,
                  }"
                  :data-disabled-from="768"
                >
                  <template slot="custom-swipe-icon">
                    <svg>
                      <use href="#icon-scroller" />
                    </svg>
                  </template>
                  <template slot="components">
                    <slot name="numbers" />
                  </template>
                </slider-cmp-extended>
              </template>
              <div
                v-if="
                  themeVariant === 'with-icons' ||
                  themeVariant === 'with-images'
                "
                class="row justify-content-center"
              >
                <div class="col-12 col-custom-md-8-10 content-wrapper">
                  <slot name="heading" />
                  <slider-cmp-extended
                    v-if="themeVariant === 'with-icons'"
                    :data-bootstrap-container-classes="[
                      'container-fluid',
                      'px-0',
                    ]"
                    :data-component-slides="true"
                    :data-options="{
                      observer: true,
                      navigation: false,
                    }"
                    :data-disabled-from="768"
                  >
                    <template slot="custom-swipe-icon">
                      <svg>
                        <use href="#icon-scroller" />
                      </svg>
                    </template>
                    <template slot="components">
                      <slot name="icons" />
                    </template>
                  </slider-cmp-extended>
                  <div v-else-if="themeVariant === 'with-images'" class="row">
                    <div class="col-12 col-custom-md-4-8 images-wrapper">
                      <slot name="first-image" />
                    </div>
                    <div class="col-12 col-custom-md-4-8 images-wrapper">
                      <slot name="second-image" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  themeVariant === 'with-tab-menu' ||
                  themeVariant === 'with-tab-menu-detailed' ||
                  themeVariant === 'with-tab-menu-with-image-bg'
                "
                class="row justify-content-center"
              >
                <div class="col-12 col-md-10">
                  <tabs-cmp
                    :data-active-tab-id="dataActiveTabId"
                    :data-tabs="tabs"
                  >
                    <template
                      v-for="(_, name) in $scopedSlots"
                      v-slot:[name]="data"
                    >
                      <slot :name="name" v-bind="data" />
                    </template>
                  </tabs-cmp>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SliderCmpExtended from './slider-cmp-extended';
import TabsCmp from './tabs-cmp';
import { castPropToType } from '@/helpers/cms-support';
import { mdAndUp } from '@/helpers/breakpoints';

export default {
  name: 'FocusItemsCmp',
  components: { TabsCmp, SliderCmpExtended },
  props: {
    dataTabs: {
      type: [Array, String],
      default: null,
    },
    dataActiveTabId: {
      type: String,
      default: null,
    },
    dataBoxColor: {
      type: String,
      default: null,
    },
  },
  setup() {
    return {
      castPropToType,
      mdAndUp,
    };
  },
  data() {
    return {
      themeVariant: null,
      boxColor: null,
    };
  },
  computed: {
    tabs() {
      return this.dataTabs ? this.castPropToType(this.dataTabs, 'array') : null;
    },
  },
  mounted() {
    this.themeVariant = this.$el.classList.contains('with-stepper')
      ? 'with-stepper'
      : this.$el.classList.contains('with-icons')
      ? 'with-icons'
      : this.$el.classList.contains('with-numbers')
      ? 'with-numbers'
      : this.$el.classList.contains('with-images')
      ? 'with-images'
      : this.$el.classList.contains('with-tab-menu')
      ? 'with-tab-menu'
      : this.$el.classList.contains('with-tab-menu-detailed')
      ? 'with-tab-menu-detailed'
      : this.$el.classList.contains('with-tab-menu-with-image-bg')
      ? 'with-tab-menu-with-image-bg'
      : 'with-stepper';

    this.boxColor = window.getComputedStyle(
      this.$refs.$wrapper
    ).backgroundColor;
  },
};
</script>

<style lang="scss" scoped>
.FocusItemsCmp {
  &.with-stepper {
    .wrapper {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        flex-wrap: wrap;
      }

      @include media-breakpoint-between(md, lg) {
        justify-content: space-evenly; /* fallback on space-between in case of issues */
      }

      @include media-breakpoint-up(lg) {
        justify-content: space-between;
      }

      .TitleTextCmp {
        flex: 1;
        --title-text-text-align: left;
        --title-text-overtitle-wrapper-padding: 0 5px 0 0;
        @include override-responsive-typography-properties(
          $font-secondary,
          n-paragraph-1,
          --title-text-overtitle-wrapper
        );

        @include media-breakpoint-down(md) {
          flex-direction: row;
          align-items: center;
          --title-text-overtitle-wrapper-padding: 0 0 0 8px;

          &:not(:first-child) {
            --title-text-padding: 16px 0 0;
          }
        }

        @include media-breakpoint-between(md, lg) {
          flex: 0;
          min-width: 33.33333333%;

          &:nth-child(n + 4) {
            margin-top: 40px;
          }
        }

        @include media-breakpoint-up(xl) {
          --title-text-overtitle-wrapper-padding: 0 45px 0 0;
        }
      }
    }
  }

  &.with-icons {
    .wrapper {
      @include media-breakpoint-up(md) {
        background-color: var(--box-bg-color, #{$color-blue});
        padding: 64px 0;
        border-radius: 8px;
      }

      @include media-breakpoint-up(lg) {
        padding: 80px 0;
      }

      .content-wrapper {
        .TitleTextCmp {
          --title-text-padding: 0 0 40px;
          @include override-responsive-typography-properties(
            $font-secondary,
            n-heading-3,
            --title-text-title-wrapper
          );

          @include media-breakpoint-up(md) {
            --title-text-title-wrapper-color: #{$color-white};
            --title-text-text-wrapper-color: #{$color-white};
          }
        }

        ::v-deep .SliderCmpExtended {
          --slider-slide-gutter-y: 40px;
          --slider-container-overflow: visible;

          &:first-child {
            padding-top: 0;
          }

          .slider-content:not(.disabled) {
            padding-bottom: 82px;

            .swiper-container {
              .swiper-pagination {
                padding: 55px 0 15px;
              }
            }
          }

          .swiper-container {
            &:not(.disabled) {
              --slider-slide-gutter-x: 0 !important;
              --slider-slide-gutter-y: 0 !important;

              .swiper-slide {
                height: auto;
                background-color: var(--box-bg-color, #{$color-blue});
                margin-left: -1px; /* tofix a 1px white space between slides on swiping */

                .TitleTextCmp {
                  --title-text-padding: 40px 0;
                }

                &:first-child {
                  margin: 0;
                  border-radius: 8px 0 0 8px;
                }

                &:last-child {
                  border-radius: 0 8px 8px 0;
                }
              }
            }

            &.disabled {
              .swiper-slide {
                @include media-breakpoint-up(md) {
                  flex: 1 0 50%;
                  max-width: 50%;
                }

                @include media-breakpoint-up(lg) {
                  flex: 1 0 0;
                  max-width: none;
                }
              }
            }
          }

          .TitleTextCmp {
            --title-text-icon-wrapper-color: #{$color-white};
            --title-text-icon-wrapper-padding: 0 0 16px;
            --title-text-icon-wrapper-justify-content: center;
            --title-text-icon-wrapper-icon-width: 72px !important;
            --title-text-icon-wrapper-icon-height: 72px !important;
            --title-text-title-wrapper-color: #{$color-white};
            --title-text-text-wrapper-color: #{$color-white};
            --title-text-text-wrapper-padding: 0;
            @include override-responsive-typography-properties(
              $font-primary,
              a-paragraph-2,
              --title-text-title-wrapper
            );
            @include override-responsive-typography-properties(
              $font-primary,
              a-support,
              --title-text-text-wrapper
            );
          }
        }
      }
    }
  }

  &.with-numbers {
    .TitleTextCmp {
      --title-text-icon-wrapper-justify-content: center;
      --title-text-icon-wrapper-padding: 0 0 24px;
    }

    ::v-deep .SliderCmpExtended {
      padding-top: 40px;
      --slider-slide-gutter-y: 24px;
      --slider-container-overflow: visible;

      @include media-breakpoint-up(md) {
        --slider-slide-gutter-y: 16px;
      }

      @include media-breakpoint-up(lg) {
        --slider-slide-gutter-y: 24px;
        padding-top: 80px;
      }

      &:first-child {
        padding-top: 0;
      }

      .slider-content:not(.disabled) {
        padding-bottom: 82px;

        .swiper-container {
          .swiper-pagination {
            padding: 55px 0 15px;
          }
        }
      }

      .swiper-container {
        .swiper-slide {
          flex: 1 0 auto;
          width: 100%;
          height: auto;

          @include media-breakpoint-up(md) {
            width: 33%;
          }

          @include media-breakpoint-up(xl) {
            flex: 1 0 0;
            width: 100%;
          }
        }

        &:not(.disabled) {
          .swiper-slide {
            width: 90%;
          }
        }
      }

      .TitleTextCmp {
        --title-text-text-align: left;
        --title-text-padding: 24px;
        height: 100%;
        background-color: var(--box-bg-color, #{$color-blue-10});
        border-radius: 8px;
        @include override-responsive-typography-properties(
          $font-secondary,
          n-heading-3,
          --title-text-title-wrapper
        );
        @include override-responsive-typography-properties(
          $font-primary,
          a-paragraph-3,
          --title-text-text-wrapper
        );

        @include media-breakpoint-down(md) {
          --title-text-text-wrapper-padding: 16px 0 0;
        }
      }
    }
  }

  &.with-images {
    .container {
      @include media-breakpoint-down(md) {
        background-color: $color-blue-10;
      }

      .wrapper {
        padding: 40px 0;
        background-color: $color-blue-10;

        @include media-breakpoint-up(md) {
          padding: 80px 0;
          border-radius: 8px;
        }

        .content-wrapper {
          .TitleTextCmp {
            @include override-responsive-typography-properties(
              $font-secondary,
              n-heading-3,
              --title-text-title-wrapper
            );
          }

          .images-wrapper {
            .TitleTextCmp {
              --title-text-text-align: left;
              --title-text-icon-wrapper-padding: 40px 0;
              --title-text-icon-wrapper-icon-width: auto;
              --title-text-icon-wrapper-icon-height: 120px;
              @include override-responsive-typography-properties(
                $font-secondary,
                n-paragraph-1,
                --title-text-title-wrapper
              );

              @include media-breakpoint-up(lg) {
                --title-text-icon-wrapper-icon-height: 180px;
              }

              @include media-breakpoint-up(xl) {
                --title-text-icon-wrapper-icon-height: 260px;
              }
            }
          }
        }
      }
    }
  }

  &.with-tab-menu,
  &.with-tab-menu-detailed {
    .wrapper {
      padding-top: 40px;

      @include media-breakpoint-up(md) {
        padding-top: 64px;
      }

      @include media-breakpoint-up(lg) {
        padding-top: 80px;
      }
    }
  }

  &.with-tab-menu-with-image-bg {
    .inner {
      position: relative;
      padding-top: 568px;

      @include media-breakpoint-up(md) {
        padding-top: 608px;
      }

      @include media-breakpoint-up(lg) {
        padding-top: 499px;
      }

      @include media-breakpoint-up(xl) {
        padding-top: 478px;
      }
    }

    figure.background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      height: 568px;
      overflow: hidden;

      @include media-breakpoint-up(md) {
        height: 760px;
      }

      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
      }

      figcaption {
        position: absolute;
        top: 32px;
        right: 0;
        left: 0;

        @include media-breakpoint-up(md) {
          top: 80px;
        }

        .content {
          display: flex;
          flex-direction: column-reverse;
          gap: 24px;

          @include media-breakpoint-up(md) {
            flex-direction: row;
            gap: 40px;
          }

          @include media-breakpoint-up(xl) {
            gap: 80px;
          }

          .figcaption-wrapper {
            flex: 1 0 0;

            .TitleTextCmp {
              --title-text-text-align: left;
              --title-text-title-wrapper-color: #{$color-white};
              --title-text-text-wrapper-color: #{$color-blue-10};
            }
          }

          .logo-wrapper {
            flex-shrink: 0;

            img {
              max-width: 100%;
              height: auto;
            }
          }
        }
      }
    }

    .wrapper {
      position: relative;
      z-index: map-get($z, base);
      padding: 40px 0;

      @include media-breakpoint-up(md) {
        padding: 64px 0;
        background-color: $color-white;
        border-radius: 8px;
      }

      @include media-breakpoint-up(lg) {
        padding: 80px 0;
      }
    }

    ::v-deep .FocusItemsTabContentCmp {
      .items-wrapper {
        gap: 40px;
        padding: 0 0 40px !important;
      }
    }
  }
}
</style>
