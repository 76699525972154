const VueDjangoCMSPatch = function (instance, selector, Vue) {
  //we are in edit mode edit anabled and not edit mode published preview
  const canBePatched = function () {
    return window.CMS !== undefined && window.CMS.config.mode === 'draft';
  };

  const getTemplateNodesInElementThatDoNotHaveAnySlotDirective = (el) => {
    const nn = Array.prototype.slice.call(el.querySelectorAll('template'));
    const filtered = nn.filter((n) => {
      return (
        n &&
        n.attributes &&
        [...n.attributes].filter(
          ({ name }) => !name.startsWith('slot') && !name.startsWith('v-slot')
        ).length > 0
      );
    });
    return filtered;
  };

  //changes the node tag for a set of passed nodes
  const updateNodesTag = (nodes, tag) => {
    for (let i = 0; i < nodes.length; i++) {
      const referenceNode = nodes[i];
      if (
        !getTemplateNodesInElementThatDoNotHaveAnySlotDirective(referenceNode)
          .length
      ) {
        const newElement = document.createElement(tag);

        if (referenceNode.hasAttributes()) {
          const attrs = referenceNode.attributes;
          for (let j = attrs.length - 1; j >= 0; j--) {
            newElement.setAttribute(attrs[j].name, attrs[j].value);
          }
          newElement.innerHTML = referenceNode.innerHTML;
        } else {
          newElement.innerHTML = referenceNode.innerHTML;
        }

        referenceNode?.parentNode?.insertBefore(newElement, referenceNode);
        referenceNode?.parentNode?.removeChild(referenceNode);
      }
    }
  };

  /*
    In this method we:
    1. take all scripts cms related inside a selector
    2. we remove them from the selector
    3. we append them at the end of the body
  */
  const moveCmsScriptTagsInsideElementToTheBottomOfBody = function (selector) {
    const scripts = document.querySelectorAll(selector + ' script[data-cms]');
    for (let i = 0; i < scripts.length; i++) {
      if (scripts[i].parentNode) {
        scripts[i]?.parentNode?.removeChild(scripts[i]);
        const script = scripts[i];
        document.body.appendChild(script);
      }
    }
  };

  /*
    In this method we:
    1. We return a promise that resolve when the script is loaded
  */
  const loadScriptAsAPromise = function (url) {
    return new Promise(function (resolve, reject) {
      const script = document.createElement('script');
      script.src = `${url}`;
      script.addEventListener('load', function () {
        resolve(true);
      });
      document.head.appendChild(script);
    });
  };

  /*
    In this method we:
    1. We load a set of script in Waterfall
  */
  const loadScriptsInWaterfall = async (scripts) => {
    for (let index = 0; index < scripts.length; index++) {
      const url = scripts[index];
      const loadedJs = await loadScriptAsAPromise(url);
    }
  };

  // Patch the cms
  const patch = (selector) => {
    /*
      Here we:
      1. go trought all <template> tags and transform them into <cms-template> keeping all attributes
      2. we select all the <cms-template> nodes that have a slot or v-slot directive
      4. we transform them back to template

      Example
        FROM:
          <template class="cms-plugin cms-plugin-start cms-plugin-116064"></template>
          <hero-cmp data-is-hover-animated="false">
            <template slot="title">EVENTI</template>
          </hero-cmp>
          <template class="cms-plugin cms-plugin-end cms-plugin-116064"></template>
        TO:
          <cms-template class="cms-plugin cms-plugin-start cms-plugin-116064"></cms-template>
          <hero-cmp data-is-hover-animated="false" class="cms-plugin-116064">
            <template slot="title">EVENTI</template>
          </hero-cmp>
          <cms-template class="cms-plugin cms-plugin-end cms-plugin-116064"></cms-template>


    */
    updateNodesTag(
      getTemplateNodesInElementThatDoNotHaveAnySlotDirective(document),
      'cms-template'
    );
    /*
      Move scripts inside templates outside of them
    */
    moveCmsScriptTagsInsideElementToTheBottomOfBody(selector);

    if (!window.djangoCmsIsPatched) {
      window.djangoCmsIsPatched = true;

      window.CMS.$(window).on('cms-content-refresh', async () => {
        instance.$destroy();
        delete instance.$options.render;

        //needed for pages with router
        /**
          This is due to the following code in vue-router

          if (inBrowser && window.Vue) {
            window.Vue.use(VueRouter);
          }
       */
        if (window.Vue) {
          window.Vue = null;
          delete window.Vue;
        }

        await loadScriptsInWaterfall(applicationScripts);
        window.CMS.Plugin._refreshPlugins();
        window.CMS.$('.cms-toolbar-item-cms-mode-switcher .cms-btn')
          .first()
          .removeClass('cms-btn-disabled');
      });
    }
  };

  const init = function (selector) {
    if (canBePatched()) {
      patch(selector);
    }
  };

  Vue.config.ignoredElements = ['cms-template'];

  const applicationScripts = Array.prototype.slice
    .call(document.querySelectorAll('script:not([data-cms])'))
    .filter((l) => l.getAttribute('src'))
    .filter((l) => !l.getAttribute('src').startsWith('http'))
    .map((g) => g.getAttribute('src'));

  init(selector);
};

export { VueDjangoCMSPatch as default };
