<template>
  <div class="GuidelinesCmp">
    <section class="grid-system">
      <div class="container">
        <h2>Grid</h2>
        <hr />
      </div>
      <div class="example-grid">
        <div class="container">
          <div class="row">
            <div class="col-3 col-md-1">
              <div class="box" />
            </div>
            <div class="col-3 col-md-1">
              <div class="box" />
            </div>
            <div class="col-3 col-md-1">
              <div class="box" />
            </div>
            <div class="col-3 col-md-1">
              <div class="box" />
            </div>
            <div class="col-3 col-md-1">
              <div class="box" />
            </div>
            <div class="col-3 col-md-1">
              <div class="box" />
            </div>
            <div class="col-3 col-md-1">
              <div class="box" />
            </div>
            <div class="col-3 col-md-1">
              <div class="box" />
            </div>
            <div class="col-3 col-md-1">
              <div class="box" />
            </div>
            <div class="col-3 col-md-1">
              <div class="box" />
            </div>
            <div class="col-3 col-md-1">
              <div class="box" />
            </div>
            <div class="col-3 col-md-1">
              <div class="box" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="spacing">
      <div class="container">
        <h2>Spacing</h2>
        <hr />
        <div
          v-for="(key, idx) in spacing"
          :key="`spacing-${idx}-${_uid}`"
          class="row align-items-end"
        >
          <div class="col-2 col-md-1">
            <span class="label">{{ key }}</span>
          </div>
          <div class="col-10 col-md-11">
            <div :class="[`pt-${key}`, 'box']"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="palette">
      <div class="container">
        <h2>Palette</h2>
        <hr />
        <div class="row row-cols-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6">
          <div
            v-for="(key, idx) in palette"
            :key="`palette-${idx}-${_uid}`"
            class="col palette-card"
          >
            <div
              class="color-box shadow-soft"
              :style="{
                backgroundColor: window
                  .getComputedStyle(document.body)
                  .getPropertyValue(
                    `${window
                      .getComputedStyle(document.body)
                      .getPropertyValue('--variable-prefix')
                      .trim()}${key}`
                  ),
              }"
            />
            <span class="key">{{ key }}</span>
            <span class="value">
              {{
                window
                  .getComputedStyle(document.body)
                  .getPropertyValue(
                    `${window
                      .getComputedStyle(document.body)
                      .getPropertyValue('--variable-prefix')
                      .trim()}${key}`
                  )
              }}
            </span>
          </div>
        </div>
      </div>
    </section>
    <section class="shadow">
      <div class="container">
        <h2>Shadow</h2>
        <hr />
        <div
          class="row row-cols-2 row-cols-md-4 row-cols-lg-5 row-cols-xl-6 justify-content-center"
        >
          <div class="col">
            <div class="ratio ratio-1x1 bg-color-blue shadow-soft">
              <div
                class="d-flex align-items-center justify-content-center text-color-white"
              >
                shadow-soft
              </div>
            </div>
          </div>
          <div class="col offset-0 offset-md-1">
            <div class="ratio ratio-1x1 bg-color-blue shadow-hard">
              <div
                class="d-flex align-items-center justify-content-center text-color-white"
              >
                shadow-hard
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="typography">
      <div class="container">
        <h2>Typography</h2>
        <hr />
        <div v-for="(fontObj, i) in fonts" :key="`font-${i}-${_uid}`">
          <h3>
            Font:
            <strong>{{ fontObj.fontFamily }}</strong>
          </h3>
          <div
            v-for="(className, j) in fontObj.classes"
            :key="`fontClass-${j}-${_uid}`"
          >
            <span :class="['typo-class', className]">{{ className }}</span>
          </div>
        </div>
      </div>
    </section>
    <section class="breakpoints">
      <div class="container">
        <h2>Breakpoints utilities</h2>
        <hr />
        <div class="row">
          <div class="col">
            <dl>
              <dt>projectBreakpoints:</dt>
              <dd>{{ projectBreakpoints }}</dd>
              <dt>gridBreakpoints:</dt>
              <dd>{{ gridBreakpoints }}</dd>
              <dt>currentBreakpoint:</dt>
              <dd>{{ currentBreakpoint }}</dd>
              <dt>xsOnly:</dt>
              <dd>{{ xsOnly }}</dd>
              <dt>smOnly:</dt>
              <dd>{{ smOnly }}</dd>
              <dt>mdOnly:</dt>
              <dd>{{ mdOnly }}</dd>
              <dt>lgOnly:</dt>
              <dd>{{ lgOnly }}</dd>
              <dt>xlOnly:</dt>
              <dd>{{ xlOnly }}</dd>
              <dt>xsAndUp:</dt>
              <dd>{{ xsAndUp }}</dd>
              <dt>smAndDown:</dt>
              <dd>{{ smAndDown }}</dd>
              <dt>smAndUp:</dt>
              <dd>{{ smAndUp }}</dd>
              <dt>mdAndDown:</dt>
              <dd>{{ mdAndDown }}</dd>
              <dt>mdAndUp:</dt>
              <dd>{{ mdAndUp }}</dd>
              <dt>lgAndDown:</dt>
              <dd>{{ lgAndDown }}</dd>
              <dt>lgAndUp:</dt>
              <dd>{{ lgAndUp }}</dd>
              <dt>xlAndDown:</dt>
              <dd>{{ xlAndDown }}</dd>
              <dt>xlAndUp:</dt>
              <dd>{{ xlAndUp }}</dd>
              <dt>xxlAndUp:</dt>
              <dd>{{ xxlAndUp }}</dd>
            </dl>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  projectBreakpoints,
  gridBreakpoints,
  currentBreakpoint,
  xsOnly,
  smOnly,
  mdOnly,
  lgOnly,
  xlOnly,
  xsAndUp,
  smAndDown,
  smAndUp,
  mdAndDown,
  mdAndUp,
  lgAndDown,
  lgAndUp,
  xlAndDown,
  xlAndUp,
  xxlAndUp,
} from '@/helpers/breakpoints';

export default {
  name: 'GuidelinesCmp',
  setup() {
    return {
      projectBreakpoints,
      gridBreakpoints,
      currentBreakpoint,
      xsOnly,
      smOnly,
      mdOnly,
      lgOnly,
      xlOnly,
      xsAndUp,
      smAndDown,
      smAndUp,
      mdAndDown,
      mdAndUp,
      lgAndDown,
      lgAndUp,
      xlAndDown,
      xlAndUp,
      xxlAndUp,
    };
  },
  data() {
    return {
      window: window,
      document: document,
      /**
       * copy this values from _variables.scss $colors
       */
      palette: [
        'black',
        'white',
        'light-blue',
        'blue',
        'blue-75',
        'blue-10',
        'dark-blue',
        'light-red',
        'red',
        'red-75',
        'red-10',
        'dark-red',
        'emiliane',
        'emiliane-75',
        'emiliane-10',
        'selezione-oro',
        'selezione-oro-75',
        'selezione-oro-10',
        'legumi',
        'legumi-75',
        'legumi-10',
        'integrale',
        'integrale-75',
        'integrale-10',
        'classica',
        'specialita',
        'piccolini',
        'red-sauces',
        'pesto-sauces',
        'ready-meals',
        'gluten-free',
        'lasagne-chef',
        'frozen-pasta',
        'flour-semolina',
        'informative-red',
        'informative-green',
        'informative-yellow',
        'grey-200',
        'grey-200-75',
        'grey-200-50',
        'grey-400',
        'grey-400-75',
        'grey-400-50',
        'grey-600',
        'grey-600-75',
        'grey-600-50',
        'grey-800',
        'grey-800-75',
        'grey-800-50',
        'grey-900',
        'grey-900-75',
        'grey-900-50',
      ],
      /**
       * copy this values from _spacings-variables.scss $spacings
       */
      spacing: ['0', 'xs', 's', 'r', 'm', 'l', 'xl', 'xxl'],
      /**
       * copy this values from _typography-variables.scss $typography-fonts
       */
      fonts: [
        // {
        //   fontFamily: 'Avenir',
        //   classes: [
        //     'a-caption',
        //     'a-support',
        //     'a-body',
        //     'a-paragraph-3',
        //     'a-paragraph-2',
        //     'a-paragraph-1',
        //     'a-cta',
        //     'a-heading',
        //   ],
        // },
        {
          fontFamily: 'Netto Pro',
          classes: [
            'n-paragraph-1',
            'n-heading-3',
            'n-heading-2',
            'n-heading-1',
            'n-display',
          ],
        },
        {
          fontFamily: 'Comfortaa',
          classes: [
            'a-caption',
            'a-support',
            'a-body',
            'a-paragraph-3',
            'a-paragraph-2',
            'a-paragraph-1',
            'a-cta',
            'a-heading',
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.GuidelinesCmp {
  section {
    @include responsive-properties(
      ('padding-block'),
      $grid-gutter-width-resp,
      $fallback: $grid-gutter-width
    );

    h2 {
      font-size: 24px;
      line-height: 1.3;
      padding-bottom: 5px;
    }
  }

  .grid-system {
    .example-grid {
      @include responsive-properties(
        ('margin-top'),
        $grid-gutter-width-resp,
        $fallback: $grid-gutter-width
      );
      border-block: 1px solid #cccccc;

      .box {
        width: 100%;
        padding-top: 30%;
        background-color: #ebebeb;

        @include media-breakpoint-up(md) {
          padding-top: 120%;
        }
      }
    }
  }

  .spacing {
    .row {
      padding-top: var(--#{$variable-prefix}gutter-x, #{$grid-gutter-width});

      .label {
        display: block;
        line-height: 1;
        padding-right: var(
          --#{$variable-prefix}gutter-x,
          #{$grid-gutter-width}
        );
      }

      .box {
        background-color: #ebebeb;
      }
    }
  }

  .palette {
    .palette-card {
      display: flex;
      flex-direction: column;
      padding-top: var(--#{$variable-prefix}gutter-x, #{$grid-gutter-width});

      .color-box {
        width: 100%;
        padding-top: 100%;
        margin-bottom: calc(
          var(--#{$variable-prefix}gutter-x, #{$grid-gutter-width}) * 0.5
        );
        border-radius: 8px;
      }

      .key {
        font-size: 14px;
        margin-bottom: calc(
          var(--#{$variable-prefix}gutter-x, #{$grid-gutter-width}) * 0.25
        );

        @include media-breakpoint-up(md) {
          font-size: 15px;
        }
      }

      .value {
        font-size: 11px;

        @include media-breakpoint-up(md) {
          font-size: 13px;
        }
      }
    }
  }

  .typography {
    h3 {
      font-size: 20px;
      line-height: 1.3;
      @include responsive-properties(
        ('padding-top'),
        $grid-gutter-width-resp,
        $fallback: $grid-gutter-width
      );
    }

    .typo-class {
      display: block;
      @include responsive-properties(
        ('padding-top', 'padding-left'),
        $grid-gutter-width-resp,
        $fallback: $grid-gutter-width
      );
    }
  }

  .shadow {
    .row {
      padding-top: var(--#{$variable-prefix}gutter-x, #{$grid-gutter-width});

      .ratio {
        border-radius: 8px;
      }
    }
  }

  .breakpoints {
    dl {
      display: flex;
      flex-wrap: wrap;

      dt {
        width: 200px;
        padding-top: var(--#{$variable-prefix}gutter-x, #{$grid-gutter-width});
        font-weight: bold;
      }

      dd {
        width: calc(100% - 200px);
        padding-top: var(--#{$variable-prefix}gutter-x, #{$grid-gutter-width});
      }
    }
  }
}
</style>
