import * as types from '../mutation_types';
import { colorLog } from '@/helpers/utils.js';
import axios from 'axios';

export default {
  setCategory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { news_and_media_type, slug, title } = payload;
        commit(types.SET_CATEGORY, {
          news_and_media_type,
          slug,
          title,
        });
        resolve();
      } catch (e) {
        colorLog({
          label: 'Store error',
          message: `${e}`,
          themeName: 'blue',
        });
        reject(e);
      }
    });
  },
  setPagination({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { count, current_page, final_page, next, page, previous } =
          payload;
        commit(types.SET_PAGINATION, {
          count,
          current_page,
          final_page,
          next,
          page,
          previous,
        });
        resolve();
      } catch (e) {
        colorLog({
          label: 'Store error',
          message: `${e}`,
          themeName: 'blue',
        });
        reject(e);
      }
    });
  },
  setTopics({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_TOPICS, payload);
        resolve();
      } catch (e) {
        colorLog({
          label: 'Store error',
          message: `${e}`,
          themeName: 'blue',
        });
        reject(e);
      }
    });
  },
  setFilters({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_FILTERS, payload);
        resolve();
      } catch (e) {
        colorLog({
          label: 'Store error',
          message: `${e}`,
          themeName: 'blue',
        });
        reject(e);
      }
    });
  },
  resetFilters({ commit }) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.RESET_FILTERS);
        resolve();
      } catch (e) {
        colorLog({
          label: 'Store error',
          message: `${e}`,
          themeName: 'blue',
        });
        reject(e);
      }
    });
  },
  configPages({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.CONFIG_PAGES, payload);
        resolve();
      } catch (e) {
        colorLog({
          label: 'Store error',
          message: `${e}`,
          themeName: 'blue',
        });
        reject(e);
      }
    });
  },
  setPagesLoading({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_PAGES_LOADING, payload);
        resolve();
      } catch (e) {
        colorLog({
          label: 'Store error',
          message: `${e}`,
          themeName: 'blue',
        });
        reject(e);
      }
    });
  },
  async setPages(
    { state, commit, dispatch },
    { category_slug, page_number = null, filters = null }
  ) {
    dispatch('setPagesLoading', true);
    try {
      const params = {};
      params.page_size = state.pagination.page_size;
      if (page_number) {
        params.page = page_number;
      }
      if (filters) {
        params.topics = filters;
      }

      const response = await axios.get(
        `${state.news_and_media_list_api}${category_slug}`,
        { params }
      );

      if (response.data) {
        const {
          count,
          current_page,
          final_page,
          next,
          page,
          previous,
          results,
        } = response.data;

        dispatch('setPagination', {
          count,
          current_page,
          final_page,
          next,
          page,
          previous,
        });

        if (state.category.slug !== category_slug) {
          // we reset all if tab change
          dispatch('resetFilters');
          await dispatch('resetPages', category_slug);
        } else if (!page_number) {
          // we reset just if is a new load, for example in landing or for example when we add a filter
          await dispatch('resetPages', category_slug);
        }

        commit(types.SET_PAGES, {
          category_slug: category_slug,
          page_number: current_page,
          data: results,
        });
      } else {
        colorLog({
          label: 'Store error',
          message: 'Api error',
          themeName: 'orange',
        });
      }
    } catch (e) {
      colorLog({
        label: 'Store error',
        message: `${e}`,
        themeName: 'blue',
      });
    } finally {
      // eslint-disable-next-line
      dispatch('setPagesLoading', false);
    }
  },
  resetPages({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.RESET_PAGES, payload);
        resolve();
      } catch (e) {
        colorLog({
          label: 'Store error',
          message: `${e}`,
          themeName: 'blue',
        });
        reject(e);
      }
    });
  },
};
