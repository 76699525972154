var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"BannerCmp"},[_c('div',{staticClass:"wrapper"},[(_vm.themeVariant === 'section' || _vm.themeVariant === 'contact')?_c('figure',{staticClass:"background"},[_vm._t("image")],2):_vm._e(),_c('div',{staticClass:"container h-100"},[_c('div',{staticClass:"row h-100"},[(
            _vm.themeVariant === 'contact' ||
            _vm.themeVariant === 'product-story' ||
            _vm.themeVariant === 'quote' ||
            _vm.themeVariant === 'section'
          )?_c('div',{class:[
            {
              'col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2':
                _vm.themeVariant === 'contact',
              'col-12 col-md-8 offset-md-2': _vm.themeVariant === 'quote',
              'col-12 col-md-10 offset-md-1':
                _vm.themeVariant === 'product-story' ||
                _vm.themeVariant === 'section',
              'd-flex align-items-end': _vm.themeVariant === 'section',
            } ]},[_c('div',{staticClass:"content"},[(_vm.themeVariant === 'product-story')?_c('figure',{staticClass:"background"},[_vm._t("image")],2):_vm._e(),_vm._t("default")],2)]):(_vm.themeVariant === 'newsletter')?[_c('div',{staticClass:"col-12 col-lg-5 col-xl-6"},[_vm._t("default")],2),_c('div',{staticClass:"col-12 col-lg-7 col-xl-6"},[_c('div',{staticClass:"newsletter-text-wrapper"},[_vm._t("newsletter-text")],2),_c('newsletter-form-cmp',{attrs:{"data-api-url":""}})],1)]:_vm._e()],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }