import * as types from '../mutation_types';

export default {
  [types.SET_CONFIG_PROPERTY](state, payload) {
    state[payload.key] = payload.value;
  },
  [types.SET_CONFIG_PROPERTY_WITH_TARGET](state, payload) {
    state[payload.target][payload.key] = payload.value;
  },
  [types.UPDATE_PAGINATION](
    state,
    { count, current_page, final_page, next, previous }
  ) {
    state.backendData = { count, current_page, final_page, next, previous };
  },
  [types.RESET_PAGE](state) {
    state.pages = [];
  },
  [types.ADD_PAGE](state, { page_number, data }) {
    state.pages = [...state.pages, { page_number, data }];
  },
};
