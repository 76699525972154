<template>
  <div class="TitleFormatCmp">
    <bicolor-background-cmp
      v-if="themeVariant === 'bicolor'"
      :data-background-color="dataBackgroundColor"
    >
      <slot />
    </bicolor-background-cmp>
    <div v-else class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <div class="wrapper">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BicolorBackgroundCmp } from '@frankhoodbs/lib';

export default {
  name: 'TitleFormatCmp',
  components: { BicolorBackgroundCmp },
  props: {
    dataBackgroundColor: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      themeVariant: null,
    };
  },
  mounted() {
    this.themeVariant = this.$el.classList.contains('bicolor')
      ? 'bicolor'
      : 'default';
  },
};
</script>

<style lang="scss" scoped>
.TitleFormatCmp {
  /* theme variant class */
  &.bicolor {
    --bicolor-background-bg-color: #{$color-blue};
    --bicolor-background-wrapper-bg-color: #{$color-white};
    --bicolor-background-wrapper-padding: 0 16px 24px;

    @include media-breakpoint-up(md) {
      --bicolor-background-wrapper-padding: 0 24px 24px;
    }

    @include media-breakpoint-up(lg) {
      --bicolor-background-wrapper-padding: 0 40px 24px;
    }
  }
}
</style>
