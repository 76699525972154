<template>
  <div class="JsLoader" />
</template>

<script>
import { castPropToType } from '@/helpers/cms-support';
export default {
  name: 'JsLoader',
  props: {
    dataSrc: { type: String, required: true },
    dataAsync: { type: [String, Boolean], default: true },
    dataDefer: { type: [String, Boolean], default: false },
    dataType: { type: String, default: 'text/javascript' },
    dataId: { type: String, required: false, default: null },
    dataExtraAttrs: { type: [String, Object], default: () => ({}) },
  },
  setup() {
    return { castPropToType };
  },
  computed: {
    isAsync() {
      return this.castPropToType(this.dataAsync, 'boolean');
    },
    isDefer() {
      return this.castPropToType(this.dataDefer, 'boolean');
    },
    extraAttrs() {
      return this.castPropToType(this.dataExtraAttrs, 'object');
    },
  },
  created() {
    let scriptTag = document.createElement('script');
    scriptTag.setAttribute('src', this.dataSrc);
    scriptTag.setAttribute('async', this.isAsync);
    scriptTag.setAttribute('defer', this.isDefer);
    scriptTag.setAttribute('type', this.dataType);
    if (this.dataId) {
      scriptTag.setAttribute('id', this.dataId);
    }
    Object.keys(this.extraAttrs).forEach((key) => {
      scriptTag.setAttribute(key, this.extraAttrs[key]);
    });

    this.$nextTick(() => {
      if (this.dataId) {
        if (!document.getElementById(this.dataId)) {
          this.$el.appendChild(scriptTag);
        }
      } else {
        this.$el.appendChild(scriptTag);
      }
    });
  },
};
</script>
