var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:['HeroCmp', { intersected: _vm.intersected }]},[(
      _vm.themeVariant === 'home' ||
      _vm.themeVariant === 'article' ||
      _vm.themeVariant === 'image'
    )?_c('figure',{staticClass:"background"},[_vm._t("image")],2):_vm._e(),_c('div',{staticClass:"container h-100"},[_c('div',{class:[
        'row h-100',
        {
          'elevated-row':
            _vm.themeVariant === 'article' || _vm.themeVariant === 'image',
        } ]},[(_vm.themeVariant === 'article')?_c('div',{staticClass:"box-content col-12 col-md-10 offset-md-1"}):_vm._e(),_c('div',{class:[
          {
            'col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2':
              _vm.themeVariant === 'home',
            'col-12 col-md-10 offset-md-1':
              _vm.themeVariant === 'colorful-bg' ||
              _vm.themeVariant === 'recipes' ||
              _vm.themeVariant === 'image',
            'col-12 col-md-8 offset-md-2': _vm.themeVariant === 'article',
            'col-12 col-md-11 offset-md-1':
              _vm.themeVariant === 'colorful-bg-with-img',
          } ]},[_c('div',{staticClass:"content"},[(_vm.themeVariant === 'home')?[_c(_vm.dataHeadingTag,{tag:"component",staticClass:"text-color-white text-center"},[_c('span',{class:[{ 'n-heading-2': _vm.xsOnly, 'n-heading-1': _vm.mdAndUp }]},[_vm._v(" "+_vm._s(_vm.xsOnly ? _vm.splittedHeadingTextMobile[0].trim() : _vm.splittedHeadingTextDesktop[0].trim())+" ")]),_c('span',{staticClass:"text-separator n-paragraph-1"},[_c('span',{staticClass:"inner-text"},[_vm._v(" "+_vm._s(_vm.xsOnly ? _vm.splittedHeadingTextMobile[1].trim() : _vm.splittedHeadingTextDesktop[1].trim())+" ")])]),_c('span',{class:[{ 'n-heading-2': _vm.xsOnly, 'n-display': _vm.mdAndUp }]},[_vm._v(" "+_vm._s(_vm.xsOnly ? _vm.splittedHeadingTextMobile[2].trim() : _vm.splittedHeadingTextDesktop[2].trim())+" ")])]),_c('div',{staticClass:"scroll-down"},[_c('svg',[_c('use',{attrs:{"href":_vm.mdAndDown ? '#icon-caret-down' : '#icon-scroll-down',"stroke":"currentColor"}})]),(_vm.lgAndUp)?_c('span',{staticClass:"a-caption"},[_vm._t("scroll-down")],2):_vm._e()]),_c('div',{directives:[{name:"intersect",rawName:"v-intersect.once",value:(_vm.onIntersect),expression:"onIntersect",modifiers:{"once":true}}],staticClass:"intersect"})]:(
              _vm.themeVariant === 'colorful-bg' ||
              _vm.themeVariant === 'colorful-bg-with-img' ||
              _vm.themeVariant === 'recipes'
            )?[_c('div',{staticClass:"type-wrapper"},[_vm._t("type")],2),_c('hr'),_c('div',{staticClass:"title-wrapper"},[_vm._t("title")],2),(_vm.themeVariant === 'colorful-bg-with-img')?_vm._t("image"):_vm._e()]:(_vm.themeVariant === 'image')?[_c('div',{staticClass:"type-wrapper"},[_vm._t("type")],2),_c('hr'),_c('div',{staticClass:"title-wrapper"},[_vm._t("title")],2),_c('div',{staticClass:"breadcrumbs-wrapper"},[_vm._t("breadcrumbs")],2)]:(_vm.themeVariant === 'article')?[_vm._t("breadcrumbs"),_c('div',{staticClass:"type-wrapper"},[_vm._t("type")],2),_c('hr'),_c('div',{staticClass:"title-wrapper"},[_vm._t("title")],2),_c('div',{staticClass:"date-wrapper"},[_vm._t("date")],2),_vm._t("social-share")]:_vm._e()],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }