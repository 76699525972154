import { useBreakpoints, reactify } from '@vueuse/core';

const bootstrapBreakpoints = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];
export const projectBreakpoints = [];
export const gridBreakpoints = {};

bootstrapBreakpoints.forEach((bp, index) => {
  const value = getComputedStyle(document.documentElement)
    .getPropertyValue(`--grid-breakpoint-${bp}`)
    .trim();
  if (value) {
    gridBreakpoints[bp] = value;
    projectBreakpoints.push(bp);
  }
});

export const breakpoints = useBreakpoints(gridBreakpoints);

const utilityMethod = (bp, methodName) => {
  const bpIndex = projectBreakpoints.indexOf(bp);
  if (bpIndex !== -1) {
    if (methodName === 'greater') {
      return breakpoints[methodName](projectBreakpoints[bpIndex]);
    } else {
      const nextBpIndex =
        bpIndex + 1 > projectBreakpoints.length - 1 ? null : bpIndex + 1;
      if (methodName === 'smaller') {
        return nextBpIndex
          ? breakpoints[methodName](projectBreakpoints[nextBpIndex])
          : void 0;
      } else if (methodName === 'between') {
        return nextBpIndex
          ? breakpoints[methodName](
              projectBreakpoints[bpIndex],
              projectBreakpoints[nextBpIndex]
            )
          : void 0;
      }
    }
  }

  return void 0;
};

export const xsOnly = utilityMethod('xs', 'smaller');
export const smOnly = utilityMethod('sm', 'between');
export const mdOnly = utilityMethod('md', 'between');
export const lgOnly = utilityMethod('lg', 'between');
export const xlOnly = utilityMethod('xl', 'between');

export const xsAndUp = utilityMethod('xs', 'greater');

export const smAndDown = utilityMethod('sm', 'smaller');
export const smAndUp = utilityMethod('sm', 'greater');

export const mdAndDown = utilityMethod('md', 'smaller');
export const mdAndUp = utilityMethod('md', 'greater');

export const lgAndDown = utilityMethod('lg', 'smaller');
export const lgAndUp = utilityMethod('lg', 'greater');

export const xlAndDown = utilityMethod('xl', 'smaller');
export const xlAndUp = utilityMethod('xl', 'greater');

export const xxlAndUp = utilityMethod('xxl', 'greater');

export const currentBreakpoint = reactify(() => {
  let currentBp;
  projectBreakpoints.forEach((bp) => {
    if (breakpoints[bp].value) {
      currentBp = bp;
    }
  });

  return currentBp;
})();
