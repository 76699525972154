<template>
  <div class="ServicesConfig d-none" aria-hidden="true"></div>
</template>

<script>
import { castPropToType } from '@/helpers/cms-support';

export default {
  name: 'ServicesConfig',
  props: {
    dataConfig: {
      type: [Object, String],
      required: true,
    },
  },
  setup() {
    return { castPropToType };
  },
  computed: {
    conf() {
      return this.dataConfig
        ? this.castPropToType(this.dataConfig, 'object')
        : null;
    },
  },
  mounted() {
    this.$store.dispatch('services/setConfig', this.conf);
  },
};
</script>
