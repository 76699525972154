import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';

import {
  debug,
  colorLog,
  brain,
  pop,
  getMountPointId,
} from '@/helpers/utils.js';

import VueDjangoCMSPatch from '@/helpers/vue_djangocms_patch';

/* install UA parser if needed */
import UAParser from 'ua-parser-js';
const ua = new UAParser();

/* eslint-disable */
// Ignore Django CMS template tags
Vue.config.ignoredElements = [
  'django-cms-template',
  'django-cms-plugin',
  'cms-template',
  'cms-plugin'
];
/* eslint-enable */
Vue.use(VueCompositionAPI);

export const CmsEntrypoints = Vue.extend({
  data() {
    return {
      count: 3,
      isPrintPage: false,
      /* if UA parser is installed remove the comment on the next 2 lines */
      ua: ua,
      isIos: ua.getOS().name === 'iOS',
      hasIosNotch: false,
    };
  },
  watch: {
    $currentScrollPosition: {
      handler() {
        document.documentElement.style.setProperty(
          '--scroll-y',
          `${window.scrollY}px`
        );
      },
      immediate: true,
    },
  },
  // router,
  created() {
    /* eslint-disable */
    new VueDjangoCMSPatch(this, `#${getMountPointId()}`, Vue);
    /* eslint-enable */

    if (typeof Event === 'function') {
      // modern browsers
      window.dispatchEvent(new Event('vueAppReady'));
    } else {
      // This will be executed on old browsers and especially IE
      var vueAppReadyEvent = window.document.createEvent('UIEvents');
      vueAppReadyEvent.initUIEvent('vueAppReady', true, false, window, 0);
      window.dispatchEvent(vueAppReadyEvent);
    }
  },
  mounted() {
    this.mirageKiller();
    /* if UA parser is installed remove the comment on the next line */
    // this.hasIosNotch = this.isIphoneWithNotch();
  },
  methods: {
    /* if UA parser is installed remove the comment on the next block */
    /*
      isIphoneWithNotch() {
        //SE             -> 640x1136 @2x
        //6s/7/8         -> 750x1334 @2x
        //6s+/7+/8+      -> 1080x1920 @3x
        //X/XR/11pro     -> 1125x2436 @3x
        //XS/11          -> 828x1792 @3x
        //XSmax/11promax -> 1242x2688 @3x

        const iOS = this.isIos;
        const ratio = window.devicePixelRatio || 1;
        const screen = {
          width: window.screen.width * ratio,
          height: window.screen.height * ratio,
        };

        return (
          (iOS && screen.width === 1125 && screen.height === 2436) || //X & XR & 11 pro
          (iOS && screen.width === 828 && screen.height === 1792) || //xs & 11
          (iOS && screen.width === 1242 && screen.height === 2688) || //xs max & 11 Pro Max
          (iOS && screen.width === 1284 && screen.height === 2778) || //12 Pro Max
          (iOS && screen.width === 1170 && screen.height === 2532) || //12 Pro
          (iOS && screen.width === 1080 && screen.height === 2340)
        ); //12 mini
      },
    */
    mirageKiller() {
      (() => {
        let doCheck = () => {
          // feature working suppressing debug
          // if (debug) {
          //   console.log(
          //     '%c[KILLING MIRAGE]',
          //     'padding:4px;background-color:red;color:white;'
          //   );
          // }
          var images = Array.prototype.slice.call(
              document.getElementsByTagName('img')
            ),
            cfSrc,
            cfStyle;

          for (
            var i = 0, image = images[i];
            i < images.length;
            image = images[++i]
          ) {
            if ('getAttribute' in image) {
              cfSrc = image.getAttribute('data-cfsrc');
              cfStyle = image.getAttribute('data-cfstyle');
            }

            cfSrc = cfSrc || image.attributes['data-cfsrc'];
            cfStyle = cfStyle || image.attributes['data-cfstyle'];

            if (cfSrc) {
              image.src = cfSrc;
            }

            if (cfStyle) {
              image.style.cssText = cfStyle;
            } else {
              image.style.cssText = '';
            }
          }
          this.count++;
          if (this.count !== 10) {
            setTimeout(doCheck, Math.exp(this.count));
          }
        };
        doCheck();
      })();
    },
  },
});
