<template>
  <div :class="['EventInfoCmp']">
    <div
      v-if="
        hasSlot($slots.when, $scopedSlots.when) ||
        hasSlot($slots.where, $scopedSlots.where)
      "
      class="info"
    >
      <div v-if="hasSlot($slots.when, $scopedSlots.when)" class="when-wrapper">
        <slot name="when" />
      </div>
      <div
        v-if="hasSlot($slots.where, $scopedSlots.where)"
        class="where-wrapper"
      >
        <slot name="where" />
      </div>
    </div>
    <div class="separator" />

    <div v-if="hasSlot($slots.cta, $scopedSlots.cta)" class="cta-wrapper">
      <slot name="cta" />
    </div>
  </div>
</template>

<script>
import { hasSlot } from '@/helpers/cms-support';

export default {
  name: 'EventInfoCmp',
  setup() {
    return { hasSlot };
  },
};
</script>

<style lang="scss" scoped>
.EventInfoCmp {
  display: flex;
  flex-direction: column;

  .info {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    .when-wrapper {
      @include media-breakpoint-up(md) {
        padding-right: 24px;
      }
    }

    .when-wrapper,
    .where-wrapper {
      width: 100%;
      margin-bottom: 40px;

      @include media-breakpoint-up(md) {
        width: 50%;
      }

      ::v-deep {
        dd {
          margin-top: 24px;
          @include responsive-typography-properties(
            $font-primary,
            a-paragraph-2
          );

          &:first-child {
            @include media-breakpoint-up(md) {
              margin-top: 40px;
            }
          }
        }

        dt {
          color: $color-blue;
          @include responsive-typography-properties(
            $font-primary,
            a-paragraph-1
          );
        }

        dd,
        dt {
          display: flex;
        }

        svg,
        span {
          display: inline-block;
        }

        svg {
          width: 24px;
          height: 24px;
          margin-top: 1.5px;
          color: $color-blue;
          flex-shrink: 0;
        }

        span {
          margin: 0 0 0 16px;
        }
      }
    }
  }

  .separator {
    width: 100%;
    height: 0;
    border-bottom: 1px solid $color-grey-600-75;
  }

  .cta-wrapper {
    margin-top: 40px;
  }
}
</style>
