import * as types from '../mutation_types';

export default {
  [types.SET_CONFIG_PROPERTY](state, payload) {
    state[payload.key] = payload.value;
  },
  [types.SET_CONFIG_PROPERTY_WITH_TARGET](state, payload) {
    state[payload.target][payload.key] = payload.value;
  },
  [types.SET_STORE_SETUP_COMPLETED](state, value) {
    state.storeSetupCompleted = value;
  },
};
