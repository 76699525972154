<template>
  <picture class="PictureCmp">
    <source
      v-if="dataMobileImage"
      :media="`(max-width: ${gridBreakpoints[dataSwitchBreakpoint]} - 1px)`"
      :srcset="dataMobileImage"
    />
    <source
      v-if="dataDesktopImage"
      :media="`(min-width: ${gridBreakpoints[dataSwitchBreakpoint]})`"
      :srcset="dataDesktopImage"
    />
    <slot />
  </picture>
</template>

<script>
import { gridBreakpoints } from '@/helpers/breakpoints.js';

export default {
  name: 'PictureCmp',
  props: {
    dataDesktopImage: {
      type: String,
      default: null,
    },
    dataMobileImage: {
      type: String,
      default: null,
    },
    dataSwitchBreakpoint: {
      type: String,
      default: 'md',
    },
  },
  setup() {
    return { gridBreakpoints };
  },
};
</script>
