<template>
  <div
    v-if="active"
    :class="[
      'GigyaScreen',
      {
        loading: !loaded,
      },
    ]"
  >
    <div v-if="loaded">
      <title-format-cmp v-if="screenHasTitleFormat">
        <title-text-cmp>
          <template v-if="hasTitle" slot="title">
            <component
              :is="title.tag"
              class="n-heading-2"
              v-html="title.content"
            ></component>
          </template>
          <template v-if="hasText" slot="text">
            <component
              :is="text.tag"
              class="a-paragraph-2"
              v-html="text.content"
            ></component>
          </template>
        </title-text-cmp>
      </title-format-cmp>
      <div
        v-if="dataStartScreen === 'gigya-update-profile-screen'"
        :class="[
          {
            'pt-m': screenHasTitleFormat,
          },
        ]"
      >
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div
                :class="[
                  'tabs-btns',
                  {
                    'd-flex': $viewportWidth >= 768,
                    'justify-content-center': $viewportWidth >= 768,
                  },
                ]"
              >
                <div>
                  <cta-button-cmp-extended
                    :class="[
                      'btn-block',
                      {
                        'btn-primary':
                          currentScreen === 'gigya-update-profile-screen',
                        'btn-secondary':
                          currentScreen !== 'gigya-update-profile-screen',
                      },
                    ]"
                    @click.prevent="
                      currentScreen !== 'gigya-update-profile-screen'
                        ? switchScreen('gigya-update-profile-screen')
                        : null
                    "
                  >
                    {{ edit_profile_label }}
                  </cta-button-cmp-extended>
                </div>
                <div :class="[{ 'pt-m': $viewportWidth < 768 }]">
                  <cta-button-cmp-extended
                    :class="[
                      'btn-block',
                      {
                        'btn-primary': currentScreen === 'gigya-privacy-screen',
                        'btn-secondary':
                          currentScreen !== 'gigya-privacy-screen',
                      },
                    ]"
                    @click.prevent="
                      currentScreen !== 'gigya-privacy-screen'
                        ? switchScreen('gigya-privacy-screen')
                        : null
                    "
                  >
                    {{ edit_privacy_label }}
                  </cta-button-cmp-extended>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-xl-8">
            <div
              :id="relatedId"
              :class="[
                {
                  'pt-m':
                    dataStartScreen === 'gigya-update-profile-screen' ||
                    screenHasTitleFormat,
                },
              ]"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  debug,
  qsObj,
  colorLog,
  brain,
  zzz,
  prohibited,
  ok,
  rightArrow,
  info,
  wait,
} from '@/helpers/utils';
import { mapState } from 'vuex';
import { castPropToType } from '@/helpers/cms-support';

export default {
  name: 'GigyaScreen',
  props: {
    dataScreenSet: {
      type: String,
      required: true,
      validator: function (value) {
        // The value must match one of these strings
        return (
          [
            'Barilla-RegistrationLogin',
            'Barilla-ProfileUpdate',
            'BarillaFP-PasswordlessLogin',
            'BarillaFP-ProfileUpdate',
          ].indexOf(value) !== -1
        );
      },
    },
    dataStartScreen: {
      type: String,
      required: true,
      validator: function (value) {
        // The value must match one of these strings
        return (
          [
            'gigya-login-screen',
            'gigya-register-screen',
            'gigya-reset-password-screen',
            'gigya-update-profile-screen',
            'gigya-privacy-screen',
            'gigya-passwordless-login-screen',
            'gigya-privacy-screen',
            'gigya-cancellation-screen',
            'gigya-change-email-screen',
          ].indexOf(value) !== -1
        );
      },
    },
    dataGoTo: {
      type: String,
      default: null,
    },
    dataAdditionalContext: {
      type: [String, Object],
      required: false,
      default: () => ({}),
    },
  },
  setup() {
    return { castPropToType };
  },
  data() {
    return {
      relatedId: 'GigyaScreenContent',
      redirect: null,
      screenLoaded: false,
      globalContext: {
        noovleRaasGlobalCH: 'CH_BFP',
      },
    };
  },
  computed: {
    ...mapState('gigya', [
      'active',
      'loaded',
      'user',
      'home_page_url',
      'screensData',
      'currentScreen',
      'edit_profile_label',
      'edit_privacy_label',
    ]),
    hasTitle() {
      return Object.keys(this.title).length;
    },
    hasText() {
      return Object.keys(this.text).length;
    },
    currentScreenData() {
      return this.screensData.find((d) => d.gigya_task === this.currentScreen);
    },
    title() {
      if (this.currentScreenData) {
        return {
          tag: 'h3',
          content: this.currentScreenData.title,
        };
      } else {
        return {};
      }
    },
    text() {
      if (this.currentScreenData) {
        return {
          tag: 'p',
          content: this.currentScreenData.description,
        };
      } else {
        return {};
      }
    },
    screenHasTitleFormat() {
      return this.hasTitle || this.hasText;
    },
    // ...mapState('contestPlantATree', ['reg-source']),
    // ...mapState('pm9LeisureAndFun', ['reg-source-pm9-leisure-and-fun']),
    // ...mapState('pm56BrunchInNature', ['reg-source-pm5-6-brunch-in-nature']),
    // ...mapState('pm56SandwichSurf', ['reg-source-pm5-6-sandwich-surf']),
    // ...mapState('pm9SaveTheInsects', ['reg-source-pm9-save-the-insects']),
    additionalContext() {
      return this.castPropToType(this.dataAdditionalContext, 'object');
    },
    context() {
      const ret = {
        ...this.additionalContext,
        ...this.globalContext,
      };
      // we keep this for retrocompatibility with plant a tree
      if ('isContext' in qsObj && qsObj.isContext === 'true') {
        ret.noovleRaasGlobalRegSource = this['reg-source'];
      }
      // this will be the new implementation for all the contests
      /**
       * the naming convention for contestName is
       * pm(promotion module)9(module number) - leisure-and-fun (slugified name) => exemple : pm9-leisure-and-fun
       */

      if ('contestName' in qsObj) {
        debug &&
          colorLog({
            icon: ok,
            label: 'cdc',
            message: `Contest name ${qsObj.contestName}`,
            themeName: 'blue',
          });
        switch (qsObj.contestName) {
          // case 'pm9-leisure-and-fun':
          //   ret.noovleRaasGlobalRegSource =
          //     this['reg-source-pm9-leisure-and-fun'];
          //   break;
          // case 'pm5-6-brunch-in-nature':
          //   ret.noovleRaasGlobalRegSource =
          //     this['reg-source-pm5-6-brunch-in-nature'];
          //   break;
          // case 'pm5-6-sandwich-summer-campaign':
          //   ret.noovleRaasGlobalRegSource =
          //     this['reg-source-pm5-6-sandwich-summer-campaign'];
          //   break;
          // case 'pm5-6-sandwich-surf':
          //   ret.noovleRaasGlobalRegSource =
          //     this['reg-source-pm5-6-sandwich-surf'];
          //   break;
          // case 'pm9-save-the-insects':
          //   ret.noovleRaasGlobalRegSource =
          //     this['reg-source-pm9-save-the-insects'];
          //   break;
          default:
            ret.noovleRaasGlobalRegSource = '';
        }
      }
      debug &&
        colorLog({
          icon: brain,
          label: 'cdc',
          message: `Regsource: ${ret.noovleRaasGlobalRegSource}`,
          themeName: 'blue',
        });
      return ret;
    },
  },
  watch: {
    loaded: {
      handler(val) {
        // eslint-disable-next-line
        if (val) {
          debug &&
            colorLog({
              icon: ok,
              label: 'cdc',
              message: `Screen component cdc ready`,
              themeName: 'blue',
            });
        } else {
          debug &&
            colorLog({
              icon: wait,
              label: 'cdc',
              message: `Screen component waiting for cdc`,
              themeName: 'blue',
            });
        }

        if (val && !this.screenLoaded) {
          if (
            this.dataScreenSet === 'Barilla-RegistrationLogin' ||
            this.dataScreenSet === 'BarillaFP-PasswordlessLogin'
          ) {
            if (
              this.dataStartScreen === 'gigya-passwordless-login-screen' ||
              this.dataStartScreen === 'gigya-login-screen' ||
              this.dataStartScreen === 'gigya-register-screen'
            ) {
              if (this.user) {
                // eslint-disable-next-line
                debug && console.log('this.user', this.user);
                // eslint-disable-next-line
                debug && console.log('this.home_page_url', this.home_page_url);
                window.location.href = this.redirect
                  ? this.redirect
                  : this.dataGoTo
                  ? this.dataGoTo
                  : this.home_page_url;
              }
            }
          } else if (
            this.dataScreenSet === 'Barilla-ProfileUpdate' ||
            this.dataScreenSet === 'BarillaFP-ProfileUpdate'
          ) {
            if (!this.user && !window.CMS) {
              debug &&
                colorLog({
                  icon: brain,
                  label: 'cdc',
                  message: `Profile page; No user; No edit mode; Do redirect`,
                  themeName: 'blue',
                });

              window.location.href = this.redirect
                ? this.redirect
                : this.dataGoTo
                ? this.dataGoTo
                : this.home_page_url;
            } else {
              debug &&
                colorLog({
                  icon: brain,
                  label: 'cdc',
                  message: `Profile page; No user; CMS EDIT MODE; Skip redirect`,
                  themeName: 'blue',
                });
            }
          }

          window.addEventListener('customonAfterScreenLoad', () => {
            this.screenLoaded = true;
          });

          debug &&
            colorLog({
              icon: brain,
              label: 'cdc',
              message: `Ch: ${this.context.noovleRaasGlobalCH}`,
              themeName: 'blue',
            });

          this.$store.dispatch(
            'gigya/setGigyaCurrentScreen',
            this.dataStartScreen
          );

          window.gigya.accounts.showScreenSet({
            screenSet: this.dataScreenSet,
            startScreen: this.dataStartScreen,
            containerID: this.relatedId,
            context: this.context,
          });
        }
      },
      immediate: true,
    },
  },
  created() {
    debug &&
      colorLog({
        icon: wait,
        label: 'cdc',
        message: `Screen component created`,
        themeName: 'blue',
      });
  },
  async mounted() {
    debug &&
      colorLog({
        icon: wait,
        label: 'cdc',
        message: `Screen component mounted`,
        themeName: 'blue',
      });
    if (this.dataGoTo) {
      await this.$store.dispatch('gigya/setGigyaGoTo', this.dataGoTo);
    }
    //dispatch

    this.redirect = 'goTo' in qsObj && qsObj.goTo ? qsObj.goTo : null;
  },
  methods: {
    switchScreen(screenToShow) {
      if (screenToShow === this.currentScreen) {
        return false;
      }

      this.$store.dispatch('gigya/setGigyaCurrentScreen', screenToShow);

      window.gigya.accounts.showScreenSet({
        screenSet: this.dataScreenSet,
        startScreen: screenToShow,
        containerID: this.relatedId,
        context: this.context,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs-btns {
  > div + div {
    @include media-breakpoint-up(md) {
      margin-left: 30px;
    }
  }
}
.GigyaScreen {
  &.loading {
    &:before {
      content: '';
      display: block;
      background: rgba(0, 0, 0, 0.2);
      top: 0;
      left: 0;
      position: fixed;
      width: 100vw !important;
      height: 100vh !important;
    }
    &:after {
      content: '';
      display: block;
      height: 48px !important;
      width: 48px !important;
      color: rgba(255, 255, 255, 1);
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: block;
      border: 5px solid;
      border-radius: 50%;
      border-right-color: rgba(0, 0, 0, 0.8);
      animation: rotate 1s linear infinite;
      min-width: 0;
      min-height: 0;
      background: none;
    }
  }
  ::v-deep #GigyaScreenContent {
    * {
      box-sizing: border-box;
    }

    .gigya-screen-dimmer {
      background: rgba(0, 0, 0, 0.2);
      top: 0;
      left: 0;
      position: fixed;
      width: 100vw !important;
      height: 100vh !important;
    }

    .gigya-screen-loader {
      height: 48px !important;
      width: 48px !important;
      color: rgba(255, 255, 255, 1);
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: block;
      border: 5px solid;
      border-radius: 50%;
      border-right-color: rgba(0, 0, 0, 0.8);
      animation: rotate 1s linear infinite;
      min-width: 0;
      min-height: 0;
      background: none;
    }

    #GigyaScreenContent_content_caption {
      display: none;
    }

    .gigya-screen-content {
      .gigya-screen {
        width: auto;
        padding: 0;

        * {
          @include responsive-typography-properties($font-primary, a-body);
        }

        &#gigya-complete-registration-screen div[class='gigya-layout-cell'] {
          width: 50%;
        }

        &#gigya-complete-registration-screen .cityInput {
          width: 35%;
        }

        &#gigya-complete-registration-screen .addressInput {
          width: 50%;
        }

        &#gigya-complete-registration-screen .houseNumberInput {
          width: 15%;
        }

        form {
          .gigya-layout-row {
            @include responsive-properties(
              ('margin-left', 'margin-right'),
              $grid-gutter-width-resp,
              $negate: true,
              $halve: true,
              $flags: !important,
              $fallback: $grid-gutter-width
            );

            & > * {
              @include responsive-properties(
                ('padding-left', 'padding-right'),
                $grid-gutter-width-resp,
                $halve: true,
                $flags: !important,
                $fallback: $grid-gutter-width
              );
              margin-left: 0;
              margin-right: 0;

              &.gigya-layout-cell {
                width: 50%;
              }

              @include media-breakpoint-down(lg) {
                width: 100% !important;
              }
            }
          }

          .gigya-composite-control {
            &.gigya-composite-control-header {
              display: none;
            }

            &.gigya-composite-control-textbox,
            &.gigya-composite-control-dropdown {
              margin: 0;
              padding: 0 0 40px 0;

              .gigya-label {
                padding: 0 0 8px 0;

                .gigya-label-text {
                  @include responsive-typography-properties(
                    $font-primary,
                    a-support
                  );
                  color: $color-grey-800;
                  white-space: nowrap;
                }
              }

              .gigya-error {
                border-color: $color-informative-red !important;
              }
            }

            &.gigya-composite-control-textbox {
              .gigya-input-text {
                height: auto;
                margin: 0;
                padding: 12px 22px;
                border: 2px solid $color-grey-600-50;
                color: $color-black;
                border-radius: 4px;

                &::placeholder {
                  color: $color-grey-800-50;
                }

                &:not(:disabled):hover {
                  border-color: $color-grey-800-50;

                  &::placeholder {
                    color: $color-grey-900-50;
                  }
                }

                &:not(:disabled):focus {
                  border-color: $color-blue;
                  box-shadow: none;
                }
              }
            }

            &.gigya-composite-control-dropdown {
              position: relative;

              &::after {
                content: '';
                position: absolute;
                top: 42px;
                right: 32px;
                width: 14px;
                height: 14px;
                border-right: 2px solid $color-grey-800-50;
                border-bottom: 2px solid $color-grey-800-50;
                transform: rotate(45deg);

                @include media-breakpoint-up(md) {
                  top: 46px;
                }
              }

              select {
                height: auto;
                margin: 0;
                padding: 12px 58px 12px 22px;
                border: 2px solid $color-grey-600-50;
                color: $color-black;
                border-radius: 40px;

                &:not(:disabled):hover {
                  border-color: $color-grey-800-50;

                  &::placeholder {
                    color: $color-grey-900-50;
                  }
                }

                &:not(:disabled):focus {
                  border: 2px solid $color-blue !important;
                  box-shadow: none !important;
                  @include outline-mixin();

                  @include media-breakpoint-down(md) {
                    outline: none !important;
                  }
                }
              }
            }

            &.gigya-composite-control-link {
              display: inline-block;
              width: auto !important;
              margin: 0;
              @include responsive-properties(
                'margin-top',
                map-get($spacings, l)
              );
              @include responsive-typography-properties(
                $font-primary,
                a-paragraph-2
              );
              color: $color-blue;

              &:not(:disabled):focus {
                border: 0 !important;
                box-shadow: none !important;
                @include outline-mixin();

                @include media-breakpoint-down(md) {
                  outline: none !important;
                }
              }
            }

            &.gigya-composite-control-multi-choice {
              padding: 0 0 40px 0;

              .gigya-label {
                padding: 0 0 8px 0;

                .gigya-label-text {
                  color: $color-black;

                  * {
                    color: $color-black;
                  }
                }
              }

              .gigya-multi-choice-item {
                position: relative;
                padding: 16px 0 0;

                .gigya-label {
                  padding: 0;
                }

                .gigya-input-radio {
                  position: absolute;
                  visibility: hidden;
                  top: 0;
                  margin: 0;
                  opacity: 0;
                  z-index: map_get($z, under);

                  &:checked {
                    ~ label {
                      &::before {
                        border-color: $color-blue;
                      }

                      &::after {
                        display: block;
                      }
                    }
                  }
                }

                label {
                  position: relative;
                  display: flex;
                  align-items: center;
                  flex-wrap: wrap;
                  width: auto;
                  min-height: 100%;
                  margin: 0;
                  padding: 0 0 0 36px;
                  @include responsive-typography-properties(
                    $font-primary,
                    a-support
                  );
                  color: $color-blue;

                  &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 20px;
                    height: 20px;
                    border: 1px solid $color-grey-600;
                    background-color: #ffffff;
                    border-radius: 50%;
                    transform: translate(0, -50%);
                  }

                  &::after {
                    display: none;
                    content: '';
                    position: absolute;
                    left: 6px;
                    top: 50%;
                    width: 8px;
                    height: 8px;
                    background-color: $color-blue;
                    border-radius: 50%;
                    transform: translate(0, -50%);
                  }

                  &:hover {
                    &::before {
                      border-color: $color-blue;
                      background-color: $color-grey-200-50;
                    }
                  }
                }
              }
            }

            &.gigya-composite-control-submit {
              text-align: center;
              padding: 0;

              .gigya-input-submit {
                @include responsive-typography-properties($font-primary, a-cta);
                width: auto;
                height: auto;
                background-color: $color-blue;
                padding: 15px 62px 14px;
                border: 2px solid $color-blue;
                border-radius: 4px;

                &:not(:disabled):hover {
                  background-color: $color-light-blue;
                }
              }
            }

            .gigya-error-msg-active {
              min-height: auto;
              margin: 8px 0 0;
              padding: 0;
              text-align: left;
              @include responsive-typography-properties(
                $font-primary,
                a-caption
              );
              color: $color-informative-red;
            }

            &.gigya-composite-control-form-error {
              padding-bottom: 0 !important;

              .gigya-error-msg-active {
                text-align: center;
              }
            }

            .gigya-required-display {
              line-height: 1;
            }

            &.gigya-composite-control-label.noovle-msg {
              @include responsive-typography-properties(
                $font-primary,
                a-paragraph-2
              );
              color: $color-informative-green !important;
              padding-top: 0 !important;
              padding-bottom: 0 !important;
              border: 0 !important;
              border-radius: 0 !important;
              background: transparent !important;
            }
          }

          .gigya-composite-control-label {
            a {
              text-decoration: underline;
            }
          }

          &.gigya-email-code-auth-method-form {
            .gigya-composite-control {
              &.gigya-composite-control-label {
                display: none;
              }
            }

            .gigya-email-otp-links {
              @include responsive-properties(
                'padding-top',
                map-get($spacings, l)
              );

              .gigya-email-otp-links-left,
              .gigya-email-otp-links-right {
                text-align: center;

                * {
                  text-align: center;
                }
              }

              .gigya-email-otp-links-right {
                @include media-breakpoint-down(lg) {
                  margin: 40px 0 0;
                }
              }

              .gigya-composite-control-link {
                margin: 0;
              }
            }
          }

          &.gigya-profile-form {
            .gigya-composite-control {
              &.gigya-composite-control-label {
                padding: 0 0 40px 0;
                color: $color-black;

                * {
                  color: $color-black;
                }

                &.main-text {
                  display: none;
                }
              }

              &.gigya-composite-control-submit {
                padding: 40px 0 0;
              }

              &.linkDeleteAccount {
                margin-top: 40px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
