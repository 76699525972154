<template>
  <div
    :class="[
      'TabsCmp',
      {
        'with-tab-pic':
          dataTabs.find((e) => e.desktopImage || e.mobileImage) !== undefined,
      },
    ]"
  >
    <scroll-x-navbar-cmp
      v-if="dataTabs.length > 1 && showHeaders"
      :data-active-tab-id="'tab-' + activeTabId"
      @navigation="activeTabId = +$event.replace('tab-', '')"
    >
      <template>
        <li
          v-for="tab in dataTabs"
          :key="`tab-${tab.id}-${_uid}`"
          :class="['tab', { active: tab.id === activeTabId }]"
          @click="tab.id !== activeTabId ? onTabChange(tab.id) : null"
        >
          <figure v-if="tab.desktopImage || tab.mobileImage">
            <image-cmp
              width="135"
              height="144"
              :data-desktop-image="tab.desktopImage"
              :data-mobile-image="tab.mobileImage"
              :data-image-alt="
                tab.desktopImageAlt
                  ? tab.desktopImageAlt
                  : tab.mobileImageAlt
                  ? tab.mobileImageAlt
                  : ''
              "
            />
            <figcaption class="tab-label a-paragraph-3">
              {{ tab.label }}
            </figcaption>
          </figure>
          <span v-else class="tab-label a-paragraph-3">{{ tab.label }}</span>
        </li>
      </template>
    </scroll-x-navbar-cmp>
    <div v-if="!dataNoContent" class="tabs-content">
      <transition name="tab-content">
        <div
          v-for="tab in activeTab"
          :key="`tab-${tab.id}-content-${_uid}`"
          class="tab-content"
        >
          <slot :name="`tab-${tab.id}-content`" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { ScrollXNavbarCmp } from '@frankhoodbs/lib';
import ImageCmp from './image-cmp';
import { castPropToType } from '@/helpers/cms-support';

export default {
  name: 'TabsCmp',
  components: {
    ImageCmp,
    ScrollXNavbarCmp,
  },
  props: {
    dataTabs: {
      type: Array,
      required: true,
    },
    dataActiveTabId: {
      type: String,
      default: '1',
    },
    dataNoContent: {
      type: Boolean,
      default: false,
    },
    dataRouting: {
      type: Boolean,
      default: false,
    },
    dataShowHeaders: {
      type: [Boolean, String],
      default: true,
    },
  },
  setup() {
    return {
      castPropToType,
    };
  },
  data() {
    return {
      activeTabId: null,
    };
  },
  computed: {
    showHeaders() {
      return typeof this.dataShowHeaders === 'boolean'
        ? this.dataShowHeaders
        : this.castPropToType(this.dataShowHeaders, 'boolean');
    },
    activeTab() {
      return this.dataTabs.filter((e) => e.id === this.activeTabId);
    },
  },
  watch: {
    dataActiveTabId: {
      handler(val) {
        if (val) {
          this.activeTabId = this.dataActiveTabId;
        }
      },
      immediate: true,
    },
  },
  methods: {
    onTabChange(tabId) {
      //console.log(this.showHeaders);
      if (this.dataNoContent) {
        this.$emit('update:data-active-tab-id', tabId);
      } else if (this.dataRouting) {
        this.$emit('update', tabId);
      } else {
        this.activeTabId = tabId;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.TabsCmp {
  &.with-tab-pic {
    margin-top: -136px;

    @include media-breakpoint-down(md) {
      padding-top: 64px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: -176px;
    }
  }

  ::v-deep .ScrollXNavbarCmp {
    ul {
      align-items: normal;

      li.tab {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex: 1 0 0;
        width: 100%;
        min-width: 130px !important;
        height: auto;
        padding: 0 0 5px 0;
        margin-bottom: 1px;
        text-align: center;
        white-space: normal;
        z-index: map-get($z, base);

        .tab-label {
          color: $color-grey-600;
          padding: 0 1px;
          transition: color 0.3s;
        }

        &.active {
          border-bottom: 1px solid $color-blue;
          padding: 0 0 4px 0;
          cursor: default;

          .tab-label {
            color: $color-black;
          }
        }

        &:hover:not(.active) {
          .tab-label {
            color: $color-light-blue;
          }
        }

        figure {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          height: 100%;

          img {
            width: auto;
            max-width: 100%;
            height: 120px;
            margin-bottom: 16px;
            opacity: 0;
            transform: translateY(10px);
            transition: opacity 0.6s, transform 1s;

            @include media-breakpoint-up(md) {
              height: 144px;
            }

            @include media-breakpoint-up(lg) {
              margin-bottom: 32px;
            }
          }
        }

        &.active,
        &:hover:not(.active) {
          figure {
            img {
              opacity: 1;
              transform: translateY(0);
            }
          }
        }
      }

      &.grab,
      &.grabbing {
        li.tab {
          &.active {
            cursor: inherit;
          }
        }
      }
    }

    &::after {
      right: 0;
      left: 0;
      border-bottom: 1px solid $color-grey-400;
    }
  }

  .ScrollXNavbarCmp + .tabs-content {
    padding-top: 40px;

    @include media-breakpoint-up(md) {
      padding-top: 64px;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 80px;
    }

    .tab-content {
      &.tab-content-enter-active {
        transition: opacity 0.6s ease-in;
        transition-delay: 0.3s;
      }

      &.tab-content-leave-active {
        transition: opacity 0.3s ease-out;
      }

      &.tab-content-enter,
      &.tab-content-leave-to {
        opacity: 0;
      }
    }
  }

  .tabs-content {
    display: flex;
    justify-content: flex-end; /* fix jerky movements of the contents */
    width: 100%;
    overflow: hidden; /* fix jerky movements of the contents */

    .tab-content {
      width: 100%;
      flex: 1 0 100%;
    }
  }
}
</style>
