<template functional>
  <div
    :ref="data.ref"
    :class="['MediaDownloadCmp', data.class, data.staticClass]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <div :class="[...props.dataBootstrapContainerClasses]">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <div class="wrapper">
            <div class="row justify-content-center">
              <div class="col-12 col-custom-md-8-10">
                <slot />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MediaDownloadCmp',
  functional: true,
  props: {
    dataBootstrapContainerClasses: {
      type: Array,
      default: () => ['container'],
    },
  },
};
</script>

<style lang="scss" scoped>
.MediaDownloadCmp {
  .wrapper {
    background-color: $color-blue-10;
    border-radius: 8px;
    padding: 16px;

    @include media-breakpoint-up(md) {
      padding: 56px 0;
    }

    @include media-breakpoint-up(lg) {
      padding: 80px 0;
    }

    ::v-deep .TitleTextCmp {
      --title-text-text-align: left;
      --title-text-overtitle-wrapper-padding: 0 0 8px;
      @include override-responsive-typography-properties(
        $font-primary,
        a-paragraph-3,
        --title-text-overtitle-wrapper
      );
      @include override-responsive-typography-properties(
        $font-primary,
        a-paragraph-1,
        --title-text-title-wrapper
      );
      @include override-responsive-typography-properties(
        $font-primary,
        a-paragraph-3,
        --title-text-text-wrapper
      );
      --title-text-text-wrapper-color: #{$color-grey-900-75};
    }
  }
}
</style>
