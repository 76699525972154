<template>
  <slider-cmp class="SliderCmpExtended" v-bind="$attrs" v-on="$listeners">
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </slider-cmp>
</template>

<script>
import { SliderCmp } from '@frankhoodbs/lib';

export default {
  name: 'SliderCmpExtended',
  components: { SliderCmp },
  mounted() {
    //console.log(this.dataDisabledFrom);
  },
};
</script>

<style lang="scss" scoped>
.SliderCmpExtended {
  --slider-outline: calc(#{$size-outline} + #{$size-outline-offset});
  @include responsive-properties(
    (
      --slider-slide-gutter-x,
      --slider-slide-gutter-y,
      --slider-figcaption-padding
    ),
    $grid-gutter-width-resp,
    $fallback: $grid-gutter-width
  );
  --slider-custom-swipe-icon-width: 64px;
  --slider-custom-swipe-icon-height: 64px;
  --slider-custom-swipe-icon-color: #{$color-blue};
  --slider-custom-swipe-icon-pressed-color: #{$color-dark-blue};

  ::v-deep .slider-content:not(.disabled) {
    padding-bottom: 67px;

    @include media-breakpoint-up(md) {
      padding-bottom: 82px;
    }

    .swiper-container {
      .swiper-pagination {
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px 0 15px;
        pointer-events: none;

        @include media-breakpoint-up(md) {
          padding: 55px 0 15px;
        }

        .swiper-pagination-bullet {
          position: relative;
          width: 40px;
          height: 12px;
          border-radius: 0;
          background: transparent;
          opacity: 1;
          pointer-events: auto;

          &::before {
            content: '';
            position: absolute;
            width: 100%;
            top: 5px;
            left: 0;
            height: 2px;
            border-radius: 30px;
            background: $color-grey-600-50;
          }

          &.swiper-pagination-bullet-active {
            &::before {
              background: $color-blue-75;
            }
          }
        }
      }
    }
  }
}
</style>
